<form [formGroup]="model" novalidate>
    <div class="row" style="padding-bottom: 25px; margin-top: -10px">
        <div class="col-12 col-sm-4 col-md-3 input-space">
            <label class="input-label" for="ncm">Codigo NCM</label>
            <input matInput class="input-ncm" id="ncm" formControlName="ncm" type="text" (ngModelChange)="getNcmValues($event)"
                [matAutocomplete]="auto" placeholder="Informe o código NCM" />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of _listNcmSearchStruct" (click)="selectNcmValue(item)">
                    <span style="font-size: 14px"><b>{{ item.codigo }}</b></span><br /> <span style="font-size: 12px">{{ item.descricao | slice: 0:120 }} </span>
                </mat-option>
            </mat-autocomplete>
            <span *ngIf="model.get('ncm')?.touched && model.get('ncm')?.invalid">Informe o código NCM do produto.</span>
        </div>
        <div class="col-12 col-sm-4 col-md-3 input-space">
            <label class="input-label" for="cest">Cód. CEST</label>
            <input matInput class="input-ncm" id="cest" formControlName="cest" type="text" (ngModelChange)="getCestValues($event)"
                [matAutocomplete]="cestAuto" placeholder="Informe o código CEST" />
            <mat-autocomplete #cestAuto="matAutocomplete">
                <mat-option *ngFor="let cestItem of _listCestSearchStruct" [value]="cestItem.codigo" (onSelectionChange)="selectCestValue($event)">
                    <span style="font-size: 14px"><b>{{ cestItem.codigo }}</b></span><br /> <span style="font-size: 12px">{{ cestItem.descricao | slice: 0:120 }} </span>
                </mat-option>
            </mat-autocomplete>
        </div>
        <div class="col-12 col-sm-4 col-md-3 input-space">
              <label class="input-label" for="idPerfilFiscal">Perfil de imposto</label>
              <select class="input-ncm" formControlName="idPerfilFiscal" style="padding: 7px;">
                <option value="" disabled selected>Selecione o perfil</option>
                <option *ngFor="let perfil of listFiscalPerfilImpostoStruct" [value]="perfil.idPerfilImposto">
                  {{ perfil.nome }}
                </option>
              </select>
          </div>
        <div class="col-12 col-sm-4 col-md-3 input-space">
            <label class="input-label" for="cstOrigem">Origem tributária</label>
            <select class="input-ncm" formControlName="cstOrigem" style="padding: 7px;">
              <option value="" disabled selected>Selecione a origem</option>
              <option style="font-size:12px" *ngFor="let origem of listCstSituacao" [value]="origem.codigoOrigem">
                {{ origem.codigoOrigem }} | <br />{{origem.descricao | slice: 0:40 }}
              </option>
            </select>
        </div>
    </div>
</form>