import { PagamentoStruct } from "../../structs/financeiro/pagamento.struct";

export class ContasReceberRequest {
    public idContasReceber: number;
    public idBancoEmpresa: number;
    public idStatusConta: number;
    public idTipoRepeticao: number;
    public idTipoParcelamento: number;
    public valorConta: number;
    public descricao: string;
    public notaFiscal?: string;
    public centroDeCusto?: string;
    public dataEmissao: Date;
    public listPagamentoStruct: PagamentoStruct[] = [];
}
