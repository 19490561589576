import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Editor, NgxEditorModule, Toolbar, Validators } from 'ngx-editor';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { SectionProductImagesComponent } from './section-product-images/section-product-images.component';
import { EstoqueService } from '../../../shared/services/API/estoque/estoque.service';
import { ProdutoResponse } from '../../../shared/services/responses/estoque/produto.response';
import { ProdutoBaseRequest } from '../../../shared/services/requests/estoque/produto-base.request';
import { ProdutoBaseResponse } from '../../../shared/services/responses/estoque/produto-base.response';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CameraDialogComponent } from './camera-dialog/camera-dialog.component';
import { RoutesService } from '../../../routes.service';
import { FornecedorPessoaStruct } from '../../../shared/services/structs/pessoa/fornecedor-pessoa.struct';
import { Observable } from 'rxjs';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { AuthService } from '../../../shared/services/auth.service';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { SectionProductGridComponent } from './section-product-grid/section-product-grid.component';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { EstoqueFiscalComponent } from './estoque-fiscal/estoque-fiscal.component';

@Component({
  selector: 'app-estoque-novo',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule,
    MatSlideToggleModule,
    NgxEditorModule,
    MatSelectModule,
    SectionProductImagesComponent,
    MatAutocompleteModule,
    SubheaderComponent,
    ReactiveFormsModule,
    SectionProductGridComponent,
    EstoqueFiscalComponent,
    SkeletonComponent,
  ],
  templateUrl: './estoque-novo.component.html',
  styleUrl: './estoque-novo.component.css',
})
export class EstoqueNovoComponent implements OnInit {
  @ViewChild(SectionProductGridComponent) productGridComponent!: SectionProductGridComponent;
  @ViewChild(EstoqueFiscalComponent) estoqueFiscalComponent!: EstoqueFiscalComponent;
  searchControl: FormControl;
  filteredFornecedores: any[] = [];
  public userLoginResponse: UserLoginResponse;
  constructor(
    private router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private estoqueService: EstoqueService,
    public dialog: MatDialog,
    private routesService: RoutesService,
    private authService: AuthService
  ) {
    let idProdutoUrl: string = this.activatedRoute.snapshot.params['idProduto'];
    if (idProdutoUrl) {
      this._idProduto = Number(idProdutoUrl);
      this.loadProduct();
    } else {
      this._produtoResponse = new ProdutoResponse();
    }
  }

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  public isLoading: boolean = false;
  public model: FormGroup;
  public _idProduto: number;
  public _produtoResponse: ProdutoResponse;
  public listFornecedorPessoaStruct: FornecedorPessoaStruct[];
  public filteredOptions: Observable<FornecedorPessoaStruct[]>;
  public selectedOption: any;
  public profile: number = DefaultProfilesEnum.Estoque;
  public supplyProfile: number = DefaultProfilesEnum.Fornecedor;
  public color: string = DefaultColorEnum.Cor;
  public step: number = 1;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      productName: ['', [Validators.required]],
      productCode: [''],
      ean: [''],
      ncm: [''],
      htmlDescription: ['', [Validators.required]],
      isImmediateDelivery: [true],
      idCollection: [''],
      idCategory: [''],
      searchTerm: [null],
    });

    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    this.searchControl = new FormControl('');

    this.searchControl.valueChanges.subscribe((value) => {
      this.filterFornecedores(value);
    });

    this.editor = new Editor();

    let idProduto: number = this.activatedRoute.snapshot.params['idProduto'];
    
    if(this.activatedRoute.snapshot.params['navNext'])
      this.nextStep();

    if (idProduto) {
      this._idProduto = idProduto;
      this.loadProduct();
    }
  }

  loadProduct(idProduto?: number) {
    this.estoqueService.GetProduto(idProduto ? idProduto : this._idProduto).subscribe({
      next: (produtoResponse: ProdutoResponse) => {
        this.isLoading = false;
        if (produtoResponse.isError) {
          this.alertService.show(
            'Erro inesperado',
            produtoResponse.errorDescription,
            AlertType.error
          );
          return;
        }

        this._produtoResponse = produtoResponse;

        this.model.get('productName')?.setValue(produtoResponse.produtoStruct.produto.nome);
        this.model.get('ean')?.setValue(produtoResponse.produtoStruct.produto.ean);
        this.model.get('ncm')?.setValue(produtoResponse.produtoStruct.produto.ncm);
        this.model.get('productCode')?.setValue(produtoResponse.produtoStruct.produto.codProduto);
        this.model.get('htmlDescription')?.setValue(produtoResponse.produtoStruct.produto.descricao);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }


  saveProductBase() {
    let productBaseRequest: ProdutoBaseRequest = new ProdutoBaseRequest();

    if (this._idProduto) productBaseRequest.idProduto = this._idProduto;

    productBaseRequest.nome = this.model.get('productName')?.value;
    productBaseRequest.codProduto = this.model.get('productCode')?.value;
    
    if (this.estoqueFiscalComponent) {
        productBaseRequest.ncm = this.estoqueFiscalComponent.model.get('ncm')?.value;
        productBaseRequest.cest = this.estoqueFiscalComponent.model.get('cest')?.value;
        productBaseRequest.idPerfilFiscal = this.estoqueFiscalComponent.model.get('idPerfilFiscal')?.value;
        productBaseRequest.cstOrigem = this.estoqueFiscalComponent.model.get('cstOrigem')?.value;
    }

    productBaseRequest.ean = this.model.get('ean')?.value;
    productBaseRequest.codProduto = this.model.get('productCode')?.value;
    productBaseRequest.descricao = this.model.get('htmlDescription')?.value;

    this.isLoading = true;

    this.estoqueService.CreateProdutoBase(productBaseRequest).subscribe({
      next: (productBaseResponse: ProdutoBaseResponse) => {
        this.isLoading = false;
        if (productBaseResponse.isError) {
          this.alertService.show(
            'Erro inesperado',
            productBaseResponse.errorDescription,
            AlertType.error
          );
          return;
        }

        this.alertService.show(
          'Sucesso',
            this._idProduto ? 'Dados base do produto foi alterado com sucesso' : 'Produto criado com sucesso',
          AlertType.success
        );

        this._idProduto = productBaseResponse.idProduto
          ? productBaseResponse.idProduto
          : 0;

        this.router.navigate([
          this.routesService.INVENTORY_UPDATE(this._idProduto.toString()),
          { navNext: true },
        ]);

        if (this.productGridComponent) {
          this.productGridComponent.submit(this._idProduto);
        } 
        
        this.nextStep();
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  openCamera() {
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      width: '400px',
      height: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.model.get('ean')?.setValue(result);
      }
    });
  }

  filterFornecedores(searchTerm: string): void {
    if (!searchTerm) {
      this.filteredFornecedores = this.listFornecedorPessoaStruct;
    } else {
      this.filteredFornecedores = this.listFornecedorPessoaStruct.filter(
        (fornecedor) =>
          fornecedor.pessoa.nome
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    }
  }

  onSelectChange(event: any) {
    this.selectedOption = this.listFornecedorPessoaStruct.find(
      (fornecedor) => fornecedor.fornecedor.idFornecedor === event.value
    );
  }

  nextStep() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      return;
    }
    this.loadProduct();
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= 2) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_INVENTORY]);
  }

}
