import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { PerfilResponse } from '../../responses/pessoa/perfil.response';
import { ClienteSearchRequest } from '../../requests/pessoa/cliente-search.request';
import { ClienteSearchResponse } from '../../responses/pessoa/cliente-search.response';
import { ClienteRequest } from '../../requests/pessoa/cliente.request';
import { ReturnStruct } from '../../structs/return.struct';
import { ClienteSearchTableResponse } from '../../responses/pessoa/cliente-search-table.response';
import { FornecedorSearchRequest } from '../../requests/pessoa/fornecedor-search.request';
import { FornecedorSearchTableResponse } from '../../responses/pessoa/fornecedor-search-table.response';
import { FornecedorRequest } from '../../requests/pessoa/fornecedor.request';
import { ClienteResponse } from '../../responses/pessoa/cliente.response';

@Injectable({
  providedIn: 'root'
})
export class PessoaService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public GetPerfil(): Observable<PerfilResponse> {
    return this.httpClient.get<PerfilResponse>(environment.urlApiPessoa + "Perfil/", this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public searchClient(clienteSearchRequest: ClienteSearchRequest): Observable<ClienteSearchResponse> {

    return this.httpClient.post<ClienteSearchResponse>(environment.urlApiPessoa + "ClienteSearch", clienteSearchRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public searchClientById(idCliente: number): Observable<ClienteResponse> {

    return this.httpClient.get<ClienteResponse>(environment.urlApiPessoa + "Cliente/idCliente/" + idCliente, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public searchClientTable(clienteSearchRequest: ClienteSearchRequest): Observable<ClienteSearchTableResponse> {

    return this.httpClient.post<ClienteSearchTableResponse>(environment.urlApiPessoa + "ClienteSearch/ShowTable", clienteSearchRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public removeClientById(idCliente: number): Observable<ReturnStruct> {

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPessoa + "Cliente/idCliente/" + idCliente, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public searchSupplierTable(fornecedorSearchRequest: FornecedorSearchRequest): Observable<FornecedorSearchTableResponse> {

    return this.httpClient.post<FornecedorSearchTableResponse>(environment.urlApiPessoa + "FornecedorSearch/ShowTable", fornecedorSearchRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public removeSupplierById(idFornecedor: number): Observable<ReturnStruct> {

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPessoa + "Fornecedor/idFornecedor/" + idFornecedor, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }


  public clientRegister(clienteRequest:ClienteRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiPessoa + "Cliente", clienteRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public clientUpdate(clienteRequest:ClienteRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiPessoa + "Cliente", clienteRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public supplyRegister(clienteRequest:FornecedorRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiPessoa + "Fornecedor", clienteRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public supplyUpdate(clienteRequest:FornecedorRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiPessoa + "Fornecedor", clienteRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
