import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ProdutoSearchResponse } from '../../responses/estoque/produto-search.response';
import { ProdutoSearchRequest } from '../../requests/estoque/produto-search.request';
import { ProdutoResponse } from '../../responses/estoque/produto.response';
import { ProdutoBaseRequest } from '../../requests/estoque/produto-base.request';
import { ProdutoBaseResponse } from '../../responses/estoque/produto-base.response';
import { ReturnStruct } from '../../structs/return.struct';
import { ProdutoImagemRequest } from '../../requests/estoque/produto-imagem.request';
import { ProdutoGradeRequest } from '../../requests/estoque/produto-grade.request';
import { NcmSearchResponse } from '../../responses/estoque/ncm-search.response';
import { EanSearchRequest } from '../../requests/estoque/ean-search.request';
import { EanSearchResponse } from '../../responses/estoque/ean-search.response';
import { MovimentacaoResponse } from '../../responses/estoque/movimentacao.response';
import { ProdutoSubGradeResponse } from '../../responses/estoque/produto-sub-grade.response';
import { CestSearchResponse } from '../../responses/estoque/cest-search.response';

@Injectable({
  providedIn: 'root'
})
export class EstoqueService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  public SearchProduto(request: ProdutoSearchRequest): Observable<ProdutoSearchResponse> {
    return this.httpClient.post<ProdutoSearchResponse>(environment.urlApiEstoque + "ProdutoSearch", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public SearchMovimentacao(): Observable<MovimentacaoResponse> {
    return this.httpClient.get<MovimentacaoResponse>(environment.urlApiEstoque + "Movimentacao", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public CreateProdutoBase(request: ProdutoBaseRequest): Observable<ProdutoBaseResponse> {
    return this.httpClient.post<ProdutoBaseResponse>(environment.urlApiEstoque + "Produto", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public UpdateProdutoBase(request: ProdutoBaseRequest): Observable<ProdutoBaseResponse> {
    return this.httpClient.put<ProdutoBaseResponse>(environment.urlApiEstoque + "Produto", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetProduto(idProduto: number): Observable<ProdutoResponse> {
    return this.httpClient.get<ProdutoResponse>(environment.urlApiEstoque + "Produto/idProduto/" + idProduto, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  public CreateProdutoImage(request: ProdutoImagemRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ProdutoBaseResponse>(environment.urlApiEstoque + "ProdutoImagem", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  public DeleteProdutoImage(idProdutoImagem: number): Observable<ReturnStruct> {
    return this.httpClient.delete<ReturnStruct>(environment.urlApiEstoque + "ProdutoImagem/idProdutoImagem/" + idProdutoImagem, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public DeleteProduto(idProduto: number): Observable<ReturnStruct> {
    return this.httpClient.delete<ReturnStruct>(environment.urlApiEstoque + "Produto/idProduto/" + idProduto, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public CreateGrade(request: ProdutoGradeRequest): Observable<ProdutoResponse> {
    return this.httpClient.post<ProdutoResponse>(environment.urlApiEstoque + "ProdutoGrade", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public SearchNcm(searchText: string): Observable<NcmSearchResponse> {
    return this.httpClient.get<NcmSearchResponse>(environment.urlApiEstoque + "NcmSearch/searchText/" + searchText, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public SearchCest(ncm: string, cest: string | null): Observable<CestSearchResponse> {
    return this.httpClient.get<CestSearchResponse>(environment.urlApiEstoque + "CestSearch/ncm/" + ncm + "/cest/" + cest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public SearchOnlyCest(cest: string | null): Observable<CestSearchResponse> {
    return this.httpClient.get<CestSearchResponse>(environment.urlApiEstoque + "CestSearch/cest/" + cest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public SearchEan(request: EanSearchRequest): Observable<EanSearchResponse> {
    return this.httpClient.post<EanSearchResponse>(environment.urlApiEstoque + "EanSearch/ReadImages/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetProduct(): Observable<ProdutoSubGradeResponse> {
  
      return this.httpClient.get<ProdutoSubGradeResponse>(environment.urlApiEstoque + "Produto/Lista/", this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
    }

}
