import { Routes } from '@angular/router';
import { AuthComponent } from './auth/pages/auth/auth.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth-guard.service';
import { PasswordChangeComponent } from './auth/pages/password-change/password-change.component';
import { PasswordRecoveryComponent } from './auth/pages/password-recovery/password-recovery.component';

export const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    pathMatch: 'full',
    data: { menu: false, search: false },
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: false },
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent,
    data: { menu: false, search: false },
  },
  {
    path: 'change-password/:token',
    component: PasswordChangeComponent,
    data: { menu: false, search: false },
  }
];