import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxEditorModule, Validators } from 'ngx-editor';
import { RoutesService } from '../../routes.service';
import { DefaultColorEnum } from '../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { AuthService } from '../../shared/services/auth.service';
import { MaskService } from '../../shared/services/mask.service';
import { UserLoginResponse } from '../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../shared/services/structs/return.struct';
import { PerfilImpostoRequest } from '../../shared/services/requests/fiscal/perfil-imposto.request';
import { PerfilImpostoResponse } from '../../shared/services/responses/fiscal/perfil-imposto.response';
import { FiscalService } from '../../shared/services/API/fiscal/fiscal.service';
import { ListaSelectResponse } from '../../shared/services/responses/fiscal/lista-select.response';
import { TipoConsumidor } from '../../shared/services/models/fiscal/tipo-consumidor.model';
import { Uf } from '../../shared/services/models/fiscal/uf.model';
import { SkeletonComponent } from '../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../shared/component/subheader/subheader.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NcmSearchStruct } from '../../shared/services/structs/estoque/ncm-search-struct';
import { EstoqueService } from '../../shared/services/API/estoque/estoque.service';
import { NcmSearchResponse } from '../../shared/services/responses/estoque/ncm-search.response';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SectionProductImagesComponent } from '../../estoque/pages/estoque-novo/section-product-images/section-product-images.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SectionProductGridComponent } from '../../estoque/pages/estoque-novo/section-product-grid/section-product-grid.component';
import { CestSearchResponse } from '../../shared/services/responses/estoque/cest-search.response';
import { CestSearchStruct } from '../../shared/services/structs/estoque/cest-search.struct';
import { Csosn } from '../../shared/services/models/fiscal/csosn.model';
import { CstSearchStruct } from '../../shared/services/structs/fiscal/cst-search.struct';
import { CstSearchResponse } from '../../shared/services/responses/fiscal/cst-search.response';
import { MensagemTributaria } from '../../shared/services/models/fiscal/mensagem-tributaria.model';
 

@Component({
  selector: 'app-perfil-imposto-novo',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule,
    MatSlideToggleModule,
    NgxEditorModule,
    MatSelectModule,
    SectionProductImagesComponent,
    MatAutocompleteModule,
    SubheaderComponent,
    ReactiveFormsModule,
    SectionProductGridComponent,
    SkeletonComponent,
    MatExpansionModule,
  ],
  templateUrl: './perfil-imposto-novo.component.html',
  styleUrl: './perfil-imposto-novo.component.css'
})
export class PerfilImpostoNovoComponent implements OnInit {
  searchControl: FormControl;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public fiscalService: FiscalService,
    private formBuilder: FormBuilder,
    private estoqueService: EstoqueService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) { }
  public model: FormGroup;
  public requestUpdate: PerfilImpostoRequest = new PerfilImpostoRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idPerfilImposto: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Fiscal';
  public iconProfile: string = 'request_page';
  public profile: number = DefaultProfilesEnum.Fiscal;
  public step: number = 1;
  private debounceTimeout: any;
  public isDropdownOpen: boolean = false;
  public _listCstSearchStruct: CstSearchStruct[] = [];
  public _listNcmSearchStruct: NcmSearchStruct[] = [];
  public _listCestSearchStruct: CestSearchStruct[] = [];
  public isDropdownConsumerOpen: boolean = false;
  public isDropdownOriginOpen: boolean = false;
  public isDropdownDestinyOpen: boolean = false;
  public listConsumerType: TipoConsumidor[] = [];
  public listUf: Uf[] = [];
  public listCsosn: Csosn[] = [];
  public listMensagemTributaria: MensagemTributaria[] = [];
  public selectedCsosn: any = "";
  public selectedUfs: any[][] = [];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idPerfilImposto: [''],
      nome: ['', Validators.required],
      listImpostoBaseStruct: this.formBuilder.array([]),
    });
    this.createImpostoBase()
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);
    
    let listselect = this.getListSelect();

    let idPerfilImposto: number = this.activatedRoute.snapshot.params['idPerfilImposto'];
    if (idPerfilImposto) {
      this._idPerfilImposto = Number(idPerfilImposto);
      this.model.disable();
      this.getTaxProfile();
    }
    this.addImposto();
    this.isLoading = false;
  }

  get imposto() {
  const array = this.model.get('listImpostoBaseStruct') as FormArray;
  return array;
  }

  getImpostoUf(index: number): FormArray {
    const array = this.imposto.at(index).get('listPerfilImpostoUfStruct') as FormArray;
    return array;
  }

  removeImposto(index: number) {
    this.imposto.removeAt(index);
  }

  removeImpostoUf(indexTax: number, indexTaxUf: number) {
    this.getImpostoUf(indexTax).removeAt(indexTaxUf);
  }


  addImpostoUf(index: number) {
    this.getImpostoUf(index).push(
      this.formBuilder.group({
        idImpostoUf: [],
        listIdUfDestino: [[]],
        icmsAliquota: [''],
        icmsBase: [''],
        cest: [''],
        idMensagemTributaria: [],
      })
    );
  }
  
  createImpostoBase() {
    return this.formBuilder.group({
      idImpostoBase: [],
      ncm: [''],
      csosn: [''],
      cst: [''],
      idTipoConsumidor: [],
      impostoPadrao: [false],
      listPerfilImpostoUfStruct: this.formBuilder.array([this.createImpostoUf()]),
    });
  }

  addImposto() {
    const impostoGroup = this.formBuilder.group({
      idImpostoBase: [],
      ncm: [''],
      csosn: [''],
      cst: [''],
      idTipoConsumidor: [''],
      impostoPadrao: [false],
      listPerfilImpostoUfStruct: this.formBuilder.array([]),
    });

    this.imposto.push(impostoGroup);

    const index = this.imposto.length - 1;

    this.addImpostoUf(index);
  }

  createImpostoUf() {
    return this.formBuilder.group({
      idImpostoUf: [],
      listIdUfDestino: [[], [Validators.required]], // Garante que inicia sempre como um array
      icmsAliquota: ['', Validators.required],
      icmsBase: ['', Validators.required],
      cest: [''],
      idMensagemTributaria: [],
    });
  }


  getNcmValues(filterText: any) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (filterText) {
        this.estoqueService.SearchNcm(filterText).subscribe({
          next: (ncmSearchResponse: NcmSearchResponse) => {
            this.isLoading = false;
            if (ncmSearchResponse.isError) {
              this.alertService.show(
                'Erro inesperado',
                ncmSearchResponse.errorDescription,
                AlertType.error
              );
              return;
            }
            this._listNcmSearchStruct = ncmSearchResponse.listNcmSearchStruct;
          },
          error: (error) => {
            this.isLoading = false;
            this.alertService.show('Erro inesperado', error, AlertType.error);
          },
        });
      }
    }, 300);
  }

  getCstValues(filterText: any) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (filterText) {
        this.fiscalService.SearchCst(filterText).subscribe({
          next: (cstSearchResponse: CstSearchResponse) => {
            this.isLoading = false;
            if (cstSearchResponse.isError) {
              this.alertService.show(
                'Erro inesperado',
                cstSearchResponse.errorDescription,
                AlertType.error
              );
              return;
            }
            this._listCstSearchStruct = cstSearchResponse.listCstSearchStruct;
          },
          error: (error) => {
            this.isLoading = false;
            this.alertService.show('Erro inesperado', error, AlertType.error);
          },
        });
      }
    }, 300);
  }


  getCestValues(filterText: string, index?: number | null) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
      this.debounceTimeout = setTimeout(() => {
        if (filterText && index) {
          const impostosArray = this.model.get('listImpostoBaseStruct') as FormArray; // Acessa o FormArray
          let ncmValue = null;
          if (impostosArray && impostosArray.at(index)) {
            ncmValue = impostosArray.at(index).get('ncm')?.value;
          }
          this.estoqueService.SearchCest(ncmValue.codigo, filterText).subscribe({
            next: (cestSearchResponse: CestSearchResponse) => {
              this.isLoading = false;
              if (cestSearchResponse.isError) {
                this.alertService.show(
                  'Erro inesperado',
                  cestSearchResponse.errorDescription,
                  AlertType.error
                );
                return;
              }
              this._listCestSearchStruct = cestSearchResponse.listCestSearchStruct;
            },
            error: (error) => {
              this.isLoading = false;
              this.alertService.show('Erro inesperado', error, AlertType.error);
            },
          });
        }
        else if (filterText && !index) {
          this.estoqueService.SearchOnlyCest(filterText).subscribe({
            next: (cestSearchResponse: CestSearchResponse) => {
              this.isLoading = false;
              if (cestSearchResponse.isError) {
                this.alertService.show(
                  'Erro inesperado',
                  cestSearchResponse.errorDescription,
                  AlertType.error
                );
                return;
              }
              this._listCestSearchStruct = cestSearchResponse.listCestSearchStruct;
            },
            error: (error) => {
              this.isLoading = false;
              this.alertService.show('Erro inesperado', error, AlertType.error);
            },
          });
        }
      }, 300);
  }


  selectNcmValue(event: any, i: number) {
    const selectedCodigo: string = event.source.value; // Pega o código selecionado, não o objeto inteiro
    const impostosArray = this.model.get('listImpostoBaseStruct') as FormArray; // Acessa o base FormArray
    if (impostosArray && impostosArray.at(i)) {
      impostosArray.at(i).get('ncm')?.setValue(selectedCodigo); // Define o código no campo cest
    }
  }

  selectCstValue(event: any, i: number) {
    const selectedCodigo: string = event.source.value; // Pega o código selecionado, não o objeto inteiro
    const impostosArray = this.model.get('listImpostoBaseStruct') as FormArray; // Acessa o base FormArray
    if (impostosArray && impostosArray.at(i)) {
      impostosArray.at(i).get('cst')?.setValue(selectedCodigo); // Define o código no campo cest
    }
  }

  nextStep() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      return;
    }
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= 2) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else 
    {
        if (this._idPerfilImposto) this.requestUpdate.idPerfilImposto = this._idPerfilImposto;
        this.requestUpdate.listEmpresas.push(this.userLoginResponse.empresaLogada.idEmpresa); //??????
        this.requestUpdate.nome = this.model.get('nome')?.value;
        this.requestUpdate.listImpostoBaseStruct = this.model.get('listImpostoBaseStruct')?.value;
        if (this.requestUpdate.listImpostoBaseStruct.length > 0)
        {
          this.requestUpdate.listImpostoBaseStruct[0].impostoPadrao = true;
          this.requestUpdate.listImpostoBaseStruct[0].ncm = null;
          this.requestUpdate.listImpostoBaseStruct[0].cst = null;
          this.requestUpdate.listImpostoBaseStruct[0].csosn = null;
          this.requestUpdate.listImpostoBaseStruct[0].idTipoConsumidor = null;
        }

      this.update();
    }
  }

  update() {
    this.fiscalService.update(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Perfil de imposto atualizado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_TAX_PROFILE]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getTaxProfile() {
    this.fiscalService.get(Number(this._idPerfilImposto)).subscribe({
      next: (response: PerfilImpostoResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.model.disable();
        this.requestUpdate.idPerfilImposto = response.perfilImposto.idPerfilImposto;
        this.model.get('nome')?.setValue(response.perfilImposto.nome);
        this.imposto.clear();

        const impostosOrdenados = response.listImpostoBaseUfStruct.sort((a, b) =>
          b.impostoBase.impostoPadrao ? 1 : a.impostoBase.impostoPadrao ? -1 : 0
        );

        impostosOrdenados.forEach((imp, i) => {
          const impostoGroup = this.formBuilder.group({
            idImpostoBase: [imp.impostoBase.idImpostoBase],
            ncm: [{ value: imp.impostoBase.ncm, disabled: imp.impostoBase.impostoPadrao }],
            csosn: [{ value: imp.impostoBase.csosn, disabled: imp.impostoBase.impostoPadrao }],
            cst: [{ value: imp.impostoBase.cst, disabled: imp.impostoBase.impostoPadrao }],
            idTipoConsumidor: [{ value: imp.impostoBase.idTipoConsumidor ? imp.impostoBase.idTipoConsumidor : null, disabled: imp.impostoBase.impostoPadrao }],
            impostoPadrao: [imp.impostoBase.impostoPadrao],
            listPerfilImpostoUfStruct: this.formBuilder.array(imp.listPerfilImpostoUfStruct.map((impUf, j) => {
              if (!this.selectedUfs[i]) {
                  this.selectedUfs[i] = [];
              }
              if (!this.selectedUfs[i][j]) {
                  this.selectedUfs[i][j] = Array.isArray(impUf.listIdUfDestino) ? impUf.listIdUfDestino : (impUf.listIdUfDestino ? [impUf.listIdUfDestino] : []);
              }

              return this.formBuilder.group({
                  idImpostoUf: [impUf.idImpostoUf],
                  listIdUfDestino: [this.selectedUfs[i][j]],
                  icmsAliquota: [impUf.icmsAliquota],
                  icmsBase: [impUf.icmsBase],
                  cest: [impUf.cest],
                  idMensagemTributaria: [impUf.idMensagemTributaria || null],
              });
          })),
      });

      this.imposto.push(impostoGroup);
        });

        this.model.enable();
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar perfil de imposto',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_TAX_PROFILE]);
  }

  getListSelect() {
    this.fiscalService.getListSelect().subscribe({
      next: (response: ListaSelectResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }

        this.listConsumerType = response.listTipoConsumidor;
        this.listUf = response.listUf;
        this.listCsosn = response.listCsosn;
        this.listMensagemTributaria = response.listMensagemTributaria;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de clientes',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  onTipoConsumidorChange(i:number, event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue: any = selectElement.value; // Pega o item selecionado
    const selected = this.listConsumerType.find(item => item.idTipoConsumidor == selectedValue);

    const impostosArray = this.model.get('listImpostoBaseStruct') as FormArray; // Acessa o FormArray
    if (impostosArray && impostosArray.at(i)) {
      impostosArray.at(i).get('idTipoConsumidor')?.setValue(selected?.idTipoConsumidor);
    }
  }

  OnCsosnChange(i: number, event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedItem = selectElement.value;
    const impostosArray = this.model.get('listImpostoBaseStruct') as FormArray; // Acessa o FormArray
    
    if (impostosArray && impostosArray.at(i)) {
      impostosArray.at(i).get('csosn')?.setValue(selectedItem);
    }
  }

  selectCestValue(i: number, j: number, event: any) {
    const selectedCodigo: string = event.source.value; // Pega o código selecionado, não o objeto inteiro
    this.getImpostoUf(i).at(j).get('cest')?.setValue(selectedCodigo);
  }


  OnUfChange(i: number, j: number, event: any) {
    const selectedValues: any[] = event.value; // Lista de valores selecionados
    const selectedUfs = this.listUf.filter(uf => selectedValues.includes(uf.idUf)); // Filtra os objetos selecionados
    this.getImpostoUf(i).at(j).get('listIdUfDestino')?.setValue(selectedUfs.map(uf => uf.idUf)); // Salva a lista de IDs
}

  OnMensagemTributariaChange(i: number, j: number, event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue: any = selectElement.value; // Pega o item selecionado
    const selected = this.listMensagemTributaria.find(uf => uf.idMensagemTributaria == selectedValue);
    this.getImpostoUf(i).at(j).get('idMensagemTributaria')?.setValue(selected?.idMensagemTributaria);
  }
}

