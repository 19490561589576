import { CommonModule, ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { RoutesService } from '../../../routes.service';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { AuthService } from '../../../shared/services/auth.service';
import { StatusConta } from '../../../shared/services/models/financeiro/status-conta.model';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ListExhibitStruct } from '../../../shared/services/structs/list-exhibit.struct';
import { Router } from '@angular/router';
import { SubheaderComponent } from "../../../shared/component/subheader/subheader.component";
import { SkeletonComponent } from "../../../shared/component/skeleton/skeleton.component";
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { FluxoCaixaResponse } from '../../../shared/services/responses/financeiro/fluxo-caixa.response';
import { FluxoCaixaRequest } from '../../../shared/services/requests/financeiro/fluxo-caixa.request';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-fluxo-caixa',
  standalone: true,
  imports: [SubheaderComponent, SkeletonComponent, CdkAccordionModule, CommonModule, MatExpansionModule, MatIconModule],
  templateUrl: './fluxo-caixa.component.html',
  styleUrl: './fluxo-caixa.component.css',
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(20px)' }),
        animate('300ms ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0, transform: 'translateX(-20px)' }))
      ])
    ])
  ]
})
export class FluxoCaixaComponent implements OnInit {
  @ViewChild(AdvancedTableComponent) exhibit: AdvancedTableComponent;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  currentDate: Date = new Date();
  daysToShow: { day: number, weekday: string }[] = [];
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private routesService: RoutesService,
    public financeiroService: FinanceiroService
  ) {
    this.updateDays();
  }
  public model: FormGroup;
  public color: string = DefaultColorEnum.Cor;
  public dataList: any[];
  public searchTimeout: any;
  public profile: number = DefaultProfilesEnum.Financeiro;
  public tableEnum: number = ExhibitEnum.Tabela;
  public display: ListExhibitStruct = new ListExhibitStruct();
  public listDisplay = this.display.listExhibit;
  public isTable: boolean = true;
  public selectedExhibit: number;
  public listStatus: StatusConta[];
  public isBill: boolean = true;
  public panelOpen: number | null = null;
  public fluxoCaixa: FluxoCaixaResponse = new FluxoCaixaResponse();
  public request: FluxoCaixaRequest = new FluxoCaixaRequest;
  public isExpandExpense: boolean = false;
  public isExpandRevenue: boolean = false;
  public maxExpanse: number = 0;
  public maxRevenue: number = 0;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterDate: [''],
    });
    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);
    this.request.data = new Date();
    this.model.get('filterDate')?.setValue(this.currentDate);
    this.getFluxoCaixa();
  }

  getFluxoCaixa() {
    this.isLoading = true;
    this.financeiroService.cashFlow(this.request).subscribe({
      next: (response: FluxoCaixaResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isExpandExpense = false;
        this.isExpandRevenue = false;
        this.fluxoCaixa = response;
        this.maxExpanse = Math.max(...this.fluxoCaixa.listFluxoCaixaStruct.map(item => item.listDespesa.length), 0);
        this.maxRevenue = Math.max(...this.fluxoCaixa.listFluxoCaixaStruct.map(item => item.listReceita.length), 0);
        this.isLoading = false;


      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  updateDays() {
    this.daysToShow = Array.from({ length: 5 }, (_, i) => {
      const date = new Date(this.currentDate);
      date.setDate(this.currentDate.getDate() + i);
      return {
        day: date.getDate(),
        weekday: date.toLocaleDateString('pt-BR', { weekday: 'short' }).toUpperCase()
      };
    });
  }

  changeDate(days: number) {
    this.currentDate.setDate(this.currentDate.getDate() + days);
    this.model.get('filterDate')?.setValue(this.currentDate);
    this.updateDays();
    this.getFluxoCaixa();
  }

  openDatePicker() {

  }

  expandRevenue()
  {
    this.isExpandRevenue = !this.isExpandRevenue;
  }

  expandExpense()
  {
    this.isExpandExpense = !this.isExpandExpense;
  }
}