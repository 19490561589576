import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from './common-service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserLoginResponse } from './responses/auth/user-login.response';
import { RoutesService } from '../../routes.service';
import { AlertService, AlertType } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CommonService {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private routesService: RoutesService,
    private alertService: AlertService
  ) {
    super();
  }

  public signIn(
    login: string,
    password: string,
    ip: string
  ): Observable<UserLoginResponse> {
    let userAuthRequest = {
      login: login,
      password: password,
      ip: ip,
    };

    return this.httpClient
      .post<UserLoginResponse>(
        environment.urlApiPessoa + 'Auth',
        userAuthRequest
      )
      .pipe(catchError(this.handleError));
  }

  updateToken(userLoginResponse: UserLoginResponse) {
    sessionStorage.setItem('token', JSON.stringify(userLoginResponse));
  }

  getToken() {
    let userLoginResponse: UserLoginResponse = JSON.parse(
      sessionStorage.getItem('token')!
    );
    return userLoginResponse.token;
  }

  getUserInfos(): UserLoginResponse {
    let userLoginResponse: UserLoginResponse = JSON.parse(
      sessionStorage.getItem('token')!
    );
    return userLoginResponse;
  }

  logout() {
    sessionStorage.removeItem('token');
    this.router.navigate(['/']);
  }

  isAuthenticated(): boolean {
    return sessionStorage.getItem('token') == null ? false : true;
  }

  GetPerfilRoute(idPerfil: any): string | undefined {
    let route = this.getUserInfos().usuarioPermissionStruct.listPerfilStruct.find(
      (x) => x.idPerfil === idPerfil
    )?.redirect;
    return route;
  }

  GetSubPerfilRoute(idSubperfil: any): string | undefined {
    let subRoute = this.getUserInfos().usuarioPermissionStruct.listSubPerfilStruct.find(
      (x) => x.idSubperfil === idSubperfil
    )?.redirectSubperfil;
    return subRoute;
  }

  permissao(idPerfil: number, idPapel: number): void {
    let idPapelLogado = this.getUserInfos().usuarioPermissionStruct.listSubPerfilStruct.find(
      (x) => x.idPerfil === idPerfil
    )?.idPapel;
    if (idPapelLogado == null || idPapelLogado < idPapel) {
      this.alertService.show(
        'Erro!',
        'Você não tem permissão para acessar esta página',
        AlertType.warning
      );
      this.router.navigate([this.routesService.HOME]);
    }
  }

  verify(idPerfil: number, idPapel: number) : boolean {
    let idPapelLogado = this.getUserInfos().usuarioPermissionStruct.listSubPerfilStruct.find(
      (x) => x.idPerfil === idPerfil
    )?.idPapel;
    if (idPapelLogado == null || idPapelLogado < idPapel) {
      return false;
    }
    else
    {
      return true;
    }
  }
}
