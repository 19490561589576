<div class="main-container">
  <div class="home-search">
    <app-search-bar></app-search-bar>
  </div>
  <div *ngIf="isLoading" class="homecard-container">
    <app-skeleton *ngFor="let placeholder of [1, 2, 3, 4, 5]" variant="block" shape="card" width="180px" height="140px"></app-skeleton>
  </div>
  <div *ngIf="!isLoading" class="homecard-container">
    <div *ngFor="let module of homeItems" [ngClass]="
    module.corInvertida ? 'homecard-destaque' : 'homecard'" (click)="onCardClick(module)">
      <mat-icon class="home-drag-icon">drag_indicator</mat-icon>
      <mat-icon class="home-delete-icon" (click)="removeShortcut(module)">close</mat-icon>
      <mat-icon class="homecard-icon">{{ module.icon }}</mat-icon>
      <div class="homecard-content">
        <h4>{{ module.name }}</h4>
        <p>{{ module.desc }}</p>
      </div>
    </div>
    <div *ngIf="availableModules.length !== 0" class="homecard-add" (click)="openAddShortcutModal()">
      <mat-icon class="homecard-icon">add</mat-icon>
      <div class="homecard-content">
        <h4>Novo atalho</h4>
        <p>Adicionar a tela inicial</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isModalOpen" class="modal-overlay" (click)="closeModal($event)">
  <div class="modal-content">
    <h2>Adicionar Atalho</h2>
    <div class="module-list">
      <div *ngFor="let module of availableModules" class="module-item" (click)="addShortcut(module)">
        <mat-icon>{{ module.icon }}</mat-icon>
        <span>{{ module.name }}</span>
      </div>
    </div>
    <button class="main-btn" (click)="isModalOpen = false">FECHAR</button>
  </div>
</div>