import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { MenuComponent } from './shared/component/menu/menu.component';
import { PreferencesService } from './preferences.service';
import { AuthService } from './shared/services/auth.service';
import { RoutesService } from './routes.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, MenuComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'ERP';
  teste = environment.urlApiPessoa;
  showMenu = false;
  showSearch = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private routesService: RoutesService,
    private themePreferenceService: PreferencesService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe(() => {
        const currentRoute = this.getChildRoute(this.activatedRoute);
        this.showMenu = currentRoute.snapshot.data?.['menu'] || false;
        this.showSearch = currentRoute.snapshot.data?.['search'] || false;
      });
    this.themePreferenceService.loadTheme();
    this.themePreferenceService.loadCorSistema();

    let userResponse = this.authService.getUserInfos();
    if(userResponse)
      this.routesService.resetRoutes(userResponse);
  }

  toggleTheme() {
    this.themePreferenceService.toggleTheme();
  }

  private getChildRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
