import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { Validators } from 'ngx-editor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SkeletonComponent } from '../../../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../../../shared/component/subheader/subheader.component';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { EstoqueService } from '../../../../shared/services/API/estoque/estoque.service';
import { PedidoService } from '../../../../shared/services/API/pedido/pedido.service';
import { MaskService } from '../../../../shared/services/mask.service';
import { ProdutoSubGradeResponse } from '../../../../shared/services/responses/estoque/produto-sub-grade.response';
import { ProdutoSubGradeStruct } from '../../../../shared/services/structs/estoque/produto-sub-grade.struct';

@Component({
  selector: 'app-dados-produtos',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SubheaderComponent,
    MatSelectModule,
    MatCheckboxModule,
    SkeletonComponent,
    MatAutocompleteModule,
  ],
  templateUrl: './dados-produtos.component.html',
  styleUrl: './dados-produtos.component.css',
})
export class DadosProdutosComponent implements OnInit {
  @Output() produtosAtualizados = new EventEmitter<{ produtos: any[], subTotal: number }>();
  public isLoading: boolean = true;
  public masks: any;
  constructor(
    private alertService: AlertService,
    public pedidoService: PedidoService,
    public estoqueService: EstoqueService,
    private formBuilder: FormBuilder,
    private maskService: MaskService
  ) {}
  public _idPedido: number | null = null;
  public listProduto: ProdutoSubGradeStruct[] = [];
  public filteredProdutos: ProdutoSubGradeStruct[] = [];
  public listaProdutosSelecionados: ProdutoSubGradeStruct[] = [];
  public produtoSelected: ProdutoSubGradeStruct;
  public isValue: boolean = true;
  public isDiscountValue: boolean = true;
  
  @Input() model!: FormGroup;
  ngOnInit(): void {
    this.isLoading = true;
    this.masks = this.maskService.getMasks();
    this.getListProduto();
  }
  
  filtrarProdutos(value: string) {
    const filterValue = value.toLowerCase();
    if (filterValue) {      
      this.filteredProdutos = this.listProduto;
        this.filteredProdutos = this.filteredProdutos.filter(c =>
        c.nomeProduto.toLowerCase().includes(filterValue) ||
        c.nomeGrade.toLowerCase().includes(filterValue) ||
        c.nomeSubgrade.toLowerCase().includes(filterValue) ||
        c.ean.toLowerCase().includes(filterValue) ||
        c.ncm.toLowerCase().includes(filterValue)
      );
    }
    else
      this.filteredProdutos = this.listProduto;
  }

  onProdutoInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
        const filterValue = inputElement.value.toLowerCase();
        this.filtrarProdutos(filterValue);
  }

  selecionarProduto(index: number, produto: ProdutoSubGradeStruct) {
    this.listaProdutosSelecionados[index] = produto;   

    const precoFormatado = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
  }).format(produto.precoAtual);

    const produtoConcatenado = `${produto.nomeProduto} ${produto.nomeSubgrade} ${precoFormatado}`;
    this.listaProdutosSelecionados[index].nomeProduto = produtoConcatenado;
    this.changeValue(index);
  }
  
  formatMoney(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value || 0);
  }

  getListProduto() {
    this.estoqueService.GetProduct().subscribe({
      next: (response: ProdutoSubGradeResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }

        this.listProduto = response.listProduto;
        this.filteredProdutos = response.listProduto;
        
        this.produtos.controls.forEach((grupo, index) => {
          const produto = response.listProduto.find((x) => x.idSubgrade == grupo.value.idSubGrade);
          if (produto) {
            this.selecionarProduto(index, produto)
          }
        });
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de status do pedido',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  removerProduto(index: number) {
    this.listaProdutosSelecionados.splice(index, 1);
    this.produtos.removeAt(index);
    this.sumProducts();
  }

  get produtos(): FormArray {
    return this.model.get('produtos') as FormArray;
  }

  adicionarProduto() {
    const produto = this.formBuilder.group({
      idProduto: [''],
      nomeProduto: [''],
      codProduto: [''],
      quantidade: [1],
      idGrade: [''],
      idSubGrade: [''],
      gradeProdutoNome: [''],
      subGradeProdutoNome: [''],
      idProdutoDesconto: [0],
      precoUnidade: [0.01],
      idStatusPedidoProduto: [1],
      valorDesconto: [0],
      isValue: [true],
      porcentagemDesconto: [0],
      valor: [{ value: 0, disabled: true }],
    });
    this.produtos.push(produto);
  }

  changeType(index: number) {
    this.isValue = this.produtos.at(index).get('isValue')?.value;

    this.isValue = !this.isValue;

    if (this.isValue) {
      this.produtos.at(index).get('valorDesconto')?.setValue(this.produtos.at(index).get('porcentagemDesconto')?.value);
      this.produtos.at(index).get('porcentagemDesconto')?.setValue(0);
    } else {
      let value = this.produtos.at(index).get('valorDesconto')?.value;
      if (value > 100) value = 100;
      this.produtos.at(index).get('porcentagemDesconto')?.setValue(value);
      this.produtos.at(index).get('valorDesconto')?.setValue(0);
    }

    this.produtos.at(index).get('isValue')?.setValue(this.isValue);
    this.changeValue(index);
  }

  changeDiscountType() {
    this.isDiscountValue = this.model.get('isValue')?.value;

    this.isDiscountValue = !this.isDiscountValue;

    if (this.isDiscountValue) {
      this.model
        .get('valorDescontoPedido')
        ?.setValue(this.model.get('porcentagemDescontoPedido')?.value);
      this.model.get('porcentagemDescontoPedido')?.setValue(0);
    } else {
      let value = this.model.get('valorDescontoPedido')?.value;

      if (value > 100) value = 100;

      this.model.get('porcentagemDescontoPedido')?.setValue(value);
      this.model.get('valorDescontoPedido')?.setValue(0);
    }

    this.model.get('isValue')?.setValue(this.isDiscountValue);
  }

  changeValue(index: number) {
    let quantidade = this.produtos.at(index).get('quantidade')?.value;

    if (!quantidade || quantidade.toString().trim() === '') {
      quantidade = 1;
      this.produtos.at(index).get('quantidade')?.setValue(quantidade);
    }

    let valorDesconto = this.produtos.at(index).get('valorDesconto')?.value;

    if (!valorDesconto || valorDesconto.toString().trim() === '') {
      valorDesconto = 0;
      this.produtos.at(index).get('valorDesconto')?.setValue(valorDesconto);
    }

    let descontoPorcentagem = this.produtos.at(index).get('porcentagemDesconto')?.value;

    if (!descontoPorcentagem || descontoPorcentagem.toString().trim() === '') {
      descontoPorcentagem = 0;
      this.produtos.at(index).get('porcentagemDesconto')?.setValue(0);
    }

    this.produtoSelected = this.listaProdutosSelecionados[index];
    this.produtos.at(index).get('nomeProduto')?.setValue(this.produtoSelected.nomeProduto);
    this.produtos.at(index).get('codProduto')?.setValue(this.produtoSelected.idProduto);
    this.produtos.at(index).get('idGrade')?.setValue(this.produtoSelected.idGrade);
    this.produtos.at(index).get('idSubGrade')?.setValue(this.produtoSelected.idSubgrade);
    this.produtos.at(index).get('gradeProdutoNome')?.setValue(this.produtoSelected.nomeGrade);
    this.produtos.at(index).get('subGradeProdutoNome')?.setValue(this.produtoSelected.nomeSubgrade);
    this.produtos.at(index).get('precoUnidade')?.setValue(this.produtoSelected.precoAtual);
    //this.produtos.at(index).get('idStatusPedidoProduto')?.setValue(1);
    let valorProduto = this.produtoSelected.precoAtual;
    let valorTotal = valorProduto * quantidade - valorProduto * (descontoPorcentagem / 100) - valorDesconto;
    this.produtos.at(index).get('valor')?.setValue(valorTotal);

    this.sumProducts();
  }

  sumProducts() {
    let sum = 0;
    this.produtos.controls.forEach((grupo: AbstractControl, index: number) => {
      sum = sum + grupo.get('valor')?.value;
    });
    this.model.get('subTotal')?.setValue(sum);
    this.produtosAtualizados.emit({ produtos: this.produtos.value, subTotal: sum }); 
  }
}
