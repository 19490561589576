import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Validators } from 'ngx-editor';
import { catchError, of } from 'rxjs';
import { DefaultColorEnum } from '../../enum/default-color.enum';
import { TipoFornecedorEnum } from '../../enum/Fornecedor/tipo-fornecedor.enum';
import { AlertService, AlertType } from '../../services/alert.service';
import { PessoaService } from '../../services/API/pessoa/pessoa.service';
import { AuthService } from '../../services/auth.service';
import { MaskService } from '../../services/mask.service';
import { FornecedorRequest } from '../../services/requests/pessoa/fornecedor.request';
import { UserLoginResponse } from '../../services/responses/auth/user-login.response';
import { ReturnStruct } from '../../services/structs/return.struct';
import { UtilService } from '../../services/util.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-supplier-register-modal',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './supplier-register-modal.component.html',
  styleUrl: './supplier-register-modal.component.css'
})
export class SupplierRegisterModalComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  private cepTimeout: any;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private maskService: MaskService,
    public dialogRef: MatDialogRef<SupplierRegisterModalComponent>,
  ) { }
  public model: FormGroup;
  public requestUpdate: FornecedorRequest = new FornecedorRequest();
  public color: string = DefaultColorEnum.Cor;
  @ViewChild('numeroEndereco') numeroEndereco: ElementRef;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required],
      razaoSocial: [''],
      email: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      telefone: [''],
      telefone2: [''],
      nascimento: [''],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      complemento: [''],
      localidade: [''],
      uf: [''],
      pais: [''],
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.isLoading = false;
  }
  submit() {
    this.isLoading = true;

    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    }

    else {
      const fornecedorRequest = new FornecedorRequest();
      if (this.model.get('nome')?.value)
        fornecedorRequest.nome = this.model.get('nome')?.value;
      if (this.model.get('razaoSocial')?.value)
        fornecedorRequest.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value)
        fornecedorRequest.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value)
        fornecedorRequest.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value)
        fornecedorRequest.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value)
        fornecedorRequest.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value)
        fornecedorRequest.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value)
        fornecedorRequest.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value)
        fornecedorRequest.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value)
        fornecedorRequest.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value)
        fornecedorRequest.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value)
        fornecedorRequest.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value)
        fornecedorRequest.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value)
        fornecedorRequest.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value)
        fornecedorRequest.rua = this.model.get('rua')?.value;
      if (this.model.get('complemento')?.value)
        fornecedorRequest.complemento = this.model.get('complemento')?.value;
      if (this.model.get('bairro')?.value)
        fornecedorRequest.bairro = this.model.get('bairro')?.value;
      if (this.model.get('pais')?.value)
        fornecedorRequest.pais = this.model.get('pais')?.value;
      this.requestUpdate.idTipoFornecedor = TipoFornecedorEnum.Fornecedor;
      this.register(fornecedorRequest);
    }
  }

  register(supply: FornecedorRequest) {
    this.pessoaService.supplyRegister(supply).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Fornecedor registrado',
          AlertType.success
        );

        this.dialogRef.close({
          confirm: true
        });
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {
      this.utilService
        .getAddressByCEP(cep)
        .pipe(
          catchError((error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            this.model.get('logradouro')?.enable();
            this.model.get('complemento')?.enable();
            this.model.get('bairro')?.enable();
            this.model.get('uf')?.enable();
            this.model.get('pais')?.enable();
            this.model.get('cidade')?.enable();
            this.model.get('numero')?.enable();
            return of(null);
          })
        )
        .subscribe((data) => {
          if (data) {
            this.model.patchValue({
              logradouro: data.logradouro,
              complemento: data.complemento,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf,
              pais: 'Brasil',
            });
          }

          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          this.numeroEndereco.nativeElement.focus();
          this.isLoading = false;
        });
    }, 2000);
  }

  close() {
    this.dialogRef.close({
      confirm: false
    });
  }

  onCpfCnpjInput(event: any): void {
    const value = event.target.value.replace(/\D/g, '');
    let formattedValue = '';

    if (value.length < 11) {
      formattedValue = value;
    } else if (value.length == 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cpf);
    } else if (value.length > 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
    }

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  applyMasks(cpfCnpj: string) {
    const value = cpfCnpj.replace(/\D/g, '');
    let formattedValue = '';

    if (value.length < 11) {
      formattedValue = value;
    } else if (value.length == 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cpf);
    } else if (value.length > 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
    }

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  onTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone')?.setValue(formattedTelefone);
  }

  onSecondTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone2')?.setValue(formattedTelefone);
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(
      event.target.value,
      this.masks.cep
    );
    this.model.get('cep')?.setValue(formattedCep);
    if (formattedCep.length > 8) {
      this.searchCep(formattedCep);
    }
  }
}
