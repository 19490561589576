import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultColorEnum } from '../../../enum/default-color.enum';
import { UserLoginResponse } from '../../../services/responses/auth/user-login.response';
import { AuthService } from '../../../services/auth.service';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

const today = new Date();
@Component({
  selector: 'app-open-text-filter',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  templateUrl: './open-text-filter.component.html',
  styleUrls: ['./open-text-filter.component.css']
})
export class OpenTextFilterComponent implements OnInit {
  public color: string = DefaultColorEnum.Cor;
  public userLoginResponse: UserLoginResponse;
  public model: FormGroup;
  public filterText: string = "";
  @Input() placeholder: string;
  @Output() valueChanged = new EventEmitter<any>();

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();
  }

  onFilterChange(value: string) {
    this.filterText = value;
    this.valueChanged.emit(this.filterText);
  }
}