import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { MatIconModule } from '@angular/material/icon';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { UtilService } from '../../../shared/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaskService } from '../../../shared/services/mask.service';
import { ClienteRequest } from '../../../shared/services/requests/pessoa/cliente.request';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { Cliente } from '../../../shared/services/models/pessoa/cliente.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { ClienteResponse } from '../../../shared/services/responses/pessoa/cliente.response';
import { Pessoa } from '../../../shared/services/models/pessoa/pessoa.model';
import { ClienteSearchStruct } from '../../../shared/services/structs/pessoa/cliente-search.struct';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';

@Component({
  selector: 'app-client-register',
  standalone: true,
  imports: [
    MatIconModule,
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
],
  templateUrl: './client-register.component.html',
  styleUrl: './client-register.component.css'
})
export class ClientRegisterComponent implements OnInit{
  public userLoginResponse:UserLoginResponse;
  public isLoading: boolean = true;
  private cepTimeout: any;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
  ){

  }
  public model: FormGroup;
  public requestUpdate: ClienteRequest = new ClienteRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idClient: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Cliente';
  public iconProfile: string = 'person';
  public profile: number = DefaultProfilesEnum.Cliente;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required],
      sobrenome: [''],
      razaoSocial: [''],
      email: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      telefone: [''],
      telefone2: [''],
      nascimento: [''],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      localidade: [''],
      uf: [''],
      pais: ['']
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar)

    if(this.userLoginResponse.empresaLogada?.corSistema){
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.nameProfile = this.userLoginResponse.listPerfil.find(x => x.idPerfil == this.profile)?.nomePerfil ?? this.nameProfile;
    this.iconProfile = this.userLoginResponse.listPerfil.find(x => x.idPerfil == this.profile)?.googleIcone ?? this.iconProfile;
    let idClient:number = this.activatedRoute.snapshot.params["idCliente"];
    if(idClient)
    {
      this._idClient = Number(idClient);
      this.getClient();
    }
    this.isLoading = false;
  }
  
  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show("Erro!", "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    else if (this._idClient)
    {
      if (this.model.get('nome')?.value) this.requestUpdate.nome = this.model.get('nome')?.value;
      if (this.model.get('sobrenome')?.value) this.requestUpdate.sobrenome = this.model.get('sobrenome')?.value;
      if (this.model.get('razaoSocial')?.value) this.requestUpdate.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value) this.requestUpdate.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value) this.requestUpdate.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value) this.requestUpdate.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value) this.requestUpdate.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value) this.requestUpdate.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value) this.requestUpdate.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value) this.requestUpdate.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value) this.requestUpdate.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value) this.requestUpdate.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value) this.requestUpdate.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value) this.requestUpdate.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value) this.requestUpdate.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value) this.requestUpdate.rua = this.model.get('rua')?.value;
      if (this.model.get('pais')?.value) this.requestUpdate.pais = this.model.get('pais')?.value;
      this.update();
    }

    else {
      const clienteRequest = new ClienteRequest();
      if (this.model.get('nome')?.value) clienteRequest.nome = this.model.get('nome')?.value;
      if (this.model.get('sobrenome')?.value) clienteRequest.sobrenome = this.model.get('sobrenome')?.value;
      if (this.model.get('razaoSocial')?.value) clienteRequest.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value) clienteRequest.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value) clienteRequest.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value) clienteRequest.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value) clienteRequest.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value) clienteRequest.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value) clienteRequest.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value) clienteRequest.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value) clienteRequest.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value) clienteRequest.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value) clienteRequest.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value) clienteRequest.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value) clienteRequest.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value) clienteRequest.rua = this.model.get('rua')?.value;
      if (this.model.get('pais')?.value) clienteRequest.pais = this.model.get('pais')?.value;
      this.register(clienteRequest);
    }
  }

  register(client: ClienteRequest) {
    this.pessoaService.clientRegister(client).subscribe({
      next: (response:ReturnStruct) => {
        if (response.isError) {
          console.log(response);
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show("Sucesso!", "Cliente registrado", AlertType.success);
        this.router.navigate(['/list-clients']);

      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  update()
  {
    this.pessoaService.clientUpdate(this.requestUpdate).subscribe({
      next: (response:ReturnStruct) => {
        if (response.isError) {
          console.log(response);
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show("Sucesso!", "Paciente atualizado", AlertType.success);
        this.router.navigate(['/list-clients']);

      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  getClient() {
    this.pessoaService.searchClientById(Number(this._idClient)).subscribe({
      next: (response:ClienteResponse) => {
        if (response.isError) {
          console.log(response);
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.requestUpdate.idCliente = response.cliente.idCliente;
        this.requestUpdate.idTipoCliente = response.cliente.idTipoCliente;
        this.requestUpdate.renda = response.cliente.renda;
        this.requestUpdate.limiteCredito = response.cliente.limiteCredito;
        this.requestUpdate.idClienteTitular = response.cliente.idClienteTitular;
        this.requestUpdate.idPessoa = response.cliente.idPessoa;
        this.model.get('nome')?.setValue(response.pessoa.nome);
        this.model.get('sobrenome')?.setValue(response.pessoa.nome);
        this.model.get('razaoSocial')?.setValue(response.pessoa.sobrenome);
        this.model.get('email')?.setValue(response.pessoa.email);
        this.model.get('cpfCnpj')?.setValue(response.pessoa.cpfCnpj);
        this.model.get('telefone')?.setValue(response.pessoa.telefone);
        this.model.get('telefone2')?.setValue(response.pessoa.telefone2);
        this.model.get('nascimento')?.setValue(response.pessoa.nascimento);
        this.model.get('latitude')?.setValue(response.pessoa.latitude);
        this.model.get('longitude')?.setValue(response.pessoa.longitude);
        this.model.get('cep')?.setValue(response.pessoa.cep);
        this.model.get('logradouro')?.setValue(response.pessoa.logradouro);
        this.model.get('cidade')?.setValue(response.pessoa.cidade);
        this.model.get('numero')?.setValue(response.pessoa.numero);
        this.model.get('uf')?.setValue(response.pessoa.estado);
        this.model.get('rua')?.setValue(response.pessoa.rua);
        this.model.get('pais')?.setValue(response.pessoa.pais);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro inesperado", "Erro ao buscar cep", AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {

      this.utilService.getAddressByCEP(cep).pipe(

        catchError((error) => {

          this.alertService.show("Erro inesperado", error, AlertType.error);
          this.isLoading = false;
          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          return of(null);

        })).subscribe((data) => {

        if (data) {
          this.model.patchValue({
            logradouro: data.logradouro,
            complemento: data.complemento,
            bairro: data.bairro,
            cidade: data.localidade,
            uf: data.uf,
            pais: 'Brasil'
          });
        }

        this.model.get('logradouro')?.enable();
        this.model.get('complemento')?.enable();
        this.model.get('bairro')?.enable();
        this.model.get('uf')?.enable();
        this.model.get('pais')?.enable();
        this.model.get('cidade')?.enable();
        this.model.get('numero')?.enable();
        this.isLoading = false;

      });
    },2000);
  }
  
  backPage() {
    this.router.navigate(['client/list-clients/']);
  }

  onCpfCnpjInput(event: any): void {
    const value = event.target.value.replace(/\D/g, '');
  let formattedValue = '';

  if(value.length < 11)
  {
    formattedValue = value;
  }

  else if (value.length == 11) 
  {
    formattedValue = this.maskService.applyMask(value, this.masks.cpf);
  }

  else if (value.length > 11)
  {
    formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
  }

  this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  onTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(event.target.value, this.masks.phone);
    this.model.get('telefone')?.setValue(formattedTelefone);
  }

  onSecondTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(event.target.value, this.masks.phone);
    this.model.get('telefone2')?.setValue(formattedTelefone);
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(event.target.value, this.masks.cep);
    this.model.get('cep')?.setValue(formattedCep);
}
}