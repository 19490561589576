<div class="table-container">
  <div *ngIf="isShowLoading; else tableContent" class="loading-placeholder">
    <table *ngIf="isLoading">
      <tr *ngFor="let row of emptyRows">
        <td *ngFor="let _ of emptyColumns" class="loading-cell">
          <mat-progress-spinner mode="indeterminate" diameter="20" class="spinner"></mat-progress-spinner>
        </td>
      </tr>
    </table>
  </div>
  <ng-template #tableContent>
    <div class="filter-container">
      <app-open-text-filter (valueChanged)="onFilterChange($event)"></app-open-text-filter>
      <div></div>
      <app-date-filter (valueChanged)="onDateSearchChange($event)"></app-date-filter>
    </div>
    <table>
      <thead>
        <tr>
          <th *ngFor="let column of columns" (click)="sortData(column)" style="cursor: pointer;">
            {{ column }}
            <span *ngIf="sortedColumn === column">
              {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
            </span>
          </th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of filteredData">
          <td *ngFor="let key of columns">
            {{ row[key] }}
          </td>
          <td>
            <mat-icon class="button" (click)="edit(row)">edit</mat-icon>
            <mat-icon class="button" (click)="remove(row)">delete</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</div>
