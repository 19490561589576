<div class="subheader">
  <div class="breadcrumb">
    <mat-icon (click)="navigateTo('/home')" class="icon-home">home</mat-icon>
    <span class="breadcrumb-separator">›</span>
    <span (click)="navigateToBasePath()" class="breadcrumb-item">{{ title }}</span>
    @if (isAddPage) {
    <span class="breadcrumb-separator">›</span>
    <span class="breadcrumb-item">Novo {{ title.toLowerCase() }}</span>
    }
    @if (isEditPage) {
    <span class="breadcrumb-separator">›</span>
    <span class="breadcrumb-item">Editando {{ title.toLowerCase() }}</span>
    }
  </div>
  @if (!isAddPage && !isEditPage) {
  <div [ngClass]="showSubheader ? 'wrapper' : 'no-wrapper'">
    <div class="list-item">
      <mat-icon class="icon-item">{{ icon }}</mat-icon>
      <div class="list-content">
        <h1 class="title">{{ title }}</h1>
        <p class="desc">{{ description }}</p>
      </div>
    </div>
    <button class="cancel-btn">EXPORTAR</button>
  </div>
  }
  @if (!isAddPage && !isEditPage) {
  <a class="expand" (click)="onToggleSubheader()">
    <span class="icon-container">
      <mat-icon>{{ showSubheader ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
    </span>
  </a>
  }
</div>