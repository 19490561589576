import { Component, OnInit, signal} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { UtilService } from '../../../shared/services/util.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import {MatIconModule} from '@angular/material/icon';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { SelectModalComponent } from '../select-modal/select-modal.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AuthTokenService } from '../../../shared/services/auth-token.service';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [    
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatIconModule,
    MatDialogModule
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.css'
})
export class AuthComponent implements OnInit {
  
  public selectModal: SelectModalComponent;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private authTokenService: AuthTokenService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    private dialog: MatDialog
  ){}

  public model: FormGroup;
  public isLoading:boolean = false;
  private ip: string = '192.168.0.2';
  private idEmpresaLogada: number;
  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    setTimeout(() => { this.getIP(); }, 2000);
  }

  singin() {
    if (this.model.invalid  || this.isLoading)
      return;

    this.isLoading = true;

    this.authService.signIn(this.model.get('login')?.value, this.model.get('password')?.value, this.ip).subscribe({
      next: (response:UserLoginResponse) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.authService.updateToken(response);
        const modalRef = this.dialog.open(SelectModalComponent, {
          data: { response },
          hasBackdrop: true,
          disableClose: false
        });

        modalRef.afterClosed().subscribe((result: { success: boolean, idEmpresaLogada: number }) => {
          if (result.success) {
            this.idEmpresaLogada = result.idEmpresaLogada;
            this.generateToken(response.token);

          } else {
            this.isLoading = false;
            return;
          }
        });
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  generateToken(token: string) {
    this.authTokenService.signIn(this.model.get('login')?.value, this.model.get('password')?.value, this.ip, this.idEmpresaLogada, token).subscribe({
      next: (response:UserLoginResponse) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.authService.updateToken(response);
        this.isLoading = false;
        this.router.navigate(['/home']);

      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  redirectRevoceryPassword(){
    this.router.navigate(['/password-recovery'])
  }


}
