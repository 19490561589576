import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { CorPersonalizada } from '../../../../shared/services/models/pessoa/cor-personalizada.model';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-empresa-color-picker',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule
  ],
  templateUrl: './empresa-color-picker.component.html',
  styleUrl: './empresa-color-picker.component.css'
})
export class EmpresaColorPickerComponent implements OnInit {
  public coresPersonalizadas: CorPersonalizada[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmpresaColorPickerComponent>,) {
  }

  ngOnInit(): void {
    this.coresPersonalizadas = this.data.coresPersonalizadas
  }

  close(corSelecionada: CorPersonalizada) {
    this.dialogRef.close({
      confirm: corSelecionada
    });
  }

  confirm() {
    this.dialogRef.close({
      confirm: true
    });
  }
}
