<div [ngClass]="{'draggable': isDraggable, 'not-draggable': !isDraggable}" class="order-card" (click)="openModal()">
    <!-- <mat-icon matTooltip="Avançar etapa" class="order-card-icon">arrow_right_alt</mat-icon> -->
    <!-- <mat-icon matTooltip="Marcar como cancelado" class="order-card-delete-icon">block</mat-icon> -->
    <div class="order-card-content">
        <h2 class="status" [ngClass]="getCardRowClass(status)">
            {{ status }}
        </h2>
        <h4>
            Pedido Nº #{{ name }}
        </h4>
        <p>
            {{ content }}
        </p>
    </div>
</div>
<div *ngIf="isModalOpen" class="modal-overlay" (click)="closeModal($event)">
    <div class="modal-content">
        <div class="modal-header">
            <h2>Pedido Nº #{{ name }}</h2>
        </div>
        <div class="modal-body">
            <div class="modal-subbody">
                <div>
                    <h3>Observações:</h3>
                    <div class="modal-form-actions custom">
                        N/A
                    </div>
                </div>
                <ng-container *ngFor="let gatilho of coluna.listGatilhoStruct">
                    <div>
                        <h3>{{gatilho.gatilho.nomeGatilho}}:</h3>
                        <div *ngIf="gatilho.listCampoGatilhoStruct.length !== 0" class="modal-form-actions custom">
                            <ng-container *ngFor="let campo of gatilho.listCampoGatilhoStruct">
                                <div class="input-control">
                                    <label class="input-label" for="{{campo.idCampoGatilho}}" style="text-align: start;">{{campo.nomeCampo}}</label>
                                    <input matInput id="{{campo.idCampoGatilho}}" type="text" placeholder="{{campo.nomeCampo}}" class="input-field" [(ngModel)]="formValues[campo.idCampoGatilho]" required="{{campo.obrigatorio}}" />
                                    <span *ngIf="campo.obrigatorio && !formValues[campo.idCampoGatilho]">
                                        {{ campo.nomeCampo }} é obrigatório.
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="coluna.listCampoFaseStruct.length !== 0">
                    <h3>Campos personalizados</h3>
                    <div class="modal-form-actions custom">
                        <ng-container *ngFor="let campo of coluna.listCampoFaseStruct">
                            <div class="input-control">
                                <label class="input-label" for="{{campo.idCampoFase}}" style="text-align: start;">{{campo.nameCampo}}</label>
                                <input matInput id="{{campo.idCampoFase}}" type="text" placeholder="{{campo.nameCampo}}" class="input-field" [(ngModel)]="formValues[campo.idCampoFase]" required />
                                <span *ngIf="campo.obrigatorio && !formValues[campo.idCampoFase]">
                                    {{ campo.nameCampo }} é obrigatório.
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="modal-side">
                <div class="side-section">
                    <h4>Cliente:</h4>
                    <p>{{ pedido.nomeCliente }}</p>
                </div>
                <div class="side-section">
                    <h4>Valor do pedido: </h4>
                    <p>R$ {{ pedido.valorPedido }}</p>
                </div>
                <div class="side-section">
                    <h4>Origem: </h4>
                    <select class="input-ncm" style="padding: 7px;">
                        <option value="1" selected>
                            Pedido Whatsapp
                        </option>
                        <option value="2">
                            Pedido balcão
                        </option>
                        <option value="3">
                            Pedido PDV
                        </option>
                    </select>
                </div>
                <div>
                    <input type="checkbox" id="menu-toggle">
                    <label for="menu-toggle" class="menu-btn">☰</label>
                    <div class="menu">
                        <p>Cancelar pedido</p>
                        <p>Arquivar pedido</p>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-actions">
            <button class="cancel-btn" (click)="isModalOpen = false; newColumn = 0">FECHAR</button>
            @if (isDraggable) {
            <button class="main-btn" (click)="update()">ENVIAR</button>
            }
        </div>
    </div>
</div>