import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableComponent } from '../../../shared/component/table/table.component';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { Router } from '@angular/router';
import { TipoFornecedorEnum } from '../../../shared/enum/Fornecedor/tipo-fornecedor.enum';
import { FornecedorSearchRequest } from '../../../shared/services/requests/pessoa/fornecedor-search.request';
import { FornecedorSearchTableResponse } from '../../../shared/services/responses/pessoa/fornecedor-search-table.response';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { debounceTime, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';

@Component({
  selector: 'app-list-supplier',
  standalone: true,
  imports: [
    MenuComponent,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    TableComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './list-supplier.component.html',
  styleUrl: './list-supplier.component.css'
})
export class ListSupplierComponent implements OnInit{
  @ViewChild(TableComponent) table: TableComponent;
  public userLoginResponse:UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  public color: string = DefaultColorEnum.Cor;
  buttonStyle: any = {
    backgroundColor: this.color,
    color: 'white'
  };
  secondButtonStyle: any = {
    backgroundColor: 'transparent',
    color: this.color
  };
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ){
    this.searchSubject.pipe(debounceTime(2000)).subscribe(value => {
      this.model.get('filterName')?.setValue(value);
      this.searchSupplier();
    });
    this.searchDate.pipe(debounceTime(2000)).subscribe(value => {
      this.model.get('filterDate')?.setValue(value);
      this.searchSupplier();
    });
  }
  public model: FormGroup;
  public tipoFornecedor: number = TipoFornecedorEnum.Fornecedor;
  public tipoTransportador: number = TipoFornecedorEnum.Transportador;
  public tipoTabela: number = TipoFornecedorEnum.Fornecedor;
  public dataList: any[];
  public searchTimeout: any;
  public nameProfile: string = 'Fornecedores';
  public iconProfile: string = 'home_repair_service';
  public profile: number = DefaultProfilesEnum.Fornecedor;
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterName: [''],
      filterDate: [''],
      currentPage: [1],
      totalPages: [1]
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.ler)

    if(this.userLoginResponse.empresaLogada?.corSistema){
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.nameProfile = this.userLoginResponse.listPerfil.find(x => x.idPerfil == this.profile)?.nomePerfil ?? this.nameProfile;
    this.iconProfile = this.userLoginResponse.listPerfil.find(x => x.idPerfil == this.profile)?.googleIcone ?? this.iconProfile;
    this.searchSupplier();
  }
  
  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  onSearchValueChanged(value: string) {
    this.searchSubject.next(value);
  }

  onDateValueChanged(value: string) {
    this.searchDate.next(value);
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchSupplier();
    }
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchSupplier();
    }
  }

  searchSupplier() {
    this.isLoading = true;
    const searchRequest: FornecedorSearchRequest = new FornecedorSearchRequest();
    if (this.model.get('search')?.value)
      searchRequest.searchText = this.model.get('search')?.value;

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value)
      {
        const [startDateString, endDateString] = this.model.get('filterDate')?.value.split('|');
        searchRequest.dataInicio = startDateString;
        searchRequest.dataFim = endDateString;
      }

    searchRequest.tipoFornecedor = this.tipoTabela;  

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.pessoaService.searchSupplierTable(searchRequest).subscribe({
        next: (response:FornecedorSearchTableResponse) => {
          if (response.isError) {
            this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.dataList = response.listFornecedorSearchTableStruct;
          setTimeout(() => {
            this.updateColumns();
          }, 3000);
        },
        error: (error) => {
          this.alertService.show("Erro inesperado", error, AlertType.error);
          this.isLoading = false;
          return;
        }
      });
    }, 3000);
  }

  updateColumns() {
    this.table.updateColumns();
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  addSupplier() {
    this.router.navigate(['supplier/supply-register/']);
  }

  changeTable(tipo: number) {
    if(this.tipoTabela === tipo)
      return;

    else
    {
      if(tipo === this.tipoFornecedor)
      {
        this.tipoTabela = tipo;
        this.buttonStyle.backgroundColor = this.color;
        this.buttonStyle.color = 'white';
        this.secondButtonStyle.backgroundColor = 'white';
        this.secondButtonStyle.color = this.color;
        this.searchSupplier();
      }
      else
      {
        this.tipoTabela = tipo;
        this.buttonStyle.backgroundColor = 'white';
        this.buttonStyle.color = this.color;
        this.secondButtonStyle.backgroundColor = this.color;
        this.secondButtonStyle.color = 'white';
        this.searchSupplier();
      }
    }
  }

  tableActions(emitted: any) {
    
    if(emitted.action === 'search')
    {
      this.model.get('filterName')?.setValue(emitted.search);
      this.searchSupplier();
    }

    else if(emitted.action === 'edit')
    {
      this.router.navigate(['supplier/supply-register/', emitted.row.registro]);
    }

    else if(emitted.action === 'dateSearch')
    {
      this.model.get('filterDate')?.setValue(emitted.date);
      this.searchSupplier();
    }

    else if(emitted.action === 'remove')
    {
      const modalRef = this.dialog.open(ConfirmModalComponent, {
        data: { title: 'Atenção!',
          description: 'Deseja excluir o fornecedor ' + emitted.row.nome + ' ? Esta ação é irreversivel.',
          buttonConfirm: 'Excluir',
          buttonClose: 'Cancelar' 
         },
        hasBackdrop: true,
        disableClose: false
      });

      modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
        if (result.confirm) {
          this.isLoading = true;
          this.pessoaService.removeSupplierById(emitted.row.registro).subscribe({
            next: (response:ReturnStruct) => {
              if (response.isError) {
                this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
                this.isLoading = false;
                return;
              }
              this.isLoading = false;
              this.alertService.show("Sucesso!", "Fornecedor excluido", AlertType.success);
              this.searchSupplier();
      
            },
            error: (error) => {
              this.alertService.show("Erro inesperado", error, AlertType.error);
              this.isLoading = false;
              return;
            }
          });
        }
        else {
          this.isLoading = false;
          return;
        }
      });
    }
  }
}