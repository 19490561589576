import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultColorEnum } from '../../../enum/default-color.enum';
import { UserLoginResponse } from '../../../services/responses/auth/user-login.response';
import { AuthService } from '../../../services/auth.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

const today = new Date();
@Component({
  selector: 'app-date-filter',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent implements OnInit {
  public color: string = DefaultColorEnum.Cor;
  public userLoginResponse: UserLoginResponse;
  public searchDate: string;
  public startDate: Date | null = null;
  public endDate: Date | null = null;
  public model: FormGroup;
  @Input() placeholder: string;
  @Output() valueChanged = new EventEmitter<any>();

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();

    this.model = this.formBuilder.group({
      startDate: "",
      endDate: ""
    });

    this.model.get('startDate')?.valueChanges.subscribe(value => {
      this.onDateSearchChange();
    });

    this.model.get('endDate')?.valueChanges.subscribe(value => {
      this.onDateSearchChange();
    });
  }

  onDateSearchChange() {
    setTimeout(() => {
      const searchDate = this.model.value;
      if (searchDate.startDate && searchDate.endDate) {
        const startDateString = new Date(searchDate.startDate).toISOString();
        const endDateString = new Date(searchDate.endDate).toISOString();
        this.valueChanged.emit({ action: 'dateSearch', date: `${startDateString}|${endDateString}` });
      }
    }, 0);
  }

  mop() {
    this.model.get('startDate')?.setValue(null);
    this.model.get('endDate')?.setValue(null);
    this.valueChanged.emit({ action: 'dateSearch', date: null });
  }
}