import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Column } from './shared/component/advanced-table/advanced-table.component';

export interface HomeShortcut {
  name: string;
  desc: string;
  icon: string;
  redirect: string;
  idPerfil: number;
  idSubperfil: number;
}


export class ColumnsState {
  url: string;
  columns: Column[];
}


export class CorSistema {
  corHex: string;
  corHoverHex: string;
}

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  private currentTheme: 'light' | 'dark' = 'light';
  private isExpandedNavbar = new BehaviorSubject<boolean>(this.getInitialNavbarState());
  private isExpandedSubheader = new BehaviorSubject<boolean>(this.getInitialSubheaderState());
  private columnsState = new BehaviorSubject<ColumnsState[]>(this.getInitialColumnsState());
  private isViewTable = new BehaviorSubject<number>(this.getInitialViewState());
  private homeShortcuts = new BehaviorSubject<HomeShortcut[]>(this.getInitialShortcuts());
  private LogoSistema = new BehaviorSubject<string | null>(this.getInitialLogoSistema());
  private companyKey = new BehaviorSubject<number>(this.getInitialCompanyState());
  private corSistemaHex = new BehaviorSubject<string>(this.getInitialCorSistemaHex());
  private corSistemaHoverHex = new BehaviorSubject<string>(this.getInitialCorSistemaHoverHex());

  navbarState$ = this.isExpandedNavbar.asObservable();
  subheaderState$ = this.isExpandedSubheader.asObservable();
  viewState$ = this.isViewTable.asObservable();
  homeShortcuts$ = this.homeShortcuts.asObservable();
  columnsState$ = this.columnsState.asObservable();
  LogoSistema$ = this.LogoSistema.asObservable();
  company$ = this.companyKey.asObservable();

  constructor() {
    if (typeof window !== 'undefined') {
      this.navbarState$.subscribe((state) => {localStorage.setItem('navbarState', JSON.stringify(state));});
      this.subheaderState$.subscribe((state) => {localStorage.setItem('subheaderState', JSON.stringify(state));});
      this.viewState$.subscribe((state) => {localStorage.setItem('viewState', JSON.stringify(state));});
      this.homeShortcuts$.subscribe((shortcuts) => {localStorage.setItem('homeShortcuts', JSON.stringify(shortcuts));});
      this.LogoSistema$.subscribe((LogoSistema) => {localStorage.setItem('LogoSistema', JSON.stringify(LogoSistema));});
      this.columnsState$.subscribe((columnsState) => {localStorage.setItem('colunsState', JSON.stringify(columnsState));});
      this.company$.subscribe((company) => {localStorage.setItem('company', JSON.stringify(company));});
      this.checkWindowSize();
      window.addEventListener('resize', this.checkWindowSize.bind(this));
    }
  }

  // HELPERS
  private checkWindowSize(): void {
    if (this.isMobile()) {
      this.isExpandedNavbar.next(false);
      this.isExpandedSubheader.next(false);
    }
  }
  private isMobile(): boolean {
    return window.innerWidth < 992;
  }
  private getInitialNavbarState(): boolean {
    if (typeof window !== 'undefined' && localStorage.getItem('navbarState')) {
      return JSON.parse(localStorage.getItem('navbarState') as string);
    }
    return false;
  }
  private getInitialSubheaderState(): boolean {
    if (
      typeof window !== 'undefined' &&
      localStorage.getItem('subheaderState')
    ) {
      return JSON.parse(localStorage.getItem('subheaderState') as string);
    }
    return false;
  }
  private getInitialViewState(): number {
    if (typeof window !== 'undefined' && localStorage.getItem('viewState')) {
      return JSON.parse(localStorage.getItem('viewState') as string);
    }
    return 1;
  }

  private getInitialColumnsState(): ColumnsState[] {
    if (typeof window !== 'undefined' && localStorage.getItem('colunsState')) {
      return JSON.parse(localStorage.getItem('colunsState') as string);
    }
    return [];
  }

  private getInitialLogoSistema(): string | null {
    if (typeof window !== 'undefined' && localStorage.getItem('LogoSistema')) {
      return localStorage.getItem('LogoSistema');
    }
    return null;
  }

  private getInitialCorSistemaHex(): string {
    if (typeof window !== 'undefined' && localStorage.getItem('corSistemaHex')) {
      return localStorage.getItem('corSistemaHex') as string;
    }
    return "";
  }

  private getInitialCorSistemaHoverHex(): string {
    if (typeof window !== 'undefined' && localStorage.getItem('corSistemaHoverHex')) {
      return localStorage.getItem('corSistemaHoverHex') as string;
    }
    return "";
  }

  private getInitialShortcuts(): HomeShortcut[] {
    if (
      typeof window !== 'undefined' &&
      localStorage.getItem('homeShortcuts')
    ) {
      return JSON.parse(localStorage.getItem('homeShortcuts') as string);
    }
    return [];
  }

  private getInitialCompanyState(): number {
    if (typeof window !== 'undefined' && localStorage.getItem('company')) {
      return JSON.parse(localStorage.getItem('company') as string);
    }
    return 0;
  }

  // NAVBAR SERVICE
  getNavbarState(): boolean {
    return this.isExpandedNavbar.value;
  }
  setNavbarState(expanded: boolean): void {
    if (this.isMobile()) {
      this.isExpandedNavbar.next(false);
    } else {
      this.isExpandedNavbar.next(expanded);
    }
  }
  toggleNavbarState(): void {
    if (!this.isMobile()) {
      this.isExpandedNavbar.next(!this.isExpandedNavbar.value);
    }
  }

  // SUBHEADER SERVICE
  getSubheaderState(): boolean {
    return this.isExpandedSubheader.value;
  }
  setSubheaderState(expanded: boolean): void {
    if (this.isMobile()) {
      this.isExpandedSubheader.next(false);
    } else {
      this.isExpandedSubheader.next(expanded);
    }
  }

  toggleSubheaderState(): void {
    if (!this.isMobile()) {
      this.isExpandedSubheader.next(!this.isExpandedSubheader.value);
    }
  }

  getLogoSistema(): string | null {
    return this.LogoSistema.value;
  }
  
  setLogoSistema(logo: string): void {
      localStorage.setItem('LogoSistema', logo);
  }

  loadCorSistema(): void {
    let corSistemaHex =  localStorage.getItem('corSistemaHex') as string;
    let corSistemaHoverHex =  localStorage.getItem('corSistemaHoverHex') as string;
    if (corSistemaHex && corSistemaHoverHex)
      this.setCorSistema(corSistemaHex, corSistemaHoverHex);
    else {
      this.setCorSistema("", "");
    }
  }

  setCorSistema(corHex: string, corHoverHex: string): void {    
      document.documentElement.style.setProperty('--main-color', corHex);
      document.documentElement.style.setProperty('--main-100', corHex);
      document.documentElement.style.setProperty('--main-200', corHoverHex);
      localStorage.setItem('corSistemaHex', corHex);
      localStorage.setItem('corSistemaHoverHex', corHoverHex);
  }
  
  saveColumnOrder(url: string, columns: Column[]): void {
    let currentState = this.columnsState.value; // Obtém o valor do BehaviorSubject
  
    if (!Array.isArray(currentState)) {
      currentState = [];
    }
  
    const existingStateIndex = currentState.findIndex(state => state.url === url);
  
    if (existingStateIndex !== -1) {
      currentState[existingStateIndex].columns = columns;
    } else {
      currentState.push({ url, columns });
    }

    this.columnsState.next([...currentState]);
    localStorage.setItem('colunsState', JSON.stringify(currentState));
  }

  getColumnOrder(url: string): Column[] | null {
    const currentState = localStorage.getItem('colunsState');
    if (currentState){
      const currentStateParse = JSON.parse(currentState) as {url: string, columns:Column[]}[];
      const state = currentStateParse.find((state) => state.url === url);
      return state ? state.columns : null;
    }
    return null
  }

  // THEME SERVICE
  loadTheme() {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark';
    if (savedTheme) {
      this.setTheme(savedTheme);
    } else {
      this.setTheme('light');
    }
  }

  getTheme(): 'light' | 'dark' {
    return this.currentTheme;
  }

  setTheme(theme: 'light' | 'dark') {
    this.currentTheme = theme;
    document.documentElement.setAttribute('data-theme', theme);
    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', theme);
    }
  }
  toggleTheme() {
    const newTheme = this.currentTheme === 'light' ? 'dark' : 'light';
    this.setTheme(newTheme);
  }

  // VIEW SERVICE
  getViewState(): number {
    return this.isViewTable.value;
  }
  setViewState(viewId: number): void {
    this.isViewTable.next(viewId);
  }

  // HOME SHORTCUTS
  getShortcuts(): HomeShortcut[] {
    return this.homeShortcuts.value;
  }

  addShortcut(shortcut: HomeShortcut): void {
    const updatedShortcuts = [...this.homeShortcuts.value, shortcut];
    this.homeShortcuts.next(updatedShortcuts);
  }

  removeShortcut(shortcut: HomeShortcut): void {
    const updatedShortcuts = this.homeShortcuts.value.filter(
      (s) => s.redirect !== shortcut.redirect
    );
    this.homeShortcuts.next(updatedShortcuts);
  }

  // COMPANY SERVICE
  getCompanyState(): number {
    return this.companyKey.value;
  }
  setCompanyState(companyKey: number): void {
    this.companyKey.next(companyKey);
  }
}
