import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from '../../services/alert.service';
import { UserLoginResponse } from '../../services/responses/auth/user-login.response';
import { AuthService } from '../../services/auth.service';
import { DefaultColorEnum } from '../../enum/default-color.enum';

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatButtonModule, MatIconModule, ReactiveFormsModule],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.css'
})
export class SearchBarComponent  implements OnInit {
  public color: string = DefaultColorEnum.Cor;
  public userLoginResponse:UserLoginResponse;
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute
  ){

  }

  public model: FormGroup;
  @Input() isShowMobile:boolean;
  
  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos()
    this.model = this.formBuilder.group({
      seachText: [''],
    });
    
    if(this.userLoginResponse.empresaLogada?.corSistema){
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
  }

  search(){

  }

  onMicClick() {

  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }
}
