import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from '../../../shared/component/search-bar/search-bar.component';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { AuthService } from '../../../shared/services/auth.service';
import { ViewSwitcherComponent } from '../../../shared/component/view-switcher/view-switcher.component';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { RoutesService } from '../../../routes.service';
import { CaixaSearchResponse } from '../../../shared/services/responses/financeiro/caixa-search.response';
import { CaixaSearchRequest } from '../../../shared/services/requests/financeiro/caixa-search.request';
import { PerfilStruct } from '../../../shared/services/structs/pessoa/perfil.struct';

@Component({
  selector: 'app-caixa-lista',
  standalone: true,
  imports: [
    SearchBarComponent,
    MatCardModule,
    MatIconModule,
    CommonModule,
    SkeletonComponent,
    ViewSwitcherComponent,
    AdvancedTableComponent,
    SubheaderComponent
  ],
  templateUrl: './caixa-lista.component.html',
  styleUrl: './caixa-lista.component.css',
})
export class CaixaListaComponente implements OnInit {
  @ViewChild(AdvancedTableComponent) exhibit: AdvancedTableComponent;
  public userLoginResponse: UserLoginResponse;
  public listPerfil: PerfilStruct[];
  public isLoading: boolean = true;
  constructor(
      private authService: AuthService,
      private alertService: AlertService,
      private financeiroService: FinanceiroService,
      private routesService: RoutesService,
      private formBuilder: FormBuilder,
      private router: Router,
      private dialog: MatDialog,
  ) {}
  public model: FormGroup;
  public tableEnum: number = ExhibitEnum.Tabela;
  public isTable: boolean = true;
  public searchTimeout: any;
  public dataList: any[];
  
  tableColumns = [
    {
      key: 'nome',
      label: 'Nome',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      filterable: false,
      date: false,
    },
    {
      key: 'inclusao',
      label: 'Inclusão',
      sortable: true,
      filterable: false,
      date: true,
    },
    {
      key: 'valorAtual',
      label: 'Valor em caixa',
      sortable: true,
      filterable: false,
      date: true,
    },
  ];

  ngOnInit(): void {    
    this.model = this.formBuilder.group({
    filterName: [''],
    filters: [],
    filterDate: [''],
    currentPage: [0],
    totalPages: [0],
  });
    this.userLoginResponse = this.authService.getUserInfos();
    this.searchClient();

  }

  searchClient() {
    this.isLoading = true;
    const searchRequest: CaixaSearchRequest = new CaixaSearchRequest();
    if (this.model.get('filters')?.value) {
      searchRequest.filters = this.model.get('filters')?.value;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value) {
      this.model.get('currentPage')?.setValue(0);
      const [startDateString, endDateString] = this.model
        .get('filterDate')
        ?.value.split('|');
      searchRequest.dataInicio = startDateString;
      searchRequest.dataFim = endDateString;
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.financeiroService.caixaLista(searchRequest).subscribe({
        next: (response: CaixaSearchResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.dataList = response.listaCaixaStruct;
          this.model.get('totalPages')?.setValue(response.pageSize);
          setTimeout(() => {
            this.update();
          }, 3000);
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }, 10);
  }

   exhibitActions(emitted: any) {
      if (emitted.action === 'search') {
        this.model.get('filterName')?.setValue(emitted.search);
        this.model.get('filters')?.setValue(emitted.filters);
        this.searchClient();
      } else if (emitted.action === 'edit') {
        this.router.navigate([
          this.routesService.CAIXA_UPDATE(emitted.row.registro),
        ]);
      } else if (emitted.action === 'dateSearch') {
        if (emitted.dateSearch.date != null) {
          this.model.get('filterDate')?.setValue(emitted.dateSearch.date);
        } else {
          this.model.get('filterDate')?.setValue(null);
        }
        this.searchClient();
      } else if (emitted.action === 'remove') {
        const modalRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            title: 'Atenção!',
            description:
              'Deseja excluir o caixa ' +
              emitted.row.nome +
              ' ? Esta ação é irreversivel.',
            buttonConfirm: 'Excluir',
            buttonClose: 'Cancelar',
          },
          hasBackdrop: true,
          disableClose: false,
        });
  
        modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
          if (result.confirm) {
            this.isLoading = true;
            this.financeiroService.RemoveCaixa(emitted.row.registro).subscribe({
              next: (response: ReturnStruct) => {
                if (response.isError) {
                  this.alertService.show(
                    'Erro inesperado',
                    response.errorDescription,
                    AlertType.error
                  );
                  this.isLoading = false;
                  return;
                }
                this.isLoading = false;
                this.alertService.show(
                  'Sucesso!',
                  'Caixa excluido',
                  AlertType.success
                );
                this.searchClient();
              },
              error: (error) => {
                this.alertService.show('Erro inesperado', error, AlertType.error);
                this.isLoading = false;
                return;
              },
            });
          } else {
            this.isLoading = false;
            return;
          }
        });
      }
    }

      update() {
        this.exhibit.update();
        setTimeout(() => {
          this.isLoading = false;
        }, 0);
      }

    onExhibitChange(event: any) {
      if (event == this.tableEnum) {
        this.isTable = true;
      } else {
        this.isTable = false;
      }
    }

}