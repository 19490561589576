import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultColorEnum } from '../../enum/default-color.enum';
import { UserLoginResponse } from '../../services/responses/auth/user-login.response';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { DateFilterComponent } from '../filter-list/date-filter/date-filter.component';
import { OpenTextFilterComponent } from '../filter-list/open-text-filter/open-text-filter.component';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    CommonModule,
    MatIconModule,
    DateFilterComponent,
    OpenTextFilterComponent,
    FormsModule,
  ],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  public color: string = DefaultColorEnum.Cor;
  public userLoginResponse: UserLoginResponse;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {}

  public model: FormGroup;
  @Input() data: any[] = [];
  @Input() isLoading: boolean = false;
  @Input() isShowMobile: boolean;
  @Output() valueChanged = new EventEmitter<any>();
  public isShowLoading: boolean = true;
  columns: string[] = [];
  sortedColumn: string = '';
  sortOrder: 'asc' | 'desc' = 'asc';
  filterText: string = '';
  emptyRows = Array(1);
  emptyColumns = Array(6);
  private debounceTimeout: any;

  filteredData: any[] = [];

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();
    this.model = this.formBuilder.group({
      searchText: [''],
    });

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }

    this.updateColumns();
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  updateColumns(): void {
    if (this.data && this.data.length > 0) {
      this.isShowLoading = false;
      this.columns = Object.keys(this.data[0]);
      this.filteredData = [...this.data];
    } 
    else if (!this.data) {
      this.isShowLoading = false;
      this.columns = Array(1).fill('Sem dados');
    }
  }

  onDateSearchChange(value: any) {
    this.valueChanged.emit(value);
  }

  edit(row: any) {
    this.valueChanged.emit({ action: 'edit', row: row });
  }

  remove(row: any) {
    this.valueChanged.emit({ action: 'remove', name: this.userLoginResponse.nomeUsuario, row: row });
  }

  sortData(column: string): void {
    if (this.sortedColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortedColumn = column;
      this.sortOrder = 'asc';
    }

    this.filteredData.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) return this.sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return this.sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }

  onFilterChange(value: string) {
    this.filterText = value;
    this.applyFilter();
  }

  applyFilter() {
    // Cancelar a execução anterior
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    // Iniciar novo debounce
    this.debounceTimeout = setTimeout(() => {
      if (!this.filterText) {
        this.filteredData = [...this.data];
        this.isLoading = false;
      } else {
        const lowerFilter = this.filterText.toLowerCase();
        this.filteredData = this.data.filter(row =>
          this.columns.some(column =>
            String(row[column]).toLowerCase().includes(lowerFilter)
          )
        );

        this.isLoading = this.filteredData.length < 30; // Simular carregamento
        if (this.isLoading) {
          this.valueChanged.emit({ action: 'search', search: this.filterText });
        }
      }
    }, 300); // Tempo do debounce
  }  
}
