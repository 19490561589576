<app-menu></app-menu>
<div class="container">
    <div class="container-separation">
        <div class="module-content">
            <div class="module-info">
                <div class="icon-content">
                    <mat-icon class="icon">{{iconProfile}}</mat-icon>
                </div>
                <div class="name-content">
                    {{nameProfile}}
                </div>
            </div>
        </div>
    </div>
    <div class="container-separation">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="form-grid">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput id="nome" formControlName="nome" type="text"/>
                        <mat-error *ngIf="model.get('nome')?.touched && model.get('nome')?.invalid">
                            O campo nome é obrigatório.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Sobrenome</mat-label>
                        <input matInput id="sobrenome" formControlName="sobrenome" type="text" placeholder="Sobrenome" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Razão Social</mat-label>
                        <input matInput id="razaoSocial" formControlName="razaoSocial" type="text" placeholder="Razão Social" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput id="email" formControlName="email" type="email" placeholder="exemplo@dominio.com"/>
                        <mat-error *ngIf="model.get('email')?.touched && model.get('email')?.invalid">
                            O email informado é inválido.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>CPF/CNPJ</mat-label>
                        <input matInput id="cpfCnpj" formControlName="cpfCnpj" type="text" (input)="onCpfCnpjInput($event)" placeholder="000.000.000-00 || 00.000.000/0000-00"/>
                        <mat-error *ngIf="model.get('cpfCnpj')?.touched && model.get('cpfCnpj')?.invalid">
                            O campo CPF/CNPJ é obrigatório.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone</mat-label>
                        <input matInput id="telefone" formControlName="telefone" type="text" (input)="onTelefoneInput($event)" placeholder="(00) 00000-0000" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone 2</mat-label>
                        <input matInput id="telefone2" formControlName="telefone2" type="text" (input)="onSecondTelefoneInput($event)" placeholder="(00) 00000-0000" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Data de Nascimento</mat-label>
                        <input matInput id="nascimento" formControlName="nascimento" type="date" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>CEP</mat-label>
                        <input matInput id="cep" formControlName="cep" type="text" 
                               (ngModelChange)="searchCep($event)" 
                               (input)="onCepInput($event)" 
                               placeholder="CEP" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Logradouro</mat-label>
                        <input matInput id="logradouro" formControlName="logradouro" type="text" placeholder="Logradouro" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Cidade</mat-label>
                        <input matInput id="cidade" formControlName="cidade" type="text" placeholder="Cidade" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>UF</mat-label>
                        <input matInput id="uf" formControlName="uf" type="text" placeholder="UF" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Número</mat-label>
                        <input matInput id="numero" formControlName="numero" type="text" placeholder="Número" />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Bairro</mat-label>
                        <input matInput id="bairro" formControlName="bairro" type="text" placeholder="Bairro" />
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="button-display">
        <div class="submit">
            <button class="button btn-primary save" (click)="submit()" [disabled]="isLoading">
                <ng-container *ngIf="isLoading; else notLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </ng-container>
                <ng-template #notLoading>
                    Salvar
                </ng-template>
            </button>
            
            <button class="button cancel" (click)="backPage()">Cancelar</button>
        </div>
    </div>