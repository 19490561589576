<div class="main-container">
    <app-subheader [icon]="'inventory'" [title]="'Produto'" [description]="_idProduto ? 'Edição de um produto existente' : 'Cadastro de um novo produto'" [isLoading]="isLoading" [isAddPage]="_idProduto ? false : true" [isEditPage]="_idProduto ? true : false"></app-subheader>

    <div class="container">
        <ng-container *ngIf="_produtoResponse !== undefined">
                <div class="form-step">
                    <div [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                        <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
                    </div>
                    <div [ngClass]="step >= 2 ? 'step-separator-done' : 'step-separator'"></div>
                    <div [ngClass]="step === 2 ? 'step-active' : 'step-item'">
                        <mat-icon [ngClass]="step >= 2 ? 'icon-item-step-active' : 'icon-item-step'">request_page</mat-icon>
                    </div>
                    <div [ngClass]="step >= 3 ? 'step-separator-done' : 'step-separator'"></div>
                    <div [ngClass]="step === 3 ? 'step-active' : 'step-item'">
                        <mat-icon [ngClass]="step >= 2 ? 'icon-item-step-active' : 'icon-item-step'">photo_camera</mat-icon>
                    </div>
                </div>
                <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
                    <app-skeleton variant="block" shape="card" width="100%" height="180px"
                        style="padding-bottom: 30px;"></app-skeleton>
                    <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
                    <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
                    <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
                    <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
                </div>
                <form [formGroup]="model">
                    <div *ngIf="step === 1">
                        <div class="step-title" style="margin-top: 10px">
                            <p>PRODUTO</p>
                        </div>
                            <div class="row" style="padding-bottom: 25px; margin-top: -10px">
                                <div class="col-12 col-sm-5 col-md-6 input-space">
                                    <label class="input-label" for="productName">Nome do produto</label>
                                    <input matInput id="productName" formControlName="productName" type="text" 
                                        [ngClass]="model.get('productName')?.touched && model.get('productName')?.invalid ? 'input-failed' : 'input-field'" 
                                        placeholder="Informe o nome do produto" />
                                    <span *ngIf="model.get('productName')?.touched && model.get('productName')?.invalid">Informe o nome do produto.</span>
                                </div>
                                <div class="col-12 col-sm-4 col-md-2 input-space">
                                    <label class="input-label" for="ean">EAN / GTIN</label>
                                    <input matInput id="ean" formControlName="ean" type="text" 
                                        [ngClass]="model.get('ean')?.touched && model.get('ean')?.invalid ? 'input-failed' : 'input-field'" 
                                        placeholder="Informe o código EAN" />
                                    <span *ngIf="model.get('ean')?.touched && model.get('ean')?.invalid">Informe o código EAN do produto.</span>
                                </div>
                                <div class="col-12 col-sm-4 col-md-2 input-space">
                                    <label class="input-label" for="ean">Cod. interno (SKU)</label>
                                    <input matInput id="productCode" formControlName="productCode" type="text" 
                                        [ngClass]="model.get('productCode')?.touched && model.get('productCode')?.invalid ? 'input-failed' : 'input-field'" 
                                        placeholder="Informe o código interno" />
                                    <span *ngIf="model.get('productCode')?.touched && model.get('productCode')?.invalid">Informe o código do produto.</span>
                                </div>
                                <div class="col-12 col-sm-4 col-md-2 input-space" style="display: flex; flex-direction: row; justify-content: space-evenly; flex-wrap: wrap; margin-top: 12px; align-items: center;">
                                    <button class="cancel-btn">
                                            Fornecedor
                                    </button>
                                </div>
                            </div>
                            <app-section-product-grid 
                                (reloadProduct)="loadProduct()" 
                                (prevStep)="prevStep()" 
                                (nextStep)="nextStep()" 
                                [_produtoResponse]="_produtoResponse">
                            </app-section-product-grid>
                            <div class="controller">
                            <button (click)="backPage()" class="cancel-btn">Voltar</button>
                            <button (click)="saveProductBase()" class="main-btn">Salvar e continuar</button>
                        </div>
                    </div>
                </form>
        <div *ngIf="step === 2">
            <div class="step-title">
                <p>PERFIL FISCAL DO PRODUTO</p>
            </div>
            <app-estoque-fiscal
                (reloadProduct)="loadProduct()" 
                (prevStep)="prevStep()" 
                (nextStep)="nextStep()" 
                [_produtoResponse]="_produtoResponse">
            </app-estoque-fiscal>
            <div class="controller">
                <button (click)="prevStep()" class="cancel-btn">Voltar</button>
                <button (click)="saveProductBase()" class="main-btn">Salvar e continuar</button>
            </div>
        </div>
        <div *ngIf="step === 3">
            <ng-container *ngIf="_idProduto !== undefined">
                    <h2 class="subtitle-default">Imagens</h2>
                    <app-section-product-images (reloadProduct)="loadProduct()" [_produtoResponse]="_produtoResponse" (prevStep)="prevStep()" (nextStep)="backPage()" ></app-section-product-images>
            </ng-container>
        </div>
        </ng-container>
    </div>
</div>