<div class="main-container">
    <app-subheader [icon]="iconProfile" [title]="'Fornecedor'"[description]="_idFornecedor !== null ? 'Edição de um fornecedor existente' : 'Cadastro de um novo fornecedor'" [isLoading]="isLoading" [isAddPage]="_idFornecedor !== null ? false : true" [isEditPage]="_idFornecedor !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px" style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
            </div>
            <div [ngClass]="step >= 2 ? 'step-separator-done' : 'step-separator'"></div>
            <div (click)="setStep(2)" [ngClass]="step === 2 ? 'step-active' : step === 1 ? 'step-item' : 'step-done'">
                <mat-icon [ngClass]="step >= 2 ? 'icon-item-step-active' : 'icon-item-step'">location_on</mat-icon>
            </div>
            <div [ngClass]="step >= 3 ? 'step-separator-done' : 'step-separator'"></div>
            <div (click)="setStep(3)" [ngClass]="step === 3 ? 'step-active' : 'step-item'">
                <mat-icon [ngClass]="step >= 3 ? 'icon-item-step-active' : 'icon-item-step'">person_add</mat-icon>
            </div>
        </div>
        <form [formGroup]="model" (ngSubmit)="submit()">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>DADOS PRINCIPAIS</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="cpfCnpj">CPF/CNPJ</label>
                                <input matInput id="cpfCnpj" formControlName="cpfCnpj" type="text" (input)="onCpfCnpjInput($event)" [ngClass]="model.get('cpfCnpj')?.touched && model.get('cpfCnpj')?.invalid ? 'input-failed' : 'input-field'" placeholder="000.000.000-00 || 00.000.000/0000-00" />
                                <span *ngIf="model.get('cpfCnpj')?.touched && model.get('cpfCnpj')?.invalid">O campo CPF/CNPJ é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="nome">{{isJuridicPerson() ? 'Razão social' : 'Nome'}}</label>
                                <input matInput id="nome" formControlName="nome" type="text" [ngClass]="model.get('nome')?.touched && model.get('nome')?.invalid ? 'input-failed' : 'input-field'" [placeholder]="isJuridicPerson() ? 'Empresa S/A' : 'José Arnaldo'" />
                                <span *ngIf="model.get('nome')?.touched && model.get('nome')?.invalid">O campo {{isJuridicPerson() ? 'razão social' : 'nome'}} é obrigatório.</span>
                            </div>
                        </div>
                        <!-- <div *ngIf="isJuridicPerson()" class="form-group">
                                <div class="input-control">
                                    <label class="input-label" for="razaoSocial">Razão Social</label>
                                    <input matInput id="razaoSocial" formControlName="razaoSocial" type="text" placeholder="Razão Social" placeholder="Empresa S/A" class="input-field" />
                                </div>
                            </div> -->
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="email">Email</label>
                                <input matInput id="email" formControlName="email" type="email" placeholder="exemplo@dominio.com" [ngClass]="model.get('email')?.touched && model.get('email')?.invalid ? 'input-failed' : 'input-field'" />
                                <span *ngIf="model.get('email')?.touched && model.get('email')?.invalid">O email informado é inválido.</span>
                            </div>
                        </div>
                        <div class="custom-select">
                            <label class="input-label" for="tipoFornecedor">Tipo</label>
                            <input type="text" [ngClass]="model.get('tipoFornecedor')?.touched && model.get('tipoFornecedor')?.invalid ? 'input-failed' : 'input-field'" placeholder="Selecione o tipo" formControlName="tipoFornecedor" (focus)="toggleDropdown()" />
                            <ul *ngIf="isDropdownOpen" class="dropdown">
                                <li *ngFor="let supplierType of listSupplierType" (click)="selectSupplier(supplierType); $event.stopPropagation()">
                                    {{ supplierType.nomeTipoFornecedor }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="telefone">Telefone</label>
                                <input matInput id="telefone" formControlName="telefone" type="text" (input)="onTelefoneInput($event)" placeholder="(00) 00000-0000" class="input-field" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="telefone2">Telefone2</label>
                                <input matInput id="telefone2" formControlName="telefone2" type="text" (input)="onSecondTelefoneInput($event)" placeholder="(00) 00000-0000" class="input-field" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="nascimento">{{ isJuridicPerson() ? 'Data de Abertura' : 'Data de Nascimento' }}</label>
                                <input matInput id="nascimento" formControlName="nascimento" type="date" class="input-field" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="controller">
                    <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
                    <button (click)="nextStep()" class="main-btn">PRÓXIMO</button>
                </div>
            </div>
            <div *ngIf="step === 2">
                <div class="step-title">
                    <p>ENDEREÇO</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="cep">CEP</label>
                                <input matInput id="cep" formControlName="cep" type="text" (input)="onCepInput($event)" placeholder="00000-000" class="input-field" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="logradouro">Logradouro</label>
                                <input matInput id="logradouro" formControlName="logradouro" type="text" placeholder="Logradouro" class="input-field" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="cidade">Cidade</label>
                                <input matInput id="cidade" formControlName="cidade" type="text" placeholder="Cidade" class="input-field" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="uf">UF</label>
                                <input matInput id="uf" formControlName="uf" type="text" placeholder="UF" class="input-field" />
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="numero">Número</label>
                                <input matInput id="numero" formControlName="numero" type="text" placeholder="123" class="input-field" #numeroEndereco />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="bairro">Bairro</label>
                                <input matInput id="bairro" formControlName="bairro" type="text" placeholder="Bairro" class="input-field" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="complemento">Complemento</label>
                                <input matInput id="complemento" formControlName="complemento" type="text" placeholder="Complemento" class="input-field" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button (click)="nextStep()" class="main-btn">PRÓXIMO</button>
                </div>
            </div>
            <div *ngIf="step === 3">
                <div class="step-title">
                    <p>CONTATOS</p>
                </div>
                <div class="form-flex">
                    <div class="flexed-wrapper">
                        <!-- <div class="form-flexed">
                                <div class="input-control" style="width: 25%;">
                                    <label class="input-label" for="contato-nome">Nome</label>
                                    <input type="text" id="contato-nome" formControlName="nome" placeholder="José Arnaldo" class="input-field" />
                                </div>
                                <div class="input-control" style="width: 30%;">
                                    <label class="input-label" for="contato-email">Email</label>
                                    <input type="text" id="contato-email" formControlName="email" placeholder="exemplo@email.com" class="input-field" />
                                </div>
                                <div class="input-control" style="width: 20%;">
                                    <label class="input-label" for="complemento">Telefone</label>
                                    <input type="text" id="contato-telefone" formControlName="telefone" placeholder="(31) 99999-9999" class="input-field" />
                                </div>
                                <div class="input-control" style="margin-bottom: 2px; width: 15%;">
                                    <button type="button" (click)="adicionarContato()" class="main-btn">NOVO CONTATO</button>
                                </div>
                            </div> -->
                        <div formArrayName="contatos" style="width: 100%;">
                            <div *ngFor="let contato of contatos.controls; let i = index" [formGroupName]="i" class="form-flexed">
                                <div class="input-control" style="width: 25%;">
                                    <label class="input-label" for="contato-nome-{{ i }}">Nome do Contato</label>
                                    <input type="text" id="contato-nome-{{ i }}" formControlName="nome" placeholder="Nome do contato" class="input-field" />
                                </div>
                                <div class="input-control" style="width: 30%;">
                                    <label class="input-label" for="contato-email-{{ i }}">Email do Contato</label>
                                    <input type="email" id="contato-email-{{ i }}" formControlName="email" placeholder="email@contato.com" class="input-field" />
                                </div>
                                <div class="input-control" style="width: 20%;">
                                    <label class="input-label" for="contato-telefone-{{ i }}">Telefone do Contato</label>
                                    <input type="text" id="contato-telefone-{{ i }}" formControlName="telefone" placeholder="(99) 99999-9999" class="input-field" />
                                </div>
                                <div class="input-control" style="margin-bottom: 2px; width: 15%;">
                                    <button (click)="removerContato(i)" class="warning-btn">EXCLUIR CONTATO</button>
                                </div>
                            </div>
                        </div>

                        <!-- Botão para adicionar novo contato -->
                        <div class="form-flexed">
                            <div class="input-control" style="margin-bottom: 2px; width: 15%;">
                                <button type="button" (click)="adicionarContato()" class="main-btn">
                                    NOVO CONTATO
                                </button>
                            </div>
                        </div>
                        <!-- <div class="form-flexed">
                                <div class="input-control" style="width: 25%;">
                                    <input type="text" value="José Arnaldo" class="input-field" />
                                </div>
                                <div class="input-control" style="width: 30%;">
                                    <input type="text" value="exemplo@email.com" class="input-field" />
                                </div>
                                <div class="input-control" style="width: 20%;">
                                    <input type="text" value="(31) 99999-9999" class="input-field" />
                                </div>
                                <div class="input-control" style="margin-bottom: 2px; width: 15%;">
                                    <button class="warning-btn">EXCLUIR CONTATO</button>
                                </div>
                            </div> -->
                    </div>
                </div>
                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button (click)="submit()" class="main-btn">SALVAR</button>
                </div>
            </div>
        </form>
    </div>
</div>