import { CommonModule, ViewportScroller, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { RoutesService } from '../../../routes.service';
import { TipoAddConfigEnum } from '../../../shared/enum/Config/tipo-add-config.enum';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { StatusEnum } from '../../../shared/enum/status.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { BancoEmpresaSearchRequest } from '../../../shared/services/requests/financeiro/banco-empresa-search.request';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ListBancoEmpresaStructResponse } from '../../../shared/services/responses/financeiro/list-banco-empresa-struct.response';
import { ListStatusStruct } from '../../../shared/services/structs/listStatus.struct';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateFilterComponent } from '../../../shared/component/filter-list/date-filter/date-filter.component';
import { OpenTextFilterComponent } from '../../../shared/component/filter-list/open-text-filter/open-text-filter.component';
import { LoadingBoxComponent } from '../../../shared/component/loading-box/loading-box.component';
import { Router } from '@angular/router';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { ViewSwitcherComponent } from '../../../shared/component/view-switcher/view-switcher.component';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { MatDialog } from '@angular/material/dialog';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';

@Component({
  selector: 'app-banco-lista',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBoxComponent,
    OpenTextFilterComponent,
    DateFilterComponent,
    SubheaderComponent,
    ViewSwitcherComponent,
    AdvancedTableComponent,
    SkeletonComponent

  ],
  templateUrl: './banco-lista.component.html',
  styleUrl: './banco-lista.component.css',
})
export class BancoListaComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    public financeiroService: FinanceiroService,
    private formBuilder: FormBuilder,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private location: Location,
    private routesService: RoutesService
  ) { }
  public model: FormGroup;
  public dataList: any[];
  public searchTimeout: any;
  public dialog: MatDialog;
  
  public profile: number = DefaultProfilesEnum.Configuracao;
  public status: ListStatusStruct = new ListStatusStruct();
  public listStatus = this.status.listStatus;
  public ativo: boolean = true;

  public tableEnum: number = ExhibitEnum.Tabela;
  public isTable: boolean = true;

  tableColumns = [
    {
      key: 'nome',
      label: 'Nome',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'numBanco',
      label: 'Ref',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      filterable: false,
      date: false,
    },
    {
      key: 'inclusao',
      label: 'Inclusão',
      sortable: true,
      filterable: false,
      date: true,
    },
  ];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      search: [''],
      filterDate: [''],
      currentPage: [0],
      totalPages: [0],
      statusSelect: [1],
    });

    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);

    this.searchCard();
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchCard();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchCard();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  searchCard() {
    this.isLoading = true;
    const searchRequest: BancoEmpresaSearchRequest =
      new BancoEmpresaSearchRequest();
    if (this.model.get('search')?.value) {
      searchRequest.searchText = this.model.get('search')?.value;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value) {
      const [startDateString, endDateString] = this.model
        .get('filterDate')
        ?.value.split('|');
      searchRequest.dataInicio = startDateString;
      searchRequest.dataFim = endDateString;
      this.model.get('currentPage')?.setValue(0);
    }
    if (
      this.model.get('statusSelect')?.value &&
      this.model.get('statusSelect')?.value != this.listStatus[0]
    ) {
      searchRequest.ativo = this.ativo;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.financeiroService.searchBankEnterprise(searchRequest).subscribe({
        next: (response: ListBancoEmpresaStructResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.dataList = response.listBancoEmpresaStruct;
          this.model.get('totalPages')?.setValue(response.pageSize);
          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }, 3000);
  }

  redirect(idRedirect: number) {
    this.router.navigate([this.routesService.BANK_UPDATE(String(idRedirect))]);
  }

  onStatusChange(event: any) {
    if (event.target.value == StatusEnum.Ativo) {
      this.ativo = true;
      this.model.get('currentPage')?.setValue(0);
      this.searchCard();
    } else {
      this.ativo = false;
      this.model.get('currentPage')?.setValue(0);
      this.searchCard();
    }
  }

     exhibitActions(emitted: any) {
        if (emitted.action === 'search') {
          this.model.get('filterName')?.setValue(emitted.search);
          this.model.get('filters')?.setValue(emitted.filters);
          this.searchCard();
        } else if (emitted.action === 'edit') {
          this.router.navigate([
            this.routesService.USER_UPDATE(emitted.row.registro),
          ]);
        } else if (emitted.action === 'dateSearch') {
          if (emitted.dateSearch.date != null) {
            this.model.get('filterDate')?.setValue(emitted.dateSearch.date);
          } else {
            this.model.get('filterDate')?.setValue(null);
          }
          this.searchCard();
        } else if (emitted.action === 'remove') {
          const modalRef = this.dialog.open(ConfirmModalComponent, {
            data: {
              title: 'Atenção!',
              description:
                'Deseja excluir o usuario ' +
                emitted.row.nome +
                ' ? Esta ação é irreversivel.',
              buttonConfirm: 'Excluir',
              buttonClose: 'Cancelar',
            },
            hasBackdrop: true,
            disableClose: false,
          });
    
          // modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
          //   if (result.confirm) {
          //     this.isLoading = true;
          //     this.financeiroService.bankEnterprise(emitted.row.registro).subscribe({
          //       next: (response: ReturnStruct) => {
          //         if (response.isError) {
          //           this.alertService.show(
          //             'Erro inesperado',
          //             response.errorDescription,
          //             AlertType.error
          //           );
          //           this.isLoading = false;
          //           return;
          //         }
          //         this.isLoading = false;
          //         this.alertService.show(
          //           'Sucesso!',
          //           'Banco excluido',
          //           AlertType.success
          //         );
          //         this.searchCard();
          //       },
          //       error: (error) => {
          //         this.alertService.show('Erro inesperado', error, AlertType.error);
          //         this.isLoading = false;
          //         return;
          //       },
          //     });
          //   } else {
          //     this.isLoading = false;
          //     return;
          //   }
          // });
        }
      }
      onExhibitChange(event: any) {
        if (event == this.tableEnum) {
          this.isTable = true;
        } else {
          this.isTable = false;
        }
      }
}
