import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaskService {
  private masks: Masks = {
    phone: "(00) 00000-0000",
    cpf: "000.000.000-00",
    cnpj: "00.000.000/0000-00",
    email: "",
    cep: "00000-000",
    money: "R$ #.###,00"
  };

  constructor() {}

  getMasks(): Masks {
    return this.masks;
  }

  applyMask(value: string, mask: string, isDeleting = false): string {
    if (!value) return "";

    let numericValue = value.replace(/\D/g, ''); // Remove tudo que não for número
    let maskedValue = "";
    let index = 0;

    for (let char of mask) {
      if (index >= numericValue.length) break;

      if (char === '0' || char === '#') {
        maskedValue += numericValue[index++];
      } else {
        if (!isDeleting || index < numericValue.length) {
          maskedValue += char;
        }
      }
    }

    return maskedValue;
  }

  validateEmail(value: string): boolean {
    if (!value) return false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(value);
  }
  
  formatMoney(value: number): string {
    if (isNaN(value)) return "R$ 0,00";
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }
}

export interface Masks {
  phone: string;
  cpf: string;
  cnpj: string;
  email: string;
  cep: string;
  money: string;
}