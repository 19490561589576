import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxEditorModule, Validators } from 'ngx-editor';
import { RoutesService } from '../../routes.service';
import { DefaultColorEnum } from '../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { AuthService } from '../../shared/services/auth.service';
import { MaskService } from '../../shared/services/mask.service';
import { UserLoginResponse } from '../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../shared/services/structs/return.struct';
import { FiscalService } from '../../shared/services/API/fiscal/fiscal.service';
import { ListaSelectResponse } from '../../shared/services/responses/fiscal/lista-select.response';
import { TipoConsumidor } from '../../shared/services/models/fiscal/tipo-consumidor.model';
import { Uf } from '../../shared/services/models/fiscal/uf.model';
import { SkeletonComponent } from '../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../shared/component/subheader/subheader.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NcmSearchStruct } from '../../shared/services/structs/estoque/ncm-search-struct';
import { EstoqueService } from '../../shared/services/API/estoque/estoque.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SectionProductImagesComponent } from '../../estoque/pages/estoque-novo/section-product-images/section-product-images.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SectionProductGridComponent } from '../../estoque/pages/estoque-novo/section-product-grid/section-product-grid.component';
import { CestSearchResponse } from '../../shared/services/responses/estoque/cest-search.response';
import { CestSearchStruct } from '../../shared/services/structs/estoque/cest-search.struct';
import { ImpostoCestRequest } from '../../shared/services/requests/fiscal/imposto-cest.request';
import { ImpostoCestResponse } from '../../shared/services/responses/fiscal/imposto-cest.response';
 

@Component({
  selector: 'app-perfil-cest-novo',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule,
    MatSlideToggleModule,
    NgxEditorModule,
    MatSelectModule,
    SectionProductImagesComponent,
    MatAutocompleteModule,
    SubheaderComponent,
    ReactiveFormsModule,
    SectionProductGridComponent,
    SkeletonComponent,
    MatExpansionModule,
  ],
  templateUrl: './perfil-cest-novo.component.html',
  styleUrl: './perfil-cest-novo.component.css'
})
export class PerfilCestNovoComponent implements OnInit {
  searchControl: FormControl;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public fiscalService: FiscalService,
    private formBuilder: FormBuilder,
    private estoqueService: EstoqueService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) { }
  public model: FormGroup;
  public requestUpdate: ImpostoCestRequest = new ImpostoCestRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idImpostoCest: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Fiscal';
  public iconProfile: string = 'request_page';
  public profile: number = DefaultProfilesEnum.Fiscal;
  public step: number = 1;
  private debounceTimeout: any;
  public isDropdownOpen: boolean = false;
  public _listNcmSearchStruct: NcmSearchStruct[] = [];
  public _listCestSearchStruct: CestSearchStruct[] = [];
  public isDropdownConsumerOpen: boolean = false;
  public isDropdownOriginOpen: boolean = false;
  public isDropdownDestinyOpen: boolean = false;
  public listConsumerType: TipoConsumidor[] = [];
  public listUf: Uf[] = [];
  public selectedUfs: any[] = [];
  public selectedIdUf: number;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    this.model = this.formBuilder.group({
      idImpostoCest: [],
      cest: [''], 
      naturezaReceita: [''],
      listImpostoCestUfStruct: this.formBuilder.array([]),
    });
    this.createImposto()

    this.userLoginResponse = this.authService.getUserInfos();
    this.getListSelect();
    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    let idImpostoCest: number = this.activatedRoute.snapshot.params['idImpostoCest'];
    if (idImpostoCest) {
      this._idImpostoCest = Number(idImpostoCest);
      this.model.disable();
      this.getImpostoCest();
    }
    if (!this._idImpostoCest)
      this.addImposto();

    this.isLoading = false;
  }

  get imposto() {
  const array = this.model.get('listImpostoCestUfStruct') as FormArray;
  return array;
  }

  createImposto() {
    return this.formBuilder.group({
      idImpostoCestUf: [],
      stAliquota: [],
      stBase: [],
      stMva: [],
      listIdUfDestino: [[], [Validators.required]], // Garante que inicia sempre como um array
    });
  }

  removeImposto(index: number) {
    this.imposto.removeAt(index);
  }

  addImposto() {
    const impostoGroup = this.formBuilder.group({
      idImpostoCestUf: [],
      stAliquota: [],
      stBase: [],
      stMva: [],
      listIdUfDestino: [[]],
    });
    this.imposto.push(impostoGroup);
  }

  getCestValues(filterText: string) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
      this.debounceTimeout = setTimeout(() => {
        if (filterText) {
          this.estoqueService.SearchOnlyCest(filterText).subscribe({
            next: (cestSearchResponse: CestSearchResponse) => {
              this.isLoading = false;
              if (cestSearchResponse.isError) {
                this.alertService.show(
                  'Erro inesperado',
                  cestSearchResponse.errorDescription,
                  AlertType.error
                );
                return;
              }
              this._listCestSearchStruct = cestSearchResponse.listCestSearchStruct;
            },
            error: (error) => {
              this.isLoading = false;
              this.alertService.show('Erro inesperado', error, AlertType.error);
            },
          });
        }
      }, 300);
  }

  getListSelect(): any {
    this.fiscalService.getListSelect().subscribe({
      next: (response: ListaSelectResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listUf = response.listUf;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de clientes',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  selectCestValue(event: any) {
    const selectedItem: NcmSearchStruct = event.source.value; // Pega o item selecionado
    this.model.get('cest')?.setValue(selectedItem);
  }

  nextStep() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      return;
    }
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= 2) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else {
      if (this._idImpostoCest) this.requestUpdate.idImpostoCest = this._idImpostoCest;
        this.requestUpdate.cest = this.model.get('cest')?.value;
        this.requestUpdate.naturezaReceita = this.model.get('naturezaReceita')?.value;
        this.requestUpdate.listImpostoCestUfStruct = this.model.get('listImpostoCestUfStruct')?.value;
      this.update();
    }
  }

  update() {
    this.fiscalService.createImpostoCest(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Imposto cest atualizado',
          AlertType.success
        );
        this.router.navigate([this.routesService.IMPOSTO_CEST]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getImpostoCest() {
    this.fiscalService.getImpostoCest(Number(this._idImpostoCest)).subscribe({
      next: (response: ImpostoCestResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.requestUpdate.idImpostoCest = response.impostoCest.idImpostoCest;
        this.model.disable();
        this.model.get('cest')?.setValue(response.impostoCest.cest);
        this.model.get('naturezaReceita')?.setValue(response.impostoCest.naturezaReceita);
        response.listImpostoCestUfStruct.forEach((imp, i) => {
          if (!this.selectedUfs[i]) {
              this.selectedUfs[i] = Array.isArray(imp.listIdUfDestino) ? imp.listIdUfDestino : (imp.listIdUfDestino ? [imp.listIdUfDestino] : []);
          }
            const impostoGroup = this.formBuilder.group({
              idImpostoCestUf: [imp.idImpostoCestUf ? imp.idImpostoCestUf : null],
              stAliquota: [imp.stAliquota ? imp.stAliquota : null],
              stBase: [imp.stBase ? imp.stBase : null],
              stMva: [imp.stMva ? imp.stMva : null],
              listIdUfDestino: [this.selectedUfs[i] || []]
            })
            this.imposto.push(impostoGroup);
      });
      this.model.enable();
      this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar perfil de imposto',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  validateNumberField(field: string) {
    let value = this.model.get(field)?.value;
  
    if (value !== null && value !== undefined) {
      // Remove caracteres não numéricos e limita a duas casas decimais
      value = parseFloat(value).toFixed(2);
  
      // Impede valores negativos e acima de 100
      if (value < 0) value = '0.00';
      if (value > 100) value = '100.00';
  
      this.model.get(field)?.setValue(value, { emitEvent: false });
    }
  }

  backPage() {
    this.router.navigate([this.routesService.IMPOSTO_CEST]);
  }

  OnUfChange(i: number, event: any) {
    const selectedValues: any[] = event.value; // Lista de valores selecionados
    const selectedUfs = this.listUf.filter(uf => selectedValues.includes(uf.idUf)); // Filtra os objetos selecionados
    this.imposto.at(i).get('listIdUfDestino')?.setValue(selectedUfs.map(uf => uf.idUf)); // Salva a lista de IDs
  }
}

