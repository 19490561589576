import { HttpClient } from '@angular/common/http';
import { afterNextRender, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from './common-service';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserLoginResponse } from './responses/auth/user-login.response';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public signIn(login: string, password: string, ip: string): Observable<UserLoginResponse> {

    let userAuthRequest ={
      login: login,
      password: password,
      ip: ip
    };

    return this.httpClient.post<UserLoginResponse>(environment.urlApiAuth + "GenerateToken", userAuthRequest)
    .pipe(
      catchError(this.handleError)
    )
  }

  updateToken(userLoginResponse: UserLoginResponse) {
    sessionStorage.setItem("token", JSON.stringify(userLoginResponse));
  }

  getToken() {
    let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token")!);
    return userLoginResponse.token; 
  }

  getUserInfos():UserLoginResponse {
    let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token")!);
    return userLoginResponse;
  }

  logout() {
    sessionStorage.removeItem("token");
    this.router.navigate(['/'])
  }
  
  isAuthenticated(): boolean {
    return sessionStorage.getItem("token") == null ? false : true;
  }

  permissao(idPerfil:number, idPapel:number): void {
    let idPapelLogado = this.getUserInfos().empresaLogada.listPerfil.find(x => x.idPerfil === idPerfil)?.idPapel;
    if (idPapelLogado == null || idPapelLogado < idPapel)
    {
      this.router.navigate(['/home/'])
    }
  }
}
