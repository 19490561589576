import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;

  constructor() {}

  iniciarConexao(idEmpresa: number, token: string) {
    console.log("Iniciando conexão com SignalR...");
      
    this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${environment.urlApiOrchestratorGatilho}pedidoHub`, {
      accessTokenFactory: () => token,
      transport: signalR.HttpTransportType.LongPolling // Força Long Polling
    })
    .withAutomaticReconnect()
    .build();
  
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
        this.hubConnection.invoke('EntrarNoGrupo', idEmpresa)
          .then(() => console.log(`Entrou no grupo da empresa ${idEmpresa}`))
          .catch((err: any) => console.error('Erro ao entrar no grupo:', err));
      } else {
        console.error('Conexão SignalR não está ativa');
      }
  }

  ouvirAtualizacoes(callback: (idPedido: string, novaFase: string) => void) {
    this.hubConnection.on('PedidoAtualizado', (idPedido: string, novaFase: string) => {
      console.log(`Pedido atualizado: ${idPedido} para fase ${novaFase}`);
      alert(`Pedido atualizado: ${idPedido} para fase ${novaFase}`);
      callback(idPedido, novaFase);
    });
  }

  desconectar() {
    if (this.hubConnection) {
      this.hubConnection.stop().then(() => console.log('SignalR desconectado'));
    }
  }
}