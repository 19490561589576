<h2 class="h2" mat-dialog-title>{{_title}}</h2>

<mat-dialog-content>
    <p>{{_description}}</p>
    
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
    <button mat-flat-button (click)="close()">{{_buttonClose}}</button>
    <button mat-flat-button class="btn-primary" (click)="confirm()">{{_buttonConfirm}}</button>
</mat-dialog-actions>
