import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SkeletonComponent } from '../../../../shared/component/skeleton/skeleton.component';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { OrchestratorGatilhoService } from '../../../../shared/services/API/orchestrator-gatilho/orchestrator-gatilho.service';
import { FasePedidoStruct } from '../../../../shared/services/structs/fluxo-pedido/fase-pedido.struct';
import { GatilhoStruct } from '../../../../shared/services/structs/gatilho/gatilho.struct';
import { FormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { GatilhoFasePedidoStruct } from '../../../../shared/services/structs/gatilho/gatilho-fase-pedido.struct';
import {
  AlertService,
  AlertType,
} from '../../../../shared/services/alert.service';
import {
  FluxoPedidoResponse,
  FluxoPedidoStruct,
} from '../../../../shared/services/structs/fluxo-pedido/fluxo-pedido.struct';

@Component({
  selector: 'app-empty-col',
  standalone: true,
  imports: [SkeletonComponent, CommonModule, MatIcon, FormsModule],
  templateUrl: './empty-col.component.html',
  styleUrl: './empty-col.component.css',
})
export class EmptyColComponent implements OnInit {
  name: string = '';
  empty: boolean = true;
  private _isEditMode: boolean = false;
  gatilhoStruct: GatilhoStruct[] = [];
  gatilhoOrdemStruct: GatilhoFasePedidoStruct[] = [];

  @Input() coluna: FasePedidoStruct;
  @Input() gatilhos: any;
  @Input() idFluxo: any;
  @Output() confirmation: EventEmitter<any> = new EventEmitter();
  @Output() deleteColumn: EventEmitter<any> = new EventEmitter();
  @Output() moveColumnToRight: EventEmitter<any> = new EventEmitter();
  @Output() moveColumnToLeft: EventEmitter<any> = new EventEmitter();

  isModalOpen: boolean = false;
  isModalDeleteOpen: boolean = false;

  @Input()
  set isEditMode(value: boolean) {
    this._isEditMode = value;
    this.updateEmptyState();
  }
  get isEditMode(): boolean {
    return this._isEditMode;
  }

  adicionandoGatilho = false;
  novoGatilhoSelecionado: GatilhoStruct;
  opcoesGatilho: GatilhoStruct[];
  colunas: FluxoPedidoResponse[];
  fluxo: FasePedidoStruct[] = [];
  dropdownOpen = false;
  editandoName = false;

  constructor(
    public orchestratorGatilhoService: OrchestratorGatilhoService,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    // Obtém as opções de gatilho do serviço
    this.orchestratorGatilhoService.gatilhosResponse$.subscribe((response) => {
      if (response) {
        this.opcoesGatilho = response.listGatilhoStruct;
      }
    });

    // this.orchestratorGatilhoService.fluxoPedido$.subscribe((response) => {
    //   if (response) {
    //     this.colunas = response.listFasePedidoStruct;
    //   }
    // });

    this.orchestratorGatilhoService.fluxoPedido$.subscribe((response) => {
      if (response) {
        this.colunas = response.listFluxoPedidoStruct.map((item) => ({
          idFluxoPedido: item.idFluxoPedido, // ou qualquer identificador do fluxo
          nomeFluxoPedido: item.nomeFluxoPedido,
          listFasePedidoStruct: item.listFasePedidoStruct,
        }));
        let currentFluxo = this.colunas.find(
          (item) => item.idFluxoPedido === this.idFluxo
        )?.listFasePedidoStruct;
        if (currentFluxo) {
          this.fluxo = currentFluxo;
        }
      }
    });

    if (this.coluna) {
      this.name = this.coluna.nomeFasePedido;
      this.updateEmptyState();

      // Lista original de gatilhos
      const gatilhosMap = new Map(
        this.coluna.listGatilhoStruct.map((g) => [g.gatilho.idGatilho, g])
      );

      // Set para evitar duplicatas
      const addedIds = new Set<number>();

      // Ordena e filtra para evitar duplicatas
      this.gatilhoStruct = this.coluna.listGatilhoStruct
        .sort((a, b) => a.ordemExecucao - b.ordemExecucao)
        .map((ordem) => {
          const gatilho = gatilhosMap.get(ordem.gatilho.idGatilho);
          if (gatilho && !addedIds.has(gatilho.gatilho.idGatilho)) {
            addedIds.add(gatilho.gatilho.idGatilho);
            return gatilho;
          }
          return null; // Retorna null para filtrar depois
        })
        .filter((g): g is GatilhoStruct => g !== null);

      this.gatilhoOrdemStruct = this.gatilhoStruct.map((item) => {
        return {
          ordemExecucao: item.ordemExecucao,
          idGatilho: item.gatilho.idGatilho,
          idGatilhoFasePedido: item.idGatilhoFasePedido,
        };
      });
    } else {
      this.editandoName = true;
    }
  }

  private updateEmptyState(): void {
    this.empty = this.isEditMode ? false : this.coluna.itens.length === 0;
  }

  confirmModal() {
    if (this.coluna) {
      this.coluna.listGatilhoStruct = this.gatilhoStruct;
      this.coluna.listGatilhoFasePedidoStruct = this.gatilhoOrdemStruct;
      this.confirmation.emit({ coluna: this.coluna, id: this.idFluxo });
      this.isModalOpen = false;
    } else {
      if (this.name) {
        let order = this.colunas.find(
          (coluna) => coluna.idFluxoPedido === this.idFluxo
        )?.listFasePedidoStruct;
        order?.length;
        this.coluna = {
          idFasePedido: null,
          itens: [],
          listCampoFaseStruct: [],
          listGatilhoFasePedidoStruct: this.gatilhoOrdemStruct,
          listGatilhoStruct: this.gatilhoStruct,
          nomeFasePedido: this.name,
          ordem: order ? order.length : this.colunas.length,
        };

        this.confirmation.emit({ coluna: this.coluna, id: this.idFluxo });
        this.isModalOpen = false;
      } else {
        this.alertService.show(
          'Coluna sem nome!',
          'Sua coluna não pode ficar sem nome! Confirme antes de salvar.',
          AlertType.warning
        );
      }
    }
  }

  confirmDeleteModal() {
    this.deleteColumn.emit({ coluna: this.coluna, id: this.idFluxo });
    this.isModalDeleteOpen = false;
  }

  toggleAdicionarGatilho() {
    this.adicionandoGatilho = !this.adicionandoGatilho;
  }

  toggleEditandoNome() {
    this.editandoName = !this.editandoName;
    if (this.coluna) {
      this.coluna.nomeFasePedido = this.name;
    }
  }

  adicionarGatilho() {
    if (this.novoGatilhoSelecionado) {
      const novoGatilho = JSON.parse(
        JSON.stringify(this.novoGatilhoSelecionado)
      );

      if (this.novoGatilhoSelecionado.idGatilhoFasePedido) {
        novoGatilho.idGatilhoFasePedido =
          this.novoGatilhoSelecionado.idGatilhoFasePedido;
      }
      novoGatilho.ordemExecucao = this.gatilhoStruct.length + 1;

      // Adiciona o novo gatilho à lista principal
      this.gatilhoStruct.push(novoGatilho);

      // Adiciona a nova ordem ao array de ordem de execução
      this.gatilhoOrdemStruct.push({
        idGatilhoFasePedido: novoGatilho.idGatilhoFasePedido
          ? novoGatilho.idGatilhoFasePedido
          : null,
        idGatilho: novoGatilho.gatilho.idGatilho,
        ordemExecucao: novoGatilho.ordemExecucao,
      });

      this.novoGatilhoSelecionado = {} as GatilhoStruct;
      this.adicionandoGatilho = false;
    }
  }

  removerGatilho(gatilho: any) {
    // Remover o gatilho da lista principal
    this.gatilhoStruct = this.gatilhoStruct.filter((g) => g !== gatilho);

    // Reajustar a ordemExecucao para manter sequência numérica
    this.gatilhoStruct.forEach((g, index) => {
      g.ordemExecucao = index + 1;
    });

    // Remover o gatilho da list secundaria
    this.gatilhoOrdemStruct = this.gatilhoOrdemStruct.filter(
      (g) => g.ordemExecucao !== gatilho.ordemExecucao
    );

    // Reajustar a ordemExecucao para manter sequência numérica
    this.gatilhoOrdemStruct.forEach((g, index) => {
      g.ordemExecucao = index + 1;
    });
  }

  selecionarGatilho(opcao: GatilhoStruct) {
    this.novoGatilhoSelecionado = opcao;
    this.adicionarGatilho();
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  moveLeft() {
    this.moveColumnToLeft.emit({ coluna: this.coluna, id: this.idFluxo });
  }
  moveRight() {
    this.moveColumnToRight.emit({ coluna: this.coluna, id: this.idFluxo });
  }
}
