import { CommonModule, ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RoutesService } from '../../../routes.service';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { TipoListaEstoqueEnum } from '../../../shared/enum/Estoque/tipo-lista-estoque.enum';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { EstoqueService } from '../../../shared/services/API/estoque/estoque.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { MovimentacaoResponse } from '../../../shared/services/responses/estoque/movimentacao.response';
import { ProdutoSearchResponse } from '../../../shared/services/responses/estoque/produto-search.response';
import { ListExhibitStruct } from '../../../shared/services/structs/list-exhibit.struct';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-movimentacao-lista',
  standalone: true,
  imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        CommonModule,
        SubheaderComponent,
        MatExpansionModule,
        MatSelectModule,
        CdkAccordionModule
  ],
  templateUrl: './movimentacao-lista.component.html',
  styleUrl: './movimentacao-lista.component.css'
})
export class MovementacaoListaComponent implements OnInit {
  @ViewChild(AdvancedTableComponent) exhibit: AdvancedTableComponent;
  public color: string = DefaultColorEnum.Cor;
  public indexExpansao: number | null = null;
  public movimentacao: MovimentacaoResponse;
  constructor(
    public authService: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private estoqueService: EstoqueService,
    private viewportScroller: ViewportScroller,
    private dialog: MatDialog,
    private routesService: RoutesService
  ) {
  }

  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = false;
  public _page: number = 0;
  public _produtoSearchResponse: ProdutoSearchResponse;
  public nameProfile: string = 'Produto';
  public iconProfile: string = 'inventory';
  public profile: number = DefaultProfilesEnum.Estoque;
  public nameAdd: string = 'Produto';
  public redirectUrl: string = this.routesService.INVENTORY_REGISTER;
  public _isLastPage: boolean = false;
  public tableEnum: number = ExhibitEnum.Tabela;
  public display: ListExhibitStruct = new ListExhibitStruct();
  public listDisplay = this.display.listExhibit;
  public isTable: boolean = false;
  public selectedExhibit: number;
  public dataList: any[];
  public searchTimeout: any;
  public tipoProduto: number = TipoListaEstoqueEnum.Produtos;
  public tipoMovimentacao: number = TipoListaEstoqueEnum.Movimentacao;
  public selectedCard: number = TipoListaEstoqueEnum.Produtos;
  public panelOpen: number | null = null

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);

    this.searchMovement();
  }

  searchMovement() {
    this.estoqueService.SearchMovimentacao().subscribe({
      next: (response: MovimentacaoResponse) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          return;
        }
        this.movimentacao = response;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  togglePanel(index: number): void {
    this.panelOpen = this.panelOpen === index ? null : index; // Alterna entre abrir/fechar
  }
  
}
