import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { UtilService } from '../../../shared/services/util.service';
import { Perfil } from '../../../shared/services/models/pessoa/perfil.model';
import { UsuarioRequest } from '../../../shared/services/requests/pessoa/usuario.request';
import { UsuarioResponse } from '../../../shared/services/responses/pessoa/usuario.response';
import { PerfilResponse } from '../../../shared/services/responses/pessoa/perfil.response';
import { ListPapelStruct } from '../../../shared/services/structs/pessoa/list-papel.struct';
import { RoutesService } from '../../../routes.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { MatIconModule } from '@angular/material/icon';
import { PerfilPapelStruct } from '../../../shared/services/structs/pessoa/perfil-papel.struct';
import { MatDialog } from '@angular/material/dialog';
import { SupplierRegisterModalComponent } from '../../../shared/component/supplier-register-modal/supplier-register-modal.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';

@Component({
  selector: 'app-meus-dados',
  standalone: true,
  imports: [
      MatIconModule,
      FormsModule,
      ReactiveFormsModule,
      CommonModule,
      MatFormFieldModule,
      MatInputModule,
      MatProgressSpinnerModule,
      SubheaderComponent,
      MatSelectModule,
      MatCheckboxModule,
      SkeletonComponent,
  ],
  templateUrl: './meus-dados.component.html',
  styleUrl: './meus-dados.component.css',
})
export class MeusDadosComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  private cepTimeout: any;
  public masks: any;
  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) {}
  public model: FormGroup;   
  public request = new UsuarioRequest();
  public profile: number = DefaultProfilesEnum.Configuracao;

  @ViewChild('numeroEndereco') numeroEndereco: ElementRef;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      telefone: [''],
      telefone2: [''],
      nascimento: ['', Validators.required],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      complemento: [''],
      localidade: [''],
      uf: [''],
      pais: [''],
      idPapel: [1, Validators.required],
    });
    this.userLoginResponse = this.authService.getUserInfos();
    this.getUser();
    this.isLoading = false;
  }

  submit() {
    this.isLoading = true;

    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    }

    this.request.nome = this.model.get('nome')?.value;
    this.request.email = this.model.get('email')?.value;
    this.request.telefone = this.model.get('telefone')?.value;
    this.request.telefone2 = this.model.get('telefone2')?.value;
    this.request.nascimento = this.model.get('nascimento')?.value;
    this.request.latitude = this.model.get('latitude')?.value;
    this.request.longitude = this.model.get('longitude')?.value;
    this.request.cep = this.model.get('cep')?.value;
    this.request.logradouro = this.model.get('logradouro')?.value;
    this.request.cidade = this.model.get('cidade')?.value;
    this.request.numero = this.model.get('numero')?.value;
    this.request.estado = this.model.get('uf')?.value;
    this.request.rua = this.model.get('rua')?.value;
    this.request.bairro = this.model.get('bairro')?.value;
    this.request.complemento = this.model.get('complemento')?.value;
    this.request.pais = this.model.get('pais')?.value;
    this.request.listPerfilPapel = [];

    this.update();
    
  }

  update() {
    this.pessoaService.myUserUpdate(this.request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Usuario atualizado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_USERS]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getUser() {
    this.pessoaService.getMyUser().subscribe({
      next: (response: UsuarioResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.request.idUsuario = response.usuario.idUsuario;
        this.request.idPessoa = response.pessoa.idPessoa;
        this.model.get('nome')?.setValue(response.pessoa.nome);
        this.model.get('email')?.setValue(response.pessoa.email);
        this.model.get('cpfCnpj')?.setValue(response.pessoa.cpfCnpj);
        this.model.get('cpfCnpj')?.disable();
        this.model.get('telefone')?.setValue(response.pessoa.telefone);
        this.model.get('telefone2')?.setValue(response.pessoa.telefone2);
        this.model.get('nascimento')?.setValue(this.formatarData(response.pessoa.nascimento));
        this.model.get('latitude')?.setValue(response.pessoa.latitude);
        this.model.get('longitude')?.setValue(response.pessoa.longitude);
        this.model.get('cep')?.setValue(response.pessoa.cep);
        this.model.get('logradouro')?.setValue(response.pessoa.logradouro);
        this.model.get('cidade')?.setValue(response.pessoa.cidade);
        this.model.get('numero')?.setValue(response.pessoa.numero);
        this.model.get('uf')?.setValue(response.pessoa.estado);
        this.model.get('rua')?.setValue(response.pessoa.logradouro);
        this.model.get('bairro')?.setValue(response.pessoa.bairro);
        this.model.get('complemento')?.setValue(response.pessoa.complemento);
        this.model.get('pais')?.setValue(response.pessoa.pais);
        this.model.get('idPapel')?.setValue(response.idPapel);
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar cep',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }
  
  formatarData(nascimento: any): string {
    const data = (nascimento instanceof Date) ? nascimento : new Date(nascimento);
  
    if (isNaN(data.getTime())) {
      return ''; // Ou qualquer valor adequado em caso de erro
    }
  
    const ano = data.getFullYear();
    const mes = ('0' + (data.getMonth() + 1)).slice(-2); // Meses começam do 0
    const dia = ('0' + data.getDate()).slice(-2);
  
    return `${ano}-${mes}-${dia}`; // Formato correto para o input type="date"
  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {
      this.utilService
        .getAddressByCEP(cep)
        .pipe(
          catchError((error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            this.model.get('logradouro')?.enable();
            this.model.get('complemento')?.enable();
            this.model.get('bairro')?.enable();
            this.model.get('uf')?.enable();
            this.model.get('pais')?.enable();
            this.model.get('cidade')?.enable();
            this.model.get('numero')?.enable();
            return of(null);
          })
        )
        .subscribe((data) => {
          if (data) {
            this.model.patchValue({
              logradouro: data.logradouro,
              complemento: data.complemento,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf,
              pais: 'Brasil',
            });
          }

          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          this.numeroEndereco.nativeElement.focus();
          this.isLoading = false;
        });
    }, 2000);
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_USERS]);
  }

  onCpfCnpjInput(event: any): void {
    const value = event.target.value.replace(/\D/g, '');
    let formattedValue = '';

    formattedValue = this.maskService.applyMask(value, this.masks.cpf);

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  onTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone')?.setValue(formattedTelefone);
  }

  onSecondTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone2')?.setValue(formattedTelefone);
  }

  openPasswordModal() {
    const modalRef = this.dialog.open(ChangePasswordModalComponent, {
      hasBackdrop: true,
      disableClose: false,
    });

    modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
      if (result && result.confirm) {
        // this.getSupplier();
      } else {
        this.isLoading = false;
        return;
      }
    });
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(
      event.target.value,
      this.masks.cep
    );
    this.model.get('cep')?.setValue(formattedCep);
    if (formattedCep.length > 8) {
      this.searchCep(formattedCep);
    }
  }
}
