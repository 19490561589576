<div class="main-container">
    <app-subheader [icon]="'person'" [title]="'Caixa'" [description]="_idCaixa !== null ? 'Edição de um caixa existente' : 'Cadastro de um novo caixa'" [isLoading]="isLoading" [isAddPage]="_idCaixa !== null ? false : true" [isEditPage]="_idCaixa !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px" style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
        </div>
        <form [formGroup]="model" (ngSubmit)="submit()">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>NOVO CAIXA</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="nome">Nome</label>
                                <input matInput id="nome" formControlName="nome" type="text" placeholder="Nome do caixa" />
                                <span *ngIf="model.get('nome')?.touched && model.get('nome')?.invalid">O nome é obrigatorio.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button (click)="submit()" class="main-btn">SALVAR</button>
                </div>
            </div>
        </form>
    </div>
</div>