import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { EstoqueService } from '../../../../shared/services/API/estoque/estoque.service';
import { EanSearchRequest } from '../../../../shared/services/requests/estoque/ean-search.request';
import { EanSearchResponse } from '../../../../shared/services/responses/estoque/ean-search.response';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-camera-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.css'],
})
export class CameraDialogComponent implements OnInit {
  @ViewChild('video') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvasElement!: ElementRef<HTMLCanvasElement>;

  public capturedImages: string[] = [];
  public eanCode: string;
  public showCapturedImage = false;
  public _eanSearchRequest: EanSearchRequest = new EanSearchRequest;
  private videoStream!: MediaStream;

  constructor(
      private dialogRef: MatDialogRef<CameraDialogComponent>, 
      private estoqueService: EstoqueService,
      private alertService: AlertService) {}

  ngOnInit(): void {
    this.openCamera();
  }

  openCamera(): void {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.videoStream = stream;
        this.videoElement.nativeElement.srcObject = stream;
        this.videoElement.nativeElement.play();
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
  }

  captureImages(): void {
    const canvas = this.canvasElement.nativeElement;
    const video = this.videoElement.nativeElement;
    const context = canvas.getContext('2d');

    if (!context) {
      console.error('Unable to get canvas context');
      return;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    this.capturedImages = [];
    this.showCapturedImage = false;

    let captureCount = 0;

    const captureInterval = setInterval(() => {
      if (captureCount < 3) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.capturedImages.push(canvas.toDataURL('image/png'));
        this._eanSearchRequest.listCapturedImage.push(canvas.toDataURL('image/png'));
        captureCount++;
      } else {
        clearInterval(captureInterval);
        this.showCapturedImage = true;
        this.searchEan();
        //
      }
    }, 400);

  }

    searchEan(){
      this.estoqueService.SearchEan(this._eanSearchRequest).subscribe({
        next: (response: EanSearchResponse) => {
          //this.isLoading = false;
          if (response.isError) {
            this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
            return;
          }
          this.showCapturedImage = false;
          this.eanCode = response.ean;
          this.closeCamera();
        },
        error: (error) => {
          this.showCapturedImage = false;
          //this.isLoading = false;
          this.alertService.show("Erro inesperado", error, AlertType.error);
        }
      });
    }
  
    closeCamera(): void {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
      }
      this.dialogRef.close(this.eanCode);
    }
  }

