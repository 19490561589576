import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, ViewportScroller } from '@angular/common';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteSearchRequest } from '../../../shared/services/requests/pessoa/cliente-search.request';
import { ClienteSearchExhibitResponse } from '../../../shared/services/responses/pessoa/cliente-search-exhibit.response';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { debounceTime, Subject } from 'rxjs';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { ListExhibitStruct } from '../../../shared/services/structs/list-exhibit.struct';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { RoutesService } from '../../../routes.service';
import { ViewSwitcherComponent } from '../../../shared/component/view-switcher/view-switcher.component';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
@Component({
  selector: 'app-cliente-lista',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SubheaderComponent,
    MatFormFieldModule,
    MatSelectModule,
    ViewSwitcherComponent,
    SkeletonComponent,
    AdvancedTableComponent,
  ],
  templateUrl: './cliente-lista.component.html',
  styleUrl: './cliente-lista.component.css',
})
export class ClienteListaComponent implements OnInit {
  @ViewChild(AdvancedTableComponent) exhibit: AdvancedTableComponent;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private routesService: RoutesService
  ) {
  }
  public model: FormGroup;
  public dataList: any[];
  public searchTimeout: any;
  public profile: number = DefaultProfilesEnum.Cliente;
  public tableEnum: number = ExhibitEnum.Tabela;
  public isTable: boolean = true;

  tableColumns = [
    {
      key: 'nome',
      label: 'Nome',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'cpfCnpj',
      label: 'CPF/CNPJ',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      filterable: false,
      date: false,
    },
    {
      key: 'telefone',
      label: 'Telefone',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'inclusao',
      label: 'Inclusão',
      sortable: true,
      filterable: false,
      date: true,
    },
  ];

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterName: [''],
      filters: [],
      filterDate: [''],
      currentPage: [0],
      totalPages: [0],
    });
    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);
    this.searchClient();
  }

  onSearchValueChanged(value: string) {
    this.searchSubject.next(value);
  }

  onDateValueChanged(value: string) {
    this.searchDate.next(value);
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchClient();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchClient();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  searchClient() {
    this.isLoading = true;
    const searchRequest: ClienteSearchRequest = new ClienteSearchRequest();
    if (this.model.get('filters')?.value) {
      searchRequest.filters = this.model.get('filters')?.value;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value) {
      this.model.get('currentPage')?.setValue(0);
      const [startDateString, endDateString] = this.model
        .get('filterDate')
        ?.value.split('|');
      searchRequest.dataInicio = startDateString;
      searchRequest.dataFim = endDateString;
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.pessoaService.searchClientExhibit(searchRequest).subscribe({
        next: (response: ClienteSearchExhibitResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.dataList = response.listClienteSearchExhibitStruct;
          this.model.get('totalPages')?.setValue(response.pageSize);
          setTimeout(() => {
            this.update();
          }, 3000);
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }, 10);
  }

  update() {
    this.exhibit.update();
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  addClient() {
    this.router.navigate([this.routesService.CLIENT_REGISTER]);
  }

  exhibitActions(emitted: any) {
    if (emitted.action === 'search') {
      this.model.get('filterName')?.setValue(emitted.search);
      this.model.get('filters')?.setValue(emitted.filters);
      this.searchClient();
    } else if (emitted.action === 'edit') {
      this.router.navigate([
        this.routesService.CLIENT_UPDATE(emitted.row.registro),
      ]);
    } else if (emitted.action === 'dateSearch') {
      if (emitted.dateSearch.date != null) {
        this.model.get('filterDate')?.setValue(emitted.dateSearch.date);
      } else {
        this.model.get('filterDate')?.setValue(null);
      }
      this.searchClient();
    } else if (emitted.action === 'remove') {
      const modalRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title: 'Atenção!',
          description:
            'Deseja excluir o cliente ' +
            emitted.row.nome +
            ' ? Esta ação é irreversivel.',
          buttonConfirm: 'Excluir',
          buttonClose: 'Cancelar',
        },
        hasBackdrop: true,
        disableClose: false,
      });

      modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
        if (result.confirm) {
          this.isLoading = true;
          this.pessoaService.removeClientById(emitted.row.registro).subscribe({
            next: (response: ReturnStruct) => {
              if (response.isError) {
                this.alertService.show(
                  'Erro inesperado',
                  response.errorDescription,
                  AlertType.error
                );
                this.isLoading = false;
                return;
              }
              this.isLoading = false;
              this.alertService.show(
                'Sucesso!',
                'Cliente excluido',
                AlertType.success
              );
              this.searchClient();
            },
            error: (error) => {
              this.alertService.show('Erro inesperado', error, AlertType.error);
              this.isLoading = false;
              return;
            },
          });
        } else {
          this.isLoading = false;
          return;
        }
      });
    }
  }
  onExhibitChange(event: any) {
    if (event == this.tableEnum) {
      this.isTable = true;
    } else {
      this.isTable = false;
    }
  }
}
