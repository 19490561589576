<div class="main-container">
    <app-subheader [icon]="'request_page'" [title]="'Imposto Cest'"
        [description]="_idImpostoCest !== null ? 'Edição de um imposto cest existente' : 'Cadastro de um novo imposto cest'"
        [isLoading]="isLoading" [isAddPage]="_idImpostoCest !== null ? false : true"
        [isEditPage]="_idImpostoCest !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px"
            style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
            </div>
        </div>
        <form [formGroup]="model">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>IMPOSTO CEST</p>
                </div>
                <div class="container">
                        <div class="form-group">
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-6 col-sm-6 col-md-8">
                                    <label class="input-label" for="dataInicioVigencia" style="margin-bottom: 6px;">Cód. CEST</label>
                                    <input matInput class="input-ncm" id="cest" formControlName="cest" type="text" (ngModelChange)="getCestValues($event)"
                                        [matAutocomplete]="cestAuto" placeholder="Informe o código CEST" />
                                    <mat-autocomplete #cestAuto="matAutocomplete">
                                        <mat-option *ngFor="let cestItem of _listCestSearchStruct" [value]="cestItem.codigo" (onSelectionChange)="selectCestValue($event)">
                                            <span style="font-size: 14px"><b>{{ cestItem.codigo }}</b></span><br /> <span style="font-size: 12px">{{ cestItem.descricao | slice: 0:120 }} </span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div class="col-6 col-sm-6 col-md-4" >
                                    <div class="input-control">
                                        <label class="input-label" for="dataInicioVigencia">Natureza da receita</label>
                                        <input matInput id="naturezaReceita" formControlName="naturezaReceita"
                                            type="text" class="input-field" placeholder="Naturea da receita" />
                                    </div>
                                </div>
                            </div>
                        <div class="tax-uf flexed-wrapper" style="padding: 10px;" *ngFor="let impUf of imposto.controls; let i = index" formArrayName="listImpostoCestUfStruct">
                            <div [formGroupName]="i" style="margin-top: -25px; margin-bottom: -10px;">
                                <div class="row">
                                    <div class="col-11 row">
                                        <div class="form-group col-6 col-sm-3 col-md-3">
                                                <mat-select formControlName="listIdUfDestino" class="input-ncm" multiple>
                                                    <mat-option *ngFor="let uf of listUf" [value]="uf.idUf">
                                                    {{ uf.siglaUf }}
                                                    </mat-option>
                                                </mat-select>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-3" >
                                            <div class="input-control">
                                                <input matInput id="stAliquota" formControlName="stAliquota"
                                                    type="number" class="input-field" placeholder="ST Aliquota" 
                                                    min="0" max="100" />
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-3">
                                            <div class="input-control">
                                              <input matInput id="stBase" formControlName="stBase"
                                                type="number" class="input-field" placeholder="ST Base"
                                                min="0" max="100" />
                                            </div>
                                          </div>
                                        <div class="col-6 col-sm-4 col-md-3" >
                                            <div class="input-control">
                                                <input matInput id="stMva" formControlName="stMva"
                                                    type="number" class="input-field" placeholder="ST MVA"
                                                    min="0" max="100" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-1" style="display: flex;
                                        align-items: flex-start;
                                        flex-direction: row;
                                        flex-wrap: wrap;
                                        align-content: center;
                                        justify-content: center;">
                                        <mat-icon (click)="addImposto()" style="color: var(--main-100);">add</mat-icon>
                                        <mat-icon (click)="removeImposto(i)" *ngIf="i > 0" style="color: var(--warning-200);">delete</mat-icon>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="controller">
                        <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
                        <button (click)="submit()" class="main-btn">SALVAR</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>