import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from 'ngx-editor';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordRequest } from '../../../../shared/services/requests/pessoa/change-password.request';
import { PessoaService } from '../../../../shared/services/API/pessoa/pessoa.service';

@Component({
  selector: 'app-change-password-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  templateUrl: './change-password-modal.component.html',
  styleUrl: './change-password-modal.component.css'
})
export class ChangePasswordModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private pessoaService: PessoaService,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
  }
  public model: FormGroup;
  public isLoading: boolean = false;
  public _verifyTokenReturnStruct: ReturnStruct;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      passwordAtual: ['', [Validators.required]],
      password1: ['', [Validators.required]],
      password2: ['', [Validators.required]]
    });
  }

  post() {
    if (this.model.invalid || this.isLoading)
      return;

    if (this.model.get('password1')?.value != this.model.get('password2')?.value) {
      this.alertService.show("Erro", "As duas senhas precisam ser identicas", AlertType.error);
      return;
    }

    let resetPasswordRequest: ChangePasswordRequest = new ChangePasswordRequest();
    resetPasswordRequest.senhaAtual = this.model.get('passwordAtual')?.value;
    resetPasswordRequest.novaSenha = this.model.get('password1')?.value;

    this.isLoading = true;

    this.pessoaService.changePassword(resetPasswordRequest).subscribe({
      next: (response: ReturnStruct) => {
        this.isLoading = false;

        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Sua senha foi alterada", AlertType.success);
        this.close();
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
