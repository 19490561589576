import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxEditorModule } from 'ngx-editor';
import { RoutesService } from '../../routes.service';
import { DefaultProfilesEnum } from '../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { AuthService } from '../../shared/services/auth.service';
import { MaskService } from '../../shared/services/mask.service';
import { UserLoginResponse } from '../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../shared/services/structs/return.struct';
import { FiscalService } from '../../shared/services/API/fiscal/fiscal.service';
import { SkeletonComponent } from '../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../shared/component/subheader/subheader.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SectionProductImagesComponent } from '../../estoque/pages/estoque-novo/section-product-images/section-product-images.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SectionProductGridComponent } from '../../estoque/pages/estoque-novo/section-product-grid/section-product-grid.component';
import { perfilPisCofinsResponse } from '../../shared/services/responses/fiscal/perfil-pis-cofins.response';
import { PerfilPisCofinsRequest } from '../../shared/services/requests/fiscal/perfil-pis-cofins.request';
 

@Component({
  selector: 'app-perfil-pis-cofins-novo',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule,
    MatSlideToggleModule,
    NgxEditorModule,
    MatSelectModule,
    SectionProductImagesComponent,
    MatAutocompleteModule,
    SubheaderComponent,
    ReactiveFormsModule,
    SectionProductGridComponent,
    SkeletonComponent,
    MatExpansionModule,
  ],
  templateUrl: './perfil-pis-cofins-novo.component.html',
  styleUrl: './perfil-pis-cofins-novo.component.css'
})
export class PerfilPisCofinsNovoComponent implements OnInit {
  searchControl: FormControl;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public fiscalService: FiscalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) { }
  public model: FormGroup;
  public requestUpdate: PerfilPisCofinsRequest = new PerfilPisCofinsRequest();
  public _idPerfilPisCofins: number | null = null;
  public dataList: any[];
  public profile: number = DefaultProfilesEnum.Fiscal;
  public step: number = 1;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idPerfilPisCofins: [''],
      pisAliquota: [''],
      pisCst: [''],
      pisSt: [''],
      cofinsAliquota: [''],
      cofinsCst: [''],
      cofinsSt: [''],
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    let idPerfilPisCofins: number = this.activatedRoute.snapshot.params['idPerfilPisCofins'];
    if (idPerfilPisCofins) {
      this._idPerfilPisCofins = Number(idPerfilPisCofins);
      this.model.disable();
      this.getNatureza();
    }
    this.isLoading = false;
  }

  nextStep() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      return;
    }
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= 2) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else {
      if (this._idPerfilPisCofins) this.requestUpdate.idPerfilPisConfins = this._idPerfilPisCofins;
        this.requestUpdate.pisSt = this.model.get('pisSt')?.value;
        this.requestUpdate.pisCst = this.model.get('pisCst')?.value;
        this.requestUpdate.pisAliquota = this.model.get('pisAliquota')?.value;
        this.requestUpdate.cofinsSt = this.model.get('cofinsSt')?.value;
        this.requestUpdate.cofinsCst = this.model.get('cofinsCst')?.value;
        this.requestUpdate.cofinsAliquota = this.model.get('cofinsAliquota')?.value;

      this.update();
    }
  }

  onPisStChange(event: Event) {
    const pisStValue = (event.target as HTMLInputElement).checked;
    if (pisStValue) {
      this.model.get('pisAliquota')?.setValue(0);
      this.model.get('pisCst')?.setValue(99);
      this.model.get('pisAliquota')?.disable();
      this.model.get('pisCst')?.disable();
    } else {
      this.model.get('pisAliquota')?.enable();
      this.model.get('pisCst')?.enable();
    }
  }

  onCofinsStChange(event: Event) {
    const cofinsStValue = (event.target as HTMLInputElement).checked;
    if (cofinsStValue) {
      this.model.get('cofinsAliquota')?.setValue(0);
      this.model.get('cofinsCst')?.setValue(99);
      this.model.get('cofinsAliquota')?.disable();
      this.model.get('cofinsCst')?.disable();
    } else {
      this.model.get('cofinsAliquota')?.enable();
      this.model.get('cofinsCst')?.enable();
    }
  }

  update() {
    this.fiscalService.createPerfilPisCofins(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Natureza da receita atualizada',
          AlertType.success
        );
        this.router.navigate([this.routesService.NATUREZA_RECEITA]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getNatureza() {
    this.fiscalService.getPerfilPisCofins(Number(this._idPerfilPisCofins)).subscribe({
      next: (response: perfilPisCofinsResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.model.disable();
        this.requestUpdate.idPerfilPisConfins = response.perfilPisCofins.idPerfilPisCofins;
        this.model.get('pisAliquota')?.setValue(response.perfilPisCofins.pisAliquota);
        this.model.get('pisSt')?.setValue(response.perfilPisCofins.pisSt);
        this.model.get('pisCst')?.setValue(response.perfilPisCofins.pisCst);
        this.model.get('cofinsAliquota')?.setValue(response.perfilPisCofins.cofinsAliquota);
        this.model.get('cofinsSt')?.setValue(response.perfilPisCofins.cofinsSt);
        this.model.get('cofinsCst')?.setValue(response.perfilPisCofins.cofinsCst);
        this.model.enable();

        
        if (response.perfilPisCofins.pisSt){
            this.model.get('pisCst')?.disable();
            this.model.get('pisAliquota')?.disable();
            this.model.get('pisAliquota')?.setValue(0);
            this.model.get('pisCst')?.setValue(99);
        }
        if (response.perfilPisCofins.cofinsSt){
            this.model.get('cofinsCst')?.disable();
            this.model.get('cofinsAliquota')?.disable();
            this.model.get('cofinsAliquota')?.setValue(0);
            this.model.get('cofinsCst')?.setValue(99);
        }


        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar perfil de imposto',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  backPage() {
    this.router.navigate([this.routesService.NATUREZA_RECEITA]);
  }
}

