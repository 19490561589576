<h2 class="h2" mat-dialog-title>Selecionar a cor de destaque do sistema</h2>

<mat-dialog-content>
    <div class="row">
        <div *ngFor="let cor of coresPersonalizadas" class="color-option-group col-6 col-sm-4 col-md-2" (click)="close(cor)">
            <div>
                <div [ngStyle]="{ 'width':'100px', 'height':'50px', 'margin-top': '10px', 'border-radius': '10px 10px 0px 0px', 'background-color': cor.cor }"></div>
                <div [ngStyle]="{ 'width':'100px', 'height':'50px', 'border-radius': '0px 0px 10px 10px', 'background-color': cor.corHover }"></div>
            </div>       
        </div>
    </div>
</mat-dialog-content>