<div class="main-container">
    <app-subheader [icon]="'person'" [title]="'Usuário'" [description]="_idUsuario !== null ? 'Edição de um usuário existente' : 'Cadastro de um novo cliente'" [isLoading]="isLoading" [isAddPage]="_idUsuario !== null ? false : true" [isEditPage]="_idUsuario !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px" style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <mat-icon class='icon-item-step-active'>description</mat-icon>
        </div>
        <form [formGroup]="model">
            <div class="step-title">
                <p>DADOS PRINCIPAIS</p>
            </div>
            <div class="form-flex">
                <div class="row">
                    <div class="col-6" style="margin-top:10px;">
                        <div class="input-control">
                            <label class="input-label" for="cpfCnpj">CPF/CNPJ</label>
                            <input matInput id="cpfCnpj" formControlName="cpfCnpj" type="text" (input)="onCpfCnpjInput($event)" [ngClass]="model.get('cpfCnpj')?.touched && model.get('cpfCnpj')?.invalid ? 'input-failed' : 'input-field'" placeholder="000.000.000-00" />
                            <span *ngIf="model.get('cpfCnpj')?.touched && model.get('cpfCnpj')?.invalid">O campo CPF/CNPJ é obrigatório.</span>
                        </div>
                    </div>
                    <div class="col-6" style="margin-top:10px;">
                        <div class="input-control">
                            <label class="input-label" for="nome">Nome</label>
                            <input matInput id="nome" formControlName="nome" type="text" [ngClass]="model.get('nome')?.touched && model.get('nome')?.invalid ? 'input-failed' : 'input-field'" placeholder="José Arnaldo" />
                            <span *ngIf="model.get('nome')?.touched && model.get('nome')?.invalid">O campo nome é obrigatório.</span>
                        </div>
                    </div>
                    <div class="col-6" style="margin-top:10px;">
                        <div class="input-control">
                            <label class="input-label" for="email">E-mail</label>
                            <input matInput id="email" formControlName="email" type="email" [ngClass]="model.get('email')?.touched && model.get('email')?.invalid ? 'input-failed' : 'input-field'" placeholder="exemplo@dominio.com" />
                            <span *ngIf="model.get('email')?.touched && model.get('email')?.invalid">O campo e-mail é obrigatório.</span>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3" style="margin-top:10px;">
                        <div class="input-control">
                            <label class="input-label" for="nascimento">Data de Nascimento</label>
                            <input matInput id="nascimento" formControlName="nascimento" type="date" class="input-field" />
                        </div>
                    </div>
                    <div class="col-3" style="margin-top:10px;">
                        <div class="input-control">
                            <label class="input-label" for="telefone">Telefone</label>
                            <input matInput id="telefone" formControlName="telefone" type="text" (input)="onTelefoneInput($event)" [ngClass]="model.get('telefone')?.touched && model.get('telefone')?.invalid ? 'input-failed' : 'input-field'" placeholder="(00) 00000-0000" />
                            <span *ngIf="model.get('telefone')?.touched && model.get('telefone')?.invalid">O campo telefone é obrigatório.</span>
                        </div>
                    </div>

                    <div class="col-6" style="margin-top:10px;" *ngIf="!this._idUsuario">
                        <div class="input-control">
                            <label class="input-label" for="senha">Senha</label>
                            <input matInput id="senha" formControlName="senha" type="password"  [ngClass]="model.get('senha')?.touched && model.get('senha')?.invalid ? 'input-failed' : 'input-field'" />
                            <span *ngIf="model.get('senha')?.touched && model.get('senha')?.invalid">O campo senha é obrigatório.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-title">
                <p>ENDEREÇO</p>
            </div>
            <div class="form-flex">
                <div class="form-grouped">
                    <div class="form-group">
                        <div class="input-control">
                            <label class="input-label" for="cep">CEP</label>
                            <input matInput id="cep" formControlName="cep" type="text" [ngClass]="model.get('cep')?.touched && model.get('cep')?.invalid ? 'input-failed' : 'input-field'" placeholder="00000-000" (input)="onCepInput($event)" />
                            <span *ngIf="model.get('cep')?.touched && model.get('cep')?.invalid">O campo CEP é obrigatório.</span>
                        </div>
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>CEP</mat-label>
                            <input matInput id="cep" formControlName="cep" type="text" (input)="onCepInput($event)" placeholder="CEP" class="field" />
                        </mat-form-field> -->
                    </div>
                    <div class="form-group">
                        <div class="input-control">
                            <label class="input-label" for="logradouro">Logradouro</label>
                            <input matInput id="logradouro" formControlName="logradouro" type="text" [ngClass]="model.get('logradouro')?.touched && model.get('logradouro')?.invalid ? 'input-failed' : 'input-field'" placeholder="Logradouro" />
                            <span *ngIf="model.get('logradouro')?.touched && model.get('cep')?.invalid">O campo Logradouro é obrigatório.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-control">
                            <label class="input-label" for="cidade">Cidade</label>
                            <input matInput id="cidade" formControlName="cidade" type="text" [ngClass]="model.get('cidade')?.touched && model.get('cidade')?.invalid ? 'input-failed' : 'input-field'" placeholder="Cidade" />
                            <span *ngIf="model.get('cidade')?.touched && model.get('cep')?.invalid">O campo Cidade é obrigatório.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-control">
                            <label class="input-label" for="uf">UF</label>
                            <input matInput id="uf" formControlName="uf" type="text" [ngClass]="model.get('uf')?.touched && model.get('uf')?.invalid ? 'input-failed' : 'input-field'" placeholder="UF" />
                            <span *ngIf="model.get('uf')?.touched && model.get('uf')?.invalid">O campo UF é obrigatório.</span>
                        </div>
                    </div>
                </div>
                <div class="form-grouped">
                    <div class="form-group">
                        <div class="input-control">
                            <label class="input-label" for="bairro">Bairro</label>
                            <input matInput id="bairro" formControlName="bairro" type="text" [ngClass]="model.get('bairro')?.touched && model.get('bairro')?.invalid ? 'input-failed' : 'input-field'" placeholder="Bairro" />
                            <span *ngIf="model.get('bairro')?.touched && model.get('bairro')?.invalid">O campo Bairro é obrigatório.</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="input-control">
                            <label class="input-label" for="numero">Número</label>
                            <input matInput id="numero" formControlName="numero" type="text" [ngClass]="model.get('numero')?.touched && model.get('numero')?.invalid ? 'input-failed' : 'input-field'" placeholder="Número" #numeroEndereco />
                            <span *ngIf="model.get('numero')?.touched && model.get('numero')?.invalid">O campo Número é obrigatório.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-control">
                            <label class="input-label" for="complemento">Complemento</label>
                            <input matInput id="complemento" formControlName="complemento" type="text" [ngClass]="model.get('complemento')?.touched && model.get('complemento')?.invalid ? 'input-failed' : 'input-field'" placeholder="Complemento" />
                            <span *ngIf="model.get('complemento')?.touched && model.get('complemento')?.invalid">O campo Complemento é obrigatório.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-title">
                <p>PERMISSÕES DO USUÁRIO</p>
            </div>
            <div class="form-flex">
                <div class="form-grouped">
                    <div class="row">
                        <div *ngFor="let perfil of listIdPerfil" class="item-card col-3">
                            <mat-icon class="homecard-icon">{{ perfil.googleIcone }}</mat-icon>                 
                            <div class="homecard-content">
                                <h4>{{ perfil.nomePerfil }}</h4>
                            </div>
            
                            <div class="form-group custom-dropdown">
                                <div class="dropdown-wrapper">
                                    <select id="idPapel" class="form-control" (change)="changeDropdown($event, perfil.idPerfil)">
                                        <option value="0" [selected]="SelecionarPapel(0, perfil.idPerfil)">Sem permissão</option>
                                        <option *ngFor="let papel of listPapel?.listPapel" [value]="papel.id" [selected]="SelecionarPapel(papel.id, perfil.idPerfil)">
                                            {{ papel.nome }}
                                        </option>
                                    </select>
                                    <span class="dropdown-arrow">&#9662;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </form>

        <div class="controller">
            <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
            <button (click)="submit()" class="main-btn">SALVAR</button>
        </div>
    </div>
</div>