import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../shared/component/menu/menu.component';
import { SearchBarComponent } from '../shared/component/search-bar/search-bar.component';
import { AuthService } from '../shared/services/auth.service';
import { UserLoginResponse } from '../shared/services/responses/auth/user-login.response';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { PerfilResponse } from '../shared/services/responses/pessoa/perfil.response';
import { IAInsightsResponse } from '../shared/services/responses/pessoa/ia-insights.response';
import { DefaultColorEnum } from '../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../shared/services/alert.service';
import { PessoaService } from '../shared/services/API/pessoa/pessoa.service';
import { Perfil } from '../shared/services/models/pessoa/perfil.model';
import { Router } from '@angular/router';
import { EmpresaStruct } from '../shared/services/structs/pessoa/empresa.struct';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MenuComponent,
    SearchBarComponent,
    MatCardModule,
    MatIconModule,
    CommonModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit{
  public userLoginResponse:UserLoginResponse;
  public listPerfil: Perfil[];
  public suggestionCards: IAInsightsResponse[];
  public modules: any[] = [];
  public accesColor: string = DefaultColorEnum.Acesso;
  public unnaccesColor: string = DefaultColorEnum.NaoAcesso;
  public isLoading: boolean = true;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private router: Router
  ){

  }
  public color: string = DefaultColorEnum.Cor;

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();
    if(this.userLoginResponse.empresaLogada?.corSistema){
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.getPerfil();
  }

  onCardClick(card: Perfil): void {
    let filteredModule = this.userLoginResponse.empresaLogada.listIdPerfil.find(module => module === card.idPerfil)
    if(filteredModule)
    {
      this.router.navigate([card.redirect]);
    }
    else {
      this.alertService.show("Erro!", "Você não possui acesso a este perfil", AlertType.error);
    }
  }

  onSuggestionCardClick(card: any) : void {

  }

  getPerfil() {
    if(this.userLoginResponse.listPerfil)
    {
      this.listPerfil = this.userLoginResponse.listPerfil;
      this.listPerfil = this.userLoginResponse.listPerfil.filter(perfil =>
        this.userLoginResponse.empresaLogada.listIdPerfil.includes(perfil.idPerfil) && perfil.exibirTelaInicial
      );
      this.cardLogical();
      this.isLoading = false;
    }
    else
    {
      this.pessoaService.GetPerfil().subscribe({

        next:(response: PerfilResponse)=>{
          if (response.isError) {
            this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
            return;
          }
          this.listPerfil = response.listPerfil;
          this.listPerfil = response.listPerfil.filter(perfil =>
            this.userLoginResponse.empresaLogada.listIdPerfil.includes(perfil.idPerfil) && perfil.exibirTelaInicial
          );
          this.cardLogical();
          this.userLoginResponse.listPerfil = response.listPerfil;
          this.authService.updateToken(this.userLoginResponse);
          this.isLoading = false;
        },
        error: (error)=>{
          this.alertService.show("Erro inesperado", error, AlertType.error);
          this.isLoading = false;
        }
      });
    }
  }

  cardLogical() {
    this.modules = this.listPerfil.map(modulo => {
      return {
        ...modulo,
        corDeFundo: modulo.corInvertida ? this.color : this.accesColor
      };
    });
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }
}
