<div class="container container-auth">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <form class="form" [formGroup]="model" (ngSubmit)="singin()">
                <div class="logo">
                    <img class="logo-app" (load)="onLogoLoad()" (error)="onLogoError()" *ngIf="logoCarregada || logoSistema !== 'assets/easyerp.png'" [src]="logoSistema" />
                </div>
                <div class="login">
                    <div class="section">
                        <label for="login" class="label"></label>
                        <input id="login" class="input-field" type="email" formControlName="login" placeholder="Email">
                        <div class="error-message" *ngIf="model.get('login')?.touched && model.get('login')?.invalid">
                            Informe o seu e-mail cadastrado
                        </div>
                    </div>
                    <div class="section">
                        <label for="password" class="label"></label>
                        <div class="password-container">
                            <input id="password" class="input-field" [type]="hide() ? 'password' : 'text'"
                                formControlName="password" placeholder="Senha">
                            <button type="button" class="password-toggle" (click)="clickEvent($event)"
                                [attr.aria-label]="'Toggle password visibility'" [attr.aria-pressed]="hide()">
                                <mat-icon class="showPass">{{hide() ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </div>
                        <div class="error-message"
                            *ngIf="model.get('password')?.touched && model.get('password')?.invalid">
                            Informe a sua senha
                        </div>
                    </div>
                    <div class="section">
                        <button type="submit" class="button-login btn-primary">
                            <div class="button-content">
                                <ng-container *ngIf="isLoading; else notLoading">
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                </ng-container>
                                <ng-template #notLoading>
                                    Entrar
                                </ng-template>
                            </div>
                        </button>
                    </div>
                    <div class="section">
                        <button type="button" class="forgot" (click)="redirectRevoceryPassword()">Esqueceu a
                            senha?</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>