import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { MatIconModule } from '@angular/material/icon';
import { UtilService } from '../../../shared/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaskService } from '../../../shared/services/mask.service';
import { ClienteRequest } from '../../../shared/services/requests/pessoa/cliente.request';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { ClienteResponse } from '../../../shared/services/responses/pessoa/cliente.response';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { RoutesService } from '../../../routes.service';
import { ClienteStruct } from '../../../shared/services/structs/pessoa/cliente.struct';
import { MatSelectModule } from '@angular/material/select';
import { TipoEClienteEnum } from '../../../shared/enum/Pessoa/tipo-cliente.enum';
import { ListClienteStructResponse } from '../../../shared/services/responses/pessoa/list-cliente-struct.response';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { CaixaRequest } from '../../../shared/services/requests/financeiro/caixa.request';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { CaixaResponse } from '../../../shared/services/responses/financeiro/caixa.response';

@Component({
  selector: 'app-caixa-novo',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SubheaderComponent,
    MatSelectModule,
    MatCheckboxModule,
    SkeletonComponent,
  ],
  templateUrl: './caixa-novo.component.html',
  styleUrl: './caixa-novo.component.css',
})
export class CaixaNovoComponent implements OnInit {
  searchControl: FormControl;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  public isViewFinancialFields: boolean = false;
  public isWriteFinancialFields: boolean = false;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public financeiroService: FinanceiroService,
    private formBuilder: FormBuilder,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) {}
  public model: FormGroup;
  public requestUpdate: CaixaRequest = new CaixaRequest();
  public _idCaixa: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Cliente';
  public iconProfile: string = 'person';
  public profile: number = DefaultProfilesEnum.Cliente;
  public financialProfile: number = DefaultProfilesEnum.Financeiro;
  public isDependent: boolean = false;
  public listClients: ClienteStruct[] = [];
  public filteredClientes: ClienteStruct[] = [];
  public selectedOption: any;
  public step: number = 1;
  public isDropdownOpen = false;
  public selectedClient: any = null;
  public searchQuery: string = '';
  
  ngOnInit(): void {
    this.isLoading = true;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required]
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    this.searchControl = new FormControl('');
    
    this.isViewFinancialFields = this.authService.verify(
      this.financialProfile,
      PapelEnum.ler
    );
    this.isWriteFinancialFields = this.authService.verify(
      this.financialProfile,
      PapelEnum.lerGravar
    );

    let idCaixa: number = this.activatedRoute.snapshot.params['idCaixa'];
    if (idCaixa) {
      this._idCaixa = Number(idCaixa);
      this.getClient();
    }
    this.isLoading = false;
  }

  nextStep() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      return;
    }
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= 2) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else if (this._idCaixa) {
      if (this.model.get('nome')?.value)
        this.requestUpdate.nomeCaixa = this.model.get('nome')?.value;
      this.update();
    } else {
      const clienteRequest = new CaixaRequest();
      if (this.model.get('nome')?.value)
        clienteRequest.nomeCaixa = this.model.get('nome')?.value;
     
      this.register(clienteRequest);
    }
  }

  register(caixa: CaixaRequest) {
    this.financeiroService.createCaixa(caixa).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Caixa registrado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_CAIXA]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  update() {
    this.financeiroService.updateCaixa(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Caixa atualizado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_CAIXA]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getClient() {
    this.financeiroService.GetCaixaById(Number(this._idCaixa)).subscribe({
      next: (response: CaixaResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.requestUpdate.idCaixa = response.caixa.idCaixa;
        this.model.get('nome')?.setValue(response.caixa.nomeCaixa);
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar cep',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_CAIXA]);
  }
}
