import { CommonModule, Location, ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { RoutesService } from '../../../routes.service';
import { TipoAddConfigEnum } from '../../../shared/enum/Config/tipo-add-config.enum';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { StatusEnum } from '../../../shared/enum/status.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UsuarioSearchRequest } from '../../../shared/services/requests/pessoa/usuario-search.request';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ListUsuarioResponse } from '../../../shared/services/responses/pessoa/list-usuario.response';
import { ListStatusStruct } from '../../../shared/services/structs/listStatus.struct';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateFilterComponent } from '../../../shared/component/filter-list/date-filter/date-filter.component';
import { OpenTextFilterComponent } from '../../../shared/component/filter-list/open-text-filter/open-text-filter.component';
import { LoadingBoxComponent } from '../../../shared/component/loading-box/loading-box.component';
import { Router } from '@angular/router';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { ViewSwitcherComponent } from '../../../shared/component/view-switcher/view-switcher.component';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-usuario-lista',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBoxComponent,
    OpenTextFilterComponent,
    DateFilterComponent,
    SubheaderComponent,
    ViewSwitcherComponent,
    SkeletonComponent,
    AdvancedTableComponent

  ],
  templateUrl: './usuario-lista.component.html',
  styleUrl: './usuario-lista.component.css',
})
export class UsuarioListaComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  public color: string = DefaultColorEnum.Cor;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    public financeiroService: FinanceiroService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private location: Location,
    private routesService: RoutesService
  ) {
  }
  public model: FormGroup;
  public dataList: any[];
  public searchTimeout: any;
  public profile: number = DefaultProfilesEnum.Configuracao;
  public status: ListStatusStruct = new ListStatusStruct();
  public listStatus = this.status.listStatus;
  public ativo: boolean = true;
public tableEnum: number = ExhibitEnum.Tabela;
  public isTable: boolean = true;

  tableColumns = [
    {
      key: 'nome',
      label: 'Nome',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'cpf',
      label: 'CPF',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      filterable: false,
      date: false,
    },
    {
      key: 'telefone',
      label: 'Telefone',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'inclusao',
      label: 'Inclusão',
      sortable: true,
      filterable: false,
      date: true,
    },
  ];
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      search: [''],
      filters: [''],
      filterDate: [''],
      currentPage: [0],
      totalPages: [0],
      statusSelect: [1],
    });

    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);

    this.searchUsuario();
  }
  
  onSearchValueChanged(value: string) {
    this.searchSubject.next(value);
  }

  onDateValueChanged(value: any) {
    this.searchDate.next(value.date);
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchUsuario();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchUsuario();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  searchUsuario() {
    this.isLoading = true;
    const searchRequest: UsuarioSearchRequest = new UsuarioSearchRequest();
      
    if (this.model.get('filters')?.value) {
      searchRequest.filters = this.model.get('filters')?.value;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.model.get('search')?.value) {
      searchRequest.searchText = this.model.get('search')?.value;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value) {
      const [startDateString, endDateString] = this.model
        .get('filterDate')
        ?.value.split('|');
      searchRequest.dataInicio = startDateString;
      searchRequest.dataFim = endDateString;
      this.model.get('currentPage')?.setValue(0);
    }
    if (
      this.model.get('statusSelect')?.value &&
      this.model.get('statusSelect')?.value != this.listStatus[0].id
    ) {
      searchRequest.ativo = this.ativo;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.pessoaService.searchUser(searchRequest).subscribe({
        next: (response: ListUsuarioResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.dataList = response.listUsuario;
          this.model.get('totalPages')?.setValue(response.pageSize);
          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }, 3000);
  }

  redirect(idRedirect: number) {
    this.router.navigate([this.routesService.USER_UPDATE(String(idRedirect))]);
  }

  onStatusChange(event: any) {
    if (event.target.value == StatusEnum.Ativo) {
      this.ativo = true;
      this.model.get('currentPage')?.setValue(0);
      this.searchUsuario();
    } else {
      this.ativo = false;
      this.model.get('currentPage')?.setValue(0);
      this.searchUsuario();
    }
  }

  getVisibleTags(tags: string[]): {
    visibleTags: string[];
    remainingCount: number;
  } {
    const maxVisibleTags = 3;
    const visibleTags = tags.slice(0, maxVisibleTags);
    const remainingCount = tags.length - visibleTags.length;
    return { visibleTags, remainingCount };
  }

   exhibitActions(emitted: any) {
      if (emitted.action === 'search') {
        this.model.get('filterName')?.setValue(emitted.search);
        this.model.get('filters')?.setValue(emitted.filters);
        this.searchUsuario();
      } else if (emitted.action === 'edit') {
        this.router.navigate([
          this.routesService.USER_UPDATE(emitted.row.registro),
        ]);
      } else if (emitted.action === 'dateSearch') {
        if (emitted.dateSearch.date != null) {
          this.model.get('filterDate')?.setValue(emitted.dateSearch.date);
        } else {
          this.model.get('filterDate')?.setValue(null);
        }
        this.searchUsuario();
      } else if (emitted.action === 'remove') {
        const modalRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            title: 'Atenção!',
            description:
              'Deseja excluir o usuario ' +
              emitted.row.nome +
              ' ? Esta ação é irreversivel.',
            buttonConfirm: 'Excluir',
            buttonClose: 'Cancelar',
          },
          hasBackdrop: true,
          disableClose: false,
        });
  
        modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
          if (result.confirm) {
            this.isLoading = true;
            this.pessoaService.removeClientById(emitted.row.registro).subscribe({
              next: (response: ReturnStruct) => {
                if (response.isError) {
                  this.alertService.show(
                    'Erro inesperado',
                    response.errorDescription,
                    AlertType.error
                  );
                  this.isLoading = false;
                  return;
                }
                this.isLoading = false;
                this.alertService.show(
                  'Sucesso!',
                  'Cliente excluido',
                  AlertType.success
                );
                this.searchUsuario();
              },
              error: (error) => {
                this.alertService.show('Erro inesperado', error, AlertType.error);
                this.isLoading = false;
                return;
              },
            });
          } else {
            this.isLoading = false;
            return;
          }
        });
      }
    }
    onExhibitChange(event: any) {
      if (event == this.tableEnum) {
        this.isTable = true;
      } else {
        this.isTable = false;
      }
    }
}
