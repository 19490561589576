import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ProdutoSearchRequest } from '../../../shared/services/requests/estoque/produto-search.request';
import { ProdutoSearchResponse } from '../../../shared/services/responses/estoque/produto-search.response';
import { EstoqueService } from '../../../shared/services/API/estoque/estoque.service';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { MatSelectModule } from '@angular/material/select';
import { Subject, debounceTime } from 'rxjs';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { ListExhibitStruct } from '../../../shared/services/structs/list-exhibit.struct';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { RoutesService } from '../../../routes.service';
import { ViewSwitcherComponent } from '../../../shared/component/view-switcher/view-switcher.component';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { MovimentacaoResponse } from '../../../shared/services/responses/estoque/movimentacao.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { TipoListaEstoqueEnum } from '../../../shared/enum/Estoque/tipo-lista-estoque.enum';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';

@Component({
  selector: 'app-estoque-lista',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule,
    SubheaderComponent,
    MatSelectModule,
    ViewSwitcherComponent,
    SkeletonComponent,
    AdvancedTableComponent,
  ],
  templateUrl: './estoque-lista.component.html',
  styleUrl: './estoque-lista.component.css',
})
export class EstoqueListaComponent implements OnInit {
  @ViewChild(AdvancedTableComponent) exhibit: AdvancedTableComponent;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  public color: string = DefaultColorEnum.Cor;
  public indexExpansao: number | null = null;
  public movimentacao: MovimentacaoResponse;
  buttonStyle: any = {
    backgroundColor: this.color,
    color: 'white',
  };
  secondButtonStyle: any = {
    backgroundColor: 'transparent',
    color: this.color,
  };
  constructor(
    public authService: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private estoqueService: EstoqueService,
    private viewportScroller: ViewportScroller,
    private dialog: MatDialog,
    private routesService: RoutesService
  ) {
    this.searchDate.pipe(debounceTime(2000)).subscribe((value) => {
      this.model.get('filterDate')?.setValue(value);
      this.search();
    });
  }

  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = false;
  public model: FormGroup;
  public _page: number = 0;
  public _produtoSearchResponse: ProdutoSearchResponse;
  public nameProfile: string = 'Produto';
  public iconProfile: string = 'inventory';
  public profile: number = DefaultProfilesEnum.Estoque;
  public _isLastPage: boolean = false;
  public tableEnum: number = ExhibitEnum.Tabela;
  public display: ListExhibitStruct = new ListExhibitStruct();
  public listDisplay = this.display.listExhibit;
  public isTable: boolean = false;
  public selectedExhibit: number;
  public dataList: any[];
  public searchTimeout: any;
  tableColumns = [
    {
      key: 'nome',
      label: 'Nome',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'referencia',
      label: 'REF',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'ean',
      label: 'EAN',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'nomeGrupo',
      label: 'Grupo',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'quantEstoque',
      label: 'Estoque',
      sortable: true,
      filterable: false,
      date: false,
    },
  ];
  public isProduto: boolean = true;
  public tipoProduto: number = TipoListaEstoqueEnum.Produtos;
  public tipoMovimentacao: number = TipoListaEstoqueEnum.Movimentacao;
  public selectedCard: number = TipoListaEstoqueEnum.Produtos;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterName: [''],
      filterDate: [''],
      filters: [],
    });

    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);
    this.search();
  }

  search() {
    if (this.isLoading || this.model.invalid) return;

    let produtoSearchRequest: ProdutoSearchRequest = new ProdutoSearchRequest();

    this.isLoading = true;

    if (this.model.get('filters')?.value) {
      produtoSearchRequest.filters = this.model.get('filters')?.value;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.model.get('filterDate')?.value) {
      this.model.get('currentPage')?.setValue(0);
      const [startDateString, endDateString] = this.model
        .get('filterDate')
        ?.value.split('|');
      produtoSearchRequest.dataInicio = startDateString;
      produtoSearchRequest.dataFim = endDateString;
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.estoqueService.SearchProduto(produtoSearchRequest).subscribe({
        next: (produtoSearchResponse: ProdutoSearchResponse) => {
          this.isLoading = false;
          if (produtoSearchResponse.isError) {
            this.alertService.show(
              'Erro inesperado',
              produtoSearchResponse.errorDescription,
              AlertType.error
            );
            return;
          }

          if (this._page == 0) {
            this._produtoSearchResponse = new ProdutoSearchResponse();
          }

          this._isLastPage = true;

          if (produtoSearchResponse.listProdutoSearchStruct?.length > 0) {
            this._produtoSearchResponse.listProdutoSearchStruct.push(
              ...produtoSearchResponse.listProdutoSearchStruct
            );
            this._isLastPage = false;
          }

          this.dataList = produtoSearchResponse.listProdutoSearchStruct;
          this.model
            .get('totalPages')
            ?.setValue(produtoSearchResponse.pageSize);
          setTimeout(() => {
            this.update();
          }, 3000);
        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        },
      });
    }, 10);
  }

  update() {
    this.exhibit.update();
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  addProduct() {
    this.router.navigate([this.routesService.INVENTORY_REGISTER]);
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }

  exhibitActions(emitted: any) {
    if (emitted.action === 'search') {
      // this.model.get('filterName')?.setValue(emitted.search);
      this.model.get('filters')?.setValue(emitted.filters);
      this.search();
    } else if (emitted.action === 'edit') {
      this.router.navigate([
        this.routesService.INVENTORY_UPDATE(emitted.row.idProduto),
      ]);
    } else if (emitted.action === 'dateSearch') {
      if (emitted.dateSearch.date != null) {
        this.model.get('filterDate')?.setValue(emitted.dateSearch.date);
      } else {
        this.model.get('filterDate')?.setValue(null);
      }
      this.search();
    } else if (emitted.action === 'remove') {
      const modalRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title: 'Atenção!',
          description:
            'Deseja excluir o produto ' +
            emitted.row.nome +
            ' ? Esta ação é irreversivel.',
          buttonConfirm: 'Excluir',
          buttonClose: 'Cancelar',
        },
        hasBackdrop: true,
        disableClose: false,
      });

      modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
        if (result.confirm) {
          this.isLoading = true;
          this.estoqueService.DeleteProduto(emitted.row.idProduto).subscribe({
            next: (response: ReturnStruct) => {
              if (response.isError) {
                this.alertService.show(
                  'Erro inesperado',
                  response.errorDescription,
                  AlertType.error
                );
                this.isLoading = false;
                return;
              }
              this.isLoading = false;
              this.alertService.show(
                'Sucesso!',
                'Produto excluido',
                AlertType.success
              );
              this.search();
            },
            error: (error) => {
              this.alertService.show('Erro inesperado', error, AlertType.error);
              this.isLoading = false;
              return;
            },
          });
        } else {
          this.isLoading = false;
          return;
        }
      });
    }
  }

  onSearchValueChanged(value: string) {
    this.searchSubject.next(value);
  }

  onDateValueChanged(value: string) {
    this.searchDate.next(value);
  }

  onExhibitChange(event: any) {
    if (event == this.tableEnum) {
      this.isTable = true;
    } else {
      this.isTable = false;
    }
  }

  toggleExpansion(index: number): void {
    if (this.indexExpansao === index) {
      this.indexExpansao = null;
    } else {
      this.indexExpansao = index;
    }
  }

  isExpanded(index: number): boolean {
    return this.indexExpansao === index;
  }
}
