import { CommonModule, ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { Subject, debounceTime } from 'rxjs';
import { RoutesService } from '../../routes.service';
import { AdvancedTableComponent } from '../../shared/component/advanced-table/advanced-table.component';
import { ConfirmModalComponent } from '../../shared/component/confirm-modal/confirm-modal.component';
import { DefaultColorEnum } from '../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../shared/enum/default-profiles.enum';
import { ExhibitEnum } from '../../shared/enum/exhibit.enum';
import { PapelEnum } from '../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { AuthService } from '../../shared/services/auth.service';
import { UserLoginResponse } from '../../shared/services/responses/auth/user-login.response';
import { ListExhibitStruct } from '../../shared/services/structs/list-exhibit.struct';
import { ReturnStruct } from '../../shared/services/structs/return.struct';
import { Router } from '@angular/router';
import { PerfilImpostoSearchRequest } from '../../shared/services/requests/fiscal/perfil-imposto-search.request';
import { FiscalService } from '../../shared/services/API/fiscal/fiscal.service';
import { ListaPerfilImpostoResponse } from '../../shared/services/responses/fiscal/lista-perfil-imposto.response';
import { SkeletonComponent } from '../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../shared/component/subheader/subheader.component';
import { ViewSwitcherComponent } from '../../shared/component/view-switcher/view-switcher.component';

@Component({
  selector: 'app-perfil-imposto-lista',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SubheaderComponent,
    MatFormFieldModule,
    MatSelectModule,
    ViewSwitcherComponent,
    SkeletonComponent,
    AdvancedTableComponent,
],
  templateUrl: './perfil-imposto-lista.component.html',
  styleUrl: './perfil-imposto-lista.component.css'
})
export class PerfilImpostoListaComponent implements OnInit {
  @ViewChild(AdvancedTableComponent) exhibit: AdvancedTableComponent;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public fiscalService: FiscalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private routesService: RoutesService
  ) {
  }
  public model: FormGroup;
  public dataList: any[];
  public searchTimeout: any;
  public profile: number = DefaultProfilesEnum.Fiscal;
  public tableEnum: number = ExhibitEnum.Tabela;
  public display: ListExhibitStruct = new ListExhibitStruct();
  public listDisplay = this.display.listExhibit;
  public isTable: boolean = true;
  public selectedExhibit: number;

  tableColumns = [
    {
      key: 'nome',
      label: 'Nome',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      filterable: false,
      date: false,
    },
    {
      key: 'inclusao',
      label: 'Inclusão',
      sortable: true,
      filterable: false,
      date: true,
    },
  ];

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterName: [''],
      filterDate: [null],
      currentPage: [0],
      totalPages: [0],
    });
    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);

    this.searchTaxProfile();
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchTaxProfile();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchTaxProfile();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  searchTaxProfile() {
    this.isLoading = true;
    const searchRequest: PerfilImpostoSearchRequest = new PerfilImpostoSearchRequest();

    if (this.model.get('filterName')?.value) {
      searchRequest.searchText = this.model.get('filterName')?.value;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value) {
      this.model.get('currentPage')?.setValue(0);
      const [startDateString, endDateString] = this.model
        .get('filterDate')
        ?.value.split('|');
      searchRequest.dataInicio = startDateString;
      searchRequest.dataFim = endDateString;
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.fiscalService.getList(searchRequest).subscribe({
        next: (response: ListaPerfilImpostoResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.dataList = response.listPerfilImpostoStruct;
          this.model.get('totalPages')?.setValue(response.pageSize);
          setTimeout(() => {
            this.update();
          }, 3000);
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }, 10);
  }

  update() {
    this.exhibit.update();
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  addClient() {
    this.router.navigate([this.routesService.TAX_PROFILE_REGISTER]);
  }

  exhibitActions(emitted: any) {
    if (emitted.action === 'search') {
      this.model.get('filterName')?.setValue(emitted.search);
      this.model.get('filters')?.setValue(emitted.filters);
      this.searchTaxProfile();
    } else if (emitted.action === 'edit') {
      this.router.navigate([
        this.routesService.TAX_PROFILE_EDIT(emitted.row.registro),
      ]);
    } else if (emitted.action === 'dateSearch') {
      if (emitted.dateSearch.date != null) {
        this.model.get('filterDate')?.setValue(emitted.dateSearch.date);
      } else {
        this.model.get('filterDate')?.setValue(null);
      }
      this.searchTaxProfile();
    } else if (emitted.action === 'remove') {
      const modalRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title: 'Atenção!',
          description:
            'Deseja excluir o perfil de imposto ' +
            emitted.row.nome +
            ' ? Esta ação é irreversivel.',
          buttonConfirm: 'Excluir',
          buttonClose: 'Cancelar',
        },
        hasBackdrop: true,
        disableClose: false,
      });

      modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
        if (result.confirm) {
          this.isLoading = true;
          this.fiscalService.remove(emitted.row.registro).subscribe({
            next: (response: ReturnStruct) => {
              if (response.isError) {
                this.alertService.show(
                  'Erro inesperado',
                  response.errorDescription,
                  AlertType.error
                );
                this.isLoading = false;
                return;
              }
              this.isLoading = false;
              this.alertService.show(
                'Sucesso!',
                'Perfil de imposto excluido',
                AlertType.success
              );
              this.searchTaxProfile();
            },
            error: (error) => {
              this.alertService.show('Erro inesperado', error, AlertType.error);
              this.isLoading = false;
              return;
            },
          });
        } else {
          this.isLoading = false;
          return;
        }
      });
    }
  }
  onExhibitChange(event: any) {
    if (event == this.tableEnum) {
      this.isTable = true;
    } else {
      this.isTable = false;
    }
  }
}
