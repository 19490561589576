import { PedidoDescontoRequestStruct } from "../../structs/pedido/pedido-desconto-request.struct";
import { PedidoProdutoRequestStruct } from "../../structs/pedido/pedido-produto-request.struct";
import { PagamentoPedidoRequest } from "../financeiro/pagamento-pedido.request";

export class PedidoRequest {
  public idPedido?: number;
  public nomeCliente: string;
  public idCliente: string;
  public idTipoCliente: number;
  public idOrigemPedido: number = 1;
  public centroDeCusto: string;
  public nomeTransportadora: string;
  public idTransportadora: string;
  public cep: string;
  public bairro: string;
  public complemento: string;
  public latitude: string;
  public longitude: string;
  public numero: string;
  public cidade: string;
  public estado: string;
  public rua: string;
  public pais: string;
  public listPedidoDescontoRequestStruct: PedidoDescontoRequestStruct[] = [];
  public listProdutoRequestStruct: PedidoProdutoRequestStruct[] = [];
  public pagamentoPedido?: PagamentoPedidoRequest;
}
