<div class="container">
    <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
        <div class="login">
            <div class="title">
                <h2>Selecione a empresa que deseja logar:</h2>
            </div>
            <div class="section">
                <mat-form-field appearance="fill">
                    <mat-label>Escolha a empresa para logar</mat-label>
                    <mat-select formControlName="enterpriseSelect">
                      <mat-option *ngFor="let empresa of this.userLoginResponse.listEmpresaStruct" [value]="empresa.idEmpresa">
                        {{ empresa.nomeEmpresa }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="section">
                <button type="submit" class="button-login">
                    <ng-container *ngIf="isLoading; else notLoading">
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    </ng-container>
                    <ng-template #notLoading>
                        Confirmar
                    </ng-template>
                </button>
            </div>
        </div>
    </form>
</div>