<div [ngClass]="{'sidebar-expanded': showActionNav, 'sidebar': !showActionNav}">
  <div class="main-menu" (mouseover)="onMouseOver()" (mouseleave)="onMouseLeave()">
    <ng-container *ngFor="let module of modules; let isLast = last">
      <a *ngIf="!isLast" (click)="onCardClick(module)" class="menu-item" [ngClass]="{'active': isActive(module.redirect), 'highlight': module.corInvertida}">
        <div [ngClass]="{'flexbox-expanded': showActionNav, 'flexbox': !showActionNav}">
          <mat-icon class="menu-icon">
            {{ module.googleIcone }}
          </mat-icon>
          <div [ngClass]="{'text-expanded': showActionNav, 'text-collapsed': !showActionNav}">
            {{ module.nomePerfil }}
          </div>
        </div>
      </a>
      @if (!isLast) {
      <ng-container *ngFor="let submodule of module.submodules">
        <a *ngIf="isActive(module.redirect)" (click)="onSubCardClick(submodule)" class="submenu-item" [ngClass]="{'active': isActive(submodule.redirect)}">
          <div [ngClass]="{'flexbox-expanded': showActionNav, 'flexbox': !showActionNav}">
            <mat-icon class="menu-icon">
              <!-- {{ module.googleIcone }} -->
              {{ isActive(submodule.redirectSubperfil) ? 'radio_button_checked' : 'radio_button_unchecked' }}
            </mat-icon>
            <div [ngClass]="{'text-expanded': showActionNav, 'text-collapsed': !showActionNav}">
              {{ submodule.nomeSubPerfil }}
            </div>
          </div>
        </a>
      </ng-container>
      }
    </ng-container>
  </div>
  <div class="main-menu" (mouseover)="onMouseOver()" (mouseleave)="onMouseLeave()">
    <ng-container *ngFor="let module of modules; let isLast = last">
      <ng-container *ngIf="isLast">
        <ng-container *ngFor="let submodule of module.submodules">
          <a *ngIf="isActive(module.redirect)" (click)="onSubCardClick(submodule)" class="submenu-item setting" [ngClass]="{'active': isActive(submodule.redirect)}">
            <div [ngClass]="{'flexbox-expanded': showActionNav, 'flexbox': !showActionNav}">
              <mat-icon class="menu-icon">
                {{ isActive(submodule.redirectSubperfil) ? 'radio_button_checked' : 'radio_button_unchecked' }}
              </mat-icon>
              <div [ngClass]="{'text-expanded': showActionNav, 'text-collapsed': !showActionNav}">
                {{ submodule.nomeSubPerfil }}
              </div>
            </div>
          </a>
        </ng-container>
        <a class="menu-item setting" [ngClass]="{'active': isActive(module.redirect)}" (click)="onCardClick(module)">
          <div [ngClass]="{'flexbox-expanded': showActionNav, 'flexbox': !showActionNav}">
            <mat-icon class="menu-icon">{{ module.googleIcone }}</mat-icon>
            <div [ngClass]="{'text-expanded': showActionNav, 'text-collapsed': !showActionNav}">
              {{ module.nomePerfil }}
            </div>
          </div>
        </a>
      </ng-container>
    </ng-container>
  </div>
  <a class="expand" (click)="onToggleNav()">
    <span class="icon-container">
      <mat-icon>{{ showActionNav ? 'chevron_left' : 'chevron_right' }}</mat-icon>
    </span>
  </a>
</div>