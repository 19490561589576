import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { UtilService } from '../../../shared/services/util.service';
import { UsuarioRequest } from '../../../shared/services/requests/pessoa/usuario.request';
import { UsuarioResponse } from '../../../shared/services/responses/pessoa/usuario.response';
import { RoutesService } from '../../../routes.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { EmpresaResponse } from '../../../shared/services/responses/pessoa/empresa.response';
import { EmpresaRequest } from '../../../shared/services/requests/pessoa/empresa.request';
import { CoresPersonalizadasResponse } from '../../../shared/services/responses/pessoa/cores-personalizadas.response';
import { CorPersonalizada } from '../../../shared/services/models/pessoa/cor-personalizada.model';
import { EmpresaColorPickerComponent } from './empresa-color-picker/empresa-color-picker.component';

@Component({
  selector: 'app-meus-dados',
  standalone: true,
  imports: [
      MatIconModule,
      FormsModule,
      ReactiveFormsModule,
      CommonModule,
      MatFormFieldModule,
      MatInputModule,
      MatProgressSpinnerModule,
      SubheaderComponent,
      MatSelectModule,
      MatCheckboxModule,
      SkeletonComponent,
  ],
  templateUrl: './minha-empresa.component.html',
  styleUrl: './minha-empresa.component.css',
})
export class MinhaEmpresaComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  private cepTimeout: any;
  public masks: any;
  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private maskService: MaskService,
    private routesService: RoutesService
  ) {}
  public model: FormGroup;   
  public urlLogoEmpresa: string;
  public mostrarPicker: boolean;
  public corSelecionada: string;
  public corHoverSelecionada: string;
  public coresPersonalizadas: CorPersonalizada[] = [];
  public request = new EmpresaRequest();
  public profile: number = DefaultProfilesEnum.Configuracao;

  @ViewChild('numeroEndereco') numeroEndereco: ElementRef;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      telefone: [''],
      telefone2: [''],
      nascimento: ['', Validators.required],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      complemento: [''],
      localidade: [''],
      uf: [''],
      pais: [''],
      idCorPersonalizada: ['', Validators.required],
    });
    let cores = this.getCores();
    this.userLoginResponse = this.authService.getUserInfos();
    this.getUser();
    this.isLoading = false;
  }

  submit() {
    this.isLoading = true;

    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    }

    this.request.nome = this.model.get('nome')?.value;
    this.request.email = this.model.get('email')?.value;
    this.request.telefone = this.model.get('telefone')?.value;
    this.request.telefone2 = this.model.get('telefone2')?.value;
    this.request.nascimento = this.model.get('nascimento')?.value;
    this.request.latitude = this.model.get('latitude')?.value;
    this.request.longitude = this.model.get('longitude')?.value;
    this.request.cep = this.model.get('cep')?.value;
    this.request.logradouro = this.model.get('logradouro')?.value;
    this.request.cidade = this.model.get('cidade')?.value;
    this.request.numero = this.model.get('numero')?.value;
    this.request.estado = this.model.get('uf')?.value;
    this.request.rua = this.model.get('rua')?.value;
    this.request.bairro = this.model.get('bairro')?.value;
    this.request.complemento = this.model.get('complemento')?.value;
    this.request.pais = this.model.get('pais')?.value;
    this.request.idCorPersonalizada = this.request.idCorPersonalizada;

    this.update();
    
  }

  onFileSelected(event: any) {
      if (!event.target.files || this.isLoading)
        return;
  
      for (let index = 0; index < event.target.files.length; index++) {
  
        const file: File = event.target.files[index];
        let fileExtension = file.name.split(".")?.pop()?.toLowerCase();
  
        if (fileExtension != "jpg"
          && fileExtension != "png"
          && fileExtension != "jpeg"
          && fileExtension != "gif"
          && fileExtension != "bmp"
          && fileExtension != "tif") {
          this.alertService.show("Erro no upload", "Um ou mais arquivos possuem uma extenção não valida.", AlertType.error);
          this.isLoading = false;
          return;
        }
  
        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.urlLogoEmpresa = e.target.result; // Atualiza a imagem na tela
            this.request.base64LogoEmpresa = (reader.result as string).split("base64,", 2)[1];
        }
        reader.readAsDataURL(file);
      }
    }

  update() {
    this.pessoaService.myEmpresaUpdate(this.request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Empresa atualizada',
          AlertType.success
        );
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getUser() {
    this.pessoaService.getMyEmpresa().subscribe({
      next: (response: EmpresaResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.request.idEmpresa = response.empresa.idEmpresa;
        this.request.idPessoa = response.pessoa.idPessoa;
        this.model.get('nome')?.setValue(response.pessoa.nome);
        this.model.get('email')?.setValue(response.pessoa.email);
        this.model.get('cpfCnpj')?.setValue(response.pessoa.cpfCnpj);
        this.model.get('cpfCnpj')?.disable();
        this.model.get('telefone')?.setValue(response.pessoa.telefone);
        this.model.get('telefone2')?.setValue(response.pessoa.telefone2);
        this.model.get('nascimento')?.setValue(this.formatarData(response.pessoa.nascimento));
        this.model.get('latitude')?.setValue(response.pessoa.latitude);
        this.model.get('longitude')?.setValue(response.pessoa.longitude);
        this.model.get('cep')?.setValue(response.pessoa.cep);
        this.model.get('logradouro')?.setValue(response.pessoa.logradouro);
        this.model.get('cidade')?.setValue(response.pessoa.cidade);
        this.model.get('numero')?.setValue(response.pessoa.numero);
        this.model.get('uf')?.setValue(response.pessoa.estado);
        this.model.get('rua')?.setValue(response.pessoa.logradouro);
        this.model.get('bairro')?.setValue(response.pessoa.bairro);
        this.model.get('complemento')?.setValue(response.pessoa.complemento);
        this.model.get('pais')?.setValue(response.pessoa.pais);
        this.model.get('idCorPersonalizada')?.setValue(response.empresaPersonalizacao.idCorPersonalizada);
        this.urlLogoEmpresa = response.empresaPersonalizacao.urlLogoEmpresa;

        let corPersonalizada = this.coresPersonalizadas.find((cor) => cor.idCorPersonalizada == response.empresaPersonalizacao.idCorPersonalizada);
        
        if (corPersonalizada){
            this.selecionarCores(corPersonalizada.cor, corPersonalizada.corHover, corPersonalizada.idCorPersonalizada)
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar cep',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  callColorModal(){
    const modalRef = this.dialog.open(EmpresaColorPickerComponent, {
        data: {
            coresPersonalizadas: this.coresPersonalizadas
        }
    });
    modalRef.afterClosed().subscribe((result: { confirm: CorPersonalizada }) => {
        this.selecionarCores(result.confirm.cor, result.confirm.corHover,  result.confirm.idCorPersonalizada)
    });
}

  toggleColorPicker() {
    this.mostrarPicker = !this.mostrarPicker;

    
  }

  selecionarCores(cor: string, corHover: string, idCorPersonalizada: number) {
    this.corSelecionada = cor;
    this.corHoverSelecionada = corHover;
    this.mostrarPicker = false;
    this.request.idCorPersonalizada = idCorPersonalizada;
  }

  getCores() {
    this.pessoaService.GetCores().subscribe({
      next: (response: CoresPersonalizadasResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.coresPersonalizadas = response.listCorPersonalizada;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar cep',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }
  
  formatarData(nascimento: any): string {
    const data = (nascimento instanceof Date) ? nascimento : new Date(nascimento);
  
    if (isNaN(data.getTime())) {
      return ''; // Ou qualquer valor adequado em caso de erro
    }
  
    const ano = data.getFullYear();
    const mes = ('0' + (data.getMonth() + 1)).slice(-2); // Meses começam do 0
    const dia = ('0' + data.getDate()).slice(-2);
  
    return `${ano}-${mes}-${dia}`; // Formato correto para o input type="date"
  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {
      this.utilService
        .getAddressByCEP(cep)
        .pipe(
          catchError((error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            this.model.get('logradouro')?.enable();
            this.model.get('complemento')?.enable();
            this.model.get('bairro')?.enable();
            this.model.get('uf')?.enable();
            this.model.get('pais')?.enable();
            this.model.get('cidade')?.enable();
            this.model.get('numero')?.enable();
            return of(null);
          })
        )
        .subscribe((data) => {
          if (data) {
            this.model.patchValue({
              logradouro: data.logradouro,
              complemento: data.complemento,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf,
              pais: 'Brasil',
            });
          }

          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          this.numeroEndereco.nativeElement.focus();
          this.isLoading = false;
        });
    }, 2000);
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_USERS]);
  }

  onCpfCnpjInput(event: any): void {
    const value = event.target.value.replace(/\D/g, '');
    let formattedValue = '';

    formattedValue = this.maskService.applyMask(value, this.masks.cpf);

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  onTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone')?.setValue(formattedTelefone);
  }

  onSecondTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone2')?.setValue(formattedTelefone);
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(
      event.target.value,
      this.masks.cep
    );
    this.model.get('cep')?.setValue(formattedCep);
    if (formattedCep.length > 8) {
      this.searchCep(formattedCep);
    }
  }
}
