import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { ProdutoGradeStruct } from '../../../../shared/services/structs/estoque/produto-grade-struct';
import { NovaSubgradeStruct } from '../../../../shared/services/structs/estoque/nova-subgrade-struct';
import { ProdutoGradeRequest } from '../../../../shared/services/requests/estoque/produto-grade.request';
import { ProdutoResponse } from '../../../../shared/services/responses/estoque/produto.response';
import { EstoqueService } from '../../../../shared/services/API/estoque/estoque.service';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';

@Component({
  selector: 'app-section-product-grid',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
  ],
  templateUrl: './section-product-grid.component.html',
  styleUrl: './section-product-grid.component.css'
})
export class SectionProductGridComponent implements OnInit {
  @Input() _produtoResponse: ProdutoResponse;
  @Output() reloadProduct = new EventEmitter<any>()
  @Output() prevStep = new EventEmitter<any>()
  @Output() nextStep = new EventEmitter<any>()

  public model: FormGroup;
  public isLoading: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private estoqueService: EstoqueService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit(): void {

    this.model = this.formBuilder.group({
      quantidadeBase: [''],
      precoBase: [''],
      listProdutoGradeStruct: this.formBuilder.array([
        this.initListProdutoGradeStruct(null)]),
    });

    this.populate();

  }

  initListProdutoGradeStruct(produtoGradeStruct: ProdutoGradeStruct | null) {
    return new FormGroup({
      idGrade: new FormControl(produtoGradeStruct ? produtoGradeStruct.idGrade : ''),
      nomeGrade: new FormControl(produtoGradeStruct ? produtoGradeStruct.nomeGrade : '', Validators.required),
      listNovaSubgradeStruct: new FormArray([
        this.initListNovaSubgradeStruct(null),
      ])
    });
  }

  initListNovaSubgradeStruct(novaSubgradeStruct: NovaSubgradeStruct | null) {
    return new FormGroup({
      idSubgrade: new FormControl(novaSubgradeStruct ? novaSubgradeStruct.idSubgrade : ''),
      idGrade: new FormControl(novaSubgradeStruct ? novaSubgradeStruct.idGrade : ''),
      nomeSubgrade: new FormControl(novaSubgradeStruct ? novaSubgradeStruct.nomeSubgrade : '', Validators.required),
      corHex: new FormControl(novaSubgradeStruct ? novaSubgradeStruct.corHex : '#eadcc5'),
      precoAtual: new FormControl(novaSubgradeStruct ? novaSubgradeStruct.precoAtual : '', Validators.required),
      quantidadeAtual: new FormControl(novaSubgradeStruct ? novaSubgradeStruct.quantidadeAtual : '', Validators.required)
    });
  }

  addListProdutoGradeStruct(produtoGradeStruct: ProdutoGradeStruct | null, index: number | null) {
    if (index == null)
      (this.model.controls['listProdutoGradeStruct'] as FormArray).push(this.initListProdutoGradeStruct(produtoGradeStruct));
    else
      (this.model.controls['listProdutoGradeStruct'] as FormArray).insert(index + 1, this.initListProdutoGradeStruct(produtoGradeStruct));

    this.quantidadeBaseDesabilitado();
  }

  addListNovaSubgradeStruct(i: number, novaSubgradeStruct: NovaSubgradeStruct | null, index: number | null) {
    const control = <FormArray>(this.model.get('listProdutoGradeStruct') as FormArray)?.controls[i].get('listNovaSubgradeStruct');

    if (index == null)
      control.push(this.initListNovaSubgradeStruct(novaSubgradeStruct));
    else
      control.insert(index + 1, this.initListNovaSubgradeStruct(novaSubgradeStruct));

  }

  getListProdutoGradeStruct(form: any) {
    return form.controls.listProdutoGradeStruct.controls;
  }

  getListNovaSubgradeStruct(form: any) {
    return form.controls.listNovaSubgradeStruct.controls;
  }

  getListNovaSubgradeStructControls() {
    return (this.model.get("listProdutoGradeStruct") as FormArray)?.controls as FormGroup[]
  }

  getlistNovaSubgradeStructControls(index: number) {
    return ((this.getListNovaSubgradeStructControls()[index]).get("listNovaSubgradeStruct") as FormArray)?.controls as FormGroup[]
  }

  removeListNovaSubgradeStruct(i: number) {
    (this.model.controls['listProdutoGradeStruct'] as FormArray).removeAt(i);
    this.quantidadeBaseDesabilitado();
  }

  removeNovaSubgradeStruct(i: number, j: number) {
    ((this.model.controls['listProdutoGradeStruct'] as FormArray)?.controls[i].get('listNovaSubgradeStruct') as FormArray).removeAt(j)
  }

  atualizarQuantidadeBase(): void {
    const listProdutoGradeStruct = this.model.get("listProdutoGradeStruct") as FormArray;
    let totalQuantidadeAtual = 0;
  
    if (listProdutoGradeStruct.length > 0) {
      listProdutoGradeStruct.controls.forEach((produtoGrade: any) => {
        const listNovaSubgradeStruct = produtoGrade.get("listNovaSubgradeStruct") as FormArray;
        if (listNovaSubgradeStruct) {
          listNovaSubgradeStruct.controls.forEach((subgrade: any) => {
            const quantidadeAtual = subgrade.get("quantidadeAtual")?.value;
            if (quantidadeAtual) {
              totalQuantidadeAtual += Number(quantidadeAtual);
            }
          });
        }
      });
      this.model.get('quantidadeBase')?.setValue(totalQuantidadeAtual);
      this.model.get('quantidadeBase')?.disable();
      this.model.get('precoBase')?.disable();
    } else {
      this.model.get('quantidadeBase')?.enable();
      this.model.get('precoBase')?.enable();
      this.model.get('quantidadeBase')?.setValue(null); // ou 0, dependendo do comportamento desejado
    }
  }
  
  quantidadeBaseDesabilitado(): void {
    this.atualizarQuantidadeBase();
  }

  populate() {
    if (this._produtoResponse && this._produtoResponse.produtoStruct && this._produtoResponse.produtoStruct.listProdutoGradeStruct && this._produtoResponse.produtoStruct.listProdutoGradeStruct.length > 0) {
      let index: number = 0;
      this._produtoResponse.produtoStruct.listProdutoGradeStruct.forEach((produtoGradeStruct: ProdutoGradeStruct) => {
        this.addListProdutoGradeStruct(produtoGradeStruct, null);
        index++;
        produtoGradeStruct.listNovaSubgradeStruct.forEach((novaSubgradeStruct: NovaSubgradeStruct) => {
          this.addListNovaSubgradeStruct(index, novaSubgradeStruct, null);
        });
        if (produtoGradeStruct.listNovaSubgradeStruct.length > 0)
          this.removeNovaSubgradeStruct(index, 0);

      });

      if (this._produtoResponse.produtoStruct.listProdutoGradeStruct.length > 0)
        this.removeListNovaSubgradeStruct(0);

      this.quantidadeBaseDesabilitado();
    }
    else{
      this.removeNovaSubgradeStruct(0, 0);
      this.removeListNovaSubgradeStruct(0);
    }
      this.model.get('quantidadeBase')?.setValue(this._produtoResponse?.produtoStruct?.produto.quantidadeBase);
      this.model.get('precoBase')?.setValue(this._produtoResponse?.produtoStruct?.produto.precoBase);
  }

  emitPrevStep(){
    this.prevStep.emit();
  }

  submit(idProduto: number) : boolean {
    if (this.model.invalid || this.isLoading)
      return false;

    let produtoGradeRequest: ProdutoGradeRequest = new ProdutoGradeRequest();
    produtoGradeRequest.idProduto = idProduto;
    produtoGradeRequest.precoBase = this.model.get('precoBase')?.value;
    produtoGradeRequest.quantidadeBase = this.model.get('quantidadeBase')?.value;
    produtoGradeRequest.listProdutoGradeStruct = [];

    this.model.get('listProdutoGradeStruct')?.value.forEach((itemGrade: any) => {
      let listProdutoGradeStruct: ProdutoGradeStruct = new ProdutoGradeStruct();

      listProdutoGradeStruct.nomeGrade = itemGrade.nomeGrade;
      listProdutoGradeStruct.idGrade = itemGrade.idGrade ? Number(itemGrade.idGrade) : null;
      listProdutoGradeStruct.listNovaSubgradeStruct = [];

      itemGrade.listNovaSubgradeStruct.forEach((itemSubgrade: any) => {
        let novaSubgradeStruct: NovaSubgradeStruct = new NovaSubgradeStruct();

        novaSubgradeStruct.corHex = itemSubgrade.corHex;
        novaSubgradeStruct.precoAtual = itemSubgrade.precoAtual;
        novaSubgradeStruct.quantidadeAtual = itemSubgrade.quantidadeAtual;
        novaSubgradeStruct.idGrade = itemSubgrade.idGrade ? Number(itemSubgrade.idGrade) : null;
        novaSubgradeStruct.idSubgrade = itemSubgrade.idSubgrade ? Number(itemSubgrade.idSubgrade) : null;
        novaSubgradeStruct.nomeSubgrade = itemSubgrade.nomeSubgrade;

        listProdutoGradeStruct.listNovaSubgradeStruct.push(novaSubgradeStruct);
      });

      produtoGradeRequest.listProdutoGradeStruct.push(listProdutoGradeStruct);

    });

    this.isLoading = true;
    this.estoqueService.CreateGrade(produtoGradeRequest).subscribe({
      next: (returnStruct: ReturnStruct) => {
        this.isLoading = false;

        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return false;
        }

        this.alertService.show("Sucesso", "estoque atualizado com sucesso", AlertType.success);
        return true;
      },
      error: (error) => {
        this.alertService.show("Erro", error, AlertType.error);
        this.isLoading = false;
        return false;
      }
    });
    return false;
  }
}
