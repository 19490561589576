import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ListBancoEmpresaResponse } from '../../responses/financeiro/list-banco-empresa.response';
import { PerfilImpostoRequest } from '../../requests/fiscal/perfil-imposto.request';
import { ReturnStruct } from '../../structs/return.struct';
import { PerfilImpostoResponse } from '../../responses/fiscal/perfil-imposto.response';
import { ListaSelectResponse } from '../../responses/fiscal/lista-select.response';
import { ListaPerfilImpostoResponse } from '../../responses/fiscal/lista-perfil-imposto.response';
import { PerfilImpostoSearchRequest } from '../../requests/fiscal/perfil-imposto-search.request';
import { MensagemTributariaSearchRequest } from '../../requests/fiscal/mensagem-tributaria-search.request';
import { MensagemTributariaSearchResponse } from '../../responses/fiscal/mensagem-tributaria-search.response';
import { MensagemTributariaRequest } from '../../requests/fiscal/mensagem-fiscal.request';
import { MensagemTributariaResponse } from '../../responses/fiscal/mensagem-tributaria.response';
import { ImpostoCestResponse } from '../../responses/fiscal/imposto-cest.response';
import { ImpostoCestSearchRequest } from '../../requests/fiscal/imposto-cest-search.request';
import { ImpostoCestSearchResponse } from '../../responses/fiscal/imposto-cest-search.response';
import { ImpostoCestRequest } from '../../requests/fiscal/imposto-cest.request';
import { CstSearchResponse } from '../../responses/fiscal/cst-search.response';
import { perfilPisCofinsResponse } from '../../responses/fiscal/perfil-pis-cofins.response';
import { PerfilPisCofinsSearchRequest } from '../../requests/fiscal/perfil-pis-cofins-search.request';
import { PerfilPisCofinsSearchResponse } from '../../responses/fiscal/perfil-pis-cofins-search.response';
import { PerfilPisCofinsRequest } from '../../requests/fiscal/perfil-pis-cofins.request';
import { ListaSelectFiscalResponse } from '../../responses/fiscal/lista-select-fiscal.response';

@Injectable({
  providedIn: 'root'
})
export class FiscalService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }
  public get(idPerfilImposto: number): Observable<PerfilImpostoResponse> {

    return this.httpClient.get<PerfilImpostoResponse>(environment.urlApiFiscal + "PerfilImposto/idPerfilImposto/" + idPerfilImposto, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public create(request: PerfilImpostoRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiFiscal + "PerfilImposto/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public update(request: PerfilImpostoRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiFiscal + "PerfilImposto/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public remove(idPerfilImposto: number): Observable<PerfilImpostoResponse> {

    return this.httpClient.delete<PerfilImpostoResponse>(environment.urlApiFiscal + "PerfilImposto/idPerfilImposto/" + idPerfilImposto, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getList(request: PerfilImpostoSearchRequest): Observable<ListaPerfilImpostoResponse> {

    return this.httpClient.post<ListaPerfilImpostoResponse>(environment.urlApiFiscal + "PerfilImposto/Lista/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getListSelect(): Observable<ListaSelectResponse> {

    return this.httpClient.get<ListaSelectResponse>(environment.urlApiFiscal + "ListaSelect/", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getListFiscalSelect(): Observable<ListaSelectFiscalResponse> {

    return this.httpClient.get<ListaSelectFiscalResponse>(environment.urlApiFiscal + "ListaSelect/fiscal", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  
  public createMensagemTributaria(request: MensagemTributariaRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiFiscal + "MensagemTributaria/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  
  public updateMensagemTributaria(request: MensagemTributariaRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiFiscal + "MensagemTributaria/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteMensagemTributaria(idMensagemTributaria: number): Observable<ReturnStruct> {

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFiscal + "MensagemTributaria/idMensagemTributaria/" + idMensagemTributaria, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getMensagemTributaria(idMensagemTributaria: number): Observable<MensagemTributariaResponse> {

    return this.httpClient.get<MensagemTributariaResponse>(environment.urlApiFiscal + "MensagemTributaria/idMensagemTributaria/" + idMensagemTributaria, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchMensagemTributaria(request: MensagemTributariaSearchRequest): Observable<MensagemTributariaSearchResponse> {

    return this.httpClient.post<MensagemTributariaSearchResponse>(environment.urlApiFiscal + "MensagemTributariaSearch/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getImpostoCest(idImpostoCest: number): Observable<ImpostoCestResponse> {

    return this.httpClient.get<ImpostoCestResponse>(environment.urlApiFiscal + "ImpostoCest/idImpostoCest/" + idImpostoCest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchImpostoCest(request: ImpostoCestSearchRequest): Observable<ImpostoCestSearchResponse> {

    return this.httpClient.post<ImpostoCestSearchResponse>(environment.urlApiFiscal + "ImpostoCestSearch/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteImpostoCest(idImpostoCest: number): Observable<ReturnStruct> {

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFiscal + "ImpostoCest/idImpostoCest/" + idImpostoCest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createImpostoCest(request: ImpostoCestRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiFiscal + "ImpostoCest/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  
  public searchPerfilPisCofins(request: PerfilPisCofinsSearchRequest): Observable<PerfilPisCofinsSearchResponse> {

    return this.httpClient.post<PerfilPisCofinsSearchResponse>(environment.urlApiFiscal + "PerfilPisCofinsSearch/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deletePerfilPisCofins(idPerfilPisCofins: number): Observable<ReturnStruct> {

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFiscal + "PerfilPisCofins/idPerfilPisCofins/" + idPerfilPisCofins, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createPerfilPisCofins(request: PerfilPisCofinsRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiFiscal + "PerfilPisCofins/", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getPerfilPisCofins(idPerfilPisCofins: number): Observable<perfilPisCofinsResponse> {

    return this.httpClient.get<perfilPisCofinsResponse>(environment.urlApiFiscal + "PerfilPisCofins/idPerfilPisCofins/" + idPerfilPisCofins, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public SearchCst(cst: string | null): Observable<CstSearchResponse> {
    return this.httpClient.get<CstSearchResponse>(environment.urlApiFiscal + "CstSearch/searchText/" + cst, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
