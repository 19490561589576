@if(showActionNav){
  <div class="container-item row">
      <a *ngFor="let module of modules" (click)="onCardClick(module)" class="col-3">
        <mat-card class="card" [ngStyle]="{'background-color': module.corDeFundo}">
          <mat-icon 
            [ngClass]="'card-icon'">
            {{ module.googleIcone }}
          </mat-icon>
            <div 
              [ngClass]="'card-header'">
              {{ module.nomePerfil }}
            </div>
        </mat-card>
      </a>
  </div>      
    <div class="container-out" (click)="clickContainerOut()"></div>
}
