import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { Router } from '@angular/router';
import { PasswordChangeService } from '../../../shared/services/API/pessoa/password-change.service';
import { RecoverPasswordResponse } from '../../../shared/services/responses/pessoa/recover-password.response';
import { RecoverPasswordRequest } from '../../../shared/services/requests/pessoa/recover-password.request';

@Component({
  selector: 'app-password-recovery',
  standalone: true,
  imports: [ 
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule],
  templateUrl: './password-recovery.component.html',
  styleUrl: './password-recovery.component.css'
})
export class PasswordRecoveryComponent implements OnInit {
  constructor( 
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private passwordChangeService:PasswordChangeService ){

  }
  public model: FormGroup;
  public isLoading:boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  redirectLogin(){
    this.router.navigate(['/'])
  }

  post(){
    if (this.model.invalid  || this.isLoading)
      return;

    let recoverPasswordRequest:RecoverPasswordRequest = new RecoverPasswordRequest();
    recoverPasswordRequest.email = this.model.get('email')?.value;

    this.isLoading = true;

    this.passwordChangeService.post(recoverPasswordRequest).subscribe({
      next: (response:RecoverPasswordResponse) => {
        this.isLoading = false;

        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }
        
        this.alertService.show("Sucesso", "O link de redefinição de senha foi enviado para o email " + response.mockEmail, AlertType.success);
        this.redirectLogin();
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
