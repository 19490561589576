import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { Cliente } from '../../../shared/services/models/pessoa/cliente.model';
import { ClienteRequest } from '../../../shared/services/requests/pessoa/cliente.request';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { UtilService } from '../../../shared/services/util.service';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { FornecedorRequest } from '../../../shared/services/requests/pessoa/fornecedor.request';
import { catchError, of } from 'rxjs';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { TipoFornecedorEnum } from '../../../shared/enum/Fornecedor/tipo-fornecedor.enum';

@Component({
  selector: 'app-supply-register',
  standalone: true,
  imports: [
    MatIconModule,
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './supply-register.component.html',
  styleUrl: './supply-register.component.css'
})
export class SupplyRegisterComponent implements OnInit{
  public userLoginResponse:UserLoginResponse;
  public isLoading: boolean = true;
  private cepTimeout: any;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
  ){

  }
  public model: FormGroup;
  public requestUpdate: FornecedorRequest = new FornecedorRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idFornecedor: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Fornecedores';
  public iconProfile: string = 'home_repair_service';
  public profile: number = DefaultProfilesEnum.Fornecedor;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required],
      sobrenome: [''],
      razaoSocial: [''],
      email: ['', Validators.required, Validators.email],
      cpfCnpj: ['', Validators.required],
      telefone: [''],
      telefone2: [''],
      nascimento: [''],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      localidade: [''],
      uf: [''],
      pais: ['']
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar)

    if(this.userLoginResponse.empresaLogada?.corSistema){
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.nameProfile = this.userLoginResponse.listPerfil.find(x => x.idPerfil == this.profile)?.nomePerfil ?? this.nameProfile;
    this.iconProfile = this.userLoginResponse.listPerfil.find(x => x.idPerfil == this.profile)?.googleIcone ?? this.iconProfile;
    let idFornecedor:string = this.activatedRoute.snapshot.params["idFornecedor"];
    if(idFornecedor)
    {
      this._idFornecedor = Number(idFornecedor);
      this.getSupplier();
    }
    this.isLoading = false;
  }
  
  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show("Erro!", "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    else if (this._idFornecedor)
    {
      if (this.model.get('nome')?.value) this.requestUpdate.nome = this.model.get('nome')?.value;
      if (this.model.get('sobrenome')?.value) this.requestUpdate.sobrenome = this.model.get('sobrenome')?.value;
      if (this.model.get('razaoSocial')?.value) this.requestUpdate.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value) this.requestUpdate.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value) this.requestUpdate.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value) this.requestUpdate.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value) this.requestUpdate.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value) this.requestUpdate.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value) this.requestUpdate.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value) this.requestUpdate.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value) this.requestUpdate.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value) this.requestUpdate.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value) this.requestUpdate.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value) this.requestUpdate.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value) this.requestUpdate.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value) this.requestUpdate.rua = this.model.get('rua')?.value;
      if (this.model.get('pais')?.value) this.requestUpdate.pais = this.model.get('pais')?.value;
      this.requestUpdate.idTipoFornecedor = TipoFornecedorEnum.Fornecedor;
      this.update();
    }

    else {
      const fornecedorRequest = new FornecedorRequest();
      if (this.model.get('nome')?.value) fornecedorRequest.nome = this.model.get('nome')?.value;
      if (this.model.get('sobrenome')?.value) fornecedorRequest.sobrenome = this.model.get('sobrenome')?.value;
      if (this.model.get('razaoSocial')?.value) fornecedorRequest.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value) fornecedorRequest.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value) fornecedorRequest.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value) fornecedorRequest.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value) fornecedorRequest.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value) fornecedorRequest.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value) fornecedorRequest.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value) fornecedorRequest.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value) fornecedorRequest.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value) fornecedorRequest.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value) fornecedorRequest.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value) fornecedorRequest.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value) fornecedorRequest.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value) fornecedorRequest.rua = this.model.get('rua')?.value;
      if (this.model.get('pais')?.value) fornecedorRequest.pais = this.model.get('pais')?.value;
      this.requestUpdate.idTipoFornecedor = TipoFornecedorEnum.Fornecedor;
      this.register(fornecedorRequest);
    }
  }

  register(supply: FornecedorRequest) {
    this.pessoaService.supplyRegister(supply).subscribe({
      next: (response:ReturnStruct) => {
        if (response.isError) {
          console.log(response);
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show("Sucesso!", "Fornecedor registrado", AlertType.success);
        this.router.navigate(['supplier/list-supplier']);

      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  update()
  {
    this.pessoaService.supplyUpdate(this.requestUpdate).subscribe({
      next: (response:ReturnStruct) => {
        if (response.isError) {
          console.log(response);
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show("Sucesso!", "Fornecedor atualizado", AlertType.success);
        this.router.navigate(['supplier/list-supplier']);

      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  getSupplier() {

  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {

      this.utilService.getAddressByCEP(cep).pipe(

        catchError((error) => {

          this.alertService.show("Erro inesperado", error, AlertType.error);
          this.isLoading = false;
          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          return of(null);

        })).subscribe((data) => {

        if (data) {
          this.model.patchValue({
            logradouro: data.logradouro,
            complemento: data.complemento,
            bairro: data.bairro,
            cidade: data.localidade,
            uf: data.uf,
            pais: 'Brasil'
          });
        }

        this.model.get('logradouro')?.enable();
        this.model.get('complemento')?.enable();
        this.model.get('bairro')?.enable();
        this.model.get('uf')?.enable();
        this.model.get('pais')?.enable();
        this.model.get('cidade')?.enable();
        this.model.get('numero')?.enable();
        this.isLoading = false;

      });
    },2000);
  }


  backPage() {
    this.router.navigate(['supplier/list-supplier']);
  }

  onCpfCnpjInput(event: any): void {
    const value = event.target.value.replace(/\D/g, '');
  let formattedValue = '';

  if(value.length < 11)
  {
    formattedValue = value;
  }

  else if (value.length == 11) 
  {
    formattedValue = this.maskService.applyMask(value, this.masks.cpf);
  }

  else if (value.length > 11)
  {
    formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
  }

  this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  onTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(event.target.value, this.masks.phone);
    this.model.get('telefone')?.setValue(formattedTelefone);
  }

  onSecondTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(event.target.value, this.masks.phone);
    this.model.get('telefone2')?.setValue(formattedTelefone);
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(event.target.value, this.masks.cep);
    this.model.get('cep')?.setValue(formattedCep);
}
}