import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./shared/services/auth.service";
import { AlertService, AlertType } from "./shared/services/alert.service";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService, private alertService: AlertService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated()) {
      // Acessando os valores do data da rota
      const idPerfil = route.data['idPerfil'];
      const idSubperfil = route.data['idSubperfil'];
      if (idSubperfil)
        {
          let Subperfil = this.authService.getUserInfos().usuarioPermissionStruct.listSubPerfilStruct.find(
            (x) => x.idSubperfil === idSubperfil
          )

          if(!Subperfil)
          {
            this.alertService.show('Ops...', "Você não possui permissão para acessar!", AlertType.warning);
            this.router.navigate(['/home']);
          }
        }

      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}