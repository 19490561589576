import { Routes } from '@angular/router';
import { AuthComponent } from './auth/pages/auth/auth.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth-guard.service';
import { PasswordRecoveryComponent } from './auth/pages/password-recovery/password-recovery.component';
import { PasswordChangeComponent } from './auth/pages/password-change/password-change.component';
import { ListClientsComponent } from './client/pages/list-clients/list-clients.component';
import { ListSupplierComponent } from './supplier/pages/list-supplier/list-supplier.component';
import { SupplyRegisterComponent } from './supplier/pages/supply-register/supply-register.component';
import { ClientRegisterComponent } from './client/pages/client-register/client-register.component';

export const routes: Routes = [
    { path: '', component: AuthComponent, pathMatch: 'full' },
    { path: 'password-recovery', component: PasswordRecoveryComponent },
    { path: 'change-password/:token', component: PasswordChangeComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'client/list-clients', component: ListClientsComponent, canActivate: [AuthGuard] },
    { path: 'client/client-register', component: ClientRegisterComponent, canActivate: [AuthGuard] },
    { path: 'client/client-register/:idCliente', component: ClientRegisterComponent, canActivate: [AuthGuard] },
    { path: 'supplier/list-supplier', component: ListSupplierComponent, canActivate: [AuthGuard] },
    { path: 'supplier/supply-register', component: SupplyRegisterComponent, canActivate: [AuthGuard] },
    { path: 'supplier/supply-register/:idSupplier', component: SupplyRegisterComponent, canActivate: [AuthGuard] },
];
