import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from 'ngx-editor';
import { catchError, of } from 'rxjs';
import { RoutesService } from '../../../routes.service';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { ClienteRequest } from '../../../shared/services/requests/pessoa/cliente.request';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ListClienteStructResponse } from '../../../shared/services/responses/pessoa/list-cliente-struct.response';
import { ClienteStruct } from '../../../shared/services/structs/pessoa/cliente.struct';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { UtilService } from '../../../shared/services/util.service';
import { PedidoService } from '../../../shared/services/API/pedido/pedido.service';
import { StatusPedidoResponse } from '../../../shared/services/responses/pedido/status-pedido.response';
import { StatusPedido } from '../../../shared/services/models/pedido/status-pedido.model';
import { ProdutoSubGradeStruct } from '../../../shared/services/structs/estoque/produto-sub-grade.struct';
import { EstoqueService } from '../../../shared/services/API/estoque/estoque.service';
import { ProdutoSubGradeResponse } from '../../../shared/services/responses/estoque/produto-sub-grade.response';
import { FornecedorPessoaStruct } from '../../../shared/services/structs/pessoa/fornecedor-pessoa.struct';
import { ListFornecedorResponse } from '../../../shared/services/responses/pessoa/list-fornecedor.response';
import { TipoFornecedorEnum } from '../../../shared/enum/Fornecedor/tipo-fornecedor.enum';
import { TipoParcelamento } from '../../../shared/services/models/financeiro/tipo-parcelamento';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { TipoParcelamentoResponse } from '../../../shared/services/responses/financeiro/tipo-parcelamento.response';
import { TipoPagamento } from '../../../shared/services/models/financeiro/tipo-pagamento';
import { PedidoRequest } from '../../../shared/services/requests/pedido/pedido.request';
import { PedidoDescontoRequestStruct } from '../../../shared/services/structs/pedido/pedido-desconto-request.struct';
import { PedidoProdutoRequestStruct } from '../../../shared/services/structs/pedido/pedido-produto-request.struct';
import { PedidoProdutoDescontoRequestStruct } from '../../../shared/services/structs/pedido/pedido-produto-desconto-request.struct';
import { MovimentarPedidoRequest } from '../../../shared/services/requests/fluxo-pedido/movimentar-pedido.request';
import { TipoRepeticao } from '../../../shared/services/models/financeiro/tipo-repeticao';
import { TipoRepeticaoResponse } from '../../../shared/services/responses/financeiro/tipo-repeticao.response';
import { TipoRepeticaoEnum } from '../../../shared/enum/Financeiro/tipo-repeticao.enum';
import { PagamentoPedidoRequest } from '../../../shared/services/requests/financeiro/pagamento-pedido.request';
import { PagamentoStruct } from '../../../shared/services/structs/financeiro/pagamento.struct';
import { OrchestratorPedidoService } from '../../../shared/services/API/Orchestrator-pedido/orchestrator-pedido.service';
import { TipoPagamentoResponse } from '../../../shared/services/responses/financeiro/tipo-pagamento.response';
import { OrchestratorGatilhoService } from '../../../shared/services/API/orchestrator-gatilho/orchestrator-gatilho.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FiscalService } from '../../../shared/services/API/fiscal/fiscal.service';
import { TipoConsumidor } from '../../../shared/services/models/fiscal/tipo-consumidor.model';
import { ListaSelectResponse } from '../../../shared/services/responses/fiscal/lista-select.response';
import { DadosProdutosComponent } from './dados-produtos/dados-produtos.component';

@Component({
  selector: 'app-pedidos-novo',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SubheaderComponent,
    MatSelectModule,
    MatCheckboxModule,
    SkeletonComponent,
    MatAutocompleteModule,
    DadosProdutosComponent
  ],
  templateUrl: './pedidos-novo.component.html',
  styleUrl: './pedidos-novo.component.css',
})
export class PedidosNovoComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  private cepTimeout: any;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    public pedidoService: PedidoService,
    public estoqueService: EstoqueService,
    public fiscalService: FiscalService,
    public financeiroService: FinanceiroService,
    public orchestratorGatilhoService: OrchestratorGatilhoService,
    public orchestratorPedidoService: OrchestratorPedidoService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) {}
  public model: FormGroup;
  public requestUpdate: ClienteRequest = new ClienteRequest();
  public _idPedido: number | null = null;
  public listClients: ClienteStruct[] = [];
  public filteredClientes: ClienteStruct[] = [];
  public listCarrier: FornecedorPessoaStruct[] = [];
  public listTipoParcelamento: TipoParcelamento[] = [];
  public listTipoRepeticao: TipoRepeticao[] = [];
  public listTipoPagamento: TipoPagamento[] = [];
  public step: number = 1;
  public isDiscountValue: boolean = true;
  public listConsumerType: TipoConsumidor[] = [];
 
  clienteSelecionado: any = null;
  idCliente: string = '';

  @ViewChild('numeroEndereco') numeroEndereco: ElementRef;
  ngOnInit(): void {
    this.isLoading = true;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idCliente: ['', Validators.required],
      idTipoCliente: [''],
      idStatus: ['', Validators.required],
      centroDeCusto: [''],
      idTransportadora: [''],
      valorFrete: [0],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      idOrigemPedido: [1], //TODO - Bryan: Criar form select para selecionar a origem, criar get no backend
      complemento: [''],
      localidade: [''],
      uf: [''],
      pais: [''],
      idDescontoPedido: [''],
      porcentagemDescontoPedido: [0],
      valorDescontoPedido: [0],
      valorTotal: [{ value: 0, disabled: true }],
      idPedidoPagamento: [''],
      idParcelamento: [''],
      subTotal: [{ value: 0, disabled: true }, ],
      isValue: [true],
      valorAPagar: [{ value: 0, disabled: true }],
      divParcela: [this.formatDate(new Date)],
      idTipoRepeticao: [''],
      produtos: this.formBuilder.array([
        this.formBuilder.group({
          idProduto: [''],
          nomeProduto: [''],
          codProduto: [''],
          quantidade: [0],
          idGrade: [''],
          idSubGrade: [''],
          gradeProdutoNome: [''],
          subGradeProdutoNome: [''],
          idProdutoDesconto: [0],
          precoUnidade: [0],
          idStatusPedidoProduto: [1],
          valorDesconto: [0],
          isValue: [true],
          porcentagemDesconto: [0],
          valor: [{ value: 0, disabled: true }],
        }),
      ]),
      parcelamentos: this.formBuilder.array([]),
    });

    this.userLoginResponse = this.authService.getUserInfos();

    let idPedido: number = this.activatedRoute.snapshot.params['idPedido'];
    if (idPedido) {
      this._idPedido = Number(idPedido);
      this.model.disable();
      this.getOrder();
    }
    this.getListClient();
    this.getListSelect();
    this.getListCarrier();
    this.getPortionType();
    this.getPaymentType();
    this.getRepetitionType();
  }

  nextStep() {
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= this.step) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  atualizarProdutos(evento: { produtos: any[], subTotal: number }) {
    this.model.patchValue({
      produtos: evento.produtos,
      subTotal: evento.subTotal
    });
    this.sumOrder();
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else if (this._idPedido) {
      const formValue = this.model.getRawValue();

      const request: PedidoRequest = new PedidoRequest();
      request.idPedido = formValue.idPedido || null;
      request.idCliente = this.idCliente.toString();
      request.idTipoCliente = formValue.idTipoCliente;
      request.centroDeCusto = formValue.centroDeCusto;
      request.idOrigemPedido = formValue.idOrigemPedido;
      request.nomeTransportadora = formValue.nomeTransportadora;
      request.idTransportadora = formValue.idTransportadora.toString() || null;
      request.cep = formValue.cep;
      request.bairro = formValue.bairro;
      request.complemento = formValue.complemento;
      request.latitude = formValue.latitude;
      request.longitude = formValue.longitude;
      request.numero = formValue.numero;
      request.cidade = formValue.cidade;
      request.estado = formValue.uf;
      request.rua = formValue.logradouro;
      request.pais = formValue.pais;

      request.listPedidoDescontoRequestStruct = [
        new PedidoDescontoRequestStruct(),
      ];
      request.listPedidoDescontoRequestStruct[0].porcentagem =
        formValue.porcentagemDescontoPedido;
      request.listPedidoDescontoRequestStruct[0].valor =
        formValue.valorDescontoPedido;

      request.listProdutoRequestStruct = formValue.produtos.map(
        (produto: any) => {
          const pedidoProduto = new PedidoProdutoRequestStruct();
          pedidoProduto.idPedidoProduto = 0;
          pedidoProduto.idProduto = produto.idProduto;
          pedidoProduto.nomeProduto = produto.nomeProduto;
          pedidoProduto.codProduto = produto.codProduto.toString();
          pedidoProduto.precoTotal = produto.valor;
          pedidoProduto.idStatusPedidoProduto = produto.idStatusPedidoProduto;
          pedidoProduto.quantidadeTotal = produto.quantidade;
          pedidoProduto.idGrade = produto.idGrade;
          pedidoProduto.idSubGrade = produto.idSubGrade;
          pedidoProduto.gradeProdutoNome = produto.gradeProdutoNome;
          pedidoProduto.subGradeProdutoNome = produto.subGradeProdutoNome;
          pedidoProduto.quantidade = produto.quantidade;
          pedidoProduto.precoUnidade = produto.precoUnidade;

          pedidoProduto.listPedidoProdutoDescontoRequestStruct = [
            new PedidoProdutoDescontoRequestStruct(),
          ];
          pedidoProduto.listPedidoProdutoDescontoRequestStruct[0].idPedidoProdutoDesconto = 0;
          pedidoProduto.listPedidoProdutoDescontoRequestStruct[0].porcentagem =
            produto.porcentagemDesconto;
          pedidoProduto.listPedidoProdutoDescontoRequestStruct[0].valor =
            produto.valorDesconto;

        return pedidoProduto;

      });

      const pagamentoRequest: PagamentoPedidoRequest = new PagamentoPedidoRequest();
      pagamentoRequest.idPedido = request.idPedido;
      pagamentoRequest.idPedidoPagamento = formValue.idPedidoPagamento;
      pagamentoRequest.idTipoParcelamento = formValue.idParcelamento;
      pagamentoRequest.idTipoRepeticao = formValue.idTipoRepeticao;
      pagamentoRequest.valorConta = formValue.valorTotal;
      pagamentoRequest.listPagamentoStruct = formValue.pagamento.map((listPagamento: any) => {
        const pagamentoPedido = new PagamentoStruct();
        pagamentoPedido.idPagamento = listPagamento.idPagamento;
        pagamentoPedido.idTipoPagamento = listPagamento.idTipoPagamento;
        pagamentoPedido.valorPagamento = listPagamento.valor;
        pagamentoPedido.dataPrevisaoPagamento = listPagamento.previsaoPagamento
        return pagamentoPedido;

      });

      request.pagamentoPedido = pagamentoRequest;


      this.update(request);
    }
    else {
      const formValue = this.model.getRawValue();

      const request: PedidoRequest = new PedidoRequest();
      request.idPedido = formValue.idPedido || null;
      request.idCliente = formValue.idCliente.toString();
      request.idTipoCliente = formValue.idTipoCliente;
      request.idOrigemPedido = formValue.idOrigemPedido;
      request.centroDeCusto = formValue.centroDeCusto;
      request.nomeTransportadora = formValue.nomeTransportadora;
      request.idTransportadora = formValue.idTransportadora.toString() || null;
      request.cep = formValue.cep;
      request.bairro = formValue.bairro;
      request.complemento = formValue.complemento;
      request.latitude = formValue.latitude;
      request.longitude = formValue.longitude;
      request.numero = formValue.numero;
      request.cidade = formValue.cidade;
      request.estado = formValue.uf;
      request.rua = formValue.logradouro;
      request.pais = formValue.pais;

      request.listPedidoDescontoRequestStruct = [
        new PedidoDescontoRequestStruct(),
      ];
      request.listPedidoDescontoRequestStruct[0].porcentagem =
        formValue.porcentagemDescontoPedido;
      request.listPedidoDescontoRequestStruct[0].valor =
        formValue.valorDescontoPedido;

      request.listProdutoRequestStruct = formValue.produtos.map(
        (produto: any) => {
          const pedidoProduto = new PedidoProdutoRequestStruct();
          pedidoProduto.idPedidoProduto = 0;
          pedidoProduto.idProduto = produto.idProduto;
          pedidoProduto.nomeProduto = produto.nomeProduto;
          pedidoProduto.codProduto = produto.codProduto.toString();
          pedidoProduto.precoTotal = produto.valor;
          pedidoProduto.idStatusPedidoProduto = produto.idStatusPedidoProduto;
          pedidoProduto.quantidadeTotal = produto.quantidade;
          pedidoProduto.idGrade = produto.idGrade;
          pedidoProduto.idSubGrade = produto.idSubGrade;
          pedidoProduto.gradeProdutoNome = produto.gradeProdutoNome;
          pedidoProduto.subGradeProdutoNome = produto.subGradeProdutoNome;
          pedidoProduto.quantidade = produto.quantidade;
          pedidoProduto.precoUnidade = produto.precoUnidade;

          pedidoProduto.listPedidoProdutoDescontoRequestStruct = [
            new PedidoProdutoDescontoRequestStruct(),
          ];
          pedidoProduto.listPedidoProdutoDescontoRequestStruct[0].idPedidoProdutoDesconto = 0;
          pedidoProduto.listPedidoProdutoDescontoRequestStruct[0].porcentagem =
            produto.porcentagemDesconto;
          pedidoProduto.listPedidoProdutoDescontoRequestStruct[0].valor =
            produto.valorDesconto;

        return pedidoProduto;
      });

      const pagamentoRequest: PagamentoPedidoRequest = new PagamentoPedidoRequest();
      pagamentoRequest.idPedido = 0;
      pagamentoRequest.idPedidoPagamento = 0;
      pagamentoRequest.idTipoParcelamento = formValue.idParcelamento;
      pagamentoRequest.idTipoRepeticao = formValue.idTipoRepeticao;
      pagamentoRequest.valorConta = formValue.valorTotal;
      pagamentoRequest.listPagamentoStruct = formValue.pagamento.map((listPagamento: any) => {
        const pagamentoPedido = new PagamentoStruct();
        pagamentoPedido.idPagamento = 0;
        pagamentoPedido.idTipoPagamento = listPagamento.idTipoPagamento;
        pagamentoPedido.valorPagamento = listPagamento.valor;
        pagamentoPedido.dataPrevisaoPagamento = listPagamento.previsaoPagamento
        return pagamentoPedido;

      });

      request.pagamentoPedido = pagamentoRequest;

      this.register(request);
    }
  }

  register(request: PedidoRequest) {
    this.orchestratorPedidoService.createPedido(request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Pedido registrado',
          AlertType.success
        );

        this.router.navigate(['venda/pedidos']);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  update(request: PedidoRequest) {
    this.orchestratorPedidoService.updatePedido(request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Pedido Atualizado',
          AlertType.success
        );
        this.router.navigate(['venda/pedidos']);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getOrder() {

  }

  filtrarClientes(value: string) {
    const filterValue = value.toLowerCase();
    if (filterValue) {      
      this.filteredClientes = this.listClients;
        this.filteredClientes = this.filteredClientes.filter(c =>
        c.pessoa.nome.toLowerCase().includes(filterValue) ||
        c.pessoa.cpfCnpj.includes(filterValue)
      );
    }
    else
      this.filteredClientes = this.listClients;

  }

  onClienteInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
        const filterValue = inputElement.value.toLowerCase();
        this.filtrarClientes(filterValue);
  }

  selecionarCliente(cliente: any) {
    this.clienteSelecionado = cliente;
    this.idCliente = cliente.cliente.idCliente;
  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {
      this.utilService
        .getAddressByCEP(cep)
        .pipe(
          catchError((error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            this.model.get('logradouro')?.enable();
            this.model.get('complemento')?.enable();
            this.model.get('bairro')?.enable();
            this.model.get('uf')?.enable();
            this.model.get('pais')?.enable();
            this.model.get('cidade')?.enable();
            this.model.get('numero')?.enable();
            return of(null);
          })
        )
        .subscribe((data) => {
          if (data) {
            this.model.patchValue({
              logradouro: data.logradouro,
              complemento: data.complemento,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf,
              pais: 'Brasil',
            });
            this.isLoading = false;
          }

          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          this.numeroEndereco.nativeElement.focus();
        });
    }, 2000);
  }

  backPage() {
    this.router.navigate(['venda/pedidos']);
  }
  
  formatMoney(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value || 0);
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(
      event.target.value,
      this.masks.cep
    );
    this.model.get('cep')?.setValue(formattedCep);
    if (formattedCep.length > 8) {
      this.searchCep(formattedCep);
    }
  }

  getListClient() {
    this.pessoaService.searchList().subscribe({
      next: (response: ListClienteStructResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }

        this.listClients = response.listClienteStruct;
        this.filteredClientes = response.listClienteStruct;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de clientes',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  getListCarrier() {
    this.pessoaService.searchSupplier().subscribe({
      next: (response: ListFornecedorResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.listCarrier = response.listFornecedorPessoaStruct.filter(
          (x) =>
            x.fornecedor.idTipoFornecedor == TipoFornecedorEnum.Transportadora
        );
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de status do pedido',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

   getListSelect() {
      this.fiscalService.getListSelect().subscribe({
        next: (response: ListaSelectResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
  
          this.listConsumerType = response.listTipoConsumidor;
          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show(
            'Erro inesperado',
            'Erro ao buscar lista de tipos de consumidor',
            AlertType.error
          );
          this.isLoading = false;
          return;
        },
      });
    }

  getPortionType() {
    this.financeiroService.GetTipoParcelamento().subscribe({
      next: (response: TipoParcelamentoResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.listTipoParcelamento = response.listTipoParcelamento;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de status do pedido',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  getRepetitionType() {
    this.financeiroService.GetTipoRepeticao().subscribe({
      next: (response: TipoRepeticaoResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.listTipoRepeticao = response.listTipoRepeticao;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de status do pedido',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  getPaymentType() {
    this.financeiroService.GetTipoPagamento().subscribe({
      next: (response: TipoPagamentoResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.listTipoPagamento = response.listTipoPagamento;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de status do pedido',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  get produtos(): FormArray {
    return this.model.get('produtos') as FormArray;
  }

  get parcelamentos(): FormArray {
    return this.model.get('parcelamentos') as FormArray;
  }

  adjustArray(event: any) {
    let idTipoParcelamento = event.value;
    let i: number =
      this.listTipoParcelamento.find(
        (x) => x.idTipoParcelamento == idTipoParcelamento
      )?.valor ?? 0;
    const parcelamentos = this.parcelamentos;

    while (parcelamentos.length < i) {
      parcelamentos.push(this.AdicionarParcela());
    }

    while (parcelamentos.length > i) {
      parcelamentos.removeAt(parcelamentos.length - 1);
    }

    if (this.model.get('idTipoRepeticao')?.value) {
      this.adjustData();
    }

    this.sumPortion();

  }

  adjustData() {
    let idRepeticao = this.model.get('idTipoRepeticao')?.value;
    if (idRepeticao !== TipoRepeticaoEnum.Indefinido) {
      let data: Date = new Date();
      if (this.model.get('divParcela')?.value) {
        data = new Date(this.model.get('divParcela')?.value);
      }

      let parcelamentos = this.parcelamentos.length;

      let lenght = 0;

      while (lenght < parcelamentos) {

        if (lenght == 0) {
          this.parcelamentos.at(lenght).get('previsaoPagamento')?.setValue(this.formatDate(data));
          lenght++;
        }

        else {
          data = this.setDates(data, idRepeticao);
          this.parcelamentos.at(lenght).get('previsaoPagamento')?.setValue(this.formatDate(data));
          lenght++;
        }

      }

    }
  }

  AdicionarParcela(): FormGroup {
    return this.formBuilder.group({
      idPagamento: [''],
      idTipoPagamento: [''],
      valor: [0],
      previsaoPagamento: [''],
    });
  }

  selectClient(event: any) {
    let idCliente = event.value;
    this.model.get('idTipoCliente')?.setValue(idCliente);
  }

  changeDiscountType() {
    this.isDiscountValue = this.model.get('isValue')?.value;

    this.isDiscountValue = !this.isDiscountValue;

    if (this.isDiscountValue) {
      this.model
        .get('valorDescontoPedido')
        ?.setValue(this.model.get('porcentagemDescontoPedido')?.value);
      this.model.get('porcentagemDescontoPedido')?.setValue(0);
    } else {
      let value = this.model.get('valorDescontoPedido')?.value;

      if (value > 100) value = 100;

      this.model.get('porcentagemDescontoPedido')?.setValue(value);
      this.model.get('valorDescontoPedido')?.setValue(0);
    }

    this.model.get('isValue')?.setValue(this.isDiscountValue);

    this.sumOrder();
  }

  changePortionValue(index: number) {
    let valor = this.parcelamentos.at(index).get('valor')?.value;

    if (!valor || valor.toString().trim() === '') {
      valor = 0;
      this.parcelamentos.at(index).get('valor')?.setValue(valor);
    }

    this.sumPortion();
  }

  sumPortion() {
    let sum = 0;
    this.parcelamentos.controls.forEach(
      (grupo: AbstractControl, index: number) => {
        sum = sum + grupo.get('valor')?.value;
      }
    );
    this.model.get('valorAPagar')?.setValue(sum);
  }

  sumOrder() {
    let sum = 0;
    let totalProdutos = this.model.get('subTotal')?.value;

    if (!totalProdutos || totalProdutos.toString().trim() === '') {
      totalProdutos = 0;
      this.model.get('subTotal')?.setValue(0);
    }

    let valorFrete = this.model.get('valorFrete')?.value;

    if (!valorFrete || valorFrete.toString().trim() === '') {
      valorFrete = 0;
      this.model.get('valorFrete')?.setValue(0);
    }

    let valorDescontoPedido = this.model.get('valorDescontoPedido')?.value;

    if (!valorDescontoPedido || valorDescontoPedido.toString().trim() === '') {
      valorDescontoPedido = 0;
      this.model.get('valorDescontoPedido')?.setValue(0);
    }

    let porcentagemDescontoPedido = this.model.get(
      'porcentagemDescontoPedido'
    )?.value;

    if (
      !porcentagemDescontoPedido ||
      porcentagemDescontoPedido.toString().trim() === ''
    ) {
      porcentagemDescontoPedido = 0;
      this.model.get('porcentagemDescontoPedido')?.setValue(0);
    }

    sum =
      totalProdutos +
      valorFrete -
      (totalProdutos + valorFrete) * (porcentagemDescontoPedido / 100) -
      valorDescontoPedido;

    this.model.get('valorTotal')?.setValue(sum);
  }

  private setDates(data: Date, tipoRepeticao: number): Date {
    let novaData = new Date(data);
    if (tipoRepeticao == TipoRepeticaoEnum.Quinzenal) {
      novaData.setDate(novaData.getDate() + 15);
    }
    else if (tipoRepeticao == TipoRepeticaoEnum.Mensal) {
      novaData.setMonth(novaData.getMonth() + 1);
    }
    else if (tipoRepeticao == TipoRepeticaoEnum.Anual) {
      novaData.setFullYear(novaData.getFullYear() + 1);
    }

    return novaData;
  }

  private formatDate(data: Date): string {
    return data.toISOString().split('T')[0];
  }
}
