import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { StatusPedidoResponse } from '../../responses/pedido/status-pedido.response';
import { PedidoRequest } from '../../requests/pedido/pedido.request';
import { ReturnStruct } from '../../structs/return.struct';
import { CriarPedidoResponse } from '../../responses/pedido/criar-pedido.response';
import { OrigemPedidoResponse } from '../../responses/pedido/origem-pedido.response';

@Injectable({
  providedIn: 'root'
})
export class PedidoService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  public GetOrigemPedido(): Observable<OrigemPedidoResponse> {

    return this.httpClient.get<OrigemPedidoResponse>(environment.urlApiPedido + "OrigemPedido", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetStatus(): Observable<StatusPedidoResponse> {

    return this.httpClient.get<StatusPedidoResponse>(environment.urlApiPedido + "StatusPedido", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createPedido(request: PedidoRequest): Observable<CriarPedidoResponse> {

    return this.httpClient.post<CriarPedidoResponse>(environment.urlApiPedido + "Pedido", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updatePedido(request: PedidoRequest): Observable<ReturnStruct> {

    return this.httpClient.put<ReturnStruct>(environment.urlApiPedido + "Pedido", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}
