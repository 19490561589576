<app-menu [showSearch]="false"></app-menu>
<div class="container">
  <div class="home-search">
  <app-search-bar></app-search-bar></div>
  <div class="card-container">
      <div *ngIf="isLoading" class="card-placeholder">
        <mat-card class="card" *ngFor="let placeholder of [1,2,3,4,5]">
          <mat-card-header class="card-header">
            <div class="card-icon-placeholder"></div>
          </mat-card-header>
        </mat-card>
      </div>
        <a *ngFor="let module of modules" (click)="onCardClick(module)">
          <mat-card class="card" [ngStyle]="{'background-color': module.corDeFundo}">
            <mat-icon 
              [ngClass]="module.corInvertida ? 'card-icon-invertido card-icon' : 'card-icon'">
              {{ module.googleIcone }}
            </mat-icon>
              <div 
                [ngClass]="module.corInvertida ? 'card-header-invertido card-header' : 'card-header'">
                {{ module.nomePerfil }}
              </div>

              <div 
                [ngClass]="module.corInvertida ? 'card-description-invertido card-description' : 'card-description'">
                {{ module.descricao }}
              </div>
          </mat-card>
        </a>
    </div>      
</div>
<div class="container">
    <div class="suggestion-card-container">
        <a *ngFor="let suggestionCard of suggestionCards" (click)="onSuggestionCardClick(suggestionCard)" class="suggestion-card-list">
          <mat-card class="suggestion-card">
            <mat-card-header class="suggestion-card-header">
                <div class="suggestion-icon">
                    <div class="suggestion-card-icon">
                        <mat-icon>star_border</mat-icon>
                    </div>
                    <div class="suggestion-card-icon-superimposed">
                        <mat-icon>star_border</mat-icon>
                    </div>
                </div>
              <mat-card-title class="suggestion-text">{{ suggestionCard.mensagem }}</mat-card-title>
            </mat-card-header>
          </mat-card>
        </a>
      </div>
</div>