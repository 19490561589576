import { PedidoProdutoDescontoRequestStruct } from "./pedido-produto-desconto-request.struct";

export class PedidoProdutoRequestStruct {
    public idPedidoProduto?: number;
    public idProduto: number;
    public nomeProduto: string;
    public codProduto: string;
    public precoTotal: number;
    public idStatusPedidoProduto: number;
    public quantidadeTotal: number;
    public idGrade: number;
    public idSubGrade: number;
    public gradeProdutoNome: string;
    public subGradeProdutoNome: string;
    public quantidade: number;
    public precoUnidade: number;
    public listPedidoProdutoDescontoRequestStruct: PedidoProdutoDescontoRequestStruct[] = [];
}