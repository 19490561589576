<div class="main-container">
    <app-subheader [icon]="'request_page'" [title]="'Mensagem tributaria'"
        [description]="_idMensagemTributaria !== null ? 'Edição de uma mensagem tributaria' : 'Cadastro de uma nova mensagem tributraria'"
        [isLoading]="isLoading" [isAddPage]="_idMensagemTributaria !== null ? false : true"
        [isEditPage]="_idMensagemTributaria !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px"
            style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
            </div>
        </div>
        <form [formGroup]="model" (ngSubmit)="submit()">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>MENSAGEM TRIBUTÁRIA</p>
                </div>
                <div class="container">
                    <div class="row">
                                <div class="col-12 col-sm-6" style="margin-top:10px;">
                                    <div class="input-control">
                                        <label class="input-label" for="dataFimVigencia">Mensagem tributária</label>
                                        <input matInput id="mensagem" formControlName="mensagem" type="text"
                                            [ngClass]="model.get('mensagem')?.touched && model.get('mensagem')?.invalid ? 'input-failed' : 'input-field'"
                                            placeholder="mensagem" />
                                        <span *ngIf="model.get('mensagem')?.touched && model.get('mensagem')?.invalid">O campo mensagem é
                                            obrigatório.</span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-3" style="margin-top:10px;">
                                    <div class="input-control">
                                        <label class="input-label" for="dataInicioVigencia">Inicio da vigencia</label>
                                        <input matInput id="dataInicioVigencia" formControlName="dataInicioVigencia" type="date" class="input-field" />
                                    </div>
                                </div>
                                <div class="col-6 col-sm-3" style="margin-top:10px;">
                                    <div class="input-control">
                                        <label class="input-label" for="dataFimVigencia">Fim da vigencia</label>
                                        <input matInput id="dataFimVigencia" formControlName="dataFimVigencia" type="date" class="input-field" />
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="controller">
                        <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
                        <button (click)="submit()" class="main-btn">SALVAR</button>
                    </div>
            </div>
        </form>
    </div>
</div>