import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FasePedidoStruct } from '../../../../shared/services/structs/fluxo-pedido/fase-pedido.struct';
import { FormsModule } from '@angular/forms';
import { FluxoPedidoService } from '../../../../shared/services/API/fluxo-pedido/fluxo-pedido.service';
import { MovimentacaoPedidoResponse } from '../../../../shared/services/responses/fluxo-pedido/movimentacao-pedido.response';
import {
  AlertService,
  AlertType,
} from '../../../../shared/services/alert.service';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { MovimentarPedidoRequest } from '../../../../shared/services/requests/fluxo-pedido/movimentar-pedido.request';
import { OrchestratorGatilhoService } from '../../../../shared/services/API/orchestrator-gatilho/orchestrator-gatilho.service';

@Component({
  selector: 'app-order-card',
  standalone: true,
  imports: [MatIconModule, CommonModule, FormsModule],
  templateUrl: './order-card.component.html',
  styleUrl: './order-card.component.css',
})
export class OrderCardComponent {
  @Input() name: string = '';
  @Input() content: string = '';
  @Input() status: string = '';
  @Input() pedido: any;
  @Input() isDraggable: boolean = true;
  @Input() coluna: FasePedidoStruct;
  newColumn: number = 0;
  isModalOpen = false;
  formValues: { [key: string]: string } = {};

  constructor(
    private orchestratorGatilhoService: OrchestratorGatilhoService,
    private alertService: AlertService
  ) {}

  getCardRowClass(value: string): any {
    return {
      pending:
        value === 'Pendente' ||
        value === 'Parcialmente Pago' ||
        value === 'Em Análise' ||
        value === 'Aguardando Aprovação' ||
        value === 'Aguardando Pagamento' ||
        value === 'Em Processo',
      success:
        value === 'Ativo' ||
        value === 'Aprovado' ||
        value === 'Pago' ||
        value === 'Estornado' ||
        value === 'Liquidado',
      warning:
        value === 'Cancelado' ||
        value === 'Em Suspensão' ||
        value === 'Inativo' ||
        value === 'Atrasado' ||
        value === 'Vencido',
    };
  }

  closeModal(event: MouseEvent) {
    if ((event.target as HTMLElement).classList.contains('modal-overlay')) {
      this.newColumn = 0;
      this.isModalOpen = false;
    }
  }

  update() {
    const listPedidoCampoFaseStruct = Object.entries(this.formValues).map(
      ([key, value]) => ({
        idPedidoCampoFase: Number(this.pedido.idPedido),
        idCampoFase: Number(key),
        valor: String(value),
      })
    );
    let idPedido: number = this.pedido.idPedido;
    let idFasePedidoDestino: number =
      this.newColumn !== 0
        ? this.newColumn
        : Number(this.coluna.idFasePedido) + 1;
    let idStatusPedidoNovo: number = this.pedido.idStatusPedidoAtual;

    let body: MovimentarPedidoRequest = {
      idPedido,
      idFasePedidoDestino,
      idStatusPedidoNovo,
      listPedidoCampoFaseStruct,
    };
    this.isModalOpen = false;
    this.orchestratorGatilhoService.PostMovimentarPedido(body);
  }

  openModal() {
    this.isModalOpen = true;
  }
}
