<div class="main-container">
  <app-subheader [icon]="iconProfile" [title]="'Transportadoras'" [description]="'Sua base de transportadoras cadastradas'" [isLoading]="isLoading" [isAddPage]="false" [isEditPage]="false"></app-subheader>
  <!-- <div class="container-separation">
      <div class="card-filter">
        <div class="filters">
          <div class="exhibit">
            <mat-form-field appearance="fill">
              <mat-label>Exibição</mat-label>
              <mat-select id="exhibit" [(value)]="selectedExhibit" (selectionChange)="onExhibitChange($event)" [disabled]="isLoading">
                <mat-option *ngFor="let exhibit of listDisplay" [value]="exhibit.id">
                  <mat-icon>{{ exhibit.icon }}</mat-icon> {{ exhibit.nome }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div> -->
  <app-view-switcher *ngIf="!isLoading" (viewChanged)="onExhibitChange($event)"></app-view-switcher>
  <div class="container-separation">
    <div class="container-flex">
      <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="45px" height="45px"></app-skeleton>
      <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="45px" height="45px"></app-skeleton>
    </div>
    <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="100%" height="300px"></app-skeleton>
    <app-advanced-table newItemLink="/fornecedor/fornecedores/novo" [isLoading]="isLoading" [data]="dataList" [columns]="tableColumns" (valueChanged)="exhibitActions($event)"></app-advanced-table>
    <!-- <app-exhibit [data]="dataList" [isLoading]="isLoading" [isTable]="isTable" [isPerson]="true" (valueChanged)="exhibitActions($event)"></app-exhibit> -->
  </div>
  <!-- <div class="container-separation">
    <div class="pagination">
      <button class="pagination-btn" [disabled]="model.get('currentPage')?.value === 1" (click)="goToPreviousPage()">
        <mat-icon class="arrow">arrow_left</mat-icon>
      </button>

      <button class="pagination-btn" [disabled]="
            model.get('currentPage')?.value === model.get('totalPages')?.value
          " (click)="goToNextPage()">
        <mat-icon class="arrow">arrow_right</mat-icon>
      </button>
    </div>
    <div class="paginator">
      <span>{{ model.get("currentPage")?.value }} -
        {{ model.get("totalPages")?.value }}</span>
    </div>
  </div> -->
</div>