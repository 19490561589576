import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { PreferencesService } from '../../../preferences.service';

@Component({
  selector: 'app-subheader',
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule, CommonModule],
  templateUrl: './subheader.component.html',
  styleUrl: './subheader.component.css',
})
export class SubheaderComponent {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() isLoading: boolean = false;
  @Input() isAddPage: boolean = false;
  @Input() isEditPage: boolean = false;
  showSubheader: boolean = false;
  private subscription!: Subscription;

  constructor(
    private router: Router,
    private PreferenceService: PreferencesService
  ) {}

  ngOnInit(): void {
    this.subscription = this.PreferenceService.subheaderState$.subscribe(
      (state: boolean) => {
        this.showSubheader = state;
      }
    );
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  navigateToBasePath() {
    let url = this.router.url;
    url = url.replace(/\/(novo|editar).*/, ''); // Remove /novo ou /editar e tudo depois
    this.router.navigate([url]);
  }

  onToggleSubheader(): void {
    this.PreferenceService.toggleSubheaderState();
    this.showSubheader = this.PreferenceService.getSubheaderState();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
