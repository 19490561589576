<div class="search-container">
    <div class="search-bar">
      <div class="ia-button">
            <mat-icon class="left-icon-superimposed">star_border</mat-icon>
            <mat-icon class="left-icon">star_border</mat-icon>
      </div>
      <input
        type="text"
        class="search-input"
        placeholder="Bem vindo! Digite ou fale comigo"
      />
      <button class="mic-button" (click)="onMicClick()">
        <mat-icon>mic</mat-icon>
      </button>
    </div>
  </div>
  