export const environment = {
  production: false,
  staging: false,
  urlApiBase: 'https://dev.atsone.com.br/',
  urlApiPessoa: 'https://api.dev.atsone.com.br/one-api-pessoa/',
  urlApiFinanceiro: 'https://api.dev.atsone.com.br/one-api-financeiro/',
  urlApiEstoque: 'https://api.dev.atsone.com.br/one-api-estoque/',
  urlApiSpeech: 'https://api.dev.atsone.com.br/one-api-speech/',
  urlApiFiscal: 'https://api.dev.atsone.com.br/one-api-fiscal/',
  urlApiOrchestratorSearch: 'https://api.dev.atsone.com.br/one-api-orchestrator-search/',
  urlApiFluxoPedido: 'https://api.dev.atsone.com.br/one-api-fluxo-pedido/',
  urlApiPedido: 'https://api.dev.atsone.com.br/one-api-pedido/',
  urlApiOrchestratorGatilho: 'https://api.dev.atsone.com.br/one-api-orchestrator-gatilho/',
  urlApiGatilho: 'https://api.dev.atsone.com.br/one-api-gatilho/',
  urlOrchestratorPedido: 'https://api.dev.atsone.com.br/one-api-orchestrator-pedido/',
  
  //urlApiFluxoPedido: "https://localhost:61225/",
  //urlApiOrchestratorGatilho: "https://localhost:50072/",
  //urlApiOrchestratorSearch: "https://localhost:56893/",
  //urlApiPessoa: 'https://localhost:62996/',
  //urlApiEstoque: "https://localhost:59318/",
  //urlApiSpeech: "https://localhost:61615/",
  //urlApiFinanceiro: "https://localhost:54984/",
  //urlApiFiscal: 'https://localhost:57391/',
}