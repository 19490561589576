import { PagamentoStruct } from "../../structs/financeiro/pagamento.struct";

export class PagamentoPedidoRequest {
    public idPedido?: number;
    public idPedidoPagamento?: number;
    public valorConta: number;
    public idTipoParcelamento: number;
    public idTipoRepeticao: number;
    public listPagamentoStruct: PagamentoStruct[] = [];
}
