import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common-service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserLoginResponse } from './responses/auth/user-login.response';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  public signIn(login: string, password: string, ip: string, idEmpresaLogada: number, token: string): Observable<UserLoginResponse> {

    let userAuthRequest = {
      login: login,
      password: password,
      ip: ip,
      idEmpresaLogada: idEmpresaLogada,
      token: token
    };

    return this.httpClient.post<UserLoginResponse>(environment.urlApiPessoa + "Auth", userAuthRequest)
      .pipe(
        catchError(this.handleError)
      )
  }
}
