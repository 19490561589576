<div *ngIf="!orchestratorGatilhoService.isLoading" class="column-header">
  <h3 *ngIf="!EditingName" class="dashboard-name">
    {{name}}
  </h3>
  <div class="flex-box-main">
    <input *ngIf="EditingName" type="text" class="input-field" [(ngModel)]="name" />
    <button *ngIf="EditingName" (click)="toggleEditingName(dashboard.idFluxoPedido)" class="main-btn">CONFIRMAR</button>
  </div>

  <div *ngIf="isEditMode" class="icons-container">
    <button (click)="toggleEditingName(dashboard.idFluxoPedido)" class="icon-button">
      <mat-icon class="edit-icon">edit</mat-icon>
    </button>
    <button (click)="isModalDeleteOpen = true" class="icon-delete-button">
      <mat-icon class="edit-icon">delete</mat-icon>
    </button>
  </div>

</div>
<div *ngIf="!orchestratorGatilhoService.isLoading" class="columns-wrapper">
  <div [ngClass]="{ 'column-edit': isEditMode, 'column': !isEditMode }" *ngFor="let coluna of dashboard.listDashboard" cdkDropList [id]="'coluna-' + coluna.idFasePedido" [cdkDropListData]="coluna.itens" [cdkDropListConnectedTo]="dropListConnected" (cdkDropListDropped)="onDrop($event, coluna, dashboard.idFluxoPedido ? dashboard.idFluxoPedido : 1)">
    <app-empty-col [coluna]="coluna" [isEditMode]="isEditMode" [gatilhos]="getGatilhosResponse" (confirmation)="postBody($event)" (deleteColumn)="removeColumn($event)" (moveColumnToLeft)="moveColumn($event, 'left')" (moveColumnToRight)="moveColumn($event, 'right')" [idFluxo]="dashboard.idFluxoPedido">
      <div *ngFor="let item of coluna.itens" cdkDrag>
        <app-order-card *ngIf="!isEditMode" [isDraggable]="true" [pedido]="item" [coluna]="coluna" [name]="''+item.idPedido" [status]="statusPedido(item.idStatusPedidoAtual)"></app-order-card>
      </div>
    </app-empty-col>
  </div>

  <!-- adicionar uma nova coluna a um fluxo -->
  <app-empty-col *ngIf="isEditMode" [isEditMode]="true" [gatilhos]="getGatilhosResponse" (confirmation)="postBody($event)" [idFluxo]="dashboard.idFluxoPedido"></app-empty-col>

  <!-- coluna 'finalizado' -->
  <div class="column" *ngFor="let coluna of dashboard.listFinished">
    <app-empty-col [idFluxo]="dashboard.idFluxoPedido" [coluna]="coluna" [isEditMode]="isEditMode" (confirmation)="postBody($event)">
      <div *ngFor="let item of coluna.itens">
        <app-order-card [isDraggable]="false" [pedido]="item" [coluna]="coluna" [name]="item.idPedido" [status]="statusPedido(item.idStatusPedidoAtual)"></app-order-card>
      </div>
    </app-empty-col>
  </div>
</div>
<div *ngIf="isModalDeleteOpen" class="modal-overlay" (click)="closeModal($event)">
  <div class="modal-content">
    <h2>Excluindo</h2>
    <h3>Você está excluindo o fluxo {{dashboard.nomeFluxoPedido}}, tem certeza?</h3>
    <div class="modal-action">
      <button class="cancel-btn" (click)="isModalDeleteOpen = false">FECHAR</button>
      <button class="warning-btn" (click)="confirmDeleteModal(dashboard.idFluxoPedido)">EXCLUIR</button>
    </div>
  </div>
</div>