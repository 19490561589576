import { CommonModule } from '@angular/common';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FluxoPedidoResponse } from '../../../shared/services/structs/fluxo-pedido/fluxo-pedido.struct';
import { OrchestratorGatilhoService } from '../../../shared/services/API/orchestrator-gatilho/orchestrator-gatilho.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { GetFluxoResponse } from '../../../shared/services/responses/fluxo-pedido/get-fluxo.response';
import { combineLatest } from 'rxjs';
import { CampoFaseStruct } from '../../../shared/services/structs/fluxo-pedido/campo-fase.struct';
import { OrderCardComponent } from '../dashboard-orders/order-card/order-card.component';
import { EmptyColComponent } from '../dashboard-orders/empty-col/empty-col.component';
import { ListGatilhoResponse } from '../../../shared/services/responses/fluxo-pedido/list-gatilho.response';
import { FasePedidoStruct } from '../../../shared/services/structs/fluxo-pedido/fase-pedido.struct';
import { FluxoPedidoRequestStruct } from '../../../shared/services/structs/fluxo-pedido/fluxo-pedido-request.struct';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dashboard-list',
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    DragDropModule,
    EmptyColComponent,
    OrderCardComponent,
    FormsModule,
  ],
  templateUrl: './dashboard-list.component.html',
  styleUrl: './dashboard-list.component.css',
})
export class DashboardListComponent {
  @Input() dashboard: any;
  @Input() isEditMode: boolean = false;
  isModalDeleteOpen: boolean = false;
  EditingName: boolean = false;
  name: string;

  public getFluxoResponse: GetFluxoResponse;

  @ViewChildren(OrderCardComponent) orderCards!: QueryList<OrderCardComponent>;
  public getGatilhosResponse: ListGatilhoResponse;

  constructor(
    public orchestratorGatilhoService: OrchestratorGatilhoService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.orchestratorGatilhoService.fluxoPedido$,
      this.orchestratorGatilhoService.gatilhosResponse$,
    ]).subscribe(([fluxoPedido, gatilhosResponse]) => {
      if (fluxoPedido && gatilhosResponse) {
        this.getFluxoResponse = fluxoPedido;
        this.getGatilhosResponse = gatilhosResponse;
      }
    });
    this.name = this.dashboard.nomeFluxoPedido;
  }

  closeModal(event: MouseEvent) {
    if ((event.target as HTMLElement).classList.contains('modal-overlay')) {
      this.isModalDeleteOpen = false;
    }
  }

  confirmDeleteModal(idFluxoPedido: number) {
    let items = this.dashboard.listDashboard.filter(
      (item: any) => item.itens.length !== 0
    );
    if (items.length === 0) {
      this.orchestratorGatilhoService.deleteFluxo(
        idFluxoPedido,
        'Fluxo excluído com sucesso.'
      );
    } else {
      this.alertService.show(
        'Ação não permitida',
        'Não é possível remover um fluxo com pedidos.',
        AlertType.warning
      );
    }
    this.isModalDeleteOpen = false;
  }

  statusPedido(id: number): string {
    let status;
    switch (id) {
      case 1:
        status = 'Pago';
        break;
      case 2:
        status = 'Aguardando Pagamento';
        break;
      default:
        status = 'Sem status';
        break;
    }
    return status;
  }

  public openCardModal(id: number, idDestino: any) {
    const card = this.orderCards.find((card) => card.pedido.idPedido === id);
    if (card) {
      card.newColumn = idDestino;
      card.isModalOpen = true;
    }
  }

  toggleEditingName(idFluxo: number) {
    const fluxoAtual = this.getFluxoResponse.listFluxoPedidoStruct.find(
      (fluxo) => fluxo.idFluxoPedido === idFluxo
    );
    if (fluxoAtual) {
      if (this.name !== fluxoAtual.nomeFluxoPedido) {
        fluxoAtual.nomeFluxoPedido = this.name;
        let payload = {
          idFluxoPedido: fluxoAtual?.idFluxoPedido
            ? fluxoAtual.idFluxoPedido
            : null,
          nomeFluxoPedido: this.name,
          listFluxoPedidoRequestStruct: fluxoAtual.listFasePedidoStruct.map(
            (i) => this.convertFasePedidoToFluxoPedido(i)
          ),
          listIdOrigemPedido: [],
        };
        this.orchestratorGatilhoService.postFluxo(
          payload,
          'Fluxo atualizado com sucesso!'
        );
      }
      this.EditingName = !this.EditingName;
    }
  }

  onDrop(event: CdkDragDrop<any[]>, colunaDestino: any, idFluxo: number) {
    const fluxoAtual = this.getFluxoResponse.listFluxoPedidoStruct.find(
      (fluxo) => fluxo.idFluxoPedido === idFluxo
    )?.listFasePedidoStruct;
    const previousContainerId = event.previousContainer.id;
    const currentContainerId = event.container.id;

    if (previousContainerId === currentContainerId) {
      // Movendo dentro da mesma coluna
      moveItemInArray(
        colunaDestino.itens,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (fluxoAtual) {
        const pedidoMovido = event.previousContainer.data[event.previousIndex];

        const etapaAtualObj = fluxoAtual.find(
          (item) =>
            Number(item.idFasePedido) === Number(pedidoMovido.idFasePedidoAtual)
        );

        const etapaDestinoObj = fluxoAtual.find(
          (item) =>
            Number(item.idFasePedido) === Number(colunaDestino.idFasePedido)
        );

        if (!etapaAtualObj || !etapaDestinoObj) return;

        const indexAtual = fluxoAtual.indexOf(etapaAtualObj);
        const indexDestino = fluxoAtual.indexOf(etapaDestinoObj);

        if (
          indexDestino > indexAtual &&
          Math.abs(indexDestino - indexAtual) !== 1
        ) {
          console.log('invalid');
        } else {
          const hasRequiredField = etapaAtualObj.listCampoFaseStruct.some(
            (campo: CampoFaseStruct) => campo.obrigatorio
          );
          const hasRequiredTriggerField =
            etapaAtualObj?.listGatilhoStruct
              ?.flatMap((gatilho) => gatilho.listCampoGatilhoStruct)
              ?.some((campo) => campo.obrigatorio) ?? false;

          if (colunaDestino.idFasePedido > pedidoMovido.idFasePedidoAtual) {
            if (hasRequiredField || hasRequiredTriggerField) {
              this.openCardModal(
                pedidoMovido.idPedido,
                colunaDestino.idFasePedido
              );
            } else {
              transferArrayItem(
                event.previousContainer.data,
                colunaDestino.itens,
                event.previousIndex,
                event.currentIndex
              );
              pedidoMovido.idFasePedidoAtual = colunaDestino.idFasePedido;
              const card = this.orderCards.find(
                (card) => card.pedido.idPedido === pedidoMovido.idPedido
              );
              if (card) {
                const listPedidoCampoFaseStruct = Object.entries(
                  card.formValues
                ).map(([key, value]) => ({
                  idPedidoCampoFase: Number(pedidoMovido.idPedido),
                  idCampoFase: Number(key),
                  valor: String(value),
                }));

                let payload: any = {};

                payload.idPedido = pedidoMovido.idPedido;
                payload.idFasePedidoDestino = pedidoMovido.idFasePedidoAtual;
                payload.idStatusPedidoNovo = pedidoMovido.idStatusPedidoAtual;
                payload.listPedidoCampoFaseStruct = listPedidoCampoFaseStruct;

                this.orchestratorGatilhoService.PostMovimentarPedido(payload);
              }
            }
          } else {
            transferArrayItem(
              event.previousContainer.data,
              colunaDestino.itens,
              event.previousIndex,
              event.currentIndex
            );
            pedidoMovido.idFasePedidoAtual = colunaDestino.idFasePedido;
            const card = this.orderCards.find(
              (card) => card.pedido.idPedido === pedidoMovido.idPedido
            );
            if (card) {
              const listPedidoCampoFaseStruct = Object.entries(
                card.formValues
              ).map(([key, value]) => ({
                idPedidoCampoFase: Number(pedidoMovido.idPedido),
                idCampoFase: Number(key),
                valor: String(value),
              }));

              let payload: any = {};

              payload.idPedido = pedidoMovido.idPedido;
              payload.idFasePedidoDestino = pedidoMovido.idFasePedidoAtual;
              payload.idStatusPedidoNovo = pedidoMovido.idStatusPedidoAtual;
              payload.listPedidoCampoFaseStruct = listPedidoCampoFaseStruct;

              this.orchestratorGatilhoService.PostMovimentarPedido(payload);
            }
          }
        }
      }
    }
  }

  get dropListConnected(): string[] {
    let columns = this.dashboard.listDashboard
      .map((item: any) => {
        return 'coluna-' + item.idFasePedido;
      })
      .flat();
    return columns;
  }

  convertFasePedidoToFluxoPedido(
    fasePedido: FasePedidoStruct
  ): FluxoPedidoRequestStruct {
    return {
      idFasePedido: fasePedido.idFasePedido ? fasePedido.idFasePedido : null,
      ordemFasePedido: fasePedido.ordem ? fasePedido.ordem : 1,
      nomeFasePedido: fasePedido.nomeFasePedido,
      interacaoUsuario: fasePedido.listGatilhoStruct.length > 0,
      listGatilhoFasePedidoStruct: fasePedido.listGatilhoFasePedidoStruct,
      listCampoFaseStruct: fasePedido.listCampoFaseStruct,
    };
  }

  postBody(body: FasePedidoStruct | any) {
    let listFluxoPedido = this.getFluxoResponse.listFluxoPedidoStruct;
    let fasePedido = listFluxoPedido.find(
      (fluxo) => fluxo.idFluxoPedido === body.id
    )?.listFasePedidoStruct;
    if (fasePedido) {
      let lastItem = this.convertFasePedidoToFluxoPedido(
        fasePedido[fasePedido.length - 1]
      );
      let newBodyArray = fasePedido
        .filter((item) => item.idFasePedido !== body.coluna.idFasePedido)
        .filter((item) => item.idFasePedido !== lastItem.idFasePedido);
      let newBody = newBodyArray.map(this.convertFasePedidoToFluxoPedido);
      let newItem = this.convertFasePedidoToFluxoPedido(body.coluna);
      let payload = {
        listFluxoPedidoRequestStruct: [...newBody],
      };

      payload.listFluxoPedidoRequestStruct.push(newItem);

      lastItem.ordemFasePedido =
        payload.listFluxoPedidoRequestStruct.length + 2;
      if (newItem.idFasePedido !== lastItem.idFasePedido) {
        lastItem.ordemFasePedido -= 1;
        payload.listFluxoPedidoRequestStruct.push(lastItem);
      }

      let currentFluxo = this.getFluxoResponse.listFluxoPedidoStruct.find(
        (item) => item.idFluxoPedido === body.id
      );

      this.orchestratorGatilhoService.postFluxo(
        {
          idFluxoPedido: currentFluxo?.idFluxoPedido
            ? currentFluxo.idFluxoPedido
            : null,
          nomeFluxoPedido: currentFluxo?.nomeFluxoPedido
            ? currentFluxo.nomeFluxoPedido
            : null,
          listFluxoPedidoRequestStruct: payload.listFluxoPedidoRequestStruct,
          listIdOrigemPedido: [],
        },
        'Coluna atualizada com sucesso!'
      );
    }
  }

  removeColumn(body: FasePedidoStruct | any) {
    let listFluxoPedido = this.getFluxoResponse.listFluxoPedidoStruct;
    let fasePedido = listFluxoPedido.find(
      (fluxo) => fluxo.idFluxoPedido === body.id
    )?.listFasePedidoStruct;
    if (fasePedido) {
      if (
        fasePedido.length <= 2 ||
        body.coluna.idFasePedido === fasePedido[0].idFasePedido ||
        body.coluna.idFasePedido ===
          fasePedido[fasePedido.length - 1].idFasePedido
      ) {
        this.alertService.show(
          'Ação não permitida',
          'Não é possível remover a primeira ou última fase.',
          AlertType.warning
        );
        return;
      }

      if (body.coluna.itens.length !== 0) {
        this.alertService.show(
          'Ação não permitida',
          'Não é possível remover uma coluna com pedidos.',
          AlertType.warning
        );
        return;
      }

      let newBody = fasePedido
        .filter((item) => item.idFasePedido !== body.coluna.idFasePedido)
        .map(this.convertFasePedidoToFluxoPedido)
        .map((item, index) => ({
          ...item,
          ordemFasePedido: index + 1, // Atualiza a ordem com base na nova posição
        }));

      let payload = {
        listFluxoPedidoRequestStruct: [...newBody],
      };

      let currentFluxo = this.getFluxoResponse.listFluxoPedidoStruct.find(
        (item) =>
          item.listFasePedidoStruct.some(
            (fase) => fase.idFasePedido === body.coluna.idFasePedido
          )
      );
      this.orchestratorGatilhoService.postFluxo(
        {
          idFluxoPedido: currentFluxo?.idFluxoPedido
            ? currentFluxo.idFluxoPedido
            : null,
          nomeFluxoPedido: currentFluxo?.nomeFluxoPedido
            ? currentFluxo.nomeFluxoPedido
            : null,
          listFluxoPedidoRequestStruct: payload.listFluxoPedidoRequestStruct,
          listIdOrigemPedido: [],
        },
        'Coluna atualizada com sucesso!'
      );
    }
  }

  moveColumn(body: FasePedidoStruct | any, direction: 'left' | 'right') {
    let listFluxoPedido = this.getFluxoResponse.listFluxoPedidoStruct;
    let fasePedido = listFluxoPedido.find(
      (fluxo) => fluxo.idFluxoPedido === body.id
    )?.listFasePedidoStruct;
    if (fasePedido) {
      const index = fasePedido.findIndex(
        (item) => item.idFasePedido === body.coluna.idFasePedido
      );

      if (index <= 0 || index >= fasePedido.length - 1) {
        this.alertService.show(
          'Ação não permitida',
          'Não é possível mover para a primeira ou última posição.',
          AlertType.warning
        );
        return;
      }

      const newIndex = direction === 'left' ? index - 1 : index + 1;

      [fasePedido[index], fasePedido[newIndex]] = [
        fasePedido[newIndex],
        fasePedido[index],
      ];

      fasePedido = fasePedido.map((item, idx) => ({
        ...item,
        ordem: idx + 1,
      }));

      let newItem = fasePedido.map(this.convertFasePedidoToFluxoPedido);

      let payload = {
        listFluxoPedidoRequestStruct: [...newItem],
      };

      let currentFluxo = this.getFluxoResponse.listFluxoPedidoStruct.find(
        (item) =>
          item.listFasePedidoStruct.some(
            (fase) => fase.idFasePedido === body.coluna.idFasePedido
          )
      );
      this.orchestratorGatilhoService.postFluxo(
        {
          idFluxoPedido: currentFluxo?.idFluxoPedido
            ? currentFluxo.idFluxoPedido
            : null,
          nomeFluxoPedido: currentFluxo?.nomeFluxoPedido
            ? currentFluxo.nomeFluxoPedido
            : null,
          listFluxoPedidoRequestStruct: payload.listFluxoPedidoRequestStruct,
          listIdOrigemPedido: [],
        },
        'Coluna atualizada com sucesso!'
      );
    }
  }
}
