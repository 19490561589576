<div class="main-container">
    <app-subheader [icon]="'person'" [title]="'Cliente'" [description]="_idClient !== null ? 'Edição de um cliente existente' : 'Cadastro de um novo cliente'" [isLoading]="isLoading" [isAddPage]="_idClient !== null ? false : true" [isEditPage]="_idClient !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px" style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
            </div>
            <div [ngClass]="step >= 2 ? 'step-separator-done' : 'step-separator'"></div>
            <div (click)="setStep(2)" [ngClass]="step === 2 ? 'step-active' : 'step-item'">
                <mat-icon [ngClass]="step >= 2 ? 'icon-item-step-active' : 'icon-item-step'">location_on</mat-icon>
            </div>
        </div>
        <form [formGroup]="model" (ngSubmit)="submit()">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>DADOS PRINCIPAIS</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="flex-switch">
                            <p [ngClass]="model.get('dependente')?.value ? '' : 'is-active'">Titular</p>
                            <label class="switch">
                                <input type="checkbox" formControlName="dependente" (ngModelChange)="changeClientType()">
                                <span class="slider round"></span>
                            </label>
                            <p [ngClass]="model.get('dependente')?.value ? 'is-active' : ''">Dependente</p>
                        </div>
                        <div class="form-group" style="padding-bottom: 18px;" *ngIf="isDependent">
                            <div class="input-control">
                                <label class="input-label" for="clienteTitular">Cliente Titular</label>

                                <div class="custom-select">
                                    <!-- <p>{{ selectedClient?.pessoa?.nome || 'Selecione um cliente' }}</p> -->
                                    <input type="text" [ngClass]="model.get('clienteTitular')?.touched && model.get('clienteTitular')?.invalid ? 'input-failed' : 'input-field'" placeholder="Selecione um cliente" formControlName="clienteTitular" (focus)="toggleDropdown()" (input)="filtrarClientes()" (blur)="validateTitular()" />
                                    <ul *ngIf="isDropdownOpen" class="dropdown">
                                        <li *ngFor="let client of filteredClientes" (click)="selectClient(client); $event.stopPropagation()">
                                            {{ client.pessoa.nome + " - (" + client.pessoa.email + ")"}}
                                        </li>
                                    </ul>
                                </div>
                                <!-- <select id="idClienteTitular" formControlName="idClienteTitular" [ngClass]="model.get('idClienteTitular')?.touched && model.get('idClienteTitular')?.invalid ? 'input-failed' : 'input-field'">
                                    <option value="" disabled selected>Selecione um cliente</option>
                                    <option *ngFor="let client of filteredClientes" [value]="client.cliente.idCliente">
                                        {{ client.pessoa.nome }}
                                    </option>
                                </select> -->

                                <span *ngIf="model.get('clienteTitular')?.touched && model.get('clienteTitular')?.invalid">O campo Cliente Titular é obrigatório.</span>
                            </div>
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Cliente Titular</mat-label>
                                <mat-select formControlName="idClienteTitular" class="field">
                                    <mat-select-trigger>
                                        {{ selectedOption?.pessoa?.nome || 'Selecione um cliente' }}
                                    </mat-select-trigger>

                                    <input type="text" [formControl]="searchControl" placeholder="Pesquise um cliente..." class="input" />

                                    <mat-option *ngFor="let client of filteredClientes" [value]="client.cliente.idCliente">
                                        {{ client.pessoa.nome }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                        </div>
                        <!-- <div class="form-group check-box">
                            <mat-checkbox formControlName="dependente" class="field" (ngModelChange)="changeClientType()">
                                Dependente
                            </mat-checkbox>
                        </div> -->

                    </div>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="cpfCnpj">CPF/CNPJ</label>
                                <input matInput id="cpfCnpj" formControlName="cpfCnpj" type="text" (input)="onCpfCnpjInput($event)" [ngClass]="model.get('cpfCnpj')?.touched && model.get('cpfCnpj')?.invalid ? 'input-failed' : 'input-field'" placeholder="000.000.000-00 || 00.000.000/0000-00" />
                                <span *ngIf="model.get('cpfCnpj')?.touched && model.get('cpfCnpj')?.invalid">O campo CPF/CNPJ é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="nome">{{isJuridicPerson() ? 'Razão social' : 'Nome'}}</label>
                                <input matInput id="nome" formControlName="nome" type="text" [ngClass]="model.get('nome')?.touched && model.get('nome')?.invalid ? 'input-failed' : 'input-field'" [placeholder]="isJuridicPerson() ? 'Empresa S/A' : 'José Arnaldo'" />
                                <span *ngIf="model.get('nome')?.touched && model.get('nome')?.invalid">O campo {{isJuridicPerson() ? 'razão social' : 'nome'}} é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="email">E-mail</label>
                                <input matInput id="email" formControlName="email" type="email" [ngClass]="model.get('email')?.touched && model.get('email')?.invalid ? 'input-failed' : 'input-field'" placeholder="exemplo@dominio.com" />
                                <span *ngIf="model.get('email')?.touched && model.get('email')?.invalid">O campo e-mail é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="nascimento">{{ isJuridicPerson() ? 'Data de Abertura' : 'Data de Nascimento' }}</label>
                                <input matInput id="nascimento" formControlName="nascimento" type="date" class="input-field" />
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="telefone">Telefone</label>
                                <input matInput id="telefone" formControlName="telefone" type="text" (input)="onTelefoneInput($event)" [ngClass]="model.get('telefone')?.touched && model.get('telefone')?.invalid ? 'input-failed' : 'input-field'" placeholder="(00) 00000-0000" />
                                <span *ngIf="model.get('telefone')?.touched && model.get('telefone')?.invalid">O campo telefone é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="telefone2">Telefone 2</label>
                                <input matInput id="telefone2" formControlName="telefone2" type="text" (input)="onSecondTelefoneInput($event)" [ngClass]="model.get('telefone2')?.touched && model.get('telefone2')?.invalid ? 'input-failed' : 'input-field'" placeholder="(00) 00000-0000" />
                                <span *ngIf="model.get('telefone2')?.touched && model.get('telefone2')?.invalid">O campo telefone2 é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="isViewFinancialFields">
                            <div class="input-control">
                                <label class="input-label" for="limite">Limite</label>
                                <input matInput id="limite" formControlName="limite" type="number" [ngClass]="model.get('limite')?.touched && model.get('limite')?.invalid ? 'input-failed' : 'input-field'" (disabled)="!isWriteFinancialFields" placeholder="Limite" />
                                <span *ngIf="model.get('limite')?.touched && model.get('limite')?.invalid">O campo limite é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="isViewFinancialFields">
                            <div class="input-control">
                                <label class="input-label" for="renda">Renda</label>
                                <input matInput id="renda" formControlName="renda" type="number" [ngClass]="model.get('renda')?.touched && model.get('renda')?.invalid ? 'input-failed' : 'input-field'" (disabled)="!isWriteFinancialFields" placeholder="Renda" />
                                <span *ngIf="model.get('renda')?.touched && model.get('renda')?.invalid">O campo renda é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="controller">
                    <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
                    <button (click)="nextStep()" class="main-btn">PRÓXIMO</button>
                </div>
            </div>
            <div *ngIf="step === 2">
                <div class="step-title">
                    <p>ENDEREÇO</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="cep">CEP</label>
                                <input matInput id="cep" formControlName="cep" type="text" [ngClass]="model.get('cep')?.touched && model.get('cep')?.invalid ? 'input-failed' : 'input-field'" placeholder="00000-000" (input)="onCepInput($event)" />
                                <span *ngIf="model.get('cep')?.touched && model.get('cep')?.invalid">O campo CEP é obrigatório.</span>
                            </div>
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>CEP</mat-label>
                                <input matInput id="cep" formControlName="cep" type="text" (input)="onCepInput($event)" placeholder="CEP" class="field" />
                            </mat-form-field> -->
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="logradouro">Logradouro</label>
                                <input matInput id="logradouro" formControlName="logradouro" type="text" [ngClass]="model.get('logradouro')?.touched && model.get('logradouro')?.invalid ? 'input-failed' : 'input-field'" placeholder="Logradouro" />
                                <span *ngIf="model.get('logradouro')?.touched && model.get('cep')?.invalid">O campo Logradouro é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="cidade">Cidade</label>
                                <input matInput id="cidade" formControlName="cidade" type="text" [ngClass]="model.get('cidade')?.touched && model.get('cidade')?.invalid ? 'input-failed' : 'input-field'" placeholder="Cidade" />
                                <span *ngIf="model.get('cidade')?.touched && model.get('cep')?.invalid">O campo Cidade é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="uf">UF</label>
                                <input matInput id="uf" formControlName="uf" type="text" [ngClass]="model.get('uf')?.touched && model.get('uf')?.invalid ? 'input-failed' : 'input-field'" placeholder="UF" />
                                <span *ngIf="model.get('uf')?.touched && model.get('uf')?.invalid">O campo UF é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="bairro">Bairro</label>
                                <input matInput id="bairro" formControlName="bairro" type="text" [ngClass]="model.get('bairro')?.touched && model.get('bairro')?.invalid ? 'input-failed' : 'input-field'" placeholder="Bairro" />
                                <span *ngIf="model.get('bairro')?.touched && model.get('bairro')?.invalid">O campo Bairro é obrigatório.</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="numero">Número</label>
                                <input matInput id="numero" formControlName="numero" type="text" [ngClass]="model.get('numero')?.touched && model.get('numero')?.invalid ? 'input-failed' : 'input-field'" placeholder="Número" #numeroEndereco />
                                <span *ngIf="model.get('numero')?.touched && model.get('numero')?.invalid">O campo Número é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="complemento">Complemento</label>
                                <input matInput id="complemento" formControlName="complemento" type="text" [ngClass]="model.get('complemento')?.touched && model.get('complemento')?.invalid ? 'input-failed' : 'input-field'" placeholder="Complemento" />
                                <span *ngIf="model.get('complemento')?.touched && model.get('complemento')?.invalid">O campo Complemento é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button (click)="submit()" class="main-btn">SALVAR</button>
                </div>
            </div>
        </form>
    </div>
</div>