<div class="main-container">
    <app-subheader [icon]="'currency_exchange'" [title]="'Contas a receber'"
        [description]="'Sua base de contas a receber'" [isLoading]="isLoading" [isAddPage]="false"
        [isEditPage]="false"></app-subheader>
    <app-view-switcher *ngIf="!isLoading" (viewChanged)="onExhibitChange($event)"></app-view-switcher>
    <div class="container-separation">
        <div class="container-flex">
            <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="45px" height="45px"></app-skeleton>
            <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="45px" height="45px"></app-skeleton>
        </div>
        <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="100%" height="300px"></app-skeleton>
        <app-advanced-table newItemLink="/financeiro/contas-receber/novo" [isLoading]="isLoading" [data]="dataList"
            [columns]="tableColumns" (valueChanged)="exhibitActions($event)"></app-advanced-table>
    </div>
</div>