import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { AuthService } from '../../services/auth.service';
import { ActionNavComponent } from './action-nav/action-nav.component';
import { ActionUserComponent } from './action-user/action-user.component';
import { ActionNotificationComponent } from './action-notification/action-notification.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoutesService } from '../../../routes.service';
import { PreferencesService } from '../../../preferences.service';
import { HttpClient } from '@angular/common/http';
import { UserLoginResponse } from '../../services/responses/auth/user-login.response';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SearchBarComponent,
    MatBadgeModule,
    ActionNavComponent,
    ActionUserComponent,
    ActionNotificationComponent,
    MatTooltipModule,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css',
})
export class MenuComponent implements OnInit {
  @Input() showSearch: boolean = true;
  public showActionUser: boolean = false;
  public showActionNotification: boolean = false;
  public areThereNotifications: boolean = false;
  public isFactory: boolean = false;
  public isConsult: boolean = false;
  public empresaLogo: string = '';
  logoValid: boolean = false;
  public isStore: boolean = false;
  public userLoginResponse: UserLoginResponse;
  showActionNav: boolean = false;
  private subscription!: Subscription;
  currentTheme: 'light' | 'dark' = 'light';

  constructor(
    private authService: AuthService,
    private router: Router,
    private navbarPreferenceService: PreferencesService,
    private themePreferenceService: PreferencesService,
    private routesService: RoutesService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.subscription = this.navbarPreferenceService.navbarState$.subscribe(
      (state: boolean) => {
        this.showActionNav = state;
      }
    );

    this.userLoginResponse = this.authService.getUserInfos();
    this.empresaLogo = this.userLoginResponse.empresaLogada.preferenciaEmpresaStruct.urlLogoEmpresa;

    this.currentTheme = this.themePreferenceService.getTheme();
    this.themePreferenceService.loadCorSistema();
  }

  get logoSrc(): string {
    return this.empresaLogo ? this.empresaLogo : '/assets/images/atsone.png'; // Se inválida, usa o placeholder
  }

  changeLogo(){
    this.empresaLogo = this.userLoginResponse.empresaLogada.preferenciaEmpresaStruct.urlLogoEmpresa;
  }

  toggleTheme() {
    this.themePreferenceService.toggleTheme();
    this.currentTheme = this.themePreferenceService.getTheme();
  }

  clickShowActionUser(action: any) {
    if (action == null) {
      this.showActionUser = !this.showActionUser;
      return;
    }

    this.showActionUser = action;
  }

  toggleNavbar(): void {
    this.navbarPreferenceService.toggleNavbarState();
    this.showActionNav = this.navbarPreferenceService.getNavbarState();
  }

  clickRedirectHome() {
    this.router.navigate([this.routesService.HOME]);
  }
  
  clickShowActionNotification(action: any) {
    if (action == null) {
      this.showActionNotification = !this.showActionNotification;
      return;
    }

    this.showActionNotification = action;
  }

  setAreThereNotifications(areThereNotifications: boolean) {
    this.areThereNotifications = areThereNotifications;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
