<app-menu></app-menu>
<div class="container">
    <div class="container-separation">
        <div class="module-content">
            <div class="module-info">
                <div class="center-title">
                    <div class="name-content">
                        <mat-icon class="icon">{{iconProfile}}</mat-icon>
                        {{nameProfile}}
                    </div>
                </div>
                <div></div>
                <div class="module-action">
                    <div class="button">
                        <button type="submit" class="button-add btn-primary" (click)="addClient()">
                            <div class="button-content">
                                <ng-container *ngIf="isLoading; else notLoading">
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                </ng-container>
                                <ng-template #notLoading>
                                    <mat-icon class="add">add</mat-icon> <span class="name">Cliente</span>
                                </ng-template>
                            </div>
                        </button>
                    </div>
            </div>
            </div>
        </div>
    </div>
    <div class="container-separation">
        <div class="container-table">
            <app-table [data]="dataList" [isLoading]="isLoading" (valueChanged)="tableActions($event)"></app-table>
        </div>
    </div>
    <div class="container-separation">
        <div class="pagination">
            <button 
              class="pagination-btn" 
              [disabled]="model.get('currentPage')?.value === 1" 
              (click)="goToPreviousPage()">
              <mat-icon class="arrow">arrow_left</mat-icon>
            </button>
        
            <button 
              class="pagination-btn" 
              [disabled]="model.get('currentPage')?.value === model.get('totalPages')?.value" 
              (click)="goToNextPage()">
              <mat-icon class="arrow">arrow_right</mat-icon>
            </button>
        </div>
        <div class="paginator">
            <span>{{model.get('currentPage')?.value}} - {{model.get('totalPages')?.value}}</span>
        </div>
    </div>
</div>