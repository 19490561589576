import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from '../shared/component/search-bar/search-bar.component';
import { AuthService } from '../shared/services/auth.service';
import { UserLoginResponse } from '../shared/services/responses/auth/user-login.response';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { IAInsightsResponse } from '../shared/services/responses/pessoa/ia-insights.response';
import { DefaultColorEnum } from '../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../shared/services/alert.service';
import { PessoaService } from '../shared/services/API/pessoa/pessoa.service';
import { Router } from '@angular/router';
import { SkeletonComponent } from '../shared/component/skeleton/skeleton.component';
import { SubPerfilStruct } from '../shared/services/structs/pessoa/sub-perfil.struct';
import { HomeShortcut, PreferencesService } from '../preferences.service';
import { PerfilStruct } from '../shared/services/structs/pessoa/perfil.struct';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    SearchBarComponent,
    MatCardModule,
    MatIconModule,
    CommonModule,
    SkeletonComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public listPerfil: PerfilStruct[];
  public suggestionCards: IAInsightsResponse[];
  public modules: any[] = [];
  public accesColor: string = DefaultColorEnum.Acesso;
  public unnaccesColor: string = DefaultColorEnum.NaoAcesso;
  public isLoading: boolean = true;
  constructor(
    private titleService: Title,
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private router: Router,
    private preferencesService: PreferencesService
  ) {}
  public color: string = DefaultColorEnum.Cor;
  public listSubPerfil: SubPerfilStruct[];
  isModalOpen = false;
  public homeItems: any[] = [];
  public availableModules: any[] = [];

  ngOnInit(): void {    
    this.userLoginResponse = this.authService.getUserInfos();
    this.titleService.setTitle(this.userLoginResponse.empresaLogada.nomeEmpresa + " ERP");
    this.preferencesService.homeShortcuts$.subscribe((shortcuts) => {
      this.homeItems = shortcuts;
    });
    this.getPerfil();
  }

  updateAvailableModules() {
    this.availableModules = this.modules.filter(
      (module) =>
        !this.homeItems.some((shortcut) => shortcut.name === module.name)
    );
    this.availableModules = this.availableModules.filter(
      (item) => !item.redirect.startsWith('config')
    );
  }

  onCardClick(card: PerfilStruct): void {
    let filteredModule =
      this.userLoginResponse.usuarioPermissionStruct.listSubPerfilStruct.find(
        (module) => module.idPerfil === card.idPerfil
      );
    if (filteredModule) {
      this.router.navigate([card.redirect]);
    } else {
      this.alertService.show(
        'Erro!',
        'Você não possui acesso a este perfil',
        AlertType.error
      );
    }
  }

  openAddShortcutModal() {
    this.updateAvailableModules();
    this.isModalOpen = true;
  }

  closeModal(event: MouseEvent) {
    if ((event.target as HTMLElement).classList.contains('modal-overlay')) {
      this.isModalOpen = false;
    }
  }

  addShortcut(module: HomeShortcut) {
    this.preferencesService.addShortcut(module);
    this.isModalOpen = false;
    this.updateAvailableModules();
  }

  removeShortcut(module: HomeShortcut) {
    this.preferencesService.removeShortcut(module);
    this.updateAvailableModules();
  }

  onSuggestionCardClick(card: any): void {}

  getPerfil() {
    this.userLoginResponse = this.authService.getUserInfos();
    this.listPerfil = this.userLoginResponse.usuarioPermissionStruct.listPerfilStruct;
    this.listSubPerfil = this.userLoginResponse.usuarioPermissionStruct.listSubPerfilStruct;
    this.listPerfil = this.userLoginResponse.usuarioPermissionStruct.listPerfilStruct.filter(
      (perfil) =>
        this.userLoginResponse.usuarioPermissionStruct.listPerfilStruct.map(x => x.idPerfil).includes(
          perfil.idPerfil
        ) && perfil.exibirTelaInicial
    );
    this.cardLogical();
    this.isLoading = false;
  }

  cardLogical() {
    let menu: any = [];
    this.listSubPerfil.map((modulo) => {
      let parent: PerfilStruct | undefined = this.listPerfil.find(
        (item) => item.idPerfil === modulo.idPerfil
      );
      menu.push({
        name: modulo.nomeSubPerfil,
        desc: parent ? parent.descricao : '',
        icon: parent ? parent.googleIcone : '',
        redirect: modulo.redirectSubperfil,
        idPerfil: modulo.idPerfil,
        idSubperfil: modulo.idSubperfil,
      });
    });
    this.modules = menu;
    this.updateAvailableModules();
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }
}
