export enum DefaultProfilesEnum {
    Vendas = 1,
    Financeiro = 2,
    Fiscal = 3,
    Cliente = 4,
    Fornecedor = 5,
    Estoque = 6,
    Configuracao = 7
}

export enum SubPerfilEnum {
    Clientes = 1,
    ContasPagar = 2,
    PerfilImposto = 3,
    Produtos = 4,
    Fornecedores = 5,
    Transportadoras = 6,
    Empresas = 7,
    Usuarios = 8,
    Bancos = 9,
    Movimentacoes = 10,
    Pedidos = 11,
    Caixas = 12,
    MeusDados = 13,
    ContasReceber = 14,
    FluxoCaixa = 15,
    MinhaEmpresa = 16,
    MensagemTributaria = 17,
    NaturezaReceita = 18,
    ImpostoCest = 19,
}