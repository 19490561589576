import { PessoaStruct } from '../../structs/pessoa/pessoa.struct';

export class IContatos {
  nome: string;
  email: string;
  telefone: string;
}

export class FornecedorRequest extends PessoaStruct {
  public idFornecedor: number;
  public idTipoFornecedor: number;
  public contatos: IContatos[];
}
