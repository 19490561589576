<div *ngIf="coluna" class="column-wrapper">
  <div class="column-header">
    <h3>{{ name }}</h3>

    @if (coluna.ordem !== 1 && coluna.ordem !== fluxo.length) {
    <div class="icons-container">
      <ng-container *ngIf="coluna.ordem !== 2">
        <button *ngIf="isEditMode" class="icon-button" (click)="moveLeft()">
          <mat-icon class="edit-icon">arrow_left</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="coluna.ordem !== fluxo.length - 1">
        <button *ngIf="isEditMode" class="icon-button" (click)="moveRight()">
          <mat-icon class="edit-icon">arrow_right</mat-icon>
        </button>
      </ng-container>
      <button *ngIf="isEditMode" class="icon-button" (click)="isModalOpen = true">
        <mat-icon class="edit-icon">edit</mat-icon>
      </button>
      <button *ngIf="isEditMode" class="icon-delete-button" (click)="isModalDeleteOpen = true">
        <mat-icon class="edit-icon">delete</mat-icon>
      </button>
    </div>
    } @else if (coluna.ordem === 1 || coluna.ordem === fluxo.length) {
    <div class="icons-container">
      <button *ngIf="isEditMode" class="icon-button" (click)="isModalOpen = true">
        <mat-icon class="edit-icon">edit</mat-icon>
      </button>
    </div>
    }
  </div>
  @if (!orchestratorGatilhoService.isLoading && !isEditMode) {
  <ng-content></ng-content>
  }
  @if (empty) {
  <p *ngIf="!orchestratorGatilhoService.isLoading">
    Nenhum pedido nesta etapa
  </p>
  }
  @if (orchestratorGatilhoService.isLoading) {
  <app-skeleton variant="block" shape="card" width="258px" height="60px"></app-skeleton>
  }

  <ng-container *ngIf="isEditMode">
    <div class="gatilho-small-item" *ngFor="let gatilho of gatilhoStruct">
      <div class="gatilho-card-info">
        <div class="gatilho-info">
          <h5>{{ gatilho.ordemExecucao }}º</h5>
        </div>
      </div>
      <div class="gatilho-card-info">
        <div class="gatilho-info">
          <h4>{{ gatilho.gatilho.nomeGatilho }}</h4>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<div *ngIf="!coluna" class="homecard-add" (click)="isModalOpen = true">
  <mat-icon class="homecard-icon">add</mat-icon>
  <div class="homecard-content">
    <h4>Novo item</h4>
    <p>Adicionar nova coluna</p>
  </div>
</div>

<div *ngIf="isModalOpen" class="modal-overlay">

  <div class="modal-content">
    <div *ngIf="!editandoName" class="modal-header">
      <h2>{{name}}</h2>
      <mat-icon (click)="toggleEditandoNome()" class="icon-edit">edit_note</mat-icon>
    </div>
    <div *ngIf="editandoName" class="modal-header mini">
      <input type="text" class="input-field" [(ngModel)]="name" />
      <button (click)="toggleEditandoNome()" class="main-btn">CONFIRMAR</button>
    </div>

    <div class="gatilho-wrapper">


      <div class="gatilho-item" *ngFor="let gatilho of gatilhoStruct">
        <div class="gatilho-card-info">
          <div class="gatilho-info">
            <span>Ordem de execução:</span>
            <h3>{{ gatilho.ordemExecucao }}º</h3>
          </div>
        </div>
        <div class="gatilho-card-info">
          <div>
            <mat-icon class="gatilho-avatar">{{ gatilho.gatilho.googleIcon }}</mat-icon>
          </div>
          <div class="gatilho-info">
            <h3>{{ gatilho.gatilho.nomeGatilho }}</h3>
            <p>{{ gatilho.gatilho.descricaoGatilho }}</p>
          </div>
        </div>

        <mat-icon class="icon-delete" (click)="removerGatilho(gatilho)">delete</mat-icon>
        <mat-icon class="icon-trigger-drag">drag_indicator</mat-icon>
      </div>

      <div *ngIf="adicionandoGatilho" class="novo-gatilho-dropdown">
        <div *ngFor="let opcao of opcoesGatilho" (click)="selecionarGatilho(opcao)" class="gatilho-item novo-gatilho">
          <div class="gatilho-card-info">
            <div>
              <mat-icon class="gatilho-avatar">{{ opcao.gatilho.googleIcon }}</mat-icon>
            </div>
            <div class="gatilho-info">
              <h3>{{ opcao.gatilho.nomeGatilho }}</h3>
              <p>{{ opcao.gatilho.descricaoGatilho }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="gatilho-item novo-gatilho" (click)="toggleAdicionarGatilho()">
        <div class="gatilho-card-info">
          <mat-icon class="gatilho-avatar">add</mat-icon>
          <div class="gatilho-info">
            <h3>Novo Gatilho</h3>
            <p>Clique para adicionar um novo gatilho</p>
          </div>
        </div>
      </div>

    </div>

    <div class="modal-action">
      <button class="cancel-btn" (click)="isModalOpen = false">FECHAR</button>
      <button class="main-btn" (click)="confirmModal()">SALVAR</button>
    </div>

  </div>

</div>



<div *ngIf="isModalDeleteOpen" class="modal-overlay">
  <div class="modal-content">
    <div class="modal-header">
      <h2>Excluindo {{name}}</h2>
    </div>
    <div class="modal-header">
      <h3>Você está excluindo a coluna {{name}}, tem certeza?</h3>
    </div>
    <div class="modal-action">
      <button class="cancel-btn" (click)="isModalDeleteOpen = false">FECHAR</button>
      <button class="warning-btn" (click)="confirmDeleteModal()">EXCLUIR</button>
    </div>
  </div>
</div>