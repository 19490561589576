  <div class="mini-search-bar">
    <mat-icon class="table-search-icon">search</mat-icon>
    <input
      type="text"
      placeholder="Pesquisar"
      [(ngModel)]="filterText"
      (ngModelChange)="onFilterChange($event)"
      class="filter-input"
    />
  </div>
  <div>