<div class="main-container">
    <app-subheader [icon]="'currency_exchange'" [title]="'Contas a Receber'"
        [description]="_idContasReceber !== null ? 'Edição de uma conta a receber' : 'Cadastro de uma nova conta a receber'"
        [isLoading]="isLoading" [isAddPage]="_idContasReceber !== null ? false : true"
        [isEditPage]="_idContasReceber !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px"
            style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">edit_note</mat-icon>
            </div>
            <div [ngClass]="step >= 2 ? 'step-separator-done' : 'step-separator'"></div>
            <div (click)="setStep(2)" [ngClass]="step === 2 ? 'step-active' : 'step-item'">
                <mat-icon [ngClass]="step >= 2 ? 'icon-item-step-active' : 'icon-item-step'">local_atm</mat-icon>
            </div>
        </div>
        <form *ngIf="model" [formGroup]="model">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>DADOS DA CONTA A RECEBER</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="descricao">Descrição</label>
                                <input matInput id="descricao" formControlName="descricao" type="text"
                                    [ngClass]="model.get('descricao')?.touched && model.get('descricao')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Descrição" />
                                <span *ngIf="model.get('descricao')?.touched && model.get('descricao')?.invalid">O campo
                                    Descrição é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="dataEmissao">Data Emissão</label>
                                <input matInput id="dataEmissao" formControlName="dataEmissao" type="date"
                                    [ngClass]="model.get('dataEmissao')?.touched && model.get('dataEmissao')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Data Emissão" />
                                <span *ngIf="model.get('dataEmissao')?.touched && model.get('dataEmissao')?.invalid">O
                                    campo Data Emissão é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="select-control">
                                <label class="input-label" for="idBancoEmpresa">Conta Corrente</label>
                                <mat-select formControlName="idBancoEmpresa" class="input-field select">
                                    <div class="option">
                                        <mat-option *ngFor="let bank of filteredBank" [value]="bank.idBancoEmpresa">
                                            {{ bank.nomeAgencia }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="centroDeCusto">Centro De Custo</label>
                                <input matInput id="centroDeCusto" formControlName="centroDeCusto" type="text"
                                    [ngClass]="model.get('centroDeCusto')?.touched && model.get('centroDeCusto')?.invalid ? 'input-field' : 'input-field'"
                                    placeholder="Centro De Custo" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="notaFiscal">Nota Fiscal</label>
                                <input matInput id="notaFiscal" formControlName="notaFiscal" type="text"
                                    [ngClass]="model.get('notaFiscal')?.touched && model.get('notaFiscal')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Nota Fiscal" />
                                <span *ngIf="model.get('notaFiscal')?.touched && model.get('notaFiscal')?.invalid">O
                                    campo Nota Fiscal é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="valorConta">Valor Da Conta</label>
                                <input matInput id="valorConta" formControlName="valorConta" type="number"
                                    [ngClass]="model.get('valorConta')?.touched && model.get('valorConta')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Valor Da Conta" (input)="setValue($event)" />
                                <span *ngIf="model.get('valorConta')?.touched && model.get('valorConta')?.invalid">O
                                    campo Valor Da Conta é obrigatório.</span>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="controller">
                    <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
                    <button (click)="nextStep()" class="main-btn">PRÓXIMO</button>
                </div>
            </div>
            <div *ngIf="step === 2">
                <div class="step-title">
                    <p>PAGAMENTO</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="select-control">
                                <label class="input-label" for="idParcelamento">Forma De Pagamento</label>
                                <mat-select formControlName="idParcelamento" class="input-field select"
                                    (selectionChange)="adjustArray($event)">
                                    <div class="option">
                                        <mat-option *ngFor="let portion of listTipoParcelamento"
                                            [value]="portion.idTipoParcelamento">
                                            {{ portion.nome }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </div>
                        </div>
                        <div class="form-group" style="padding-bottom: 18px;">
                            <div class="select-control">
                                <label class="input-label" for="idTipoRepeticao">Repetição</label>
                                <mat-select formControlName="idTipoRepeticao" class="input-field select"
                                    (selectionChange)="adjustData()">
                                    <div class="option">
                                        <mat-option *ngFor="let repetition of listTipoRepeticao"
                                            [value]="repetition.idTipoRepeticao">
                                            {{ repetition.nomeTipoRepeticao }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group" style="padding-bottom: 18px;">
                            <div class="input-control">
                                <label class="input-label" for="divParcela">Data 1° Parcela</label>
                                <input matInput id="divParcela" formControlName="divParcela" type="date"
                                    class="input-field" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-flex">
                    <div class="flexed-wrapper">
                        <div formArrayName="parcelamentos" style="width: 100%;">
                            <div *ngFor="let parcelamento of parcelamentos.controls; let i = index" [formGroupName]="i"
                                class="form-flexed">
                                <div class="select-control" style="width: 30%;">
                                    <label class="input-label" for="parcelamento-tipo-pagamento-{{ i }}">Tipo
                                        Pagamento</label>
                                    <mat-select id="parcelamento-tipo-pagamento-{{ i }}"
                                        formControlName="idTipoPagamento" class="input-field select">
                                        <div class="option">
                                            <mat-option *ngFor="let payment of listTipoPagamento"
                                                [value]="payment.idTipoPagamento">
                                                {{ payment.nomeTipoPagamento }}
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                </div>
                                <div class="input-control" style="width: 30%;">
                                    <label class="input-label" for="parcelamento-valor-{{ i }}">Valor</label>
                                    <input type="number" id="parcelamento-valor-{{ i }}" formControlName="valor"
                                        placeholder="R$ 00,00" class="input-field" min="0"
                                        (input)="changePortionValue(i)" />
                                </div>
                                <div class="input-control" style="width: 20%;">
                                    <label class="input-label" for="parcelamento-previsao-pagamento-{{ i }}">Previsão
                                        pagamento</label>
                                    <input type="date" id="parcelamento-previsao-pagamento-{{ i }}"
                                        formControlName="previsaoPagamento" placeholder="previsaoPagamento"
                                        class="input-field" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-flex" style="padding-top: 18px;">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="valorTotal">Total</label>
                                <input matInput id="valorTotal" formControlName="valorTotal" type="number"
                                    [ngClass]="model.get('valorTotal')?.touched && model.get('valorTotal')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="R$ 00,00" min="0" />
                                <span *ngIf="model.get('valorTotal')?.touched && model.get('valorTotal')?.invalid">O
                                    campo Total é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="valorAPagar">Soma Parcelas</label>
                                <input matInput id="valorAPagar" formControlName="valorAPagar" type="number"
                                    [ngClass]="model.get('valorAPagar')?.touched && model.get('valorAPagar')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="R$ 00,00" min="0" />
                                <span *ngIf="model.get('valorAPagar')?.touched && model.get('valorAPagar')?.invalid">O
                                    campo Valor Total é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button (click)="submit()" class="main-btn">SALVAR</button>
                </div>
            </div>
        </form>
    </div>
</div>