<button type="submit" mat-flat-button class="btn-secundary" (click)="fileUpload.click()" style="width: 200px;">
    <ng-container *ngIf="isLoading">
        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
        <mat-icon>add_photo_alternate</mat-icon>
        Adicionar Imagem
    </ng-container>
</button>
<input type="file" class="file-input" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif" (change)="onFileSelected($event)"
    multiple #fileUpload>

@if(_produtoResponse.produtoStruct.listProdutoImagemStruct == null ||
_produtoResponse.produtoStruct.listProdutoImagemStruct.length == 0){
<div class="text-center empty-list">
    <img src="/assets/images/no-image.jpg">
    <h1>Ainda não há imagens cadastradas para este produto</h1>
    <h2>Boas imagens te ajudam a vender mais!</h2>
</div>
}
@else{
<div class="list-imgs">
    @for (item of _produtoResponse.produtoStruct.listProdutoImagemStruct; track $index) {
    <div class="list-imgs-item">
        <img src="{{item.urlImagem}}">
        <button mat-fab class="fab-action red" (click)="deleteImg(item)">
            <mat-icon>delete</mat-icon>

        </button>
    </div>
    }
</div>
}

<div class="controller">
    <button (click)="emitBackPage()" class="cancel-btn">Voltar</button>
    <button (click)="emitFinishPage()" class="main-btn">Salvar e continuar</button>
</div>