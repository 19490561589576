<div class="container container-auth">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <form class="form" [formGroup]="model" (ngSubmit)="post()" class="text-center">
                <h1>Redefinir senha</h1>
                <p>
                    Insira sua senha atual
                </p>
                <mat-form-field appearance="outline">
                    <mat-label>Senha atual</mat-label>
                    <input matInput formControlName="passwordAtual" type="password">
                    @if (model.get('password1')?.invalid) {
                    <mat-error>Informe a nova senha</mat-error>
                    }
                </mat-form-field>
                <p>
                    Insira sua senha atual
                </p>

                <mat-form-field appearance="outline">
                    <mat-label>Nova senha</mat-label>
                    <input matInput formControlName="password1" type="password">
                    @if (model.get('password1')?.invalid) {
                    <mat-error>Informe a nova senha</mat-error>
                    }
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Repita sua nova senha</mat-label>
                    <input matInput formControlName="password2" type="password">
                    @if (model.get('password2')?.invalid) {
                    <mat-error>Repita a nova senha informada</mat-error>
                    }
                </mat-form-field>

                <button type="submit" mat-flat-button class="btn-primary button-big button-login">
                    @if (isLoading == true) {
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                    Redefinir Senha
                    }
                </button>
                <button type="button" mat-button class="button-big button-login" (click)="close()">Voltar</button>
            </form>
        </div>
    </div>
</div>