import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../common-service';
import { BehaviorSubject, catchError, forkJoin, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { GetFluxoResponse } from '../../responses/fluxo-pedido/get-fluxo.response';
import { MovimentacaoPedidoResponse } from '../../responses/fluxo-pedido/movimentacao-pedido.response';
import { ReturnStruct } from '../../structs/return.struct';
import { MovimentarPedidoRequest } from '../../requests/fluxo-pedido/movimentar-pedido.request';
import { AlertService, AlertType } from '../../alert.service';
import { ListGatilhoResponse } from '../../responses/fluxo-pedido/list-gatilho.response';
import { FluxoRequest } from '../../requests/fluxo-pedido/fluxo.request';
import { OrigemPedidoResponse } from '../../responses/pedido/origem-pedido.response';

@Injectable({
  providedIn: 'root',
})
export class OrchestratorGatilhoService extends CommonService {
  public isLoading: boolean = false;
  public getFluxoResponse: GetFluxoResponse;
  public getCardsResponse: MovimentacaoPedidoResponse;
  public getGatilhoResponse: ListGatilhoResponse;
  public getFluxosResponse: ListGatilhoResponse;

  private fluxoPedidoSubject = new BehaviorSubject<GetFluxoResponse | null>(
    null
  );
  private gatilhoSubject = new BehaviorSubject<ListGatilhoResponse | null>(
    null
  );
  private origensSubject = new BehaviorSubject<OrigemPedidoResponse | null>(
    null
  );

  private cardsResponseSubject =
    new BehaviorSubject<MovimentacaoPedidoResponse | null>(null);

  fluxoPedido$ = this.fluxoPedidoSubject.asObservable();
  cardsResponse$ = this.cardsResponseSubject.asObservable();
  gatilhosResponse$ = this.gatilhoSubject.asObservable();
  origemResponse$ = this.origensSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) {
    super();
  }

  public GetFluxoPedido(): Observable<GetFluxoResponse> {
    return this.httpClient
      .get<GetFluxoResponse>(
        environment.urlApiOrchestratorGatilho + 'GetFluxo/',
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public GetFluxoPedidoPadrao(): Observable<GetFluxoResponse> {
    return this.httpClient
      .get<GetFluxoResponse>(
        environment.urlApiOrchestratorGatilho + 'GetFluxoPadrao/',
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public GetMovimentacaoPedido(): Observable<MovimentacaoPedidoResponse> {
    return this.httpClient
      .get<MovimentacaoPedidoResponse>(
        environment.urlApiOrchestratorGatilho + 'MovimentacaoPedido/',
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public GetListGatilho(): Observable<ListGatilhoResponse> {
    return this.httpClient
      .get<ListGatilhoResponse>(
        environment.urlApiGatilho + 'ListGatilho/GatilhosAtivos',
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public GetListFluxo(): Observable<ListGatilhoResponse> {
    return this.httpClient
      .get<ListGatilhoResponse>(
        environment.urlApiOrchestratorGatilho + 'GetFluxoPadrao',
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public PostFluxoPedido(request: FluxoRequest): Observable<ReturnStruct> {
    return this.httpClient
      .post<ReturnStruct>(
        environment.urlApiFluxoPedido + 'Fluxo/',
        request,
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public DeleteFluxoPedido(id: number): Observable<ReturnStruct> {
    return this.httpClient
      .delete<ReturnStruct>(
        environment.urlApiFluxoPedido + 'Fluxo/idFluxoPedido/' + id,
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public fetchFluxo() {
    this.GetFluxoPedido().subscribe({
      next: (fluxoPedido: GetFluxoResponse) => {
        if (fluxoPedido.isError) {
          this.alertService.show(
            'Erro inesperado',
            fluxoPedido.errorDescription,
            AlertType.error
          );
          return;
        }
        this.fluxoPedidoSubject.next(fluxoPedido);
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  public fetchData() {
    this.isLoading = true;
    forkJoin({
      fluxoPedido: this.GetFluxoPedido(),
      movimentacaoPedido: this.GetMovimentacaoPedido(),
      gatilhos: this.GetListGatilho(),
      origens: this.GetListOrigem(),
      // fluxos: this.GetListFluxo(),
    }).subscribe({
      // next: ({ fluxoPedido, movimentacaoPedido, gatilhos, fluxos }) => {
      next: ({ fluxoPedido, movimentacaoPedido, gatilhos, origens }) => {
        if (
          fluxoPedido.isError ||
          movimentacaoPedido.isError ||
          gatilhos.isError ||
          origens.isError
        ) {
          this.alertService.show(
            'Erro inesperado',
            fluxoPedido.isError
              ? fluxoPedido.errorDescription
              : movimentacaoPedido.isError
              ? movimentacaoPedido.errorDescription
              : origens.isError
              ? origens.errorDescription
              : gatilhos.errorDescription,
            AlertType.error
          );
          return;
        }

        this.fluxoPedidoSubject.next(fluxoPedido);
        this.cardsResponseSubject.next(movimentacaoPedido);
        this.gatilhoSubject.next(gatilhos);
        this.origensSubject.next(origens);

        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      },
    });
  }

  public fetchPedidos() {
    this.isLoading = true;
    this.GetMovimentacaoPedido().subscribe({
      next: (movimentacaoPedido: MovimentacaoPedidoResponse) => {
        if (movimentacaoPedido.isError) {
          this.alertService.show(
            'Erro inesperado',
            movimentacaoPedido.errorDescription,
            AlertType.error
          );
          return;
        }
        this.cardsResponseSubject.next(movimentacaoPedido);
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  public MovimentarPedido(
    request: MovimentarPedidoRequest
  ): Observable<ReturnStruct> {
    return this.httpClient
      .post<MovimentacaoPedidoResponse>(
        environment.urlApiOrchestratorGatilho + 'MovimentarPedido/',
        request,
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public PostMovimentarPedido(body: MovimentarPedidoRequest) {
    this.isLoading = true;
    this.MovimentarPedido(body).subscribe({
      next: (movimentacaoResponse: ReturnStruct) => {
        if (movimentacaoResponse.isError) {
          this.alertService.show(
            'Erro inesperado',
            movimentacaoResponse.errorDescription,
            AlertType.error
          );
          this.fetchData();
          return;
        }
        this.alertService.show(
          'Sucesso',
          'Cartão movido com sucesso!',
          AlertType.success
        );
        this.fetchPedidos();
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.fetchPedidos();
      },
    });
  }

  public postFluxo(payload: FluxoRequest, successMessage: string) {
    this.isLoading = true;
    this.PostFluxoPedido(payload).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.fetchFluxo();
          return;
        }
        this.fetchFluxo();
        this.alertService.show('Sucesso!', successMessage, AlertType.success);
      },
      error: (error) => {
        this.fetchFluxo();
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      },
    });
  }

  public deleteFluxo(id: number, successMessage: string) {
    this.isLoading = true;
    this.DeleteFluxoPedido(id).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.fetchFluxo();
          return;
        }
        this.fetchFluxo();
        this.alertService.show('Sucesso!', successMessage, AlertType.success);
      },
      error: (error) => {
        this.fetchFluxo();
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      },
    });
  }

  public GetListOrigem(): Observable<OrigemPedidoResponse> {
    return this.httpClient
      .get<OrigemPedidoResponse>(
        environment.urlApiPedido + 'OrigemPedido',
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }
}
