import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AlertService, AlertType } from "../../../../shared/services/alert.service";
import { EstoqueService } from "../../../../shared/services/API/estoque/estoque.service";
import { ProdutoResponse } from "../../../../shared/services/responses/estoque/produto.response";
import { FormGroup, FormBuilder, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CestSearchResponse } from "../../../../shared/services/responses/estoque/cest-search.response";
import { NcmSearchStruct } from "../../../../shared/services/structs/estoque/ncm-search-struct";
import { NcmSearchResponse } from "../../../../shared/services/responses/estoque/ncm-search.response";
import { CestSearchStruct } from "../../../../shared/services/structs/estoque/cest-search.struct";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FiscalService } from "../../../../shared/services/API/fiscal/fiscal.service";
import { ListaSelectFiscalResponse } from "../../../../shared/services/responses/fiscal/lista-select-fiscal.response";
import { CstSituacao } from "../../../../shared/services/models/fiscal/cst-situacao.model";
import { FiscalPerfilImpostoStruct } from "../../../../shared/services/structs/fiscal/fiscal-perfil-imposto.struct";
import { MatSelectModule } from "@angular/material/select";

@Component({
  selector: 'app-estoque-fiscal',
  standalone: true,
  imports: [
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
  ],
  templateUrl: './estoque-fiscal.component.html',
  styleUrl: './estoque-fiscal.component.css'
})
export class EstoqueFiscalComponent implements OnInit {
    @Input() _produtoResponse: ProdutoResponse;
    @Output() reloadProduct = new EventEmitter<any>()
    @Output() prevStep = new EventEmitter<any>()
    @Output() nextStep = new EventEmitter<any>()
    
    public isLoading: boolean = false;
    public model: FormGroup;
    public _listNcmSearchStruct: NcmSearchStruct[] = [];
    public _listCestSearchStruct: CestSearchStruct[] = [];
    public listCstSituacao: CstSituacao[] = [];
    public listFiscalPerfilImpostoStruct: FiscalPerfilImpostoStruct[] = [];
    private debounceTimeout: any;

    constructor(private formBuilder: FormBuilder,
        private estoqueService: EstoqueService,
        private alertService: AlertService,
        private fiscalService: FiscalService,
    ) {
  }
  ngOnInit(): void {

    this.model = this.formBuilder.group({
      idPerfilFiscal: [''],
      cstOrigem: [''],
      ncm: [''],
      cest: ['']
    });
    let testlist = this.getListSelect();
    this.populate();
  }

  populate() {
        if (this._produtoResponse && this._produtoResponse.produtoStruct) {
        this.model.get('idPerfilFiscal')?.setValue(this._produtoResponse?.produtoStruct?.produto.idPerfilFiscal);
        this.model.get('cstOrigem')?.setValue(this._produtoResponse?.produtoStruct?.produto.cstOrigem);        
        this.model.get('ncm')?.setValue(this._produtoResponse?.produtoStruct?.produto.ncm);
        this.model.get('cest')?.setValue(this._produtoResponse?.produtoStruct?.produto.cest);
        }
    }
    
    getListSelect() {
        this.fiscalService.getListFiscalSelect().subscribe({
        next: (response: ListaSelectFiscalResponse) => {
            if (response.isError) {
            this.alertService.show(
                'Erro inesperado',
                response.errorDescription,
                AlertType.error
            );
            this.isLoading = false;
            return;
            }

            this.listCstSituacao = response.listCstSituacao;
            this.listFiscalPerfilImpostoStruct = response.listFiscalPerfilImpostoStruct;
            this.isLoading = false;
        },
        error: (error) => {
            this.alertService.show(
            'Erro inesperado',
            'Erro ao buscar lista de clientes',
            AlertType.error
            );
            this.isLoading = false;
            return;
        },
        });
    }

  getNcmValues(filterText: any) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (filterText) {
        const lowerFilter = filterText.toLowerCase();
        this.estoqueService.SearchNcm(lowerFilter).subscribe({
          next: (ncmSearchResponse: NcmSearchResponse) => {
            this.isLoading = false;
            if (ncmSearchResponse.isError) {
              this.alertService.show(
                'Erro inesperado',
                ncmSearchResponse.errorDescription,
                AlertType.error
              );
              return;
            }
            this._listNcmSearchStruct = ncmSearchResponse.listNcmSearchStruct;
          },
          error: (error) => {
            this.isLoading = false;
            this.alertService.show('Erro inesperado', error, AlertType.error);
          },
        });
      }
    }, 300);
  }

  selectNcmValue(item: NcmSearchStruct) {
    this.model.get('ncm')?.setValue(item.codigo);
  }

  getCestValues(filterText: string) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
      this.debounceTimeout = setTimeout(() => {
        if (filterText) {
          this.estoqueService.SearchOnlyCest(filterText).subscribe({
            next: (cestSearchResponse: CestSearchResponse) => {
              this.isLoading = false;
              if (cestSearchResponse.isError) {
                this.alertService.show(
                  'Erro inesperado',
                  cestSearchResponse.errorDescription,
                  AlertType.error
                );
                return;
              }
              this._listCestSearchStruct = cestSearchResponse.listCestSearchStruct;
            },
            error: (error) => {
              this.isLoading = false;
              this.alertService.show('Erro inesperado', error, AlertType.error);
            },
          });
        }
      }, 300);
  }

  selectCestValue(event: any) {
    const selectedItem: CestSearchStruct = event.source.value; // Pega o item selecionado
    this.model.get('cest')?.setValue(selectedItem);
  }

  emitPrevStep(){
    this.prevStep.emit();
  }

}
