<div class="main-container">
    <app-subheader [icon]="'currency_exchange'" [title]="'Contas a Pagar'" [description]="_idContasPagar !== null ? 'Edição de uma conta a pagar' : 'Cadastro de uma nova conta a pagar'" [isLoading]="isLoading" [isAddPage]="_idContasPagar !== null ? false : true" [isEditPage]="_idContasPagar !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px" style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">edit_note</mat-icon>
            </div>
            <div [ngClass]="step >= 2 ? 'step-separator-done' : 'step-separator'"></div>
            <div (click)="setStep(2)" [ngClass]="step === 2 ? 'step-active' : 'step-item'">
                <mat-icon [ngClass]="step >= 2 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
            </div>
        </div>
        <form *ngIf="model" [formGroup]="model">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>DADOS DA CONTA A PAGAR</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-betweened">
                            <div class="input-control">
                                <label class="input-label" for="nameFornecedor">Fornecedor</label>
                                <div class="custom-select">
                                    <input type="text" [ngClass]="model.get('nameFornecedor')?.touched && model.get('nameFornecedor')?.invalid ? 'input-failed' : 'input-field'" placeholder="Selecione um fornecedor" id="nameFornecedor" formControlName="nameFornecedor" (focus)="toggleDropdown()" (input)="filtrarFornecedor()" (blur)="validateFornecedor()" />
                                    <ul *ngIf="isDropdownOpen" class="dropdown">
                                        <li *ngFor="let fornecedor of filteredFornecedores" (click)="selectFornecedor(fornecedor); $event.stopPropagation()">
                                            {{ fornecedor.pessoa.nome }}
                                        </li>
                                    </ul>
                                </div>
                                <span *ngIf="model.get('nameFornecedor')?.touched && model.get('nameFornecedor')?.invalid">O campo Fornecedor é obrigatório.</span>
                            </div>
                            <button (click)="openSupplyModal()" [disabled]="isLoading" class="main-btn">ADICIONAR</button>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="vencimento">Vencimento</label>
                                <input matInput id="vencimento" formControlName="vencimento" type="date" [ngClass]="model.get('vencimento')?.touched && model.get('vencimento')?.invalid ? 'input-failed' : 'input-field'" placeholder="Vencimento" />
                                <span *ngIf="model.get('vencimento')?.touched && model.get('vencimento')?.invalid">O campo Vencimento é obrigatório.</span>
                            </div>
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Vencimento</mat-label>
                                <input matInput id="vencimento" formControlName="vencimento" type="date" class="field" />
                                <mat-error *ngIf="model.get('vencimento')?.touched && model.get('vencimento')?.invalid">
                                    O campo Vencimento é obrigatório.
                                </mat-error>
                            </mat-form-field> -->
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="valorConta">Valor Da Conta</label>
                                <input matInput id="valorConta" formControlName="valorConta" type="number" [ngClass]="model.get('valorConta')?.touched && model.get('valorConta')?.invalid ? 'input-failed' : 'input-field'" placeholder="Valor Da Conta" />
                                <span *ngIf="model.get('valorConta')?.touched && model.get('valorConta')?.invalid">O campo Valor Da Conta é obrigatório.</span>
                            </div>
                            <!-- <div class="last-field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Valor Da Conta</mat-label>
                                    <input matInput formControlName="valorConta" type="number" placeholder="Valor Da Conta" class="field" />
                                </mat-form-field>
                                <mat-error *ngIf="model.get('valorConta')?.touched && model.get('valorConta')?.invalid">
                                    O campo Valor Da Conta é obrigatório.
                                </mat-error>
                            </div> -->
                        </div>
                        <div class="form-group">


                            <div class="input-control">
                                <label class="input-label" for="nameStatusConta">Status Conta</label>
                                <div class="custom-select">
                                    <input type="text" [ngClass]="model.get('nameStatusConta')?.touched && model.get('nameStatusConta')?.invalid ? 'input-failed' : 'input-field'" placeholder="Selecione um status" id="nameStatusConta" formControlName="nameStatusConta" (focus)="toggleDropdownStatus()" (input)="filterStatus()" (blur)="validateStatus()" />
                                    <ul *ngIf="isDropdownStatusOpen" class="dropdown">
                                        <li *ngFor="let status of filteredStatus" (click)="selectStatus(status); $event.stopPropagation()">
                                            {{ status.nomeStatusConta }}
                                        </li>
                                    </ul>
                                </div>
                                <span *ngIf="model.get('nameStatusConta')?.touched && model.get('nameStatusConta')?.invalid">O campo Status Conta é obrigatório.</span>
                            </div>


                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Status Conta</mat-label>
                                <mat-select formControlName="idStatusConta" (disabled)="isLoading">

                                    <mat-option *ngFor="let status of listStatus" [value]="status.idStatusConta">
                                        {{ status.nomeStatusConta }}
                                    </mat-option>

                                </mat-select>
                                <mat-error *ngIf="model.get('idStatusConta')?.touched && model.get('idStatusConta')?.invalid">
                                    O campo Status Conta é obrigatório.
                                </mat-error>
                            </mat-form-field> -->
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="notaFiscal">Nota Fiscal</label>
                                <input matInput id="notaFiscal" formControlName="notaFiscal" type="text" [ngClass]="model.get('notaFiscal')?.touched && model.get('notaFiscal')?.invalid ? 'input-failed' : 'input-field'" placeholder="Nota Fiscal" />
                                <span *ngIf="model.get('notaFiscal')?.touched && model.get('notaFiscal')?.invalid">O campo Nota Fiscal é obrigatório.</span>
                            </div>
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Nota Fiscal</mat-label>
                                <input matInput id="notaFiscal" formControlName="notaFiscal" type="text" class="field" />
                                <mat-error *ngIf="model.get('notaFiscal')?.touched && model.get('notaFiscal')?.invalid">
                                    O campo Nota Fiscal é obrigatório.
                                </mat-error>
                            </mat-form-field> -->
                        </div>
                    </div>
                    <div class="form-grouped">

                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="nameSubCategoria">Categoria</label>
                                <div class="custom-select">
                                    <input type="text" [ngClass]="model.get('nameSubCategoria')?.touched && model.get('nameSubCategoria')?.invalid ? 'input-failed' : 'input-field'" placeholder="Selecione uma categoria" id="nameSubCategoria" formControlName="nameSubCategoria" (focus)="toggleDropdownCategory()" (input)="filtrarCategory()" (blur)="validateCategoria()" />
                                    <ul *ngIf="isDropdownCategoryOpen" class="dropdown">
                                        <div *ngFor="let categoria of filteredCategories">
                                            <p class="category-title">{{ categoria.categoria.nomeCategoria }}</p>
                                            <li (click)="selectCategory(subCategoria); $event.stopPropagation()" *ngFor="let subCategoria of categoria.listSubCategoria">
                                                {{ subCategoria.nomeSubCategoria }}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <span *ngIf="model.get('nameSubCategoria')?.touched && model.get('nameSubCategoria')?.invalid">O campo Categoria é obrigatório.</span>
                            </div>
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Categoria</mat-label>
                                <mat-select formControlName="idSubCategoria" (disabled)="isLoading">
                                    <div class="category-select" *ngFor="let categoria of listCategoriaStructResponse.listCategoriaStruct">
                                        <span class="title-select">{{ categoria.categoria.nomeCategoria }}</span>
                                        <mat-option *ngFor="let subCategoria of categoria.listSubCategoria" [value]="subCategoria.idSubCategoria">
                                            {{ subCategoria.nomeSubCategoria }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                                <mat-error *ngIf="model.get('idSubCategoria')?.touched && model.get('idSubCategoria')?.invalid">
                                    O campo Categoria é obrigatório.
                                </mat-error>
                            </mat-form-field> -->
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="previsaoPagamento">Previsão De Pagamento</label>
                                <input matInput id="previsaoPagamento" formControlName="previsaoPagamento" type="date" [ngClass]="model.get('previsaoPagamento')?.touched && model.get('previsaoPagamento')?.invalid ? 'input-failed' : 'input-field'" placeholder="Previsão De Pagamento" />
                                <span *ngIf="model.get('previsaoPagamento')?.touched && model.get('previsaoPagamento')?.invalid">O campo Previsão De Pagamento é obrigatório.</span>
                            </div>
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Previsão De Pagamento</mat-label>
                                <input matInput id="previsaoPagamento" formControlName="previsaoPagamento" type="date" class="field" />
                                <mat-error *ngIf="model.get('previsaoPagamento')?.touched && model.get('previsaoPagamento')?.invalid">
                                    O campo Previsão De Pagamento é obrigatório.
                                </mat-error>
                            </mat-form-field> -->
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="nameBancoEmpresa">Conta Corrente</label>
                                <div class="custom-select">
                                    <input type="text" [ngClass]="model.get('nameBancoEmpresa')?.touched && model.get('nameBancoEmpresa')?.invalid ? 'input-failed' : 'input-field'" placeholder="Selecione uma conta corrente" id="nameBancoEmpresa" formControlName="nameBancoEmpresa" (focus)="toggleDropdownBank()" (input)="filterBank()" (blur)="validateBanco()" />
                                    <ul *ngIf="isDropdownBankOpen" class="dropdown">
                                        <li *ngFor="let bank of filteredBank" (click)="selectBank(bank); $event.stopPropagation()">
                                            {{ bank.nomeAgencia }}
                                        </li>
                                    </ul>
                                </div>
                                <span *ngIf="model.get('nameBancoEmpresa')?.touched && model.get('nameBancoEmpresa')?.invalid">O campo Conta Corrente é obrigatório.</span>
                            </div>
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Conta Corrente</mat-label>
                                <mat-select formControlName="idBancoEmpresa">
                                    <mat-option *ngFor="let bancoEmpresa of listBancoEmpresaResponse.listBancoEmpresa" [value]="bancoEmpresa.idBancoEmpresa">
                                        {{ bancoEmpresa.nomeAgencia }}
                                    </mat-option>
                                    <mat-error *ngIf="model.get('idBancoEmpresa')?.touched && model.get('idBancoEmpresa')?.invalid">
                                        O campo Conta Corrente é obrigatório.
                                    </mat-error>
                                </mat-select>
                            </mat-form-field> -->
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="dataEmissao">Data Emissão</label>
                                <input matInput id="dataEmissao" formControlName="dataEmissao" type="date" [ngClass]="model.get('dataEmissao')?.touched && model.get('dataEmissao')?.invalid ? 'input-failed' : 'input-field'" placeholder="Data Emissão" />
                                <span *ngIf="model.get('dataEmissao')?.touched && model.get('dataEmissao')?.invalid">O campo Data Emissão é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="dataRegistro">Data Registro</label>
                                <input matInput id="dataRegistro" formControlName="dataRegistro" type="date" [ngClass]="model.get('dataRegistro')?.touched && model.get('dataRegistro')?.invalid ? 'input-failed' : 'input-field'" placeholder="Data Registro" />
                                <span *ngIf="model.get('dataRegistro')?.touched && model.get('dataRegistro')?.invalid">O campo Data Registro é obrigatório.</span>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="controller">
                    <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
                    <button (click)="nextStep()" class="main-btn">PRÓXIMO</button>
                </div>
            </div>
            <!-- <div *ngIf="step === 1">
                <div class="title-section">
                    <p>DETALHES</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Nota Fiscal</mat-label>
                                <input matInput id="notaFiscal" formControlName="notaFiscal" type="text" class="field" />
                                <mat-error *ngIf="model.get('notaFiscal')?.touched && model.get('notaFiscal')?.invalid">
                                    O campo Nota Fiscal é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Data Emissão</mat-label>
                                <input matInput id="dataEmissao" formControlName="dataEmissao" type="date" class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Data Registro</mat-label>
                                <input matInput id="dataRegistro" formControlName="dataRegistro" type="date" class="field" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div> -->
            <div *ngIf="step === 2">
                <div class="step-title">
                    <p>OBSERVAÇÕES</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">

                            <div class="NgxEditor__Wrapper">
                                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                                <ngx-editor [editor]="editor" formControlName="observacoes" [spellcheck]="true" [placeholder]="'Observacoes'">
                                </ngx-editor>
                            </div>
                        </div>
                        <div *ngIf="isLoading && isUpdate">
                            <div class="step-title">
                                <p>INFORMAÇÕES ADICIONAIS</p>
                            </div>
                            <div class="sub-section">
                                <div class="last-edit">
                                    <span class="last-edit-title">Última Alteração</span>
                                    <span class="last-edit-text">{{contasPagarResponse.contasPagar.nomeUsuarioAlteracao}}</span>
                                </div>
                                <div class="include">
                                    <span class="include-title">Inclusão</span>
                                    <span class="include-text">{{contasPagarResponse.contasPagar.nomeUsuarioRegistro}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button (click)="submit()" class="main-btn">SALVAR</button>
                </div>
            </div>
        </form>
    </div>
    <!-- <div class="button-display">
        <div class="submit">
            <button class="button btn-primary save" (click)="submit()" [disabled]="isLoading">
                <ng-container *ngIf="isLoading; else notLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </ng-container>
                <ng-template #notLoading>
                    Salvar
                </ng-template>
            </button>

            <button class="button cancel" (click)="backPage()">Cancelar</button>
        </div>
    </div> -->
</div>