import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { CommonModule, ViewportScroller } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { Router } from '@angular/router';
import { TipoFornecedorEnum } from '../../../shared/enum/Fornecedor/tipo-fornecedor.enum';
import { FornecedorSearchRequest } from '../../../shared/services/requests/pessoa/fornecedor-search.request';
import { FornecedorSearchExhibitResponse } from '../../../shared/services/responses/pessoa/fornecedor-search-exhibit.response';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { debounceTime, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { ListExhibitStruct } from '../../../shared/services/structs/list-exhibit.struct';
import { RoutesService } from '../../../routes.service';
import { ViewSwitcherComponent } from '../../../shared/component/view-switcher/view-switcher.component';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';

@Component({
  selector: 'app-fornecedor-lista',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SubheaderComponent,
    MatFormFieldModule,
    MatSelectModule,
    ViewSwitcherComponent,
    SkeletonComponent,
    AdvancedTableComponent,
  ],
  templateUrl: './fornecedor-lista.component.html',
  styleUrl: './fornecedor-lista.component.css',
})
export class FornecedorListaComponent implements OnInit {
  @ViewChild(AdvancedTableComponent) exhibit: AdvancedTableComponent;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  public color: string = DefaultColorEnum.Cor;
  buttonStyle: any = {
    backgroundColor: this.color,
    color: 'white',
  };
  secondButtonStyle: any = {
    backgroundColor: 'transparent',
    color: this.color,
  };
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private routesService: RoutesService
  ) {
    this.searchSubject.pipe(debounceTime(2000)).subscribe((value) => {
      this.model.get('filterName')?.setValue(value);
      this.searchSupplier();
    });
    this.searchDate.pipe(debounceTime(2000)).subscribe((value) => {
      this.model.get('filterDate')?.setValue(value);
      this.searchSupplier();
    });
  }
  public model: FormGroup;
  public tipoFornecedor: number = TipoFornecedorEnum.Fornecedor;
  public tipoTransportador: number = TipoFornecedorEnum.Transportadora;
  public tipoTabela: number = TipoFornecedorEnum.Fornecedor;
  public dataList: any[];
  public searchTimeout: any;
  public nameProfile: string = 'Fornecedores';
  public iconProfile: string = 'home_repair_service';
  public profile: number = DefaultProfilesEnum.Fornecedor;
  public nameAdd: string = 'Fornecedores';
  public redirectUrl: string = this.routesService.SUPPLY_REGISTER;
  public tableEnum: number = ExhibitEnum.Tabela;
  public display: ListExhibitStruct = new ListExhibitStruct();
  public listDisplay = this.display.listExhibit;
  public isTable: boolean = true;
  public selectedExhibit: number;
  tableColumns = [
    {
      key: 'cpfCnpj',
      label: 'CPF/CNPJ',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'nome',
      label: 'Nome',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      filterable: false,
      date: false,
    },
    {
      key: 'telefone',
      label: 'Telefone',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'inclusao',
      label: 'Inclusão',
      sortable: true,
      filterable: false,
      date: true,
    },
  ];
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterName: [''],
      filters: [],
      filterDate: [''],
      currentPage: [0],
      totalPages: [0],
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.ler);

    this.searchSupplier();
  }

  onSearchValueChanged(value: string) {
    this.searchSubject.next(value);
  }

  onDateValueChanged(value: string) {
    this.searchDate.next(value);
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchSupplier();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchSupplier();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  searchSupplier() {
    this.isLoading = true;
    const searchRequest: FornecedorSearchRequest = new FornecedorSearchRequest();
      if (this.model.get('filters')?.value) {
        searchRequest.filters = this.model.get('filters')?.value;
        this.model.get('currentPage')?.setValue(0);
      }

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value) {
      this.model.get('currentPage')?.setValue(0);
      const [startDateString, endDateString] = this.model
        .get('filterDate')
        ?.value.split('|');
      searchRequest.dataInicio = startDateString;
      searchRequest.dataFim = endDateString;
    }

    searchRequest.tipoFornecedor = this.tipoFornecedor;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.pessoaService.searchSupplierExhibit(searchRequest).subscribe({
        next: (response: FornecedorSearchExhibitResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.dataList = response.listFornecedorSearchExhibitStruct;
          this.model.get('totalPages')?.setValue(response.pageSize);
          setTimeout(() => {
            this.update();
          }, 3000);
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }, 3000);
  }

  update() {
    this.exhibit.update();
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  addSupplier() {
    this.router.navigate([this.routesService.SUPPLY_REGISTER]);
  }

  changeExhibit(tipo: number) {
    if (this.tipoTabela === tipo) return;
    else {
      if (tipo === this.tipoFornecedor) {
        this.tipoTabela = tipo;
        this.buttonStyle.backgroundColor = this.color;
        this.buttonStyle.color = 'white';
        this.secondButtonStyle.backgroundColor = 'white';
        this.secondButtonStyle.color = this.color;
        this.searchSupplier();
      } else {
        this.tipoTabela = tipo;
        this.buttonStyle.backgroundColor = 'white';
        this.buttonStyle.color = this.color;
        this.secondButtonStyle.backgroundColor = this.color;
        this.secondButtonStyle.color = 'white';
        this.searchSupplier();
      }
    }
  }

  exhibitActions(emitted: any) {
    if (emitted.action === 'search') {
      this.model.get('filterName')?.setValue(emitted.search);
      this.model.get('filters')?.setValue(emitted.filters);
      this.searchSupplier();
    } else if (emitted.action === 'edit') {
      this.router.navigate([
        this.routesService.SUPPLY_UPDATE(emitted.row.registro),
      ]);
    } else if (emitted.action === 'dateSearch') {
      if (emitted.dateSearch.date != null) {
        this.model.get('filterDate')?.setValue(emitted.dateSearch.date);
      } else {
        this.model.get('filterDate')?.setValue(null);
      }
      this.searchSupplier();
    } else if (emitted.action === 'remove') {
      const modalRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title: 'Atenção!',
          description:
            'Deseja excluir o fornecedor ' +
            emitted.row.nome +
            ' ? Esta ação é irreversivel.',
          buttonConfirm: 'Excluir',
          buttonClose: 'Cancelar',
        },
        hasBackdrop: true,
        disableClose: false,
      });

      modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
        if (result.confirm) {
          this.isLoading = true;
          this.pessoaService
            .removeSupplierById(emitted.row.registro)
            .subscribe({
              next: (response: ReturnStruct) => {
                if (response.isError) {
                  this.alertService.show(
                    'Erro inesperado',
                    response.errorDescription,
                    AlertType.error
                  );
                  this.isLoading = false;
                  return;
                }
                this.isLoading = false;
                this.alertService.show(
                  'Sucesso!',
                  'Fornecedor excluido',
                  AlertType.success
                );
                this.searchSupplier();
              },
              error: (error) => {
                this.alertService.show(
                  'Erro inesperado',
                  error,
                  AlertType.error
                );
                this.isLoading = false;
                return;
              },
            });
        } else {
          this.isLoading = false;
          return;
        }
      });
    }
  }

  onExhibitChange(event: any) {
    if (event == this.tableEnum) {
      this.isTable = true;
    } else {
      this.isTable = false;
    }
  }
}
