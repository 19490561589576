<div class="main-container">
    <app-subheader [icon]="'request_page'" [title]="'Perfil de imposto'"
        [description]="_idPerfilImposto !== null ? 'Edição de um perfil de imposto existente' : 'Cadastro de um novo perfil de imposto'"
        [isLoading]="isLoading" [isAddPage]="_idPerfilImposto !== null ? false : true"
        [isEditPage]="_idPerfilImposto !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px"
            style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
            </div>
        </div>
        <form [formGroup]="model" (ngSubmit)="submit()">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>PERFIL DE IMPOSTO</p>
                </div>
                <div class="container">
                    <div class="row">
                                <div class="col-12">
                                    <input matInput id="nome" formControlName="nome" type="text"
                                        [ngClass]="model.get('nome')?.touched && model.get('nome')?.invalid ? 'input-failed' : 'input-field'"
                                        placeholder="Nome" />
                                    <span *ngIf="model.get('nome')?.touched && model.get('nome')?.invalid">O campo NOME é
                                        obrigatório.</span>
                                </div>
                            </div>
                    <hr />
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let imp of imposto.controls; let i = index" formArrayName="listImpostoBaseStruct" class="accordion-perfil">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="step-title accordion-title">
                                        @if(i == 0) { REGRA PADRÃO }
                                        @else{REGRA {{ i }}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div [formGroupName]="i" class="tax">
                                    @if(i != 0) { 
                                        <div class="row" style="margin-top: 0px;">
                                            <div class="form-group col-12 col-sm-6 col-md-3">
                                                <input matInput class="input-ncm" id="ncm" formControlName="ncm" type="text" (ngModelChange)="getNcmValues($event)"
                                                    [matAutocomplete]="ncmAuto" placeholder="Informe o código NCM" />
                                                <mat-autocomplete #ncmAuto="matAutocomplete">
                                                    <mat-option *ngFor="let item of _listNcmSearchStruct" [value]="item.codigo" (onSelectionChange)="selectNcmValue($event, i)">
                                                        <span style="font-size: 14px"><b>{{ item.codigo }}</b></span><br /> <span style="font-size: 12px">{{ item.descricao | slice: 0:120 }} </span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <span *ngIf="model.get('ncm')?.touched && model.get('ncm')?.invalid">Informe o código NCM do produto.</span>
                                            </div>

                                            <div class="form-group col-12 col-sm-6 col-md-3">
                                                <input matInput class="input-ncm" id="cst" formControlName="cst" type="text" (ngModelChange)="getCstValues($event)"
                                                    [matAutocomplete]="cstAuto" placeholder="Informe o código cst" />
                                                <mat-autocomplete #cstAuto="matAutocomplete">
                                                    <mat-option *ngFor="let item of _listCstSearchStruct" [value]="item.codigoCst" (onSelectionChange)="selectCstValue($event, i)">
                                                        <span style="font-size: 14px"><b>{{ item.codigoCst }}</b> <span style="font-size: 12px"> - {{ item.descricaoCstTributacao }} </span></span><br /> <span style="font-size: 12px">{{ item.descricaoCstSituacao }} </span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <span *ngIf="model.get('cst')?.touched && model.get('cst')?.invalid">Informe o código NCM do produto.</span>
                                            </div>

                                            <div class="form-group col-6 col-sm-3 col-md-2">
                                                <div class="input-control">
                                                  <select class="input-ncm" id="csosn" formControlName="csosn" (change)="OnCsosnChange(i, $event)" style="padding: 7px;">
                                                    <option value="" disabled selected>CSOSN</option>
                                                    <option *ngFor="let csosn of listCsosn" [value]="csosn.idCsosn">
                                                      {{ csosn.codigoCsosn }}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                              <div class="form-group col-6 col-sm-3 col-md-4">
                                                <div class="input-control">
                                                  <select class="input-ncm" formControlName="idTipoConsumidor" (change)="onTipoConsumidorChange(i, $event)" style="padding: 7px;">
                                                    <option value="" disabled selected>Tipo Consumidor</option>
                                                    <option *ngFor="let consumer of listConsumerType" [value]="consumer.idTipoConsumidor">
                                                      {{ consumer.nomeTipoConsumidor }}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                        </div>
                                    }
                                        <hr />
                                        <div class="tax-uf flexed-wrapper" style="margin-bottom: 10px;" *ngFor="let impUf of getImpostoUf(i).controls; let j = index"
                                            formArrayName="listPerfilImpostoUfStruct">
                                            <div [formGroupName]="j" style="margin-top: -25px; margin-bottom: -10px;">
                                                <div class="row" style="padding-left: 30px; margin-top: 4px; margin-bottom: 0px;">
                                                    <div class="col-11 row">
                                                        <div class="form-group col-6 col-sm-3 col-md-3">
                                                            <!-- <label class="input-label" for="listIdUfDestino" style="margin-bottom: 6px;">Uf Destino</label> -->
                                                                <mat-select formControlName="listIdUfDestino" class="input-ncm" multiple  style="padding: 7px;">
                                                                  <mat-option *ngFor="let uf of listUf" [value]="uf.idUf">
                                                                    {{ uf.siglaUf }}
                                                                  </mat-option>
                                                                </mat-select>
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md-2" >
                                                            <div class="input-control">
                                                                <!-- <label class="input-label" for="icmsAliquota" >ICMS Aliquota</label> -->
                                                                <input matInput id="icmsAliquota" formControlName="icmsAliquota"
                                                                    type="number" class="input-field" placeholder="ICMS Aliquota" 
                                                                    min="0" max="100" />
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md-2" >
                                                            <div class="input-control">
                                                                <!-- <label class="input-label" for="icmsBase" >ICMS Base</label> -->
                                                                <input matInput id="icmsBase" formControlName="icmsBase" type="number"
                                                                    class="input-field" placeholder="ICMS Base" 
                                                                    min="0" max="100" />
                                                            </div>
                                                        </div> 

                                                        <div class="form-group col-12 col-sm-6 col-md-5">
                                                            <!-- <label class="input-label" for="cest" style="margin-bottom: 6px;">Cód. CEST</label> -->
                                                            <input matInput class="input-ncm" id="cest" formControlName="cest" type="text" (ngModelChange)="getCestValues($event, null)"
                                                                [matAutocomplete]="cestAuto" placeholder="Informe o código CEST" />
                                                            <mat-autocomplete #cestAuto="matAutocomplete">
                                                                <mat-option *ngFor="let cestItem of _listCestSearchStruct" [value]="cestItem.codigo" (onSelectionChange)="selectCestValue(i, j, $event)">
                                                                    <span style="font-size: 14px"><b>{{ cestItem.codigo }}</b></span><br /> <span style="font-size: 12px">{{ cestItem.descricao | slice: 0:120 }} </span>
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                        <div class="form-group col-12">
                                                            <div class="input-control">
                                                                <!-- <label class="input-label" for="idMensagemTributaria" style="margin-bottom: 6px;">Mensagem tributária</label> -->
                                                                <select class="input-ncm" id="idMensagemTributaria" formControlName="idMensagemTributaria" (change)="OnMensagemTributariaChange(i, j, $event)" style="padding: 7px;">
                                                                    <option value="" selected>Mensagem Tributária</option>
                                                                    <option *ngFor="let mensagemTributaria of listMensagemTributaria" [value]="mensagemTributaria.idMensagemTributaria">
                                                                        {{ mensagemTributaria.mensagem }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-1" style="display: flex;
                                                        align-items: flex-start;
                                                        flex-direction: row;
                                                        flex-wrap: wrap;
                                                        align-content: center;
                                                        justify-content: center;">
                                                        <mat-icon (click)="addImpostoUf(i)" style="color: var(--main-100);">add</mat-icon>
                                                        <mat-icon (click)="removeImpostoUf(i, j)" *ngIf="j > 0" style="color: var(--warning-200);">delete</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-flex" style="padding-top: 18px;">
                                            <div class="form-grouped-button" *ngIf="i > 0">
                                                <div class="form-group">
                                                    <div class="input-control" style="margin-bottom: 2px;">
                                                        <button class="warning-btn" (click)="removeImposto(i)">
                                                            REMOVER REGRA {{ i }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="form-grouped-button" style="padding: 10px;">
                            <div class="form-group">
                                <div class="input-control" style="margin-bottom: 2px;">
                                    <button type="button" (click)="addImposto()" class="main-btn">
                                        NOVA REGRA
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="controller">
                        <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
                        <button (click)="submit()" class="main-btn">SALVAR</button>
                    </div>
            </div>
        </form>
    </div>
</div>