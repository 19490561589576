<div class="main-container">
    <app-subheader [icon]="'request_page'" [title]="'Perfil de imposto'"
        [description]="_idPerfilPisCofins !== null ? 'Edição de um perfil de imposto existente' : 'Cadastro de um novo perfil de imposto'"
        [isLoading]="isLoading" [isAddPage]="_idPerfilPisCofins !== null ? false : true"
        [isEditPage]="_idPerfilPisCofins !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px"
            style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
            </div>
        </div>
        <form [formGroup]="model">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>PERFIL PIS COFINS</p>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-4 col-sm-1" style="align-content: flex-end;">
                            <label class="switch">
                                <input type="checkbox" formControlName="pisSt" (change)="onPisStChange($event)">
                                <span class="slider round"></span>
                            </label>
                            <p [ngClass]="model.get('pisSt')?.value ? 'is-active' : ''">PIS ST</p>
                        </div>
                        <div class="form-group col-6 col-sm-3 col-md-2">
                            <div class="input-control">
                                <label class="input-label" for="pisAliquota">PIS ALIQUOTA</label>
                                <input matInput id="pisAliquota" formControlName="pisAliquota" type="number" class="input-field" 
                                min="0" max="100" />
                            </div>
                        </div>
                        <div class="form-group col-6 col-sm-3 col-md-2">
                            <div class="input-control">
                                <label class="input-label" for="pisCst">PIS CST</label>
                                <input matInput id="pisCst" formControlName="pisCst" type="number" class="input-field" 
                                min="0" max="100" />
                            </div>
                        </div>
                        <div class="col-4 col-sm-1" style="align-content: flex-end;">
                            <label class="switch">
                                <input type="checkbox" formControlName="cofinsSt" (change)="onCofinsStChange($event)">
                                <span class="slider round"></span>
                            </label>
                            <p [ngClass]="model.get('cofinsSt')?.value ? 'is-active' : ''">COFINS ST</p>
                        </div>
                        <div class="form-group col-6 col-sm-3 col-md-2">
                            <div class="input-control">
                                <label class="input-label" for="cofinsAliquota">COFINS ALIQUOTA</label>
                                <input matInput id="cofinsAliquota" formControlName="cofinsAliquota" type="number" class="input-field" 
                                min="0" max="100" />
                            </div>
                        </div>
                        <div class="form-group col-6 col-sm-3 col-md-2">
                            <div class="input-control">
                                <label class="input-label" for="cofinsCst">COFINS CST</label>
                                <input matInput id="cofinsCst" formControlName="cofinsCst" type="number" class="input-field" 
                                min="0" max="100" />
                            </div>
                        </div>
                    </div>
                    <div class="controller">
                        <button (click)="backPage()" class="cancel-btn">VOLTAR</button>
                        <button (click)="submit()" class="main-btn">SALVAR</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>