
<form [formGroup]="model">
    <div class="form-flex" style="margin-top:30px">
        <div class="flexed-wrapper">
            <div formArrayName="produtos" style="width: 100%; padding: 0px 20px 0px 20px">
                <div *ngFor="let produto of produtos.controls; let i = index" [formGroupName]="i" class="form-flexed">

                    <div class="input-control" style="width: 40%;">
                        <input 
                          matInput 
                          class="input-ncm" 
                          id="produto" 
                          type="text"
                          formControlName="nomeProduto"
                          (input)="onProdutoInput($event)"
                          [matAutocomplete]="produtoAuto"
                          placeholder="Produto" />
                          
                        <mat-autocomplete #produtoAuto="matAutocomplete" 
                          (optionSelected)="selecionarProduto(i, $event.option.value)">
                          <mat-option *ngFor="let product of filteredProdutos" [value]="product">
                            <span style="font-size: 14px"><b>{{ product.nomeProduto }}</b></span>
                            <span style="font-size: 10px"> EAN: {{ product.ean }}</span><br />
                            <span style="font-size: 12px"> {{ product.nomeSubgrade }}  R$ {{ product.precoAtual | number: '1.2-2' }}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    
                    <div class="input-control" style="width: 10%;">
                        <label class="input-label" for="produto-quantidade-{{ i }}">Quantidade</label>
                        <input type="number" id="produto-quantidade-{{ i }}" formControlName="quantidade"
                            placeholder="1" class="input-field" (input)="changeValue(i)" />
                    </div>
                    <div class="input-control" style="width: 15%;"
                        *ngIf="produto.get('isValue')?.value == true">
                        <label class="input-label" for="produto-valor-desconto-{{ i }}">Desconto R$</label>
                        <div class="field-discount">
                            <input type="number" id="produto-valor-desconto-{{ i }}"
                                formControlName="valorDesconto" placeholder="R$ 0,00" class="input-field"
                                (input)="changeValue(i)" />
                            <div matSuffix class="currency-box" (click)="changeType(i)"><b>$</b></div>
                        </div>
                    </div>
                    <div class="input-control" style="width: 15%;"
                        *ngIf="produto.get('isValue')?.value == false">
                        <label class="input-label" for="produto-porcentagem-desconto-{{ i }}">Desconto %</label>
                        <div class="field-discount">
                            <input type="number" id="produto-porcentagem-desconto--{{ i }}"
                                formControlName="porcentagemDesconto" placeholder="0%" class="input-field"
                                min="-99" max="99" (input)="changeValue(i)" />
                            <div matSuffix class="currency-box" (click)="changeType(i)"><b>%</b></div>
                        </div>
                    </div>
                    <div class="input-control" style="width: 15%;">
                        <label class="input-label" for="produto-valor-{{ i }}">Valor</label>
                        <input type="text" id="produto-valor-{{ i }}" formControlName="valor"
                            placeholder="R$ 0,00" class="input-field" 
                            [value]="formatMoney(produto.get('valor')?.value)"/>
                    </div>
                    <div class="input-control" style="margin-bottom: 2px; width: 15%;">
                        <button (click)="removerProduto(i)" class="warning-btn">EXCLUIR</button>
                    </div>
                </div>
            </div>
            <div class="form-flexed">
                <div class="input-control" style="margin-bottom: 2px; width: 15%;">
                    <button type="button" (click)="adicionarProduto()" class="main-btn">
                        NOVO PRODUTO
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="form-flex" style="padding-top: 18px;">
        <div class="form-grouped">
            <div class="form-group">
                <div class="input-control">
                    <label class="input-label" for="subTotal">SubTotal em produtos</label>
                    <input 
                        matInput 
                        disabled
                        id="subTotal" 
                        type="text" 
                        [ngClass]="model.get('subTotal')?.touched && model.get('subTotal')?.invalid ? 'input-failed' : 'input-field'"
                        placeholder="R$ 0,00"
                        [value]="formatMoney(model.get('subTotal')?.value)"
                    />
                </div>
            </div>
        </div>
    </div>
</form>