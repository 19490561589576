<form [formGroup]="model" class="estoque-wrapper" novalidate submit="submit()">
    
    <div class="row" style="padding-bottom: 25px; margin-top: -10px">
        <div class="col-12 col-md-6 input-space">
            <label class="input-label" for="quantidadeBase">Estoque base</label>
            <input matInput id="quantidadeBase" formControlName="quantidadeBase" placeholder="Preço base" class="input-field" type="number" 
            [ngClass]="model.get('quantidadeBase')?.touched && model.get('quantidadeBase')?.invalid ? 'input-failed' : 'input-field'" 
            placeholder="Informe a quantidade base" 
            >
            <span *ngIf="model.get('quantidadeBase')?.touched && model.get('quantidadeBase')?.invalid">Informe o preço base do produto.</span>
        </div>
        <div class="col-12 col-md-6 input-space">
            <label class="input-label" for="precoBase">Preço base</label>
            <input matInput id="precoBase" formControlName="precoBase" placeholder="Preço base" class="input-field" type="number" 
            [ngClass]="model.get('precoBase')?.touched && model.get('precoBase')?.invalid ? 'input-failed' : 'input-field'" 
            placeholder="Informe o preço base" 
            >
            <span *ngIf="model.get('precoBase')?.touched && model.get('precoBase')?.invalid">Informe o preço base do produto.</span>
        </div>    
    </div>
    <div formArrayName="listProdutoGradeStruct">
        <mat-accordion>
            <mat-expansion-panel
            *ngFor="let produtoGradeStruct of getListProdutoGradeStruct(model); let i = index"
            [formGroupName]="i"
            class="custom-expansion"
            [expanded]="i === 0"  >
            <mat-expansion-panel-header style="height: auto">
                <div class="row custom-header">
                <mat-panel-title class="col-12 col-sm-8 col-md-10 row">
                    <label class="input-label col-12" for="nomeGrade">Grade </label>
                    <input
                    matInput
                    formControlName="nomeGrade"
                    placeholder="Nome da grade"
                    type="text"
                    class="input-field col-12"
                    [value]="produtoGradeStruct.get('nomeGrade').value"
                    />
                    </mat-panel-title>
                <div class="excluir-grade col-12 col-sm-4 col-md-2">
                    <button class="warning-btn" style="height: 44px" (click)="removeListNovaSubgradeStruct(i)">
                    Excluir
                    </button>
                </div>
                </div>
            </mat-expansion-panel-header>
            <div formArrayName="listNovaSubgradeStruct">
                <div class="flexed-wrapper" style="padding: 10px;" *ngFor="let novaSubgradeStruct of getListNovaSubgradeStruct(produtoGradeStruct); let j = index">
                <div [formGroupName]="j" style="margin-top: -25px; margin-bottom: -10px;">
                    <div class="row">
                    <div class="col-11 row">
                        <div class="col-12 col-sm-3 col-md-4 input-space">
                        <label class="input-label" for="nomeSubgrade">Nome da subgrade</label>
                        <input
                            matInput
                            formControlName="nomeSubgrade"
                            placeholder="Nome da subgrade"
                            class="input-field"
                            type="text"
                        />
                        </div>
                        <div class="col-6 col-sm-3 col-md-3 input-space">
                        <label class="input-label" for="precoAtual">Preço</label>
                        <input matInput formControlName="precoAtual" placeholder="Preço" class="input-field" type="number" />
                        </div>
                        <div class="col-6 col-sm-3 col-md-3 input-space">
                        <label class="input-label" for="quantidadeAtual">Estoque</label>
                        <input
                            matInput
                            formControlName="quantidadeAtual"
                            (input)="atualizarQuantidadeBase()"
                            placeholder="Quant. estoque"
                            class="input-field"
                            type="number"
                        />
                        </div>
                        <div class="col-12 col-sm-3 col-md-2 input-space">
                        <label class="input-label" for="corHex">Cor</label>
                        <input matInput formControlName="corHex" placeholder="Cor" class="input-field" style="height: 43px;" type="color" />
                        </div>
                    </div>
                    <div
                        class="col-1"
                        style="
                        display: flex;
                        align-items: flex-start;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: center;
                        "
                    >
                        @if(getListNovaSubgradeStruct(produtoGradeStruct).length > 1){
                        <mat-icon (click)="removeNovaSubgradeStruct(i, j)" *ngIf="j > 0" style="color: var(--warning-200);">delete</mat-icon>
                        }
                        <mat-icon (click)="addListNovaSubgradeStruct(i, null, j)" style="color: var(--main-100);">add</mat-icon>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="form-grouped-button" style="padding: 10px;display: flex; align-items: center; flex-direction: row; justify-content: space-around;">
            <div class="form-group">
                <div class="input-control" style="margin-bottom: 2px; max-width: 200px;">
                    <button type="button" (click)="addListProdutoGradeStruct(null, null)" class="cancel-btn">
                        Adicionar grade
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>