<div class="main-container">
    <app-subheader [icon]="iconProfile" [title]="'Dashboard de pedidos'" [description]="'Acompanhe e gerencie seus pedidos'" [isLoading]="orchestratorGatilhoService.isLoading" [isAddPage]="false" [isEditPage]="false"></app-subheader>
    <div class="view-switcher">
        <button class="icon-button" [ngClass]="{ 'active': !isEditMode }" [matTooltip]="'Modo de trabalho'" matTooltipPosition="above" (click)="setWorkMode()">
            <mat-icon>work</mat-icon>
        </button>
        <button class="icon-button" [ngClass]="{ 'active': isEditMode }" [matTooltip]="'Modo edição'" matTooltipPosition="above" (click)="setEditMode()">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
    <div *ngIf="orchestratorGatilhoService.isLoading && !isEditMode" class="dashboard-action">
        <app-skeleton variant="block" shape="card" width="180px" height="51px"></app-skeleton>
    </div>
    <div *ngIf="orchestratorGatilhoService.isLoading" class="columns-wrapper">
        <app-skeleton *ngFor="let placeholder of [1, 2, 3, 4, 5, 6]" variant="block" shape="card" width="270px" height="189px"></app-skeleton>
    </div>
    <div *ngIf="!orchestratorGatilhoService.isLoading && !isEditMode" class="dashboard-action">
        <button class="main-btn" (click)="newItem()">NOVO PEDIDO</button>
    </div>
    <div *ngIf="!orchestratorGatilhoService.isLoading && isEditMode" class="dashboard-action">
        <button class="main-btn" (click)="isModalOpen = true">NOVO FLUXO</button>
    </div>
    <!-- itera sob os fluxos -->
    <ng-container *ngFor="let dashboard of dashboards">
        <app-dashboard-list [dashboard]="dashboard" [isEditMode]="isEditMode"></app-dashboard-list>
    </ng-container>
</div>

<div *ngIf="isModalOpen" class="modal-overlay">

    <div class="modal-content">
        <div class="modal-header">
            <h2>Criando um novo fluxo</h2>
        </div>
        <div class="modal-subheader">
            <h3 *ngIf="!EditingName" class="dashboard-name">
                {{name}}
            </h3>
            <mat-icon *ngIf="!EditingName" (click)="EditingName = true" class="icon-edit">edit_note</mat-icon>
            <div class="flex-box-main">
                <input *ngIf="EditingName" type="text" class="input-field" [(ngModel)]="name" />
                <button *ngIf="EditingName" class="main-btn" (click)="selectName()">CONFIRMAR</button>
            </div>
        </div>
        <div class="gatilho-wrapper">
            <div *ngIf="opcaoSelecionada" class="gatilho-item">
                <div class="gatilho-card-info">
                    <div>
                        <mat-icon class="gatilho-avatar">{{ opcaoSelecionada.googleIcon }}</mat-icon>
                    </div>
                    <div class="gatilho-info">
                        <h3>{{ opcaoSelecionada.nome }}</h3>
                        <p>{{ opcaoSelecionada.descricao }}</p>
                    </div>
                </div>
                <mat-icon class="icon-delete" (click)="removerOpcao()">delete</mat-icon>
            </div>

            <div *ngIf="alterandoOpcao" class="novo-gatilho-dropdown">
                <div *ngFor="let opcao of opcoesPadrao" (click)="selecionarOpcao(opcao)" class="gatilho-item novo-gatilho">
                    <div class="gatilho-card-info">
                        <div>
                            <mat-icon class="gatilho-avatar">{{ opcao.googleIcon }}</mat-icon>
                        </div>
                        <div class="gatilho-info">
                            <h3>{{ opcao.nome }}</h3>
                            <p>{{ opcao.descricao }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!opcaoSelecionada" class="gatilho-item novo-gatilho" (click)="toggleAlterandoOpcao()">
                <div class="gatilho-card-info">
                    <mat-icon class="gatilho-avatar">add</mat-icon>
                    <div class="gatilho-info">
                        <h3>Escolher template</h3>
                        <p>Clique para escolher um template inicial</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal-action">
            <button class="cancel-btn" (click)="cancelModal()">FECHAR</button>
            <button class="main-btn" (click)="confirmModal()">SALVAR</button>
        </div>

    </div>

</div>