import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { UtilService } from '../../../shared/services/util.service';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { FornecedorRequest } from '../../../shared/services/requests/pessoa/fornecedor.request';
import { catchError, of } from 'rxjs';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { TipoFornecedorEnum } from '../../../shared/enum/Fornecedor/tipo-fornecedor.enum';
import { FornecedorResponse } from '../../../shared/services/responses/pessoa/fornecedor.response';
import { RoutesService } from '../../../routes.service';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { TipoFornecedor } from '../../../shared/services/models/pessoa/tipo-fornecedor.model';
import { TipoFornecedorResponse } from '../../../shared/services/responses/pessoa/tipo-fornecedor.response';

@Component({
  selector: 'app-fornecedor-novo',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SubheaderComponent,
    SkeletonComponent,
  ],
  templateUrl: './fornecedor-novo.component.html',
  styleUrl: './fornecedor-novo.component.css',
})
export class FornecedorNovoComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  private cepTimeout: any;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) { }
  public model: FormGroup;
  public requestUpdate: FornecedorRequest = new FornecedorRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idFornecedor: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Fornecedores';
  public iconProfile: string = 'home_repair_service';
  public profile: number = DefaultProfilesEnum.Fornecedor;
  public step: number = 1;
  public isDropdownOpen = false;
  public listSupplierType: TipoFornecedor[] = [];
  @ViewChild('numeroEndereco') numeroEndereco: ElementRef;
  ngOnInit(): void {
    this.isLoading = true;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required],
      razaoSocial: [''],
      email: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      tipoFornecedor: [''],
      idTipoFornecedor: ['', Validators.required],
      telefone: [''],
      telefone2: [''],
      nascimento: [''],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      complemento: [''],
      localidade: [''],
      uf: [''],
      pais: [''],
      contatos: this.formBuilder.array([
        this.formBuilder.group({
          nome: [''],
          telefone: [''],
          email: [''],
        }),
      ]),
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    let idFornecedor: string =
      this.activatedRoute.snapshot.params['idFornecedor'];
    if (idFornecedor) {
      this._idFornecedor = Number(idFornecedor);
      this.getSupplier();
    } 

    this.searchSupplierType();
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else if (this._idFornecedor) {
      if (this.model.get('nome')?.value)
        this.requestUpdate.nome = this.model.get('nome')?.value;
      if (this.model.get('razaoSocial')?.value)
        this.requestUpdate.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value)
        this.requestUpdate.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value)
        this.requestUpdate.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('idTipoFornecedor')?.value)
        this.requestUpdate.idTipoFornecedor = this.model.get('idTipoFornecedor')?.value;
      if (this.model.get('telefone')?.value)
        this.requestUpdate.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value)
        this.requestUpdate.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value)
        this.requestUpdate.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value)
        this.requestUpdate.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value)
        this.requestUpdate.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value)
        this.requestUpdate.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value)
        this.requestUpdate.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value)
        this.requestUpdate.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value)
        this.requestUpdate.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value)
        this.requestUpdate.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value)
        this.requestUpdate.rua = this.model.get('rua')?.value;
      if (this.model.get('bairro')?.value)
        this.requestUpdate.bairro = this.model.get('bairro')?.value;
      if (this.model.get('complemento')?.value)
        this.requestUpdate.complemento = this.model.get('complemento')?.value;
      if (this.model.get('pais')?.value)
        this.requestUpdate.pais = this.model.get('pais')?.value;

      // Processa contatos do FormArray
      const contatos = this.model.get('contatos')?.value || [];
      this.requestUpdate.contatos = contatos.map((contato: any) => ({
        nome: contato.nome,
        email: contato.email,
        telefone: contato.telefone,
      }));

      this.update();
    } else {
      const fornecedorRequest = new FornecedorRequest();
      if (this.model.get('nome')?.value)
        fornecedorRequest.nome = this.model.get('nome')?.value;
      if (this.model.get('razaoSocial')?.value)
        fornecedorRequest.razaoSocial = this.model.get('razaoSocial')?.value;

      if (this.isJuridicPerson()) {
        if (this.model.get('nome')?.value)
          fornecedorRequest.razaoSocial = this.model.get('nome')?.value;
      }
      if (this.model.get('email')?.value)
        fornecedorRequest.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value)
        fornecedorRequest.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('idTipoFornecedor')?.value)
        fornecedorRequest.idTipoFornecedor = this.model.get('idTipoFornecedor')?.value;
      if (this.model.get('telefone')?.value)
        fornecedorRequest.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value)
        fornecedorRequest.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value)
        fornecedorRequest.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value)
        fornecedorRequest.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value)
        fornecedorRequest.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value)
        fornecedorRequest.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value)
        fornecedorRequest.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value)
        fornecedorRequest.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value)
        fornecedorRequest.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value)
        fornecedorRequest.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value)
        fornecedorRequest.rua = this.model.get('rua')?.value;
      if (this.model.get('complemento')?.value)
        fornecedorRequest.complemento = this.model.get('complemento')?.value;
      if (this.model.get('bairro')?.value)
        fornecedorRequest.bairro = this.model.get('bairro')?.value;
      if (this.model.get('pais')?.value)
        fornecedorRequest.pais = this.model.get('pais')?.value;

      // Processa contatos do FormArray
      const contatos = this.model.get('contatos')?.value || [];
      fornecedorRequest.contatos = contatos.map((contato: any) => ({
        nome: contato.nome,
        email: contato.email,
        telefone: contato.telefone,
      }));

      this.register(fornecedorRequest);
    }
  }

  nextStep() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      return;
    }
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= 2) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  isJuridicPerson() {
    return (
      String(this.model.get('cpfCnpj')?.value).replace(/[.-]/g, '').length > 11
    );
  }

  get contatos(): FormArray {
    return this.model.get('contatos') as FormArray;
  }

  adicionarContato(): void {
    const contato = this.formBuilder.group({
      nome: [''],
      telefone: [''],
      email: [''],
    });
    this.contatos.push(contato);
  }

  removerContato(index: number): void {
    this.contatos.removeAt(index);
  }

  register(supply: FornecedorRequest) {
    this.pessoaService.supplyRegister(supply).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Fornecedor registrado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_SUPPLIER]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  update() {
    this.pessoaService.supplyUpdate(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Fornecedor atualizado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_SUPPLIER]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getSupplier() {
    this.pessoaService
      .searchSupplierById(Number(this._idFornecedor))
      .subscribe({
        next: (response: FornecedorResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.requestUpdate.idFornecedor = response.fornecedor.idFornecedor;
          this.requestUpdate.idTipoFornecedor =
            response.fornecedor.idTipoFornecedor;
          this.requestUpdate.idPessoa = response.fornecedor.idPessoa;
          this.model.get('nome')?.setValue(response.pessoa.nome);
          this.model.get('razaoSocial')?.setValue(response.pessoa.razaoSocial);
          this.model.get('email')?.setValue(response.pessoa.email);
          this.model.get('cpfCnpj')?.setValue(response.pessoa.cpfCnpj);
          this.model.get('idTipoFornecedor')?.setValue(response.fornecedor.idTipoFornecedor);
          this.model.get('tipoFornecedor')?.setValue(TipoFornecedorEnum[response.fornecedor.idTipoFornecedor]);
          this.model.get('telefone')?.setValue(response.pessoa.telefone);
          this.model.get('telefone2')?.setValue(response.pessoa.telefone2);
          if (response.pessoa.nascimento) {
            var date = response.pessoa.nascimento.toString();
            date = date.split('T')[0];
            this.model.get('nascimento')?.setValue(date);
          }
          this.model.get('latitude')?.setValue(response.pessoa.latitude);
          this.model.get('longitude')?.setValue(response.pessoa.longitude);
          this.model.get('cep')?.setValue(response.pessoa.cep);
          this.model.get('logradouro')?.setValue(response.pessoa.logradouro);
          this.model.get('cidade')?.setValue(response.pessoa.cidade);
          this.model.get('numero')?.setValue(response.pessoa.numero);
          this.model.get('uf')?.setValue(response.pessoa.estado);
          this.model.get('rua')?.setValue(response.pessoa.logradouro);
          this.model.get('bairro')?.setValue(response.pessoa.bairro);
          this.model.get('complemento')?.setValue(response.pessoa.complemento);
          this.model.get('pais')?.setValue(response.pessoa.pais);
          this.model.enable();
          this.model.get('email')?.disable();
          this.model.get('cpfCnpj')?.disable();
          this.applyMasks(this.model.get('cpfCnpj')?.value);
        },
        error: (error) => {
          this.alertService.show(
            'Erro inesperado',
            'Erro ao buscar cep',
            AlertType.error
          );
          this.isLoading = false;
          return;
        },
      });
  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {
      this.utilService
        .getAddressByCEP(cep)
        .pipe(
          catchError((error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            this.model.get('logradouro')?.enable();
            this.model.get('complemento')?.enable();
            this.model.get('bairro')?.enable();
            this.model.get('uf')?.enable();
            this.model.get('pais')?.enable();
            this.model.get('cidade')?.enable();
            this.model.get('numero')?.enable();
            return of(null);
          })
        )
        .subscribe((data) => {
          if (data) {
            this.model.patchValue({
              logradouro: data.logradouro,
              complemento: data.complemento,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf,
              pais: 'Brasil',
            });
            this.isLoading = false;
          }

          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          this.numeroEndereco.nativeElement.focus();
        });
    }, 2000);
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_SUPPLIER]);
  }

  onCpfCnpjInput(event: any): void {
    const value = event.target.value.replace(/\D/g, '');
    let formattedValue = '';

    if (value.length < 11) {
      formattedValue = value;
    } else if (value.length == 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cpf);
    } else if (value.length > 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
    }

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  applyMasks(cpfCnpj: string) {
    const value = cpfCnpj.replace(/\D/g, '');
    let formattedValue = '';

    if (value.length < 11) {
      formattedValue = value;
    } else if (value.length == 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cpf);
    } else if (value.length > 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
    }

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  onTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone')?.setValue(formattedTelefone);
  }

  onSecondTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone2')?.setValue(formattedTelefone);
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(
      event.target.value,
      this.masks.cep
    );
    this.model.get('cep')?.setValue(formattedCep);
    if (formattedCep.length > 8) {
      this.searchCep(formattedCep);
    }
  }

  searchSupplierType() {
    this.isLoading = true;
    this.pessoaService.searchTypeSupplier().subscribe({
        next: (response: TipoFornecedorResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.listSupplierType = response.listTipoFornecedor;


          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show(
            'Erro inesperado',
            'Erro ao buscar cep',
            AlertType.error
          );
          this.isLoading = false;
          return;
        },
      });
  }

  toggleDropdown() {
    this.isDropdownOpen = true;
  }

  selectSupplier(supplier: any) {
    this.isDropdownOpen = false;
    this.model.get('idTipoFornecedor')?.setValue(supplier.idTipoFornecedor);
    this.model.get('tipoFornecedor')?.setValue(supplier.nomeTipoFornecedor);
  }

  @HostListener('document:click', ['$event'])
  fecharDropdown(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.custom-select')) {
      this.isDropdownOpen = false;
    }
  }
}
