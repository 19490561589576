import { Injectable } from '@angular/core';
import { SubPerfilEnum } from './shared/enum/default-profiles.enum';
import { ClienteListaComponent } from './cliente/pages/cliente-lista/cliente-lista.component';
import { ContasPagarListaComponent } from './financial/pages/contas-pagar-lista/contas-pagar-lista.component';
import { PerfilImpostoListaComponent } from './fiscal/perfil-imposto-lista/perfil-imposto-lista.component';
import { EstoqueListaComponent } from './estoque/pages/estoque-lista/estoque-lista.component';
import { FornecedorListaComponent } from './fornecedores/pages/fornecedor-lista/fornecedor-lista.component';
import { TransportadorListaComponent } from './fornecedores/pages/transportador-lista/transportador-lista.component';
import { UsuarioListaComponent } from './config/pages/usuario-lista/usuario-lista.component';
import { BancoListaComponent } from './config/pages/banco-lista/banco-lista.component';
import { MovementacaoListaComponent } from './estoque/pages/movimentacao-lista/movimentacao-lista.component';
import { DashboardOrdersComponent } from './vendas/pages/dashboard-orders/dashboard-orders.component';
import { CaixaListaComponente } from './financial/pages/caixa-lista/caixa-lista.component';
import { MeusDadosComponent } from './config/pages/meus-dados/meus-dados.component';
import { MinhaEmpresaComponent } from './config/pages/minha-empresa/minha-empresa.component';
import { ContasReceberListaComponent } from './financial/pages/contas-receber-lista/contas-receber-lista.component';
import { MensagemTributariaListaComponent } from './fiscal/mensagem-tributaria-lista/mensagem-tributaria-lista.component';
import { PerfilCestListaComponent } from './fiscal/perfil-cest-lista/perfil-cest-lista.component';
import { FluxoCaixaComponent } from './financial/pages/fluxo-caixa/fluxo-caixa.component';
import { ClienteNovoComponent } from './cliente/pages/cliente-novo/cliente-novo.component';
import { ContasPagarNovoComponent } from './financial/pages/contas-pagar-novo/contas-pagar-novo.component';
import { PerfilImpostoNovoComponent } from './fiscal/perfil-imposto-novo/perfil-imposto-novo.component';
import { EstoqueNovoComponent } from './estoque/pages/estoque-novo/estoque-novo.component';
import { FornecedorNovoComponent } from './fornecedores/pages/fornecedor-novo/fornecedor-novo.component';
import { UsuarioNovoComponent } from './config/pages/usuario-novo/usuario-novo.component';
import { BancoNovoComponent } from './config/pages/banco-novo/banco-novo.component';
import { PedidosNovoComponent } from './vendas/pages/pedidos-novo/pedidos-novo.component';
import { CaixaNovoComponent } from './financial/pages/caixa-novo/caixa-novo.component';
import { ContasReceberNovoComponent } from './financial/pages/contas-receber-novo/contas-receber-novo.component';
import { MensagemTributariaNovoComponent } from './fiscal/mensagem-tributaria-novo/mensagem-tributaria-novo.component';
import { PerfilCestNovoComponent } from './fiscal/perfil-cest-novo/perfil-cest-novo.component';
import { PerfilPisCofinsNovoComponent } from './fiscal/perfil-pis-cofins-novo/perfil-pis-cofins-novo.component';
import { Router, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { AuthComponent } from './auth/pages/auth/auth.component';
import { PasswordChangeComponent } from './auth/pages/password-change/password-change.component';
import { PasswordRecoveryComponent } from './auth/pages/password-recovery/password-recovery.component';
import { HomeComponent } from './home/home.component';
import { UserLoginResponse } from './shared/services/responses/auth/user-login.response';
import { PerfilPisCofinsListaComponent } from './fiscal/perfil-pis-cofins-lista/perfil-pis-cofins-lista.component';


const subPerfis = [
  { idSubperfil: SubPerfilEnum.Clientes, lista: ClienteListaComponent, form: ClienteNovoComponent, idParam: 'idCliente' },
  { idSubperfil: SubPerfilEnum.ContasPagar, lista: ContasPagarListaComponent, form: ContasPagarNovoComponent, idParam: 'idContasPagar' },
  { idSubperfil: SubPerfilEnum.PerfilImposto, lista: PerfilImpostoListaComponent, form: PerfilImpostoNovoComponent, idParam: 'idPerfilImposto' },
  { idSubperfil: SubPerfilEnum.Produtos, lista: EstoqueListaComponent, form: EstoqueNovoComponent, idParam: 'idProduto' },
  { idSubperfil: SubPerfilEnum.Fornecedores, lista: FornecedorListaComponent, form: FornecedorNovoComponent, idParam: 'idFornecedor' },
  { idSubperfil: SubPerfilEnum.Transportadoras, lista: TransportadorListaComponent, form: FornecedorNovoComponent, idParam: 'idTransportadora' },
  { idSubperfil: SubPerfilEnum.Usuarios, lista: UsuarioListaComponent, form: UsuarioNovoComponent, idParam: 'idUsuario' },
  { idSubperfil: SubPerfilEnum.Bancos, lista: BancoListaComponent, form: BancoNovoComponent, idParam: 'idBanco' },
  { idSubperfil: SubPerfilEnum.Movimentacoes, lista: MovementacaoListaComponent, form: null, idParam: null }, // Sem edição
  { idSubperfil: SubPerfilEnum.Pedidos, lista: DashboardOrdersComponent, form: PedidosNovoComponent, idParam: 'idPedido' },
  { idSubperfil: SubPerfilEnum.Caixas, lista: CaixaListaComponente, form: CaixaNovoComponent, idParam: 'idCaixa' },
  { idSubperfil: SubPerfilEnum.MeusDados, lista: MeusDadosComponent, form: null, idParam: null }, // Sem edição
  { idSubperfil: SubPerfilEnum.MinhaEmpresa, lista: MinhaEmpresaComponent, form: null, idParam: null }, // Sem edição
  { idSubperfil: SubPerfilEnum.ContasReceber, lista: ContasReceberListaComponent, form: ContasReceberNovoComponent, idParam: 'idContasReceber' },
  { idSubperfil: SubPerfilEnum.MensagemTributaria, lista: MensagemTributariaListaComponent, form: MensagemTributariaNovoComponent, idParam: 'idMensagemTributaria' },
  { idSubperfil: SubPerfilEnum.ImpostoCest, lista: PerfilCestListaComponent, form: PerfilCestNovoComponent, idParam: 'idImpostoCest' },
  { idSubperfil: SubPerfilEnum.NaturezaReceita, lista: PerfilPisCofinsListaComponent, form: PerfilPisCofinsNovoComponent, idParam: 'idPerfilPisCofins' },
  { idSubperfil: SubPerfilEnum.FluxoCaixa, lista: FluxoCaixaComponent, form: null, idParam: null }, // Sem edição
];


@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  // Rotas padrão
  private defaultRoutes: Routes = [
    {
      path: '',
      redirectTo: 'auth',
      pathMatch: 'full'
    },
    {
      path: 'auth',
      component: AuthComponent,
      data: { menu: false, search: false }
    },
    {
      path: 'home',
      component: HomeComponent,
      canActivate: [AuthGuard],
      data: { menu: true, search: false }
    },
    {
      path: 'password-recovery',
      component: PasswordRecoveryComponent,
      data: { menu: false, search: false }
    },
    {
      path: 'change-password/:token',
      component: PasswordChangeComponent,
      data: { menu: false, search: false }
    }
  ];
  
  constructor(private router: Router) {}

  generateRoutes(userInfo: UserLoginResponse): Routes {
    const listSubperfil = userInfo.usuarioPermissionStruct?.listSubPerfilStruct || [];
    const dynamicRoutes = subPerfis.map(({ idSubperfil, lista, form, idParam }) => {
      const subperfil = listSubperfil.find((sp: any) => sp.idSubperfil === idSubperfil);
      const path = subperfil ? subperfil.redirectSubperfil : ''; // se não existir, retorna string vazia ou path padrão

      return {
        path,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: lista, data: { menu: true, search: true, idSubperfil } },
          ...(form ? [
            { path: 'novo', component: form, data: { menu: true, search: true, idSubperfil } },
            { path: `editar/:${idParam}`, component: form, data: { menu: true, search: true, idSubperfil } }
          ] : [])
        ]
      };
    });
    return dynamicRoutes;
  }

  resetRoutes(userInfo: UserLoginResponse): void {
    const dynamicRoutes = this.generateRoutes(userInfo);
    const updatedRoutes: Routes = [
      ...this.defaultRoutes,
      ...dynamicRoutes
    ];
    this.router.resetConfig(updatedRoutes);
  }

  getDefaultRoutes(): Routes {
    return this.defaultRoutes;
  }

  readonly HOME = 'home';
  readonly PASSWORD_RECOVERY = 'password-recovery';
  readonly LIST_CLIENTS = 'cliente/clientes';
  readonly CLIENT_REGISTER = 'cliente/clientes/novo';
  readonly LIST_SUPPLIER = 'fornecedor/fornecedores';
  readonly SUPPLY_REGISTER = 'fornecedor/fornecedores/novo';
  readonly LIST_INVENTORY = 'estoque/produtos';
  readonly INVENTORY_REGISTER = 'estoque/produtos/novo';
  readonly ADD_PERFIL_IMPOSTO = 'fiscal/perfil-imposto/novo';
  readonly ADD_USER = 'config/usuarios/novo';
  readonly ADD_BANK = 'config/bancos/novo';
  readonly MEUS_DADOS = 'config/meus-dados';
  readonly VENDAS = 'venda/vendas';
  readonly LIST_BILLS_PAY = 'financeiro/contas-pagar';
  readonly BILLS_PAY_REGISTER = 'financeiro/contas-pagar/novo';
  readonly NATUREZA_RECEITA = 'fiscal/perfil-pis-cofins';
  readonly NATUREZA_RECEITA_REGISTER = 'fiscal/perfil-pis-cofins/novo';
  readonly IMPOSTO_CEST = 'fiscal/perfil-cest';
  readonly IMPOSTO_CEST_REGISTER = 'fiscal/perfil-cest/novo';
  readonly MENSAGEM_TRIBUTARIA = 'fiscal/mensagem-tributaria';
  readonly MENSAGEM_TRIBUTARIA_REGISTER = 'fiscal/mensagem-tributaria/novo';
  readonly LIST_TAX_PROFILE = 'fiscal/perfil-imposto';
  readonly TAX_PROFILE_REGISTER = 'fiscal/perfil-imposto/novo';
  readonly LIST_USERS = 'config/usuarios';
  readonly LIST_BANK = 'config/bancos';
  readonly LIST_MOVEMENT = 'estoque/movimentacoes';
  readonly LIST_CAIXA = 'financeiro/caixas';
  readonly CAIXA_REGISTER = 'financeiro/caixas/novo';
  readonly DASHBOARD_PEDIDOS = 'venda/pedidos';
  readonly PEDIDO_REGISTER = 'venda/pedidos/novo';
  readonly LIST_BILLS_RECEIVE = 'financeiro/contas-receber';
  readonly BILLS_RECEIVE_REGISTER = 'financeiro/contas-receber/novo';
  readonly CASH_FLOW = 'financeiro/fluxo-caixa';
  
  // Dinâmicas
  CHANGE_PASSWORD(token: string): string {
    return `change-password/${token}`;
  }

  CLIENT_UPDATE(id: string): string {
    return `cliente/clientes/editar/${id}`;
  }

  SUPPLY_UPDATE(id: string): string {
    return `fornecedor/fornecedores/editar/${id}`;
  }

  INVENTORY_UPDATE(id: string): string {
    return `estoque/produtos/editar/${id}`;
  }

  USER_UPDATE(id: string): string {
    return `config/usuarios/editar/${id}`;
  }

  BANK_UPDATE(id: string): string {
    return `config/bancos/editar/${id}`;
  }

  BILLS_PAY_UPDATE(id: string): string {
    return `financeiro/contas-pagar/editar/${id}`;
  }

  IMPOSTO_CEST_EDIT(id: string): string {
    return `fiscal/perfil-cest/editar/${id}`;
  }

  NATUREZA_RECEITA_EDIT(id: string): string {
    return `fiscal/perfil-pis-cofins/editar/${id}`;
  }

  MENSAGEM_TRIBUTARIA_EDIT(id: string): string {
    return `fiscal/mensagem-tributaria/editar/${id}`;
  }

  TAX_PROFILE_EDIT(id: string): string {
    return `fiscal/perfil-imposto/editar/${id}`;
  }
  
  CAIXA_UPDATE(id: string): string {
    return `financeiro/caixas/editar/${id}`;
  }

  BILLS_RECEIVE_UPDATE(id: string): string {
    return `financeiro/contas-receber/editar/${id}`;
  }
}
