import { CommonModule, ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { RoutesService } from '../../../routes.service';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { ExhibitEnum } from '../../../shared/enum/exhibit.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { AuthService } from '../../../shared/services/auth.service';
import { StatusConta } from '../../../shared/services/models/financeiro/status-conta.model';
import { ContasPagarSearchRequest } from '../../../shared/services/requests/financeiro/contas-pagar-search.request';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ListContasPagarResponse } from '../../../shared/services/responses/financeiro/list-contas-pagar.response';
import { StatusContaResponse } from '../../../shared/services/responses/financeiro/status-conta.response';
import { ListExhibitStruct } from '../../../shared/services/structs/list-exhibit.struct';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { Router } from '@angular/router';
import { SkeletonComponent } from "../../../shared/component/skeleton/skeleton.component";
import { SubheaderComponent } from "../../../shared/component/subheader/subheader.component";
import { ViewSwitcherComponent } from "../../../shared/component/view-switcher/view-switcher.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ListContasReceberResponse } from '../../../shared/services/responses/financeiro/list-contas-receber.response';

@Component({
  selector: 'app-contas-receber-lista',
  standalone: true,
  imports: [MatIconModule,
            MatProgressSpinnerModule,
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            SubheaderComponent,
            MatFormFieldModule,
            MatSelectModule,
            ViewSwitcherComponent,
            SkeletonComponent,
            AdvancedTableComponent,],
  templateUrl: './contas-receber-lista.component.html',
  styleUrl: './contas-receber-lista.component.css'
})
export class ContasReceberListaComponent implements OnInit {
  @ViewChild(AdvancedTableComponent) exhibit: AdvancedTableComponent;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private routesService: RoutesService,
    public financeiroService: FinanceiroService
  ) { }
  public model: FormGroup;
  public color: string = DefaultColorEnum.Cor;
  public dataList: any[];
  public searchTimeout: any;
  public profile: number = DefaultProfilesEnum.Financeiro;
  public tableEnum: number = ExhibitEnum.Tabela;
  public display: ListExhibitStruct = new ListExhibitStruct();
  public listDisplay = this.display.listExhibit;
  public isTable: boolean = true;
  public selectedExhibit: number;
  public listStatus: StatusConta[];
  public isBill: boolean = true;
  tableColumns = [
    {
      key: 'descricao',
      label: 'Descrição',
      sortable: false,
      filterable: true,
      date: false,
    },
    {
      key: 'valorConta',
      label: 'Valor da conta',
      sortable: true,
      filterable: false,
      date: false,
    },
    {
      key: 'nomeStatus',
      label: 'Status',
      sortable: false,
      filterable: false,
      date: false,
    },
    {
      key: 'dataEmissao',
      label: 'Emissão',
      sortable: true,
      filterable: false,
      date: true,
    },
  ];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterName: [''],
      filterDate: [''],
      filters: [],
      currentPage: [0],
      totalPages: [0],
    });

    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);

    this.searchStatus();
    this.searchBillAccount();
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchBillAccount();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchBillAccount();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  searchBillAccount() {
    this.isLoading = true;
    const searchRequest: ContasPagarSearchRequest =
      new ContasPagarSearchRequest();
    if (this.model.get('filters')?.value) {
      searchRequest.filters = this.model.get('filters')?.value;
      this.model.get('currentPage')?.setValue(0);
    }

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value) {
      this.model.get('currentPage')?.setValue(0);
      const [startDateString, endDateString] = this.model
        .get('filterDate')
        ?.value.split('|');
      searchRequest.dataInicio = startDateString;
      searchRequest.dataFim = endDateString;
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.financeiroService.searchBillsReceive(searchRequest).subscribe({
        next: (response: ListContasReceberResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.dataList = response.listContasReceber;
          this.model.get('totalPages')?.setValue(response.pageSize);
          setTimeout(() => {
            this.update();
          }, 3000);
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }, 10);
  }

  update() {
    this.exhibit.update();
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  add() {
    this.router.navigate([this.routesService.BILLS_RECEIVE_REGISTER]);
  }

  exhibitActions(emitted: any) {
    if (emitted.action === 'search') {
      // this.model.get('filterName')?.setValue(emitted.search);
      this.model.get('filters')?.setValue(emitted.filters);
      this.searchBillAccount();
    } else if (emitted.action === 'edit') {
      this.router.navigate([
        this.routesService.BILLS_RECEIVE_UPDATE(emitted.row.idContasReceber),
      ]);
    } else if (emitted.action === 'dateSearch') {
      if (emitted.dateSearch.date != null) {
        this.model.get('filterDate')?.setValue(emitted.dateSearch.date);
      } else {
        this.model.get('filterDate')?.setValue(null);
      }
      this.searchBillAccount();
    } else if (emitted.action === 'remove') {
      const modalRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title: 'Atenção!',
          description: 'Deseja excluir a conta? Esta ação é irreversivel.',
          buttonConfirm: 'Excluir',
          buttonClose: 'Cancelar',
        },
        hasBackdrop: true,
        disableClose: false,
      });

      modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
        if (result.confirm) {
          this.isLoading = true;
          this.financeiroService
            .billsPayRemove(emitted.row.idContasPagar)
            .subscribe({
              next: (response: ReturnStruct) => {
                if (response.isError) {
                  this.alertService.show(
                    'Erro inesperado',
                    response.errorDescription,
                    AlertType.error
                  );
                  this.isLoading = false;
                  return;
                }
                this.isLoading = false;
                this.alertService.show(
                  'Sucesso!',
                  'Cliente excluido',
                  AlertType.success
                );
                this.searchBillAccount();
              },
              error: (error) => {
                this.alertService.show(
                  'Erro inesperado',
                  error,
                  AlertType.error
                );
                this.isLoading = false;
                return;
              },
            });
        } else {
          this.isLoading = false;
          return;
        }
      });
    }
  }
  onExhibitChange(event: any) {
    if (event == this.tableEnum) {
      this.isTable = true;
    } else {
      this.isTable = false;
    }
  }

  onStatusChange() {
    this.model.get('currentPage')?.setValue(0);
    this.searchBillAccount();
  }

  searchStatus() {
    this.isLoading = true;
    this.financeiroService.searchStatus().subscribe({
      next: (response: StatusContaResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          return;
        }
        this.listStatus = response.listStatusConta;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }
}

