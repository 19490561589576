<div class="main-container">
    <app-subheader [icon]="'currency_exchange'" [title]="'Fluxo De Caixa'" [description]="'Seu fluxo de caixa'"
        [isLoading]="isLoading" [isAddPage]="false" [isEditPage]="false"></app-subheader>
    <div class="container-separation">
        <div class="container-flex">
            <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="45px" height="45px"></app-skeleton>
            <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="45px" height="45px"></app-skeleton>
        </div>
        <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="100%" height="300px"></app-skeleton>
        <div class="calendar-container" *ngIf="!isLoading">
            <div class="flow">
                <div class="cash-flow">
                    <div class="flow-initial">
                        <div class="span-space">
                            <span class="flow-titles span-value basic-color">SALDO INICIAL</span>
                        </div>

                        <div class="span-space">
                            <span class="flow-titles span-value in">RECEITAS
                                <div class="item" *ngIf="maxExpanse > 0">
                                    <div class="item-place">
                                        <div class="item-values">
                                            <button *ngIf="!isExpandRevenue" class="expansion-button" (click)="expandRevenue()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                            <button *ngIf="isExpandRevenue" class="expansion-button" (click)="expandRevenue()"><mat-icon>keyboard_arrow_up</mat-icon></button>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <ng-container *ngIf="isExpandRevenue">
                            <div class="span-space-item" *ngFor="let _ of [].constructor(maxRevenue); let i = index">
                                <span class="flow-titles-item span-value-item in"><b>RECEITAS</b></span>
                            </div>
                        </ng-container>
                        <div class="span-space">
                            <span class="flow-titles span-value out">DESPESAS
                                <div class="item" *ngIf="maxRevenue > 0">
                                    <div class="item-place">
                                        <div class="item-values">
                                            <button *ngIf="!isExpandExpense" class="expansion-button" (click)="expandExpense()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                            <button *ngIf="isExpandExpense" class="expansion-button" (click)="expandExpense()"><mat-icon>keyboard_arrow_up</mat-icon></button>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <ng-container *ngIf="isExpandExpense">
                            <div class="span-space-item" *ngFor="let _ of [].constructor(maxExpanse); let i = index">
                                <span class="flow-titles-item span-value-item out">DESPESAS</span>
                            </div>
                        </ng-container>
                        <div class="span-space">
                            <span class="flow-titles span-value basic-color">SALDO FINAL</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="calendar">
                <div class="header-calendar">
                    <button class="calendar-button" (click)="changeDate(-1)"><mat-icon >arrow_back_ios</mat-icon></button>
                        <div class="month-year" (click)="openDatePicker()">
                            <b>{{ currentDate.toLocaleDateString('pt-BR', { month: 'long' }).toUpperCase() }} {{ currentDate.getFullYear() }}</b>
                        </div>
                    <button class="calendar-button" (click)="changeDate(1)"><mat-icon>arrow_forward_ios</mat-icon></button>
                </div>
                <div class="days-container" @slideAnimation>
                    <div class="day-place" *ngFor="let day of daysToShow; let i = index">
                        <div class="day-box">
                            <div class="day-number">{{ day.day }}</div>
                            <div class="day-week">{{ day.weekday }}</div>
                        </div>
                        <div *ngIf="this.fluxoCaixa.listFluxoCaixaStruct.length > 0">
                            <div class="values">
                                <div class="span-space">
                                    <span class="span-value basic-color">R$
                                        {{this.fluxoCaixa.listFluxoCaixaStruct[i].saldoInicial}}</span>
                                </div>
                                <div class="span-space">
                                    <span class="span-value in">R${{this.fluxoCaixa.listFluxoCaixaStruct[i].receitaTotal}}</span>
                                </div>
                                <ng-container *ngIf="this.isExpandRevenue">
                                    <div class="span-space-item" *ngFor="let _ of [].constructor(maxRevenue); let u = index">
                                        <ng-container *ngIf="u < this.fluxoCaixa.listFluxoCaixaStruct[i].listReceita.length">
                                            <span class="span-value-item in">R${{this.fluxoCaixa.listFluxoCaixaStruct[i].listReceita[u].valorReceita}}</span>
                                        </ng-container>
                                        <span class="span-value-item in" *ngIf="u >= this.fluxoCaixa.listFluxoCaixaStruct[i].listReceita.length">R$0.0</span>
                                    </div>
                                </ng-container>
                                <div class="span-space">
                                    <span class="span-value out">R${{this.fluxoCaixa.listFluxoCaixaStruct[i].despesaTotal}}</span>
                                </div>
                                <ng-container *ngIf="this.isExpandExpense">
                                    <div class="span-space-item" *ngFor="let _ of [].constructor(maxExpanse); let u = index">
                                        <ng-container *ngIf="u < this.fluxoCaixa.listFluxoCaixaStruct[i].listDespesa.length">
                                            <span class="span-value-item out">R${{this.fluxoCaixa.listFluxoCaixaStruct[i].listDespesa[u].valorDespesa}}</span>
                                        </ng-container>
                                        <span class="span-value-item out" *ngIf="u >= this.fluxoCaixa.listFluxoCaixaStruct[i].listDespesa.length">R$0.0</span>
                                    </div>
                                </ng-container>
                                <div class="span-space">
                                    <span class="span-value basic-color">R$
                                        {{this.fluxoCaixa.listFluxoCaixaStruct[i].saldoFinal}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>