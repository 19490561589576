<div class="main-container">
  <app-subheader [icon]="'person'" [title]="'Usuários'" [description]="'Sua base de usuários cadastrados'" [isLoading]="isLoading" [isAddPage]="false" [isEditPage]="false"></app-subheader>
  <app-view-switcher *ngIf="!isLoading" (viewChanged)="onExhibitChange($event)"></app-view-switcher>
  <div class="container-separation">
    <div class="container-flex">
      <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="45px" height="45px"></app-skeleton>
      <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="45px" height="45px"></app-skeleton>
    </div>
    <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="100%" height="300px"></app-skeleton>
    <app-advanced-table newItemLink="/config/usuarios/novo" [isLoading]="isLoading" [data]="dataList" [columns]="tableColumns" (valueChanged)="exhibitActions($event)"></app-advanced-table>
  </div>
</div>