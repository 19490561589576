import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from '../../structs/return.struct';
import { ListBancoEmpresaStructResponse } from '../../responses/financeiro/list-banco-empresa-struct.response';
import { ListBancoResponse } from '../../responses/financeiro/list-banco.response';
import { BancoEmpresaSearchRequest } from '../../requests/financeiro/banco-empresa-search.request';
import { BancoRequest } from '../../requests/financeiro/banco.request';
import { BancoResponse } from '../../responses/financeiro/banco.response';
import { ContasPagarRequest } from '../../requests/financeiro/contas-pagar.request';
import { ContasPagarSearchRequest } from '../../requests/financeiro/contas-pagar-search.request';
import { ListContasPagarResponse } from '../../responses/financeiro/list-contas-pagar.response';
import { ContasPagarResponse } from '../../responses/financeiro/contas-pagar.response';
import { StatusContaResponse } from '../../responses/financeiro/status-conta.response';
import { ListCategoriaStructResponse } from '../../responses/financeiro/list-categoria-struct.response';
import { ListBancoEmpresaResponse } from '../../responses/financeiro/list-banco-empresa.response';
import { CaixaSearchResponse } from '../../responses/financeiro/caixa-search.response';
import { CaixaRequest } from '../../requests/financeiro/caixa.request';
import { CaixaSearchRequest } from '../../requests/financeiro/caixa-search.request';
import { CaixaResponse } from '../../responses/financeiro/caixa.response';
import { TipoParcelamentoResponse } from '../../responses/financeiro/tipo-parcelamento.response';
import { TipoPagamentoResponse } from '../../responses/financeiro/tipo-pagamento.response';
import { TipoRepeticaoResponse } from '../../responses/financeiro/tipo-repeticao.response';
import { ContasReceberRequest } from '../../requests/financeiro/contas-receber.request';
import { ContasReceberSearchRequest } from '../../requests/financeiro/contas-receber-search.request';
import { ListContasReceberResponse } from '../../responses/financeiro/list-contas-receber.response';
import { FluxoCaixaRequest } from '../../requests/financeiro/fluxo-caixa.request';
import { FluxoCaixaResponse } from '../../responses/financeiro/fluxo-caixa.response';
import { ContasReceberResponse } from '../../responses/financeiro/contas-receber.response';

@Injectable({
  providedIn: 'root'
})
export class FinanceiroService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  public searchBankEnterprise(bancoEmpresaSearchRequest: BancoEmpresaSearchRequest): Observable<ListBancoEmpresaStructResponse> {

    return this.httpClient.post<ListBancoEmpresaStructResponse>(environment.urlApiFinanceiro + "Banco/List", bancoEmpresaSearchRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchBank(): Observable<ListBancoResponse> {

    return this.httpClient.get<ListBancoResponse>(environment.urlApiFinanceiro + "Banco/List", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchBankEnterpriseById(idBankEnterprise: number): Observable<BancoResponse> {

    return this.httpClient.get<BancoResponse>(environment.urlApiFinanceiro + "Banco/idBanco/" + idBankEnterprise, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public bankEnterpriseRegister(bancoRequest: BancoRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiFinanceiro + "Banco", bancoRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public bankEnterpriseUpdate(bancoRequest: BancoRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiFinanceiro + "Banco", bancoRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchBillsPay(request: ContasPagarSearchRequest): Observable<ListContasPagarResponse> {

    return this.httpClient.post<ListContasPagarResponse>(environment.urlApiFinanceiro + "ContasPagar/Exhibit", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchBillsReceive(request: ContasReceberSearchRequest): Observable<ListContasReceberResponse> {

    return this.httpClient.post<ListContasReceberResponse>(environment.urlApiFinanceiro + "ContasReceber/Exhibit", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchBillsPayById(idContasPagar: number): Observable<ContasPagarResponse> {

    return this.httpClient.get<ContasPagarResponse>(environment.urlApiFinanceiro + "ContasPagar/idContasPagar/" + idContasPagar, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchBillsReceiveById(idContasReceber: number): Observable<ContasReceberResponse> {

    return this.httpClient.get<ContasReceberResponse>(environment.urlApiFinanceiro + "ContasReceber/idContasReceber/" + idContasReceber, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public billsPayRegister(request: ContasPagarRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiFinanceiro + "ContasPagar", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public billsPayUpdate(request: ContasPagarRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiFinanceiro + "ContasPagar", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public billsPayRemove(idContasPagar: number): Observable<ReturnStruct> {
    return this.httpClient.delete<ReturnStruct>(environment.urlApiFinanceiro + "ContasPagar/idContasPagar/" + idContasPagar, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchStatus(): Observable<StatusContaResponse> {

    return this.httpClient.get<StatusContaResponse>(environment.urlApiFinanceiro + "StatusConta", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchCategory(): Observable<ListCategoriaStructResponse> {

    return this.httpClient.get<ListCategoriaStructResponse>(environment.urlApiFinanceiro + "Categoria", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchBankEnterpriseList(): Observable<ListBancoEmpresaResponse> {

    return this.httpClient.get<ListBancoEmpresaResponse>(environment.urlApiFinanceiro + "BancoEmpresa/List", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetCaixaById(idCaixa: Number): Observable<CaixaResponse> {

    return this.httpClient.get<CaixaResponse>(environment.urlApiFinanceiro + "Caixa/idCaixa/" + idCaixa, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public caixaLista(request: CaixaSearchRequest): Observable<CaixaSearchResponse> {

    return this.httpClient.post<CaixaSearchResponse>(environment.urlApiFinanceiro + "CaixaSearch", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  public updateCaixa(request: CaixaRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiFinanceiro + "Caixa", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createCaixa(request: CaixaRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiFinanceiro + "Caixa", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public RemoveCaixa(idCaixa: number): Observable<ReturnStruct> {

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFinanceiro + "Caixa/idCaixa/" + idCaixa, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetTipoParcelamento(): Observable<TipoParcelamentoResponse> {

    return this.httpClient.get<TipoParcelamentoResponse>(environment.urlApiFinanceiro + "TipoParcelamento/", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetTipoPagamento(): Observable<TipoPagamentoResponse> {

    return this.httpClient.get<TipoPagamentoResponse>(environment.urlApiFinanceiro + "TipoPagamento/", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetTipoRepeticao(): Observable<TipoRepeticaoResponse> {

    return this.httpClient.get<TipoRepeticaoResponse>(environment.urlApiFinanceiro + "TipoRepeticao/", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public billsReceiveRegister(request: ContasReceberRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiFinanceiro + "ContasReceber", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public billsReceiveUpdate(request: ContasReceberRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiFinanceiro + "ContasReceber", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public cashFlow(request: FluxoCaixaRequest): Observable<FluxoCaixaResponse> {
    return this.httpClient.post<FluxoCaixaResponse>(environment.urlApiFinanceiro + "FluxoCaixa", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
