import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { CommonModule } from '@angular/common';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { GetFluxoResponse } from '../../../shared/services/responses/fluxo-pedido/get-fluxo.response';
import { MovimentacaoPedidoResponse } from '../../../shared/services/responses/fluxo-pedido/movimentacao-pedido.response';
import { combineLatest } from 'rxjs';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { Router } from '@angular/router';
import { OrchestratorGatilhoService } from '../../../shared/services/API/orchestrator-gatilho/orchestrator-gatilho.service';
import { ListGatilhoResponse } from '../../../shared/services/responses/fluxo-pedido/list-gatilho.response';
import { DashboardListComponent } from '../dashboard-list/dashboard-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';

interface ITemplate {
  googleIcon: string;
  nome: string;
  descricao: string;
}

@Component({
  selector: 'app-dashboard-orders',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    SubheaderComponent,
    CommonModule,
    SkeletonComponent,
    DashboardListComponent,
    MatTooltipModule,
    FormsModule,
  ],
  templateUrl: './dashboard-orders.component.html',
  styleUrl: './dashboard-orders.component.css',
})
export class DashboardOrdersComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public iconProfile: string = 'vendas';
  public getFluxoResponse: GetFluxoResponse;
  public getCardsResponse: MovimentacaoPedidoResponse;
  public getGatilhosResponse: ListGatilhoResponse;
  public listDashboard: any = [];
  public listFinished: any = [];
  public dashboards: any = [];
  isModalOpen: boolean = false;
  public isEditMode = false;

  public opcoesPadrao: ITemplate[] = [];
  public opcaoSelecionada: ITemplate | null;
  public alterandoOpcao: boolean = false;

  EditingName: boolean = true;
  name: string = '';

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public orchestratorGatilhoService: OrchestratorGatilhoService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();

    combineLatest([
      this.orchestratorGatilhoService.fluxoPedido$,
      this.orchestratorGatilhoService.cardsResponse$,
      this.orchestratorGatilhoService.gatilhosResponse$,
    ]).subscribe(([fluxoPedido, cardsResponse, gatilhosResponse]) => {
      if (fluxoPedido && cardsResponse && gatilhosResponse) {
        this.getFluxoResponse = fluxoPedido;
        this.getCardsResponse = cardsResponse;
        this.getGatilhosResponse = gatilhosResponse;
        this.createDashboards();
      }
    });

    this.orchestratorGatilhoService.fetchData();
    this.opcoesPadrao = [
      {
        nome: 'Fluxo padrão',
        descricao: 'Fluxo com início e fim',
        googleIcon: 'person',
      },
    ];
  }

  createDashboards() {
    this.dashboards = this.getFluxoResponse.listFluxoPedidoStruct.map(
      (fluxo) => {
        let nomeFluxoPedido = fluxo.nomeFluxoPedido;
        let idFluxoPedido = fluxo.idFluxoPedido;

        let startItem = fluxo.listFasePedidoStruct
          .filter((_, index) => index === 0) // Mantém o primeiro item
          .map((column) => ({
            ...column,
            itens:
              this.getCardsResponse.listActiveMovimentacaoPedidoStruct.filter(
                (item) => item.idFasePedidoAtual === column.idFasePedido
              ),
            nomeFluxoPedido,
            idFluxoPedido,
          }));

        let items = fluxo.listFasePedidoStruct
          .filter((_, index, array) => index < array.length - 1) // Remove a última coluna
          .filter((_, index) => index !== 0) // Remove o primeiro item
          .map((column) => ({
            ...column,
            itens:
              this.getCardsResponse.listActiveMovimentacaoPedidoStruct.filter(
                (item) => item.idFasePedidoAtual === column.idFasePedido
              ),
            nomeFluxoPedido,
            idFluxoPedido,
          }))
          .sort((a, b) => a.ordem - b.ordem);

        let finished = fluxo.listFasePedidoStruct
          .filter((_, index, array) => index === array.length - 1) // Mantém apenas a última coluna
          .map((column) => ({
            ...column,
            itens: this.getCardsResponse.listFinishMovimentacaoPedidoStruct,
            nomeFluxoPedido,
            idFluxoPedido,
          }));
        return {
          listDashboard: [...startItem, ...items],
          listFinished: finished,
          nomeFluxoPedido,
          idFluxoPedido,
        };
      }
    );
  }

  onExhibitChange(event: any) {
    console.log(event);
  }

  newItem() {
    this.router.navigate(['/venda/pedidos/novo']);
  }

  public setEditMode() {
    this.isEditMode = true;
  }

  public setWorkMode() {
    this.isEditMode = false;
  }

  cancelModal() {
    this.opcaoSelecionada = null;
    this.alterandoOpcao = false;
    this.EditingName = true;
    this.name = '';
    this.isModalOpen = false;
  }

  confirmModal() {
    if (this.name !== '' && this.opcaoSelecionada !== null) {
      if (this.EditingName) {
        this.alertService.show(
          'Ação não permitida',
          'Confirme o campo de nome antes de prosseguir.',
          AlertType.warning
        );
      } else {
        let payload = {
          idFluxoPedido: null,
          nomeFluxoPedido: this.name,
          listFluxoPedidoRequestStruct: [
            {
              idFasePedido: null,
              ordemFasePedido: 1,
              nomeFasePedido: 'Inicio',
              interacaoUsuario: false,
              listGatilhoFasePedidoStruct: [],
              listCampoFaseStruct: [],
            },
            {
              idFasePedido: null,
              ordemFasePedido: 2,
              nomeFasePedido: 'Finalizado',
              interacaoUsuario: false,
              listGatilhoFasePedidoStruct: [],
              listCampoFaseStruct: [],
            },
          ],
          listIdOrigemPedido: [],
        };
        this.orchestratorGatilhoService.postFluxo(
          payload,
          'Fluxo criado com sucesso!'
        );

        this.opcaoSelecionada = null;
        this.alterandoOpcao = false;
        this.EditingName = true;
        this.name = '';
        this.isModalOpen = false;
      }
    } else {
      this.alertService.show(
        'Ação não permitida',
        'Revise os campos antes de prosseguir.',
        AlertType.warning
      );
    }
  }

  selecionarOpcao(opcao: ITemplate) {
    this.opcaoSelecionada = opcao;
    this.toggleAlterandoOpcao();
  }

  removerOpcao() {
    this.opcaoSelecionada = null;
  }

  toggleAlterandoOpcao() {
    this.alterandoOpcao = !this.alterandoOpcao;
  }

  selectName() {
    if (this.name !== '') {
      this.EditingName = false;
    }
  }
}
