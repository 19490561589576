import { ImpostoBaseStruct } from "../../structs/fiscal/imposto-base.struct"

export class PerfilImpostoRequest {
    public idPerfilImposto: number;
    public nome: string;
    public descricao: string;
    public idTipoConsumidor: number;
    public idUfOrigem: number;
    public listEmpresas: number[] = [];
    public listImpostoBaseStruct: ImpostoBaseStruct[] = [];
}
