export class IFilters {
    public filters: Filters[] = [];
    public ativo: boolean;
    public searchText: string;
    public page: number;
    public dataInicio: Date;
    public dataFim: Date;
    }
    
  export class Filters {
    public column: string;
    public value: string;
  }
  