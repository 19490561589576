import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ListPapelStruct } from '../../../shared/services/structs/pessoa/list-papel.struct';
import { MatSelectModule } from '@angular/material/select';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { Banco } from '../../../shared/services/models/financeiro/banco.model';
import { BancoResponse } from '../../../shared/services/responses/financeiro/banco.response';
import { BancoRequest } from '../../../shared/services/requests/financeiro/banco.request';
import { RoutesService } from '../../../routes.service';
import { SubheaderComponent } from '../../../shared/component/subheader/subheader.component';
import { ViewSwitcherComponent } from '../../../shared/component/view-switcher/view-switcher.component';
import { SkeletonComponent } from '../../../shared/component/skeleton/skeleton.component';
import { AdvancedTableComponent } from '../../../shared/component/advanced-table/advanced-table.component';

@Component({
  selector: 'app-banco-novo',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SubheaderComponent,
    MatFormFieldModule,
    MatSelectModule,
    ViewSwitcherComponent,
    SkeletonComponent,
    AdvancedTableComponent,
  ],
  templateUrl: './banco-novo.component.html',
  styleUrl: './banco-novo.component.css',
})
export class BancoNovoComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public financeiroService: FinanceiroService,
    private formBuilder: FormBuilder,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) { }
  public model: FormGroup;
  public requestUpdate: BancoRequest = new BancoRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idBankEnterprise: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Configurações';
  public iconProfile: string = 'settings';
  public profile: number = DefaultProfilesEnum.Configuracao;
  public listPapel: ListPapelStruct = new ListPapelStruct();
  public listBanco: Banco[];
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idBanco: [''],
      nomeBanco: ['', Validators.required],
      numBanco: ['', Validators.required],
      contas: this.formBuilder.array([this.createConta()]),
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    let idBankEnterprise: number =
      this.activatedRoute.snapshot.params['idBanco'];
    if (idBankEnterprise) {
      this._idBankEnterprise = Number(idBankEnterprise);
      this.getBankEnterprise();
    }
    this.isLoading = false;
  }

  get contas() {
    return this.model.get('contas') as FormArray;
  }

  addConta() {
    this.contas.push(
      this.formBuilder.group({
        numAgencia: ['', Validators.required],
        nomeAgencia: ['', Validators.required],
        conta: ['', Validators.required],
        digito: ['', Validators.required],
        limite: ['', Validators.required],
      })
    );
  }

  createConta(): FormGroup {
    return this.formBuilder.group({
      numAgencia: ['', Validators.required],
      nomeAgencia: ['', Validators.required],
      conta: ['', Validators.required],
      digito: ['', Validators.required],
      limite: ['', Validators.required],
    });
  }
  
  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    }

    else if (this._idBankEnterprise) {
      if (this.model.get('idBanco')?.value)
        this.requestUpdate.idBanco = this.model.get('idBanco')?.value;
      if (this.model.get('nomeBanco')?.value)
        this.requestUpdate.nomeBanco = this.model.get('nomeBanco')?.value;
      if (this.model.get('numBanco')?.value)
        this.requestUpdate.numBanco = this.model.get('numBanco')?.value;
      if (this.model.get('contas')?.value)
        this.requestUpdate.listBancoEmpresa = this.model.get('contas')?.value;
      this.requestUpdate.idEmpresa =
        this.userLoginResponse.empresaLogada.idEmpresa;
      this.update();
    }

    else {
      const request = new BancoRequest();
      if (this.model.get('idBanco')?.value)
        request.idBanco = this.model.get('idBanco')?.value;
      if (this.model.get('numBanco')?.value)
        request.numBanco = this.model.get('numBanco')?.value;
      if (this.model.get('nomeBanco')?.value)
        request.nomeBanco = this.model.get('nomeBanco')?.value;
      if (this.model.get('contas')?.value)
        request.listBancoEmpresa = this.model.get('contas')?.value;
      request.idEmpresa = this.userLoginResponse.empresaLogada.idEmpresa;
      this.register(request);
    }

  }

  register(bankEnterprise: BancoRequest) {
    this.financeiroService.bankEnterpriseRegister(bankEnterprise).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Banco registrado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_BANK]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  update() {
    this.financeiroService.bankEnterpriseUpdate(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Banco atualizado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_BANK]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getBankEnterprise() {
    this.financeiroService
      .searchBankEnterpriseById(Number(this._idBankEnterprise))
      .subscribe({
        next: (response: BancoResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }

          this.model.get('idBanco')?.setValue(response.banco.idBanco);
          this.model.get('numBanco')?.setValue(response.banco.numBanco);
          this.model.get('nomeBanco')?.setValue(response.banco.nome);

          this.contas.clear();
          response.listBancoEmpresa.forEach((conta) => {
            this.contas.push(
              this.formBuilder.group({
                numAgencia: [conta.numAgencia, Validators.required],
                nomeAgencia: [conta.nomeAgencia, Validators.required],
                conta: [conta.conta, Validators.required],
                digito: [conta.digito, Validators.required],
                limite: [conta.limite, Validators.required],
              })
            );
          });

          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show(
            'Erro inesperado',
            'Erro ao buscar o banco',
            AlertType.error
          );
          this.isLoading = false;
          return;
        },
      });
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_BANK]);
  }

  onInput(event: any, maxLength: number) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '').slice(0, maxLength);
  }
}
