import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxEditorModule, Validators } from 'ngx-editor';
import { RoutesService } from '../../routes.service';
import { DefaultColorEnum } from '../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { AuthService } from '../../shared/services/auth.service';
import { MaskService } from '../../shared/services/mask.service';
import { UserLoginResponse } from '../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../shared/services/structs/return.struct';
import { PerfilImpostoRequest } from '../../shared/services/requests/fiscal/perfil-imposto.request';
import { PerfilImpostoResponse } from '../../shared/services/responses/fiscal/perfil-imposto.response';
import { FiscalService } from '../../shared/services/API/fiscal/fiscal.service';
import { ListaSelectResponse } from '../../shared/services/responses/fiscal/lista-select.response';
import { TipoConsumidor } from '../../shared/services/models/fiscal/tipo-consumidor.model';
import { Uf } from '../../shared/services/models/fiscal/uf.model';
import { SkeletonComponent } from '../../shared/component/skeleton/skeleton.component';
import { SubheaderComponent } from '../../shared/component/subheader/subheader.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NcmSearchStruct } from '../../shared/services/structs/estoque/ncm-search-struct';
import { EstoqueService } from '../../shared/services/API/estoque/estoque.service';
import { NcmSearchResponse } from '../../shared/services/responses/estoque/ncm-search.response';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SectionProductImagesComponent } from '../../estoque/pages/estoque-novo/section-product-images/section-product-images.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SectionProductGridComponent } from '../../estoque/pages/estoque-novo/section-product-grid/section-product-grid.component';
import { CestSearchResponse } from '../../shared/services/responses/estoque/cest-search.response';
import { CestSearchStruct } from '../../shared/services/structs/estoque/cest-search.struct';
import { filter } from 'rxjs';
import { MensagemTributariaRequest } from '../../shared/services/requests/fiscal/mensagem-fiscal.request';
import { MensagemTributariaResponse } from '../../shared/services/responses/fiscal/mensagem-tributaria.response';
 

@Component({
  selector: 'app-mensagem-tributaria-novo',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule,
    MatSlideToggleModule,
    NgxEditorModule,
    MatSelectModule,
    SectionProductImagesComponent,
    MatAutocompleteModule,
    SubheaderComponent,
    ReactiveFormsModule,
    SectionProductGridComponent,
    SkeletonComponent,
    MatExpansionModule,
  ],
  templateUrl: './mensagem-tributaria-novo.component.html',
  styleUrl: './mensagem-tributaria-novo.component.css'
})
export class MensagemTributariaNovoComponent implements OnInit {
  searchControl: FormControl;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  public masks: any;
  constructor(
    private alertService: AlertService,
    public fiscalService: FiscalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) { }
  public model: FormGroup;
  public requestUpdate: MensagemTributariaRequest = new MensagemTributariaRequest();
  public _idMensagemTributaria: number | null = null;
  public dataList: any[];
  public step: number = 1;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idMensagemTributaria: [''],
      mensagem: ['', Validators.required],
      dataInicioVigencia: ['', Validators.required],
      dataFimVigencia: ['', Validators.required]
    });

    let idMensagemTributaria: number = this.activatedRoute.snapshot.params['idMensagemTributaria'];
    if (idMensagemTributaria) {
      this._idMensagemTributaria = Number(idMensagemTributaria);
      this.model.disable();
      this.getMensagemTributaria();
    }
    this.isLoading = false;
  }

  nextStep() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      return;
    }
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= 2) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else {
      if (this._idMensagemTributaria) this.requestUpdate.idMensagemTributaria = this._idMensagemTributaria;
        this.requestUpdate.mensagem = this.model.get('mensagem')?.value;
        this.requestUpdate.dataInicioVigencia = this.model.get('dataInicioVigencia')?.value;
        this.requestUpdate.dataFimVigencia = this.model.get('dataFimVigencia')?.value;

      this.update();
    }
  }

  update() {
    this.fiscalService.updateMensagemTributaria(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Mensagem tributária atualizada',
          AlertType.success
        );
        this.router.navigate([this.routesService.MENSAGEM_TRIBUTARIA]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getMensagemTributaria() {
    this.fiscalService.getMensagemTributaria(Number(this._idMensagemTributaria)).subscribe({
      next: (response: MensagemTributariaResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.model.disable();
        this.requestUpdate.idMensagemTributaria = response.mensagemTributaria.idMensagemTributaria;
        this.model.get('mensagem')?.setValue(response.mensagemTributaria.mensagem);
        this.model.get('dataFimVigencia')?.setValue(this.formatarData(response.mensagemTributaria.dataFimVigencia));
        this.model.get('dataInicioVigencia')?.setValue(this.formatarData(response.mensagemTributaria.dataInicioVigencia));
        this.model.enable();
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar perfil de imposto',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  formatarData(dataFormat: any): string {
    const data = (dataFormat instanceof Date) ? dataFormat : new Date(dataFormat);
  
    if (isNaN(data.getTime())) {
      return ''; // Ou qualquer valor adequado em caso de erro
    }
  
    const ano = data.getFullYear();
    const mes = ('0' + (data.getMonth() + 1)).slice(-2); // Meses começam do 0
    const dia = ('0' + data.getDate()).slice(-2);
  
    return `${ano}-${mes}-${dia}`; // Formato correto para o input type="date"
  }

  backPage() {
    this.router.navigate([this.routesService.MENSAGEM_TRIBUTARIA]);
  }
}

