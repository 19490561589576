import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { DateFilterComponent } from '../../../shared/component/filter-list/date-filter/date-filter.component';
import { TableComponent } from '../../../shared/component/table/table.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteSearchRequest } from '../../../shared/services/requests/pessoa/cliente-search.request';
import { ClienteSearchTableResponse } from '../../../shared/services/responses/pessoa/cliente-search-table.response';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { debounceTime, Subject } from 'rxjs';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
@Component({
  selector: 'app-list-clients',
  standalone: true,
  imports: [
    MenuComponent,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    DateFilterComponent,
    TableComponent,
    FormsModule,
    ReactiveFormsModule
],
  templateUrl: './list-clients.component.html',
  styleUrl: './list-clients.component.css'
})
export class ListClientsComponent implements OnInit{
  @ViewChild(TableComponent) table: TableComponent;
  public userLoginResponse:UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ){
    this.searchSubject.pipe(debounceTime(2000)).subscribe(value => {
      this.model.get('filterName')?.setValue(value);
      this.searchClient();
    });
    this.searchDate.pipe(debounceTime(2000)).subscribe(value => {
      this.model.get('filterDate')?.setValue(value);
      this.searchClient();
    });
  }
  public model: FormGroup;
  public color: string = DefaultColorEnum.Cor;
  public dataList: any[];
  public searchTimeout: any;
  public nameProfile: string = 'Cliente';
  public iconProfile: string = 'person';
  public profile: number = DefaultProfilesEnum.Cliente;

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterName: [''],
      filterDate: [''],
      currentPage: [1],
      totalPages: [1]
    });
    this.userLoginResponse = this.authService.getUserInfos();
    if(this.userLoginResponse.empresaLogada?.corSistema){
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.nameProfile = this.userLoginResponse.listPerfil?.find(x => x.idPerfil == this.profile)?.nomePerfil ?? this.nameProfile;
    this.iconProfile = this.userLoginResponse.listPerfil?.find(x => x.idPerfil == this.profile)?.googleIcone ?? this.iconProfile;
    this.searchClient();
  }
  
  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  onSearchValueChanged(value: string) {
    this.searchSubject.next(value);
  }

  onDateValueChanged(value: string) {
    this.searchDate.next(value);
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchClient();
    }
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchClient();
    }
  }

  searchClient() {
    this.isLoading = true;
    const searchRequest: ClienteSearchRequest = new ClienteSearchRequest();
    if (this.model.get('filterName')?.value)
      searchRequest.searchText = this.model.get('filterName')?.value;

    if (this.model.get('currentPage')?.value)
      searchRequest.page = this.model.get('currentPage')?.value;

    if (this.model.get('filterDate')?.value)
      {
        const [startDateString, endDateString] = this.model.get('filterDate')?.value.split('|');
        searchRequest.dataInicio = startDateString;
        searchRequest.dataFim = endDateString;
      }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.pessoaService.searchClientTable(searchRequest).subscribe({
        next: (response:ClienteSearchTableResponse) => {
          if (response.isError) {
            this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.dataList = response.listClienteSearchTableStruct;
          setTimeout(() => {
            this.updateColumns();
          }, 3000);
        },
        error: (error) => {
          this.alertService.show("Erro inesperado", error, AlertType.error);
          this.isLoading = false;
          return;
        }
      });
    }, 10);
  }

  updateColumns() {
    this.table.updateColumns();
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  addClient() {
    this.router.navigate(['client/client-register/']);
  }

  tableActions(emitted: any) {
    
    if(emitted.action === 'search')
    {
      this.model.get('filterName')?.setValue(emitted.search);
      this.searchClient();
    }

    else if(emitted.action === 'edit')
    {
      this.router.navigate(['client/client-register/', emitted.row.registro]);
    }

    else if(emitted.action === 'dateSearch')
    {
      this.model.get('filterDate')?.setValue(emitted.date);
      this.searchClient();
    }

    else if(emitted.action === 'remove')
    {
      const modalRef = this.dialog.open(ConfirmModalComponent, {
        data: { title: 'Atenção!',
          description: 'Deseja excluir o usuario ' + emitted.row.nome + ' ? Esta ação é irreversivel.',
          buttonConfirm: 'Excluir',
          buttonClose: 'Cancelar' 
         },
        hasBackdrop: true,
        disableClose: false
      });

      modalRef.afterClosed().subscribe((result: { confirm: boolean }) => {
        if (result.confirm) {
          this.isLoading = true;
          this.pessoaService.removeClientById(emitted.row.registro).subscribe({
            next: (response:ReturnStruct) => {
              if (response.isError) {
                this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
                this.isLoading = false;
                return;
              }
              this.isLoading = false;
              this.alertService.show("Sucesso!", "Cliente excluido", AlertType.success);
              this.searchClient();
      
            },
            error: (error) => {
              this.alertService.show("Erro inesperado", error, AlertType.error);
              this.isLoading = false;
              return;
            }
          });
        }
        else {
          this.isLoading = false;
          return;
        }
      });
    }
  }
}