export class ProdutoBaseRequest {
    idProduto?: number;
    nome: string;
    ean?: string;
    ncm?: string;
    idPerfilFiscal?: number;
    cstOrigem?: string;
    cest?: string;
    idGrupo?: number;
    idSubgrupo?: number;
    descricao?: string;
    referencia?: string;
    localizacaoFisica?: string;
    codProduto?: string;
}