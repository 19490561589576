<div *ngIf="!isLoading && currentView === 1" class="table-container">
  <div class="flex-action">
    <div class="hidden-columns-dropdown" style = "display: flex; justify-content: center; align-items: center;" >
      <div class="export-dropdown">
        <button class="cancel-btn" (click)="toggleExportMenu()" matTooltip="Exportar dados">
          <mat-icon>file_download</mat-icon>
        </button>
      
        <div class="custom-dropdown" *ngIf="exportMenuOpen">
          <button class="dropdown-item" (click)="exportTableData('csv')">
            <mat-icon>description</mat-icon> CSV
          </button>
          <button class="dropdown-item" (click)="exportTableData('excel')">
            <mat-icon>table_chart</mat-icon> Excel
          </button>
          <button class="dropdown-item" (click)="exportTableData('pdf')">
            <mat-icon>picture_as_pdf</mat-icon> PDF
          </button>
        </div>
      </div>
      <select (change)="showHiddenColumn($event)" style="height: 34px; padding: 1px 12px;">
        <option value="" selected>Mostrar colunas ocultas</option>
        <option *ngFor="let col of hiddenColumns" [value]="col">
          {{ getColumnLabel(col) }}
        </option>
      </select>
    </div>
    <button (click)="newItem()" class="main-btn">ADICIONAR NOVO</button>
    
  </div>
  <table>
    <thead>
      <tr cdkDropList cdkDragHandleDisabled (cdkDropListDropped)="reorderColumns($event)" cdkDropListOrientation="horizontal">
        <th cdkDrag *ngFor="let column of columns" style="border-right: solid 2px var(--gray-100);" [hidden]="hiddenColumns.includes(column.key)" (cdkDragStarted)="'this.isDragging = true'" (cdkDragEnded)="'this.isDragging = false'" [cdkDragData]="column">
          <div cdkDragHandle class="column-header">
            <div class="header-controls">
              <div class="flex-item">
                <mat-icon cdkDragHandle [class.drag-hidden]="'!this.isDragging'" matTooltip="Ocultar {{column.label}}" class="icon-button" (click)="toggleColumnVisibility(column.key)">visibility_off</mat-icon>
                <span cdkDragHandle class="header-title">{{ column.label }}</span>
              </div>
              <div class="flex-item" [class.drag-hidden]="'!this.isDragging'" >
                <!-- <mat-icon matTooltip="Clique para ordenar os dados" class="icon-header" *ngIf="column.sortable" (click)="sortData(column.key)">{{ orderType === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon> -->
                <mat-icon matTooltip="Ordenar por {{column.label}}" class="icon-header" *ngIf="column.sortable">{{ orderType === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
                <mat-icon [matTooltip]="isColumnFiltered(column.key) ? 'Coluna filtrada' : 'Filtrar '+column.label" *ngIf="column.filterable" class="icon-header" [class.active-filter]="isColumnFiltered(column.key)" (click)="toggleFilterDropdown(column.key)">filter_alt</mat-icon>
                <mat-icon matTooltip="Limpar filtro" *ngIf="column.filterable && isColumnFiltered(column.key)" class="icon-delete" (click)="onRemoveFilter(column.key)">
                  close
                </mat-icon>
                <mat-icon matTooltip="Segure e arraste para reordenar as colunas" cdkDragHandle style="cursor: grab;" class="icon-button" >drag_indicator</mat-icon>
              </div>
            </div>
            <div *ngIf="activeFilter === column.key"  class="filter-dropdown">
              <input [value]="getFilterValue(column.key)" (input)="onFilter($event, column.key)" placeholder="Filtrar {{column.label}}" />
            </div>
          </div>
        </th>
        <th><span class="header-title">Ações</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of filteredData">

        <td *ngFor="let column of columns" class="clickable-row" (click)="edit(row)" [hidden]="hiddenColumns.includes(column.key)">
          <span *ngIf="column.date; else normalCell">
            {{ formatDate(row[column.key]) }}
          </span>
          <ng-template #normalCell>
            {{ row[column.key] }}
          </ng-template>
        </td>
        <td class="wrapper-action" *ngIf="visualizeAction || editAction || deleteAction" >
          <mat-icon *ngIf="visualizeAction" matTooltip="Visualizar" class="icon-action" (click)="edit(row)">visibility</mat-icon>
          <mat-icon *ngIf="editAction" matTooltip="Editar" class="icon-action" (click)="edit(row)">edit</mat-icon>
          <mat-icon *ngIf="deleteAction" matTooltip="Excluir" class="icon-delete" (click)="remove(row)">delete</mat-icon>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!isLoading && currentView === 2" class="adv-card-container">
  <div class="flex-action">
    <div></div>
    <button (click)="newItem()" class="main-btn">ADICIONAR NOVO</button>
  </div>
  <div class="item-card-container">
    <div class="adv-card " (click)="edit(row)" *ngFor="let row of filteredData">
      <mat-icon class="adv-card-delete-icon" (click)="remove(row)">close</mat-icon>
      <div class="adv-card-content" >
        <div *ngFor="let column of columns">
          <ng-container [ngSwitch]="true">
            <div matTooltip="{{ column.label }}" *ngSwitchCase="column.date">
            </div>
            <h2 class="status" *ngSwitchCase="column.label === 'Status'" [ngClass]="getCardRowClass(row[column.key])">
              {{ row[column.key] }}
            </h2>
            <p class="name" *ngSwitchCase="column.label === 'Nome'">
              <b>{{ row[column.key] }}</b>
            </p>
            <p matTooltip="{{ column.label }}" *ngSwitchCase="column.label === 'CPF/CNPJ'">
              {{ row[column.key].slice(0,3) + '...' + row[column.key].slice(-2) }}
            </p>
            <p matTooltip="{{ column.label }}" *ngSwitchDefault>
              {{ row[column.key] }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="load-more-wrapper" *ngIf="!isLoading">
  <button [ngClass]="{'disabled-btn': true, 'main-btn': false}" disabled>CARREGAR MAIS</button>
</div>