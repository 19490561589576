import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaskService {

  constructor() { }

  getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    masks.email = "";
    masks.cep = "00000-000";
    return masks;
  }

  static getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    masks.email = "";
    masks.cep = "00000-000";
    return masks;
  }

  applyMask(value: string, mask: string): string {
    let result = value.replace(/\D/g, '');
    let i = 0;
    return mask.replace(/0/g, () => result[i++] || '');
  }

  validateEmail(value: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(value);
  }

}

export class Masks {
  public phone: string;
  public cpf: string;
  public cnpj: string;
  public email: string;
  public cep: string;
}