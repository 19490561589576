import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UserLoginResponse } from '../../../services/responses/auth/user-login.response';
import { DefaultColorEnum } from '../../../enum/default-color.enum';
import { PessoaService } from '../../../services/API/pessoa/pessoa.service';
import { AlertService, AlertType } from '../../../services/alert.service';
import { Subscription } from 'rxjs';
import { PreferencesService } from '../../../../preferences.service';
import { SubPerfilStruct } from '../../../services/structs/pessoa/sub-perfil.struct';
import { PerfilStruct } from '../../../services/structs/pessoa/perfil.struct';

@Component({
  selector: 'app-action-nav',
  standalone: true,
  imports: [CommonModule, RouterModule, MatCardModule, MatIconModule],
  templateUrl: './action-nav.component.html',
  styleUrl: './action-nav.component.css',
})
export class ActionNavComponent implements OnInit {
  @Output() showActionNavFalse = new EventEmitter<number>();
  @Output() toggleActionNav = new EventEmitter<void>();
  public color: string = DefaultColorEnum.Cor;
  public subColor: string = DefaultColorEnum.Cor;
  public listPerfil: PerfilStruct[];
  public listSubPerfil: SubPerfilStruct[];
  public modules: any[] = [];
  public accesColor: string = DefaultColorEnum.Acesso;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  showActionNav: boolean = false;
  private subscription!: Subscription;
  currentRoute: string = '';

  constructor(
    public authService: AuthService,
    public pessoaService: PessoaService,
    public alertService: AlertService,
    private router: Router,
    private navbarPreferenceService: PreferencesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscription = this.navbarPreferenceService.navbarState$.subscribe(
      (state: boolean) => {
        this.showActionNav = state;
      }
    );

    this.userLoginResponse = this.authService.getUserInfos();

    this.listSubPerfil = this.userLoginResponse.usuarioPermissionStruct.listSubPerfilStruct;

    this.listPerfil = this.userLoginResponse.usuarioPermissionStruct.listPerfilStruct;
    
    this.cardLogical();

    this.navbarPreferenceService.loadCorSistema();

    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });

    this.currentRoute = this.router.url;
  }

  isActive(route: string): boolean {
    let transformation: string = this.currentRoute.substring(1) + '/';
    return transformation.startsWith(route);
  }
  
  onCardClick(card: any): void {
    let filteredModule = this.listSubPerfil.find( (module) => module.idPerfil === card.idPerfil );
    if (filteredModule) {
      this.router.navigate([filteredModule.redirectSubperfil]);
    } else {
      this.alertService.show(
        'Erro!',
        'Você não possui acesso a este perfil',
        AlertType.error
      );
    }
  }

  onSubCardClick(card: any): void {
    let filteredModule = this.listSubPerfil.find( (module) => module.idSubperfil === card.idSubperfil );
    if (filteredModule) {
      this.router.navigate([filteredModule.redirectSubperfil]);
    } else {
      this.alertService.show(
        'Erro!',
        'Você não possui acesso a este perfil',
        AlertType.error
      );
    }
  }

  cardLogical() {
    this.modules = this.listPerfil.map((modulo) => {
      return {
        ...modulo,
        corDeFundo: '',
        submodules: this.listSubPerfil.filter(
          (item) => item.idPerfil === modulo.idPerfil
        ),
      };
    });
  }

  clickContainerOut() {
    this.showActionNavFalse.emit();
  }

  onToggleNav(): void {
    this.navbarPreferenceService.toggleNavbarState();
    this.showActionNav = this.navbarPreferenceService.getNavbarState();
  }

  onMouseOver(): void {
    if (!this.navbarPreferenceService.getNavbarState() && window.innerWidth > 996) //se navbar encolhida, entao expanda temporariamente
      this.showActionNav = true;
  }

  onMouseLeave(): void {
      this.showActionNav = this.navbarPreferenceService.getNavbarState(); //retorne ao estado da navbar
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
