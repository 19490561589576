<div class="main-container">
    <app-subheader 
        [icon]="iconProfile" 
        [title]="'Movimentações de estoque'" 
        [description]="'Sua visualização de movimentação'" 
        [isLoading]="isLoading" 
        [isAddPage]="false" 
        [isEditPage]="false">
    </app-subheader>
    
    <div class="container-separation" *ngIf="!isLoading">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let mov of movimentacao.listMovimentacaoStruct; let i = index" 
                [expanded]="panelOpen === i" 
                (opened)="togglePanel(i)">
                
                <mat-expansion-panel-header>
                    <div class="header-content">
                        <span class="accordion-text">{{ mov.produto.nome }}</span>
                        <span class="accordion-text in">+ {{ mov.entrada }}</span>
                        <span class="accordion-text out">- {{ mov.saida }}</span>
                    </div>
                </mat-expansion-panel-header>

                <!-- Movimentações carregadas somente quando o painel está aberto -->
                <div class="accordion-body" *ngIf="panelOpen === i">
                    <div class="movimentacoes">
                        <table>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Tipo de Movimentação</th>
                                    <th>Estoque Atual</th>
                                    <th>Estoque Anterior</th>
                                    <th>Movimentação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of mov.listMovimentacaoEstoque">
                                    <td>{{ row.dataInclusao }}</td>
                                    <td>{{ row.tipoMovimentacao }}</td>
                                    <td>{{ row.quantidadeAtual }}</td>
                                    <td>{{ row.quantidadeAnterior }}</td>
                                    <td>{{ row.quantidadeMovimentada }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
