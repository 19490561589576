import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, FormArray, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, NgxEditorModule, Toolbar, Validators } from 'ngx-editor';
import { RoutesService } from '../../../routes.service';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { StatusConta } from '../../../shared/services/models/financeiro/status-conta.model';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ContasPagarResponse } from '../../../shared/services/responses/financeiro/contas-pagar.response';
import { ListBancoEmpresaResponse } from '../../../shared/services/responses/financeiro/list-banco-empresa.response';
import { StatusContaResponse } from '../../../shared/services/responses/financeiro/status-conta.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { SubheaderComponent } from "../../../shared/component/subheader/subheader.component";
import { SkeletonComponent } from "../../../shared/component/skeleton/skeleton.component";
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TipoParcelamento } from '../../../shared/services/models/financeiro/tipo-parcelamento';
import { TipoRepeticaoEnum } from '../../../shared/enum/Financeiro/tipo-repeticao.enum';
import { TipoPagamento } from '../../../shared/services/models/financeiro/tipo-pagamento';
import { TipoRepeticao } from '../../../shared/services/models/financeiro/tipo-repeticao';
import { TipoRepeticaoResponse } from '../../../shared/services/responses/financeiro/tipo-repeticao.response';
import { TipoParcelamentoResponse } from '../../../shared/services/responses/financeiro/tipo-parcelamento.response';
import { TipoPagamentoResponse } from '../../../shared/services/responses/financeiro/tipo-pagamento.response';
import { ContasReceberRequest } from '../../../shared/services/requests/financeiro/contas-receber.request';
import { PagamentoStruct } from '../../../shared/services/structs/financeiro/pagamento.struct';
import { ContasReceberResponse } from '../../../shared/services/responses/financeiro/contas-receber.response';

@Component({
  selector: 'app-contas-receber-novo',
  standalone: true,
  imports: [MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SubheaderComponent,
    NgxEditorModule,
    MatSelectModule,
    SkeletonComponent,
  ],
  templateUrl: './contas-receber-novo.component.html',
  styleUrl: './contas-receber-novo.component.css'
})
export class ContasReceberNovoComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchControl: FormControl;
  filteredFornecedores: any[] = [];
  filteredCategories: any[] = [];
  filteredStatus: any[] = [];
  filteredBank: any[] = [];
  public step: number = 1;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService,
    public dialog: MatDialog,
    public financeiroService: FinanceiroService,
    public pessoaService: PessoaService
  ) { }
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  public model: FormGroup;
  public color: string = DefaultColorEnum.Cor;
  public _idContasReceber: number | null = null;
  public nameProfile: string = 'Financeiro';
  public iconProfile: string = 'currency_exchange';
  public profile: number = DefaultProfilesEnum.Financeiro;
  public selectedOption: any;
  public listStatus: StatusConta[];
  public listBancoEmpresaResponse: ListBancoEmpresaResponse = new ListBancoEmpresaResponse();
  public requestUpdate: ContasReceberRequest = new ContasReceberRequest();
  public listTipoParcelamento: TipoParcelamento[] = [];
  public listTipoPagamento: TipoPagamento[] = [];
  public listTipoRepeticao: TipoRepeticao[] = [];
  public isUpdate: boolean = false;
  public contasReceberResponse: ContasReceberResponse;
  ngOnInit(): void {
    this.isLoading = true;

    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idContasReceber: [''],
      idBancoEmpresa: ['', Validators.required],
      idStatusConta: [''],
      idTipoRepeticao: [''],
      idParcelamento: [''],
      valorConta: ['', Validators.required],
      descricao: ['', Validators.required],
      notaFiscal: [''],
      centroDeCusto: [''],
      dataEmissao: [''],
      valorAPagar: [{ value: 0, disabled: true }],
      divParcela: [this.formatDate(new Date)],
      valorTotal: [{ value: 0, disabled: true }],
      parcelamentos: this.formBuilder.array([]),
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    this.editor = new Editor();
    this.searchControl = new FormControl('');

    let idContasReceber: number =
      this.activatedRoute.snapshot.params['idContasReceber'];
    if (idContasReceber) {
      this._idContasReceber = Number(idContasReceber);
      this.getBillReceive();
    }

    this.getPortionType();
    this.getPaymentType();
    this.getRepetitionType();
    this.searchAccount();
  }

  submit() {
    this.isLoading = true;
    const formValue = this.model.getRawValue();
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    }
    else if (this._idContasReceber) {
      if (this.model.get('idContasReceber')?.value)
        this.requestUpdate.idContasReceber = this.model.get('idContasReceber')?.value;
      if (this.model.get('idBancoEmpresa')?.value)
        this.requestUpdate.idBancoEmpresa = this.model.get('idBancoEmpresa')?.value;
      if (this.model.get('idStatusConta')?.value)
        this.requestUpdate.idStatusConta = this.model.get('idStatusConta')?.value;
      if (this.model.get('dataEmissao')?.value)
        this.requestUpdate.dataEmissao = this.model.get('dataEmissao')?.value;
      if (this.model.get('valorConta')?.value)
        this.requestUpdate.valorConta = this.model.get('valorConta')?.value;
      if (this.model.get('idTipoRepeticao')?.value)
        this.requestUpdate.idTipoRepeticao = this.model.get('idTipoRepeticao')?.value;
      if (this.model.get('idParcelamento')?.value)
        this.requestUpdate.idTipoParcelamento = this.model.get('idParcelamento')?.value;
      if (this.model.get('notaFiscal')?.value)
        this.requestUpdate.notaFiscal = this.model.get('notaFiscal')?.value;
      if (this.model.get('descricao')?.value)
        this.requestUpdate.descricao = this.model.get('descricao')?.value;
      if (this.model.get('centroDeCusto')?.value)
        this.requestUpdate.centroDeCusto = this.model.get('centroDeCusto')?.value;
      if (this.parcelamentos.length > 0) {
        this.requestUpdate.listPagamentoStruct = this.model.get('parcelamentos')?.value.map((listPagamento: any) => {
          const pagamentoPedido = new PagamentoStruct();
          pagamentoPedido.idPagamento = listPagamento.idPagamento;
          pagamentoPedido.idTipoPagamento = listPagamento.idTipoPagamento;
          pagamentoPedido.valorPagamento = listPagamento.valor;
          pagamentoPedido.dataPrevisaoPagamento = listPagamento.previsaoPagamento
          return pagamentoPedido;
        });
      }
      this.update();
    }
    else {
      const request = new ContasReceberRequest();
      request.idContasReceber = this.model.get('idContasReceber')?.value ? this.model.get('idContasReceber')?.value : null;
      if (this.model.get('idBancoEmpresa')?.value)
        request.idBancoEmpresa = this.model.get('idBancoEmpresa')?.value;

      request.idStatusConta = 1;

      if (this.model.get('dataEmissao')?.value)
        request.dataEmissao = this.model.get('dataEmissao')?.value;
      if (this.model.get('valorConta')?.value)
        request.valorConta = this.model.get('valorConta')?.value;
      if (this.model.get('idTipoRepeticao')?.value)
        request.idTipoRepeticao = this.model.get('idTipoRepeticao')?.value;
      if (this.model.get('idParcelamento')?.value)
        request.idTipoParcelamento = this.model.get('idParcelamento')?.value;
      if (this.model.get('notaFiscal')?.value)
        request.notaFiscal = this.model.get('notaFiscal')?.value;
      if (this.model.get('descricao')?.value)
        request.descricao = this.model.get('descricao')?.value;
      if (this.model.get('centroDeCusto')?.value)
        request.centroDeCusto = this.model.get('centroDeCusto')?.value;
      if (this.parcelamentos.length > 0) {
        request.listPagamentoStruct = this.model.get('parcelamentos')?.value.map((listPagamento: any) => {
          const pagamentoPedido = new PagamentoStruct();
          pagamentoPedido.idPagamento = listPagamento.idPagamento;
          pagamentoPedido.idTipoPagamento = listPagamento.idTipoPagamento;
          pagamentoPedido.valorPagamento = listPagamento.valor;
          pagamentoPedido.dataPrevisaoPagamento = listPagamento.previsaoPagamento
          return pagamentoPedido;
        });
      }
      this.register(request);
    }
  }

  register(request: ContasReceberRequest) {
    this.financeiroService.billsReceiveRegister(request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Conta a receber registrada',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_BILLS_RECEIVE]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  update() {
    this.financeiroService.billsReceiveUpdate(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Conta a receber atualizada',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_BILLS_RECEIVE]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  get parcelamentos(): FormArray {
    return this.model.get('parcelamentos') as FormArray;
  }

  getBillReceive() {
    this.financeiroService.searchBillsReceiveById(Number(this._idContasReceber)).subscribe({
      next: (response: ContasReceberResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.contasReceberResponse = response;
        this.model.disable();
        this.model.get('idContasReceber')?.setValue(response.contasReceber.idContasReceber);
        this.model.get('idBancoEmpresa')?.setValue(response.contasReceber.idBancoEmpresa);
        this.model.get('idStatusConta')?.setValue(response.contasReceber.idStatusConta);
        this.model.get('idTipoRepeticao')?.setValue(response.contasReceber.idTipoRepeticao);
        this.model.get('idParcelamento')?.setValue(response.contasReceber.idTipoParcelamento);
        this.model.get('valorConta')?.setValue(response.contasReceber.valorTotal);
        this.model.get('descricao')?.setValue(response.contasReceber.descricao);
        this.model.get('notaFiscal')?.setValue(response.contasReceber.notaFiscal);
        this.model.get('centroDeCusto')?.setValue(response.contasReceber.centroDeCusto);
        this.model.get('dataEmissao')?.setValue(this.formatDate(new Date(response.contasReceber.dataEmissao)));
        this.model.get('valorAPagar')?.setValue(response.contasReceber.valorTotal);
        this.model.get('valorTotal')?.setValue(response.contasReceber.valorTotal);

        const parcelamentos = this.parcelamentos;
        
        response.pagamentos.forEach(pagamento => {
          const pagamentoForm = this.formBuilder.group({
            idPagamento: [null],
            idTipoPagamento: [pagamento.idTipoPagamento || ''],
            valor: [pagamento.valorPagamento || 0],
            previsaoPagamento: [pagamento.dataPrevisaoPagamento ? this.formatDate(new Date(pagamento.dataPrevisaoPagamento)) : '']
          });
      
          parcelamentos.push(pagamentoForm);
        });

        this.model.enable();
        this.model.get('valorConta')?.disable();
        this.model.get('idTipoRepeticao')?.disable();
        this.model.get('idParcelamento')?.disable();
        this.model.get('divParcela')?.disable();
        this.model.get('parcelamentos')?.disable();
        this.model.get('valorAPagar')?.disable();
        this.model.get('valorTotal')?.disable();
        
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar conta a receber',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_BILLS_RECEIVE]);
  }

  searchStatus() {
    this.isLoading = true;
    this.financeiroService.searchStatus().subscribe({
      next: (response: StatusContaResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          return;
        }
        this.listStatus = response.listStatusConta;
        this.filteredStatus = response.listStatusConta;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }
  searchAccount() {
    this.financeiroService.searchBankEnterpriseList().subscribe({
      next: (response: ListBancoEmpresaResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.listBancoEmpresaResponse = response;
        this.filteredBank = response.listBancoEmpresa;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  nextStep() {
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  setStep(step: number) {
    if (step >= this.step) {
      if (this.model.invalid) {
        return;
      }
    }
    this.step = step;
  }

  adjustArray(event: any) {
    let idTipoParcelamento = event.value;
    let i: number = this.listTipoParcelamento.find(x => x.idTipoParcelamento == idTipoParcelamento)?.valor ?? 0;
    const parcelamentos = this.parcelamentos;

    while (parcelamentos.length < i) {
      parcelamentos.push(this.AdicionarParcela());
    }

    while (parcelamentos.length > i) {
      parcelamentos.removeAt(parcelamentos.length - 1);
    }

    if (this.model.get('idTipoRepeticao')?.value) {
      this.adjustData();
    }

    this.sumPortion();

  }

  changePortionValue(index: number) {

    let valor = this.parcelamentos.at(index).get('valor')?.value;

    if (!valor || valor.toString().trim() === '') {
      valor = 0;
      this.parcelamentos.at(index).get('valor')?.setValue(valor);
    }

    this.sumPortion();
  }

  sumPortion() {
    let sum = 0;
    this.parcelamentos.controls.forEach((grupo: AbstractControl, index: number) => {
      sum = sum + grupo.get('valor')?.value;
    });
    this.model.get('valorAPagar')?.setValue(sum);
  }

  AdicionarParcela(): FormGroup {
    return this.formBuilder.group({
      idPagamento: [null],
      idTipoPagamento: [''],
      valor: [0],
      previsaoPagamento: [''],
    });
  }

  adjustData() {
    let idRepeticao = this.model.get('idTipoRepeticao')?.value;
    if (idRepeticao !== TipoRepeticaoEnum.Indefinido) {
      let data: Date = new Date();
      if (this.model.get('divParcela')?.value) {
        data = new Date(this.model.get('divParcela')?.value);
      }

      let parcelamentos = this.parcelamentos.length;

      let lenght = 0;

      while (lenght < parcelamentos) {

        if (lenght == 0) {
          this.parcelamentos.at(lenght).get('previsaoPagamento')?.setValue(this.formatDate(data));
          lenght++;
        }

        else {
          data = this.setDates(data, idRepeticao);
          this.parcelamentos.at(lenght).get('previsaoPagamento')?.setValue(this.formatDate(data));
          lenght++;
        }

      }

    }
  }

  private formatDate(data: Date): string {
    return data.toISOString().split('T')[0];
  }

  private setDates(data: Date, tipoRepeticao: number): Date {
    let novaData = new Date(data);
    if (tipoRepeticao == TipoRepeticaoEnum.Quinzenal) {
      novaData.setDate(novaData.getDate() + 15);
    }
    else if (tipoRepeticao == TipoRepeticaoEnum.Mensal) {
      novaData.setMonth(novaData.getMonth() + 1);
    }
    else if (tipoRepeticao == TipoRepeticaoEnum.Anual) {
      novaData.setFullYear(novaData.getFullYear() + 1);
    }

    return novaData;
  }

  getPortionType() {
    this.financeiroService.GetTipoParcelamento().subscribe({
      next: (response: TipoParcelamentoResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.listTipoParcelamento = response.listTipoParcelamento;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de status do pedido',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  getRepetitionType() {
    this.financeiroService.GetTipoRepeticao().subscribe({
      next: (response: TipoRepeticaoResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.listTipoRepeticao = response.listTipoRepeticao;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de status do pedido',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  getPaymentType() {
    this.financeiroService.GetTipoPagamento().subscribe({
      next: (response: TipoPagamentoResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.listTipoPagamento = response.listTipoPagamento;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de status do pedido',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  setValue(event: any) {
    this.model.get('valorTotal')?.setValue(event.target.value);
  }
}
