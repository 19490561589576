import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../common-service';
import { catchError, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { ReturnStruct } from '../../structs/return.struct';
import { PedidoRequest } from '../../requests/pedido/pedido.request';

@Injectable({
  providedIn: 'root',
})
export class OrchestratorPedidoService extends CommonService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public createPedido(request: PedidoRequest): Observable<ReturnStruct> {
    return this.httpClient
      .post<ReturnStruct>(
        environment.urlOrchestratorPedido + 'Pedido',
        request,
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }

  public updatePedido(request: PedidoRequest): Observable<ReturnStruct> {
    return this.httpClient
      .put<ReturnStruct>(
        environment.urlOrchestratorPedido + 'Pedido',
        request,
        this.addHeaderToken()
      )
      .pipe(catchError(this.handleError));
  }
}
