export class PessoaStruct{
    public idEmpresa:number;
    public idPessoa: number;
    public idGrupoEconomico: number;
    public nome: string;
    public sobrenome: string;
    public razaoSocial: string;
    public nomeFantasia: string;
    public inscricaoEstadual: string;
    public email: string;
    public cpfCnpj: string;
    public telefone: string;
    public telefone2: string;
    public nascimento: Date;
    public latitude: string;
    public longitude: string;
    public cep: string;
    public logradouro: string;
    public complemento: string;
    public numero: string;
    public cidade: string;
    public estado: string;
    public rua: string;
    public pais: string;
}