@if(showActionUser){
<div class="container-item">
    <div class="text-center user-name">
        <div class="avatar-user">
            <span class="material-symbols-outlined">account_circle</span>
        </div>
        Olá <span class="name">{{userLoginResponse.nomeUsuario}}</span>
    </div>
    <div class="section">
        <form class="form" [formGroup]="model" >
            <label for="enterpriseSelect" *ngIf="!isLoading" style="margin-bottom: 20px;">Selecione a empresa</label>
            <select id="enterpriseSelect" formControlName="enterpriseSelect"  style="color: var(--text-100)" class="select"
                (change)="changeEnterprise($event)" *ngIf="!isLoading">
                <option *ngFor="let empresa of userLoginResponse.listEmpresaStruct" [value]="empresa.idEmpresa">
                    {{ empresa.nomeEmpresa }}
                </option>
            </select>
        </form>
        <div class="spinner">
            <ng-container *ngIf="isLoading">
                <mat-spinner [diameter]="30" class="spinner-action"></mat-spinner>
            </ng-container>
        </div>
        <div class="data">
            <div class="data-button">
                <button type="button" class="button-color" mat-button (click)="redirectDataConfig()">Meus dados</button>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button type="button" mat-button (click)="clickLogout()" style="color: var(--text-100)" >Sair</button>
    </div>
</div>
<div class="container-out" (click)="clickContainerOut()"></div>
}