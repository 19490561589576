import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserLoginResponse } from '../../services/responses/auth/user-login.response';
import { AuthService } from '../../services/auth.service';
import { debounceTime, Subject } from 'rxjs';
import { FilterService } from '../../../filters.service';
import { NavigationEnd, Router } from '@angular/router';
import { PreferencesService } from '../../../preferences.service';
import autoTable from 'jspdf-autotable';

export interface Column {
  key: string;
  label: string;
  sortable: boolean;
  filterable: boolean;
  date: boolean;
  orderIndex?: number | undefined | null;
  hidden?: boolean;
}

@Component({
  selector: 'app-advanced-table',
  standalone: true,
  imports: [
    CommonModule,
    DragDropModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule
  ],
  templateUrl: './advanced-table.component.html',
  styleUrl: './advanced-table.component.css',
})
export class AdvancedTableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns: Column[] = [];
  @Input() defaultOrder: { orderBy: string; orderType: 'asc' | 'desc' } = {
    orderBy: '',
    orderType: 'asc',
  };
  @Output() valueChanged = new EventEmitter<any>();
  @Input() isLoading: boolean = false;
  @Input() newItemLink: string = '';
  @Input() editAction: boolean = false;
  @Input() visualizeAction: boolean = true;
  @Input() deleteAction: boolean = true;
  public userLoginResponse: UserLoginResponse;
  public currentView: number;

  filteredData: any[] = [];
  orderBy: string = '';
  orderType: 'asc' | 'desc' = 'asc';
  hiddenColumns: string[] = [];
  activeFilter: string | null = null;
  currentUrl: string = "";
  exportMenuOpen = false;
  isDragging = false;
  private filterSubject = new Subject<{ value: string; key: string }>();
  private filterRemove = new Subject<{ key: string }>();

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private filterService: FilterService,
    private router: Router,
    private preferencesService: PreferencesService
  ) {
    this.filterSubject.pipe(debounceTime(300)).subscribe(({ value, key }) => {
      this.filterService.addFilter({ value, column: key });
      this.filterData(value, key);
    });
    this.filterRemove.pipe(debounceTime(300)).subscribe(({ key }) => {
      this.filterService.removeFilter(key);
      this.filterData('', key);
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.filterService.clearFilters();
      }
    });
    this.preferencesService.viewState$.subscribe((view) => {
      this.currentView = view;
    });
  }

  ngOnInit() {
    this.currentUrl = this.router.url; // Captura a URL atual
    this.userLoginResponse = this.authService.getUserInfos();
    this.filteredData = this.data ? [...this.data] : [];
    this.orderBy = this.defaultOrder.orderBy;
    this.orderType = this.defaultOrder.orderType;
    this.currentView = this.preferencesService.getViewState();
    
    if (this.orderBy) {
      this.sortData(this.orderBy);
    }

    this.columns.forEach((col, index) => {
      if (col.orderIndex === undefined || col.orderIndex === null) {
        col.orderIndex = index;
      }
    });

    const savedColumns = this.preferencesService.getColumnOrder(this.currentUrl);
    if (savedColumns) {
      // Mantém a ordem salva e adiciona novas colunas que ainda não existiam
      const columnKeys = new Set(savedColumns.map(c => c.key));
      const newColumns = this.columns.filter(col => !columnKeys.has(col.key));
    
      this.columns = [...savedColumns, ...newColumns];
    }

    this.update();
  }

toggleExportMenu() {
  this.exportMenuOpen = !this.exportMenuOpen;
}

exportTableData(format: string) {
  const visibleColumns = this.columns.filter(col => !this.hiddenColumns.includes(col.key));

  if (format === 'csv') {
    this.exportToCSV(visibleColumns);
  } else if (format === 'excel') {
    this.exportToExcel(visibleColumns);
  } else if (format === 'pdf') {
    this.exportToPDF();
  }
}  

@HostListener('document:click', ['$event'])
onClickOutside(event: Event) {
  const targetElement = event.target as HTMLElement;
  if (!targetElement.closest('.export-dropdown')) {
    this.exportMenuOpen = false;
  }
}

// Exportação para CSV
exportToCSV(columns: Column[]) {
  let name = this.currentUrl.split("/")
  let csvContent = columns.map(col => col.label).join(";") + "\n";

  this.filteredData.forEach(row => {
    let rowData = columns.map(col => row[col.key] || "").join(";");
    csvContent += rowData + "\n";
  });

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  
  link.setAttribute("href", url);
  link.setAttribute("download", name[name.length-1]+".csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// Exportação para Excel
exportToExcel(columns: Column[]) {
  let name = this.currentUrl.split("/")

  let data = this.filteredData.map(row => {
    let rowData: any = {};
    columns.forEach(col => {
      rowData[col.label] = row[col.key] || "";
    });
    return rowData;
  });

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dados");

  XLSX.writeFile(workbook, name[name.length-1]+".xlsx");
}

// Exportação para PDF
exportToPDF() {
  const doc = new jsPDF(); // Criando um novo documento PDF
  let name = this.currentUrl.split("/")
  doc.text(name[name.length-1], 14, 10);

  const tableData = this.filteredData.map(row =>
    this.columns
      .filter(col => !this.hiddenColumns.includes(col.key)) // Exclui colunas ocultas
      .map(col => row[col.key])
  );

  const tableHeaders = this.columns
    .filter(col => !this.hiddenColumns.includes(col.key))
    .map(col => col.label);

  autoTable(doc, {
    head: [tableHeaders],
    body: tableData,
    startY: 20,
  });

  doc.save(name[name.length-1]+'.pdf');
}

  newItem() {
    this.router.navigate([this.newItemLink]);
  }

  ngOnChanges() {
    this.filteredData = this.data ? [...this.data] : [];
    this.cdr.detectChanges(); // Força a atualização da tabela
  }

  update(): void {
    if (this.data && this.data.length > 0) {
      this.isLoading = false;
      this.filteredData = this.data;
    }
  }

  toggleFilterDropdown(columnKey: string): void {
    this.activeFilter = this.activeFilter === columnKey ? null : columnKey;
  }

  sortData(columnKey: string) {
    if (this.orderBy === columnKey) {
      // Alterna entre asc e desc se a coluna for a mesma
      this.orderType = this.orderType === 'asc' ? 'desc' : 'asc';
    } else {
      // Define a nova coluna e ordem inicial como ascendente
      this.orderBy = columnKey;
      this.orderType = 'asc';
    }

    this.filteredData.sort((a, b) => {
      const valA = a[this.orderBy];
      const valB = b[this.orderBy];

      if (valA < valB) {
        return this.orderType === 'asc' ? -1 : 1;
      } else if (valA > valB) {
        return this.orderType === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  filterData(filter: string, key: string) {
    const filters = this.filterService.getFilters();
    this.valueChanged.emit({
      action: 'search',
      search: filter.toString().toLowerCase(),
      filters: filters,
    });
  }

  toggleColumnVisibility(columnKey: string) {
    if (this.hiddenColumns.includes(columnKey)) {
      this.hiddenColumns = this.hiddenColumns.filter(
        (key) => key !== columnKey
      );
    } else {
      this.hiddenColumns.push(columnKey);
    }
  }

  reorderColumns(event: CdkDragDrop<Column[]>) {
    const visibleColumns = this.columns.filter(
      (column) => !this.hiddenColumns.includes(column.key)
    );
  
    const previousIndex = visibleColumns.indexOf(event.item.data);
    const movedColumn = visibleColumns.splice(previousIndex, 1)[0];
    visibleColumns.splice(event.currentIndex, 0, movedColumn);
  
    // Atualiza os orderIndex para refletir a nova ordem
    visibleColumns.forEach((column, index) => {
      column.orderIndex = index;
    });
  
    // Atualiza a ordem geral das colunas, respeitando as ocultas
    this.columns = visibleColumns.concat(
      this.columns.filter((column) => this.hiddenColumns.includes(column.key))
    );
  
    // Salva a nova ordem no PreferencesService
    this.preferencesService.saveColumnOrder(this.currentUrl, this.columns);
  }

  onFilter(event: Event, key: string) {
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value || '';
    this.filterSubject.next({ value: filterValue, key });
  }

  onRemoveFilter(key: string) {
    this.filterService.removeFilter(key);
    const filters = this.filterService.getFilters();
    this.valueChanged.emit({
      action: 'search',
      search: '',
      filters: [...filters],
    });
  }

  showHiddenColumn(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const columnKey = target.value;

    if (columnKey) {
      this.hiddenColumns = this.hiddenColumns.filter(
        (key) => key !== columnKey
      );
    }
  }

  getColumnLabel(columnKey: string): string | undefined {
    return this.columns.find((column) => column.key === columnKey)?.label;
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  view(row: any) {
    this.valueChanged.emit({ action: 'edit', row: row });
  }

  edit(row: any) {
    this.valueChanged.emit({ action: 'edit', row: row });
  }

  remove(row: any) {
    this.valueChanged.emit({
      action: 'remove',
      name: this.userLoginResponse.nomeUsuario,
      row: row,
    });
  }

  isColumnFiltered(columnKey: string): boolean {
    return this.filterService
      .getFilters()
      .some((filter) => filter.column === columnKey);
  }

  getFilterValue(columnKey: string): string {
    const filter = this.filterService
      .getFilters()
      .find((f) => f.column === columnKey);
    return filter ? filter.value : '';
  }

  getCardRowClass(value: string): any {
    return {
      pending:
        value === 'Pendente' ||
        value === 'Parcialmente Pago' ||
        value === 'Em Análise' ||
        value === 'Aguardando Aprovação' ||
        value === 'Em Processo',
      success:
        value === 'Ativo' ||
        value === 'Aprovado' ||
        value === 'Pago' ||
        value === 'Estornado' ||
        value === 'Liquidado',
      warning:
        value === 'Cancelado' ||
        value === 'Em Suspensão' ||
        value === 'Inativo' ||
        value === 'Atrasado' ||
        value === 'Vencido',
    };
  }
}
