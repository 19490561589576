<div class="main-container">
    <app-subheader [icon]="'shopping_cart_checkout'" [title]="'Pedido'"
        [description]="'Seus pedidos'" [isLoading]="isLoading" [isAddPage]="true" [isEditPage]="_idPedido !== null ? true : false"></app-subheader>
    <div *ngIf="isLoading" style="display: flex; flex-direction: column; gap: 15px; max-width: 1150px; margin: 0 auto;">
        <app-skeleton variant="block" shape="card" width="100%" height="180px"
            style="padding-bottom: 30px;"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
        <app-skeleton variant="block" shape="card" width="100%" height="54px"></app-skeleton>
    </div>
    <div *ngIf="!isLoading">
        <div class="form-step">
            <div (click)="setStep(1)" [ngClass]="step === 1 ? 'step-active' : 'step-done'">
                <mat-icon [ngClass]="step >= 1 ? 'icon-item-step-active' : 'icon-item-step'">description</mat-icon>
            </div>
            <div [ngClass]="step >= 2 ? 'step-separator-done' : 'step-separator'"></div>
            <div (click)="setStep(2)" [ngClass]="step === 2 ? 'step-active' : step === 1 ? 'step-item' : 'step-done'">
                <mat-icon [ngClass]="step >= 2 ? 'icon-item-step-active' : 'icon-item-step'">local_shipping</mat-icon>
            </div>
            <div [ngClass]="step >= 3 ? 'step-separator-done' : 'step-separator'"></div>
            <div (click)="setStep(3)" [ngClass]="step === 3 ? 'step-active' : step < 3 ? 'step-item' : 'step-done'">
                <mat-icon [ngClass]="step >= 3 ? 'icon-item-step-active' : 'icon-item-step'">local_atm</mat-icon>
            </div>
        </div>
        <form [formGroup]="model">
            <div *ngIf="step === 1">
                <div class="step-title">
                    <p>PEDIDO</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group row">
                            <div class="col-6">
                                <label class="input-label" >Cliente</label>
                                    <input matInput class="input-ncm" id="cliente" type="text"
                                        [value]="clienteSelecionado?.pessoa?.nome || ''"
                                        (input)="onClienteInput($event)"
                                        [matAutocomplete]="ncmAuto"
                                        placeholder="Informe o cliente" />
                                
                                    <mat-autocomplete #ncmAuto="matAutocomplete" (optionSelected)="selecionarCliente($event.option.value)">
                                        <mat-option *ngFor="let item of filteredClientes" [value]="item">
                                            <span style="font-size: 14px"><b>{{ item.pessoa.nome }}</b></span><br />
                                            <span style="font-size: 12px">{{ item.pessoa.cpfCnpj }} | {{ item.pessoa.telefone }}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                
                                    <input type="hidden" name="idCliente" [value]="idCliente" />
                            </div>
                            <div class="col-6">
                                <label class="input-label" >Tipo de cliente</label>
                                <select class="input-ncm" formControlName="idTipoCliente" style="padding: 7px;">
                                <option value="" disabled selected>Tipo de cliente</option>
                                <option *ngFor="let consumer of listConsumerType" [value]="consumer.idTipoConsumidor">
                                    {{ consumer.nomeTipoConsumidor }}
                                </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <app-dados-produtos [model]="model" (produtosAtualizados)="atualizarProdutos($event)"></app-dados-produtos>

                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button 
                        [disabled]="!model.get('subTotal')?.value || model.get('subTotal')?.value <= 0" 
                        [ngClass]="{
                            'disabled-btn': !model.get('subTotal')?.value || model.get('subTotal')?.value <= 0,
                            'main-btn': model.get('subTotal')?.value && model.get('subTotal')?.value > 0
                        }"
                        (click)="nextStep()">
                        PRÓXIMO
                    </button>
            </div>
            </div>
            <div *ngIf="step === 2">
                <div class="step-title">
                    <p>TRANSPORTE</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group" style="padding-bottom: 18px;">
                            <div class="select-control">
                                <label class="input-label" for="idTransportadora">Transportadora</label>
                                <mat-select formControlName="idTransportadora" class="input-field select">
                                    <div class="option">
                                        <mat-option *ngFor="let carrier of listCarrier"
                                            [value]="carrier.fornecedor.idFornecedor">
                                            {{ carrier.pessoa.nome }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </div>
                        </div>
                        <div class="form-group" style="padding-bottom: 18px;">
                            <div class="input-control">
                                <label class="input-label" for="valorFrete">Valor Frete</label>
                                <input matInput id="valorFrete" formControlName="valorFrete" type="number"
                                    [ngClass]="model.get('valorFrete')?.touched && model.get('valorFrete')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="R$ 00,00" min="0.01" (input)="sumOrder()" />
                                <span *ngIf="model.get('valorFrete')?.touched && model.get('valorFrete')?.invalid">O
                                    campo Valor Frete é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="cep">CEP</label>
                                <input matInput id="cep" formControlName="cep" type="text"
                                    [ngClass]="model.get('cep')?.touched && model.get('cep')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="00000-000" (input)="onCepInput($event)" />
                                <span *ngIf="model.get('cep')?.touched && model.get('cep')?.invalid">O campo CEP é
                                    obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="logradouro">Logradouro</label>
                                <input matInput id="logradouro" formControlName="logradouro" type="text"
                                    [ngClass]="model.get('logradouro')?.touched && model.get('logradouro')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Logradouro" />
                                <span *ngIf="model.get('logradouro')?.touched && model.get('cep')?.invalid">O campo
                                    Logradouro é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="cidade">Cidade</label>
                                <input matInput id="cidade" formControlName="cidade" type="text"
                                    [ngClass]="model.get('cidade')?.touched && model.get('cidade')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Cidade" />
                                <span *ngIf="model.get('cidade')?.touched && model.get('cep')?.invalid">O campo Cidade é
                                    obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="uf">UF</label>
                                <input matInput id="uf" formControlName="uf" type="text"
                                    [ngClass]="model.get('uf')?.touched && model.get('uf')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="UF" />
                                <span *ngIf="model.get('uf')?.touched && model.get('uf')?.invalid">O campo UF é
                                    obrigatório.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="bairro">Bairro</label>
                                <input matInput id="bairro" formControlName="bairro" type="text"
                                    [ngClass]="model.get('bairro')?.touched && model.get('bairro')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Bairro" />
                                <span *ngIf="model.get('bairro')?.touched && model.get('bairro')?.invalid">O campo
                                    Bairro é obrigatório.</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="numero">Número</label>
                                <input matInput id="numero" formControlName="numero" type="text"
                                    [ngClass]="model.get('numero')?.touched && model.get('numero')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Número" #numeroEndereco />
                                <span *ngIf="model.get('numero')?.touched && model.get('numero')?.invalid">O campo
                                    Número é obrigatório.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="complemento">Complemento</label>
                                <input matInput id="complemento" formControlName="complemento" type="text"
                                    [ngClass]="model.get('complemento')?.touched && model.get('complemento')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="Complemento" />
                                <span *ngIf="model.get('complemento')?.touched && model.get('complemento')?.invalid">O
                                    campo Complemento é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button (click)="nextStep()" class="main-btn">PRÓXIMO</button>
                </div>
            </div>
            <div *ngIf="step === 3">
                <div class="step-title">
                    <p>TOTAL</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group" *ngIf="model.get('isValue')?.value == true">
                            <div class="input-control">
                                <label class="input-label" for="valorDescontoPedido">Desconto Valor</label>
                                <div class="field-discount">
                                    <input type="number" id="valorDescontoPedido" formControlName="valorDescontoPedido"
                                        placeholder="00" class="input-field" min="0" (input)="sumOrder()" />
                                    <div matSuffix class="currency-box" (click)="changeDiscountType()"><b>$</b></div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="model.get('isValue')?.value == false">
                            <div class="input-control">
                                <label class="input-label" for="porcentagemDescontoPedido">Desconto Porcentagem</label>
                                <div class="field-discount">
                                    <input type="number" id="porcentagemDescontoPedido" formControlName="porcentagemDescontoPedido"
                                        placeholder="00" class="input-field" min="0" max="100" (input)="sumOrder()" />
                                    <div matSuffix class="currency-box" (click)="changeDiscountType()"><b>%</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="valorTotal">Valor Total</label>
                                <input matInput id="valorTotal" formControlName="valorTotal" type="text"
                                    [ngClass]="model.get('valorTotal')?.touched && model.get('valorTotal')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="R$ 0,00" min="0" 
                                    [value]="formatMoney(model.get('valorTotal')?.value)"/>
                                <span *ngIf="model.get('valorTotal')?.touched && model.get('valorTotal')?.invalid">O
                                    campo Valor Total é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-title">
                    <p>PAGAMENTO</p>
                </div>
                <div class="form-flex">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="select-control">
                                <label class="input-label" for="idParcelamento">Forma De Pagamento</label>
                                <mat-select formControlName="idParcelamento" class="input-field select" (selectionChange)="adjustArray($event)">
                                    <div class="option">
                                        <mat-option *ngFor="let portion of listTipoParcelamento"
                                            [value]="portion.idTipoParcelamento">
                                            {{ portion.nome }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </div>
                        </div>
                        <div class="form-group" style="padding-bottom: 18px;">
                            <div class="select-control">
                                <label class="input-label" for="idTipoRepeticao">Repetição</label>
                                <mat-select formControlName="idTipoRepeticao" class="input-field select" (selectionChange)="adjustData()">
                                    <div class="option">
                                        <mat-option *ngFor="let repetition of listTipoRepeticao"
                                            [value]="repetition.idTipoRepeticao">
                                            {{ repetition.nomeTipoRepeticao }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group" style="padding-bottom: 18px;">
                            <div class="input-control">
                                <label class="input-label" for="divParcela">Data 1° Parcela</label>
                                <input matInput id="divParcela" formControlName="divParcela" type="date" class="input-field" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-flex">
                    <div class="flexed-wrapper">
                        <div formArrayName="parcelamentos" style="width: 100%;">
                            <div *ngFor="let parcelamento of parcelamentos.controls; let i = index" [formGroupName]="i"
                                class="form-flexed">
                                <div class="select-control" style="width: 30%;">
                                    <label class="input-label" for="parcelamento-tipo-pagamento-{{ i }}">Tipo
                                        Pagamento</label>
                                    <mat-select id="parcelamento-tipo-pagamento-{{ i }}"
                                        formControlName="idTipoPagamento" class="input-field select">
                                        <div class="option">
                                            <mat-option *ngFor="let payment of listTipoPagamento"
                                                [value]="payment.idTipoPagamento">
                                                {{ payment.nomeTipoPagamento }}
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                </div>
                                <div class="input-control" style="width: 30%;">
                                    <label class="input-label" for="parcelamento-valor-{{ i }}">Valor</label>
                                    <input type="number" id="parcelamento-valor-{{ i }}" formControlName="valor"
                                        placeholder="R$ 00,00" class="input-field" min="0" (input)="changePortionValue(i)" />
                                </div>
                                <div class="input-control" style="width: 20%;">
                                    <label class="input-label" for="parcelamento-previsao-pagamento-{{ i }}">Previsão
                                        pagamento</label>
                                    <input type="date" id="parcelamento-previsao-pagamento-{{ i }}"
                                        formControlName="previsaoPagamento" placeholder="previsaoPagamento"
                                        class="input-field" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-flex" style="padding-top: 18px;">
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="valorTotal">Valor Total</label>
                                <input matInput id="valorTotal" formControlName="valorTotal" type="number"
                                    [ngClass]="model.get('valorTotal')?.touched && model.get('valorTotal')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="R$ 00,00" min="0" />
                                <span *ngIf="model.get('valorTotal')?.touched && model.get('valorTotal')?.invalid">O
                                    campo Valor Total é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-grouped">
                        <div class="form-group">
                            <div class="input-control">
                                <label class="input-label" for="valorAPagar">Soma Parcelas</label>
                                <input matInput id="valorAPagar" formControlName="valorAPagar" type="number"
                                    [ngClass]="model.get('valorAPagar')?.touched && model.get('valorAPagar')?.invalid ? 'input-failed' : 'input-field'"
                                    placeholder="R$ 00,00" min="0" />
                                <span *ngIf="model.get('valorAPagar')?.touched && model.get('valorAPagar')?.invalid">O
                                    campo Valor Total é obrigatório.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="controller">
                    <button (click)="prevStep()" class="cancel-btn">VOLTAR</button>
                    <button (click)="submit()" class="main-btn">SALVAR</button>
                </div>
            </div>
        </form>
    </div>
</div>